<template>
  <div class="app-vehicle-block">
    <bdd-block
      v-loading="loading"
      title="Транспортное средство"
    >
      <div
        slot="button"
        class="edit-buttons"
      >
        <r-button
          v-if="vehicleEditing"
          simple
          mini
          @click="handleVehicle('discard')"
        >
          Отменить
        </r-button>
        <r-button
          :icon="vehicleEditing ? 'success' : 'edit'"
          :type="vehicleEditing ? 'success' : 'default'"
          mini
          @click="handleVehicle"
        >
          {{ vehicleEditing ? 'Сохранить' : 'Редактировать данные ТС' }}
        </r-button>
      </div>
      <attributes-list
        :is-editing="vehicleEditing"
        :model="model"
        :related="related"
        :source="application"
      />
    </bdd-block>
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import bddBlock from '../components/bdd-block'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    bddBlock,
    attributesList
  },
  props: {
    related: {
      type: Object,
      required: true
    },
    application: {
      type: Object,
      required: true
    },
    model: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      vehicleEditing: false,
      loading: false,
      clone: null
    }
  },
  computed: {
    source_id() {
      return this.$store.state.services.vehicles
    }
  },
  mounted() {
    this.clone = cloneDeep(this.application.vehicle)
  },
  methods: {
    async handleVehicle(cmd) {
      if (!this.vehicleEditing) {
        this.vehicleEditing = true
        return
      }

      if (cmd === 'discard') {
        this.vehicleEditing = false
        this.application.vehicle = this.clone
        return
      }

      this.loading = true
      const data = this.application.vehicle
      delete data.vehicle_type
      const url = `objectInfo/${this.source_id}`

      try {
        if (!data) return
        await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateApplications',
          value: true
        })
      } catch (e) {
        console.warn(e)
      } finally {
        this.vehicleEditing = false
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-items: center;

  .r-button {
    margin: 0 !important;
  }
}
</style>
