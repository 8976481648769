<template>
  <div class="an-content-layout">
    <div
      v-if="workspaceType !== 'table'"
      :class="['an-content-layout__row', { fullHeight: !workspaceType.includes('table') }]"
    >
      <an-map v-if="workspaceType.includes('map')" />
      <charts
        v-if="workspaceType.includes('chart') && chartData"
        data-zoom
        :types="chartTypes"
        :data="chartData"
      />
    </div>
    <div
      v-if="workspaceType.includes('table')"
      :class="[
        'an-content-layout__table-wrapper',
        { small: workspaceType !== 'table' }
      ]"
    >
      <r-table-list
        v-if="tableData && tableData.length"
        server-pagination="true"
        :class="['an-table-list', { totalField }, { totalColumn }, { doubleTotalField }, { lowerCaseHead }]"
        bordered
        :actions="hasActions"
        white-space="pre-wrap"
        :min-width="minWidth"
        :cell-bordered="cellBordered"
        :data="tableData"
        :pagy-response="pagyResponse"
        :custom-width="customWidth"
        :excluded-columns="['geom', 'id', 'orderTable']"
        :include-columns="includeColumns"
        :object-span-method="objectSpanMethod"
        @click-handler="tableClickHandler"
      >
        <template
          v-if="hasActions"
          v-slot:actions="{ row }"
        >
          <div class="restrictions-register-table__action-cell">
            <button
              class="restrictions-register-table__action-button"
              @click.stop="showOnMap(row)"
            >
              <r-icon
                name="focus-zone"
                :size="20"
              />
              <r-text> Показать на карте </r-text>
            </button>
          </div>
        </template>
      </r-table-list>
      <div
        v-else
        class="an-content-layout__no-data"
      >
        <r-text>
          Нет данных
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
import charts from '@/components/charts/charts'
import anMap from '../an-map/an-map'
import { reportOptions } from '../../configs'

import {
  CrossingsGeozones,
  MovementParkings,
  MovementsStat,
  RetranslationStatus,
  Inspections,
  ForecastArrival,
  PassingReportHourlyIn,
  PassingReportHourlyOut,
  PassingReportHourlyAll,
  PassingReportForPeriodIn,
  PassingReportForPeriodOut,
  PassingReportForPeriodAll,
  PassingReportForPeriodByType,
  PassingReportLoadForPeriod,
  CargoReportVehiclesReport,
  BddReport,
  ViolationsReport,
  CargoReportRailwayReport,
  BsmtsActualMapping
} from '../../controllers'

export default {
  components: {
    anMap,
    charts
  },
  data() {
    return {
      controllers: {},
      tableData: {},
      chartData: null,
      mapData: {}
    }
  },
  computed: {
    report() {
      return this.$store.state.analytics.report
    },
    typesList() {
      const { workspace } = reportOptions[this.report.value] || null

      return workspace
    },
    hasMap() {
      return !!this.typesList?.find(t => t.value?.includes('map'))
    },
    hasActions() {
      const { hasActions } = reportOptions[this.report.value] || false

      return hasActions
    },
    reportData() {
      return this.$store.state.analytics.data
    },
    pagyResponse() {
      return this.$store.state.analytics.pagyResponse || null
    },
    workspaceType() {
      return this.$store.state.analytics.workspaceType
    },
    objectSpanMethod() {
      return this.report.objectSpanMethod || null
    },
    totalField() {
      return this.report?.totalField || false
    },
    doubleTotalField() {
      return this.report?.doubleTotalField || false
    },
    totalColumn() {
      return this.report?.totalColumn || false
    },
    cellBordered() {
      return this.report.cellBordered || false
    },
    lowerCaseHead() {
      return this.report.lowerCaseHead || false
    },
    minWidth() {
      return this.report.minWidth || '80'
    },
    chartTypes() {
      return this.report.chartTypes || null
    },
    customWidth() {
      return this.report.customWidth || null
    },
    includeColumns() {
      return this.report.includeColumns || null
    }
  },
  created() {
    this.controllers.bsmts_actual_mapping = new BsmtsActualMapping(this)
    this.controllers.crossings_geozones = new CrossingsGeozones(this)
    this.controllers.movement_and_parkings = new MovementParkings(this)
    this.controllers.movements_stat = new MovementsStat(this)
    this.controllers.retranslation_status = new RetranslationStatus(this)
    this.controllers.inspections = new Inspections(this)
    this.controllers.forecast_arrival = new ForecastArrival(this)
    this.controllers.passing_report_hourly_in = new PassingReportHourlyIn(this)
    this.controllers.passing_report_hourly_out = new PassingReportHourlyOut(this)
    this.controllers.passing_report_hourly_all = new PassingReportHourlyAll(this)
    this.controllers.passing_report_for_period_in = new PassingReportForPeriodIn(this)
    this.controllers.passing_report_for_period_out = new PassingReportForPeriodOut(this)
    this.controllers.passing_report_for_period_all = new PassingReportForPeriodAll(this)
    this.controllers.passing_report_for_period_by_type = new PassingReportForPeriodByType(this)
    this.controllers.passing_report_load_for_period = new PassingReportLoadForPeriod(this)
    this.controllers.cargo_report_vehicles_report = new CargoReportVehiclesReport(this)
    this.controllers.bdd_report = new BddReport(this)
    this.controllers.violations_report = new ViolationsReport(this)
    this.controllers.cargo_report_railway_report = new CargoReportRailwayReport(this)
    this.getTableData()
  },
  methods: {
    getTableData() {
      if (this.report) {
        this.tableData = this.controllers[this.report.value].getTableData(
          this.reportData
        )
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'dataLength',
          value: this.tableData?.length
        })
        if (this.workspaceType.includes('chart')) {
          this.chartData = this.controllers[this.report.value].getChartData(
            this.reportData, this.tableData
          )
        }
      }
    },
    showOnMap(row) {
      if (!this.hasMap) return

      if (!this.workspaceType?.includes('map')) {
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'workspaceType',
          value: 'table-map'
        })
      }

      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'activeObject',
        value: row
      })
    },
    tableClickHandler(object) {
      const { geom } = object

      if (!geom) return

      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'flyToGeom',
        value: geom
      })
    }
  }
}
</script>

<style lang="scss">
.an-content-layout {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__no-data {
    width: 100%;
    text-align: center;
    padding-top: 4rem;
  }

  &__row {
    display: flex;
    height: 36vh;

    > * {
      flex: 1;
    }

    .echarts {
      height: calc(100% - 45px) !important;
    }

    &.fullHeight {
      height: 100%;
    }
  }

  &__table-wrapper {
    position: relative;
    overflow: auto;
    max-height: calc(100% - 100px);

    &.small {
      max-height: calc(100% - 36vh - 104px);
    }
  }

  .an-table-list {
    .r-table-list.el-table tr.row-header td {
      border-bottom: 1px solid !important;
      border-color: var(--dividers_low_contrast) !important;
    }
    &.totalField {
      table {
        tbody {
          tr {
            &:last-child {
              background-color: var(--table_header_bg) !important;
              cursor: initial;

              &:hover {
                td {
                  background-color: initial !important;
                }
              }
            }
          }
        }
      }
    }
    &.doubleTotalField {
      table {
        tbody {
          tr {
            &:last-child,
            &:nth-last-child(2) {
              background-color: var(--table_header_bg) !important;
              cursor: initial;
            }

            &:hover {
              td {
                background-color: initial !important;
                cursor: initial;
              }
            }
          }
        }
      }
    }
    &.totalColumn {
      table {
        tbody {
          tr {
            td {
              &:last-child {
                background-color: var(--table_header_bg) !important;
                cursor: initial;
              }
            }

            &:hover {
              td {
                &:last-child {
                  background-color: var(--table_header_bg) !important;
                  cursor: initial;
                }
              }
            }
          }
        }
      }
      &.doubleTotalField {
        table {
          tbody {
            tr {
              &:last-child,
              &:nth-last-child(2) {
                td {
                  &:last-child {
                    background-color: initial !important;
                  }
                }
              }
            }
          }
        }
      }
      &.totalField {
        table {
          tbody {
            tr {
              &:last-child {
                td {
                  &:last-child {
                    background-color: initial !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.lowerCaseHead {
      table {
        thead {
          th {
            .cell {
              text-transform: capitalize !important;
            }
          }
        }
      }
    }
  }
}
</style>
