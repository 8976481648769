import { ritmDate } from '@/utils'
import {
  getEventType,
  getEventColor
} from '../../journal/helpers/data-formatting'

export const getEventsData = (data, type) => {
  switch (type) {
    case 'car_event':
      return data.map(e => ({
        title: e.vehicle?.reg_number || e.reg_number || 'ГРЗ не определен',
        datetime: ritmDate.toFormat(e.event_time, 'DD.MM.YY • HH:mm'),
        description: e.description,
        type: e.event_type,
        speed: e.event_attrs?.speed || '-',
        speed_limit: e.event_attrs?.speed_limit || '-',
        over_speed: getOverSpeed(e.event_attrs?.speed_limit, e.event_attrs?.speed),
        color: getEventColor(e.event_type),
        error_message: e.error_message
      }))
    case 'system_event':
      return data.map(e => ({
        title: getEventType(e.worker_class),
        datetime: ritmDate.toFormat(e.end_time, 'DD.MM.YY • HH:mm'),
        description: !e.done ? 'Выполняется' : e.success ? 'Выполнено успешно' : 'Завершено с ошибкой',
        success: e.success,
        desc: e.description,
        color: getEventColor(e.worker_class),
        type: e.worker_class,
        done: e.done,
        file_name: e?.params?.file_name,
        error_message: e.error_message
      }))
    case 'cameras':
      return data.map(e => ({
        title: e.reg_number || 'ГРЗ не определен',
        desc: e.description,
        datetime: e.time
          ? ritmDate.toFormat(e.time, 'DD.MM.YY • HH:mm')
          : 'Не определено',
        color: getEventColor(e.action_type),
        description: getCameraDescription(e),
        error_message: e.error_message
      }))
  }
}

const getCameraDescription = event => {
  const { action_type, camera_source } = event
  const type = getEventType(action_type)

  return type + (camera_source ? `; Камера: ${camera_source}` : '')
}

const getOverSpeed = (limit, current) => {
  if (Number.isInteger(limit) && Number.isInteger(current)) {
    return Math.round(current - limit)
  } else {
    return '-'
  }
}
