<template>
  <div class="book-filters__content">
    <div class="book-filters__content-head">
      <r-text color-type="subhead">
        {{ $t('book-filters:info') }}
      </r-text>
    </div>

    <div class="book-filters__content-row">
      <r-text color-type="subhead">
        {{ $t('book-filters:name') }}
      </r-text>
      <el-input
        v-model="filterName"
        class="r-input"
        size="mini"
        :placeholder="$t('book-filters:name-placeholder')"
      />
    </div>

    <div class="book-filters__content-row">
      <r-text color-type="subhead">
        {{ $t('book-filters:logic-type') }}
      </r-text>
      <r-tabs v-model="logicType">
        <r-tab
          v-for="type in logicTypes"
          :id="type.id"
          :key="type.id"
          :name="type.title"
        />
      </r-tabs>
    </div>

    <div class="book-filters__content-row">
      <r-text color-type="subhead">
        {{ $t('book-filters:attribute') }}
      </r-text>
      <el-select
        v-model="attrValue"
        class="r-select"
        :placeholder="$t('book-filters:attribute-placeholder')"
        filterable
        clearable
        size="mini"
        @change="attrHandleChange"
      >
        <el-option
          v-for="item in activeFields"
          :key="item.title"
          :label="item.alias"
          :value="item.value"
        />
      </el-select>
    </div>
    <div
      v-if="attrValue"
      class="book-filters__content-row"
    >
      <r-text color-type="subhead">
        {{ $t('book-filters:type') }}
      </r-text>
      <el-select
        v-if="attrValue"
        v-model="filterType"
        class="r-select"
        :placeholder="$t('book-filters:type-placeholder')"
        size="mini"
        filterable
        clearable
        @change="searchTypeChange"
      >
        <el-option
          v-for="item in searchTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div
      v-if="filterType === 'between' && isDateType"
      class="book-filters__content-row"
    >
      <r-text color-type="subhead">
        {{ $t('book-filters:value') }}
      </r-text>
      <el-row
        type="flex"
        class="r-row"
      >
        <r-date-picker
          v-model="inputFrom"
          class="r-date-picker"
          size="mini"
          :placeholder="$t('book-filters:value-from-placeholder')"
          type="date"
          format="dd.MM.yyyy"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
        <r-date-picker
          v-model="inputTo"
          class="r-date-picker"
          size="mini"
          :placeholder="$t('book-filters:value-to-placeholder')"
          type="date"
          format="dd.MM.yyyy"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </el-row>
    </div>
    <div
      v-else-if="filterType === 'between'"
      class="book-filters__content-row"
    >
      <r-text color-type="subhead">
        {{ $t('book-filters:value') }}
      </r-text>
      <el-row
        type="flex"
        class="r-row"
      >
        <el-input
          v-model="inputFrom"
          class="r-input"
          size="mini"
          :placeholder="$t('book-filters:value-from-placeholder')"
        />
        <el-input
          v-model="inputTo"
          class="r-input"
          size="mini"
          :placeholder="$t('book-filters:value-to-placeholder')"
        />
      </el-row>
    </div>
    <div
      v-else-if="isLoadReflection"
      class="book-filters__content-row"
    >
      <r-text color-type="subhead">
        {{ $t('book-filters:value') }}
      </r-text>
      <el-select
        v-model="inputFrom"
        class="r-select"
        placeholder="Выберите значение"
        size="mini"
        :multiple="isMultiSelect"
        filterable
        clearable
      >
        <el-option
          v-for="item in reflectionListValues"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        />
      </el-select>
    </div>
    <div
      v-else-if="filterType && filterType !== '!null' && filterType !== 'null' && isDateType"
      class="book-filters__content-row"
    >
      <r-text color-type="subhead">
        {{ $t('book-filters:value') }}
      </r-text>
      <r-date-picker
        v-model="inputFrom"
        class="r-date-picker"
        size="mini"
        :placeholder="$t('book-filters:value-placeholder')"
        type="date"
        format="dd.MM.yyyy"
        :picker-options="{ firstDayOfWeek: 1 }"
      />
    </div>
    <div
      v-else-if="filterType && filterType !== '!null' && filterType !== 'null'"
      class="book-filters__content-row"
    >
      <r-text color-type="subhead">
        {{ $t('book-filters:value') }}
      </r-text>
      <el-input
        v-model="inputFrom"
        class="r-input"
        size="mini"
        :placeholder="$t('book-filters:value-placeholder')"
      />
    </div>
    <div class="book-filters__content-row right">
      <r-button
        simple
        @click="closeFilter"
      >
        {{ $t('book-filters:cancel') }}
      </r-button>
      <r-button
        type="primary"
        :disabled="!isFilterValid"
        @click="beforeCreateFilter"
      >
        {{ $t('book-filters:apply') }}
      </r-button>
    </div>
  </div>
</template>

<script>
import { generateUUID, isEmpty } from '@/utils'
import { filterTypes } from './filter-types.js'
import { attrsLinks } from '@/components/export-button/helpers'

export default {
  data() {
    return {
      active: true,
      fieldType: null,
      filterId: '',
      filterName: '',
      attrValue: '',
      filterType: '',
      inputFrom: '',
      inputTo: '',
      searchTypes: [],
      logicTypes: [{ id: 'AND', title: 'И' }, { id: 'OR', title: 'ИЛИ' }],
      logicType: 'AND',
      reflectionListValues: [],
      searchType: null
    }
  },
  computed: {
    isLoadReflection() {
      return (['=', '!=', 'in', '!in'].includes(this.searchType) && this.isReflection)
    },
    isMultiSelect() {
      return ['in', '!in'].includes(this.filterType)
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    activeBookId() {
      return this.activeBook.id || null
    },
    columns() {
      return this.activeBook.fields || null
    },
    activeFields() {
      return this.$store.state.book.activeFields?.fields?.map((f, i) => {
        return {
          id: i,
          title: f.title,
          value: f.origin_title || f.title,
          origin_title: f.origin_title,
          alias: f.alias || f.title,
          type: f.datatype || f.type || null,
          reflection: f.reflection
        }
      }) || []
    },
    isReflection() {
      return this.activeField.reflection !== null
    },
    activeField() {
      return this.activeFields.find(e => e.value === this.attrValue) || null
    },
    activeFieldType() {
      return this.activeField?.type || null
    },
    isDateType() {
      return 'datetime'.includes(this.activeFieldType?.toLowerCase())
    },
    openFilter() {
      return this.$store.state.book.filters.openFilter
    },
    existingFilters() {
      return this.$store.state.book.filters.filtersConfigs
    },
    isFilterValid() {
      if (
        isEmpty(this.inputFrom) &&
        isEmpty(this.inputTo) &&
        this.filterType !== '!null' &&
        this.filterType !== 'null'
      ) {
        return false
      }
      return true
    },
    changedRows() {
      return this.$store.state.tablePrime.changedRows.book_table || null
    },
    hasChanges() {
      if (!this.changedRows) return false
      return !!Object.values(this.changedRows)?.length
    }
  },
  mounted() {
    if (this.openFilter !== 'new') {
      const currentFilter = this.existingFilters.find(
        e => e.id === this.openFilter
      )

      this.filterId = currentFilter.id
      this.attrValue = currentFilter.field.split('.')[0]
      this.filterType = currentFilter.op
      this.inputFrom = currentFilter.value?.split('/')[0]
      this.inputTo = currentFilter.value?.split('/')[1]
      this.filterName = currentFilter.name
      this.active = currentFilter.active
      this.searchTypes = []
      this.logicType = currentFilter.type
      this.setSearchTypes()
    } else {
      this.filterId = generateUUID()
      this.attrValue = ''
      this.filterType = ''
      this.inputFrom = ''
      this.inputTo = ''
      this.searchTypes = []
      this.active = true

      this.filterName = `${this.$t('book-filters:default-name')} ${
        this.existingFilters.length + 1
      }`
    }
  },
  methods: {
    async setReflectionList() {
      try {
        const default_attribute = this.activeField.reflection.default_show_attribute
        const config = {
          only: ['id', default_attribute],
          order: default_attribute
        }
        const url = `objectInfo/${this.activeField.reflection.source_id}?array=true&config=${JSON.stringify(config)}`

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        this.reflectionListValues = data?.map(d => {
          return {
            id: d?.id,
            name: d?.[default_attribute]
          }
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    searchTypeChange(val) {
      this.searchType = val
      if (this.isLoadReflection) {
        this.setReflectionList()
      }
    },
    attrHandleChange(val) {
      this.filterType = ''
      this.inputFrom = ''
      this.inputTo = ''
      if (!val) {
        this.filterType = ''
        return
      }
      this.setSearchTypes()
    },
    setSearchTypes() {
      switch (this.activeFieldType) {
        case 'integer':
        case 'decimal':
        case 'numeric':
        case 'float':
        case 'number':
          this.searchTypes = filterTypes.numeric
          break
        case 'datetime':
        case 'date':
        case 'time':
          this.searchTypes = filterTypes.date
          break
        case 'boolean':
          this.searchTypes = filterTypes.boolean
          break
        default:
          this.searchTypes = filterTypes.string
          break
      }
    },
    beforeCreateFilter() {
      if (this.hasChanges) {
        const title = 'Предупреждение'
        const message = 'Все изменения в таблице будут отменены. Продолжить?'
        const confirm = 'Подтвердить'
        const cancel = 'Отмена'
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          type: 'warning',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirm,
          cancel
        })
          .then(() => {
            this.setTableFilter()
          })
          .catch(() => {
          })
      } else {
        this.setTableFilter()
      }
    },
    async setTableFilter() {
      const { attrValue, filterType } = this
      const opType = filterType
      const key = attrsLinks[attrValue] || attrsLinks.default
      let field = attrValue
      let filterValue

      switch (opType) {
        case 'between':
          filterValue = `${this.inputFrom}/${this.inputTo}`
          break
        default:
          filterValue = this.inputFrom
          break
      }
      if (this.isReflection) {
        field += `.${key}`
      }

      this.$store.commit('SET_TABLE_FILTER', {
        active: this.active,
        name: this.filterName,
        id: this.filterId,
        field,
        op: opType,
        type: this.logicType,
        value: filterValue,
        update: true
      })
      try {
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
        this.$store.commit('UPDATE_ACTIVE_TABLE')
        this.$store.commit('CLOSE_MODAL_WINDOW', '')
      } catch (e) {
        throw new Error(e)
      }
    },
    closeFilter() {
      this.$store.commit('CLOSE_MODAL_WINDOW', '')
    }
  }
}
</script>

<style lang="scss">
.book-filters__content {
  max-height: calc(100vh - 548px);
  min-height: calc(200px);
  overflow: auto;
  overflow-x: hidden;

  input,
  .r-select {
    width: 100%;
  }

  &-head {
    margin-bottom: 24px;
    word-break: normal;
  }

  &-row {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }

    &.right {
      display: grid;
      grid-gap: 0.5rem;
      grid-auto-flow: column;
      justify-content: end;
    }

    .r-row {
      display: grid;
      grid-gap: 1rem;
      grid-auto-flow: column;
    }
  }

  .el-date-editor.el-input {
    width: 100% !important;
  }

  .r-text {
    margin-bottom: 8px;
  }
}
</style>

<i18n>
{
  "ru": {
    "book-filters:info": "Выберите необходимые значения для фильтрации объектов",
    "book-filters:name": "Название",
    "book-filters:name-placeholder": "Название фильтра",
    "book-filters:attribute": "По какому атрибуту фильтровать",
    "book-filters:attribute-placeholder": "Не выбран",
    "book-filters:type": "Тип фильтра",
    "book-filters:logic-type": "Тип связи",
    "book-filters:type-placeholder": "Не выбран",
    "book-filters:value": "Критерий или значение",
    "book-filters:value-placeholder": "Введите критерий или значение",
    "book-filters:value-from-placeholder": "От",
    "book-filters:value-to-placeholder": "До",
    "book-filters:default-name": "Фильтр",
    "book-filters:cancel": "Отмена",
    "book-filters:apply": "Создать фильтр"
  }
}
</i18n>
