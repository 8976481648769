<template>
  <div class="closure-recommendations">
    <r-modal-card-block
      main
      icon="acts-document"
      title="Рекомендации"
      transparent
      no-border
      no-padding
      action-name="Добавить рекомендацю"
      action-icon="add-plus"
      @action="addRecommendation"
    >
      <r-modal-card-block
        v-for="(recommendation, i) in recommendations"
        :key="i"
        :title="`Рекомендация ${i + 1}`"
        delete-action
        @action="addRecommendation"
      >
        <div slot="title-button">
          <r-delete-button
            simple
            tiny
            @delete="deleteRecommendation(recommendation)"
          />
        </div>
        <attributes-list
          :model="recommendationModel"
          :source="recommendation"
          :related="related"
          is-editing
          @hasChanges="$emit('hasChanges')"
        />
      </r-modal-card-block>
    </r-modal-card-block>
    <attributes-list
      :model="model"
      :source="source.road_block"
      :related="related"
      is-editing
      @hasChanges="$emit('hasChanges')"
    />
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import recommendationModel from '../config/recommendation-model'
import { ritmDate } from '@/utils'

export default {
  components: {
    attributesList,
    rModalCardBlock
  },
  props: {
    related: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    },
    model: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      recommendationModel,
      modalCreateVisible: false
    }
  },
  computed: {
    recommendations() {
      return this.source.road_block.recommendations.filter(
        i => i.disabled !== true
      )
    }
  },
  methods: {
    addRecommendation() {
      const template = {
        road_block_id: this.source.road_block.id,
        disabled: false,
        temp_id: ritmDate.date()
      }

      const blank = this.model.reduce((a, c) => {
        if (c.model) {
          a[c.model] = null
        }
        return a
      }, template)

      this.source.road_block.recommendations.push(blank)
    },
    deleteRecommendation({ id, temp_id }) {
      const recommendation = this.source.road_block.recommendations.find(i =>
        temp_id ? i.temp_id === temp_id : i.id === id
      )
      recommendation.disabled = true
    }
  }
}
</script>
<style lang="scss">
.closure-recommendations {
  display: grid;
  grid-gap: 1rem;
}
</style>
