import { render, staticRenderFns } from "./tree-attributes.vue?vue&type=template&id=10cad39d"
import script from "./tree-attributes.vue?vue&type=script&lang=js"
export * from "./tree-attributes.vue?vue&type=script&lang=js"
import style0 from "./tree-attributes.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports