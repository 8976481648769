<template>
  <div class="an-select-modal-report-options">
    <div
      v-if="!noControl"
      class="an-select-modal-report-options__controls"
      @click="handleClick('return')"
    >
      <r-icon
        name="arrow-back"
        :size="24"
      />
      <r-title
        type="subtitle-1"
        :weight="400"
      >
        К выбору отчёта
      </r-title>
    </div>
    <div
      v-if="report"
      v-loading="loading"
      class="an-select-modal-report-options__content"
    >
      <r-title>
        {{ report.title }}
      </r-title>
      <div
        v-if="dateOption === 'date-and-time'"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__datepicker">
          <r-text> На какую дату сформировать отчёт </r-text>
          <r-date-picker
            v-model="date"
            class="r-date-picker"
            type="date"
            name="date"
            format="dd.MM.yyyy"
            :clearable="false"
            :picker-options="pickerOptions"
          />
        </div>
      </div>
      <div
        v-if="dateOption === 'date-and-time'"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__datepicker">
          <r-text> Время начала </r-text>
          <el-time-picker
            v-model="timeFrom"
            :picker-options="timeSelectableRange"
            format="HH:mm"
            placeholder="Arbitrary time"
          />
        </div>
        <div class="an-select-modal-report-options__datepicker">
          <r-text> Время окончания </r-text>
          <el-time-picker
            v-model="timeTo"
            :picker-options="timeSelectableRange"
            format="HH:mm"
            placeholder="23:59"
          />
        </div>
      </div>
      <div
        v-if="dateOption === 'date'"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__datepicker">
          <r-text> Дата начала </r-text>
          <r-date-picker
            v-model="from"
            class="r-date-picker"
            type="date"
            format="dd.MM.yyyy"
            :clearable="false"
            :picker-options="pickerOptions"
          />
        </div>
        <div class="an-select-modal-report-options__datepicker">
          <r-text> Дата окончания </r-text>
          <r-date-picker
            v-model="to"
            class="r-date-picker"
            type="date"
            format="dd.MM.yyyy"
            :clearable="false"
            :picker-options="pickerOptions"
          />
        </div>
      </div>
      <div
        v-if="dateOption === 'datetime'"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__datepicker">
          <r-text> Дата и время начала </r-text>
          <r-date-picker
            v-model="from"
            class="r-date-picker"
            type="datetime"
            name="datetime"
            format="dd.MM.yyyy HH:mm"
            :clearable="false"
            :picker-options="pickerOptions"
          />
        </div>
        <div class="an-select-modal-report-options__datepicker">
          <r-text> Дата и время окончания </r-text>
          <r-date-picker
            v-model="to"
            class="r-date-picker"
            type="datetime"
            name="datetime"
            format="dd.MM.yyyy HH:mm"
            :clearable="false"
            :picker-options="pickerOptions"
          />
        </div>
      </div>
      <div
        v-if="dateOption === 'year'"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__datepicker">
          <r-text>Выберите год</r-text>
          <r-date-picker
            v-model="singleDate"
            class="r-date-picker"
            format="yyyy"
            type="year"
            :clearable="false"
          />
        </div>
      </div>
      <div
        v-if="dateOption === 'single-date'"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__datepicker">
          <r-text>Выберите дату</r-text>
          <r-date-picker
            v-model="singleDate"
            class="r-date-picker"
            type="date"
            name="date"
            format="dd.MM.yyyy"
            :clearable="false"
            :picker-options="pickerOptions"
          />
        </div>
      </div>
      <div
        v-if="dateOption === 'mm-yy-from'"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__datepicker">
          <r-text>Выберите дату</r-text>
          <r-date-picker
            v-model="fromSingleDate"
            class="r-date-picker"
            type="month"
            format="MM.yyyy"
            :clearable="false"
            :picker-options="pickerOptions"
          />
        </div>
      </div>
      <div
        v-if="timeInterval"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__filter">
          <r-text>
            {{ timeInterval.name }}
          </r-text>
          <div class="an-select-modal-report-options__filter-wrap">
            <el-time-picker
              v-model="timeInterval.value1"
              format="HH:mm:ss"
            />
            <r-text>-</r-text>
            <el-time-picker
              v-model="timeInterval.value2"
              format="HH:mm:ss"
            />
          </div>
        </div>
      </div>
      <div
        v-for="service in servicesData"
        :key="service.id"
        class="an-select-modal-report-options__content__row"
      >
        <div class="an-select-modal-report-options__select">
          <r-text>
            {{ service.name }}
          </r-text>
          <ritm-select
            v-model="service.value"
            :options="service.data"
            :multiple="service.multiple"
          />
        </div>
      </div>
      <div
        v-for="filter in additionalFilters"
        :key="filter.name"
        class="an-select-modal-report-options__content__row"
      >
        <div
          v-if="filter.type === 'select'"
          class="an-select-modal-report-options__select"
        >
          <r-text>
            {{ filter.name }}
          </r-text>
          <el-select
            v-model="filter.value"
            class="r-select"
            placeholder="Выбрать"
            filterable
            clearable
          >
            <el-option
              v-for="item in filter.optionsList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div
          v-if="filter.type === 'checkbox'"
          class="an-select-modal-report-options__filter"
        >
          <el-checkbox
            v-model="filter.value"
            class="r-checkbox"
          >
            {{ filter.name }}
          </el-checkbox>
        </div>
        <div
          v-if="filter.type === 'number-interval'"
          class="an-select-modal-report-options__filter"
        >
          <r-text>
            {{ filter.name }}
          </r-text>
          <div class="an-select-modal-report-options__filter-wrap">
            <div class="an-select-modal-report-options__filter-inputwrap">
              <r-text>
                от
              </r-text>
              <el-input-number
                v-model="filter.value1"
                class="r-input"
                :controls="false"
              />
            </div>
            <div class="an-select-modal-report-options__filter-inputwrap">
              <r-text>
                до
              </r-text>
              <el-input-number
                v-model="filter.value2"
                class="r-input"
                :controls="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="an-select-modal-report-options__content__row">
        <r-button
          type="primary"
          :disabled="disabled || loading"
          :loading="loading"
          @click="handleClick(exportOnly ? 'export' : 'get-report')"
        >
          {{ exportOnly ? 'Выгрузить' : 'Сформировать отчёт' }}
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import vue from 'vue'
import { reportOptions } from '../../configs'
import cloneDeep from 'lodash.clonedeep'
import { saveAs } from 'file-saver'
import RitmSelect from '@/components/ritm-select/ritm-select.vue'
import { ritmDate } from '@/utils'

export default {
  components: { RitmSelect },
  props: {
    noControl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fromSingleDate: ritmDate.dateDiff(null, -365, 'd'),
      singleDate: ritmDate.date(),
      from: ritmDate.dateDiff(null, -1, 'h'),
      to: ritmDate.date(),
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > ritmDate.date()
        }
      },
      date: ritmDate.date(),
      timeFrom: new Date(2016, 9, 10, 0, 0),
      timeTo: new Date(2016, 9, 10, 23, 59),
      timeSelectableRange: {
        selectableRange: '00:00:00 - 23:59:59'
      },
      loading: false,
      servicesData: null,
      reportOptions
    }
  },
  computed: {
    pagy() {
      return this.$store.state.analytics.pagy || null
    },
    services() {
      return this.$store.state.services || null
    },
    selectedItem() {
      return this.$store.state.analytics.selectedItem || null
    },
    report() {
      return this.$store.state.analytics.report || null
    },
    reportConfig() {
      if (!this.report.value) return null

      return this.reportOptions[this.report.value] || null
    },
    dateOption() {
      if (!this.reportConfig) return 'datetime'

      return this.reportConfig.dateOption || 'datetime'
    },
    reportServices() {
      if (!this.reportConfig.services?.length) return null
      return this.reportConfig.services.map(e => {
        e.url = this.services[e.service]

        return e
      })
    },
    additionalFilters() {
      return this.reportConfig?.additionalFilters || null
    },
    timeInterval() {
      return this.reportConfig?.timeInterval || null
    },
    initialServicesData() {
      return this.$store.state.analytics.servicesData || null
    },
    disabled() {
      if (!this.from || !this.to) {
        return true
      }

      const services = this.servicesData ? Object.values(this.servicesData) : []

      return services.some(e => {
        const optional = e.optional === null ? true : e.optional
        return (optional === false && !e.value)
      })
    },
    exportOnly() {
      return this.reportConfig.exportOnly || false
    }
  },
  created() {
    if (this.initialServicesData) {
      vue.set(this, 'servicesData', this.initialServicesData)
    } else {
      this.getServicesData(this.reportServices)
    }
  },
  methods: {
    handleInput(value, instanceId) {
      const service = this.servicesData[instanceId]
      vue.set(this.servicesData, service.name, {
        ...service,
        value: value
      })
    },
    handleClick(val) {
      switch (val) {
        case 'return':
          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'modalTab',
            value: null
          })
          break
        case 'get-report':
          this.beforeSetReportUrl()
          break
        case 'export':
          this.beforeSetReportUrl(true)
          break
      }
    },
    async getServicesData(services) {
      if (!services?.length) return
      this.loading = true

      try {
        this.servicesData = {}
        const unique = {}

        return Promise.all(
          services.map(async e => {
            const options = {
              only: ['id']
            }
            options.only.push(e.serviceField)
            let serviceData

            if (e.select_store) {
              serviceData = this.$selectClientStore.getData(e.select_store).data
            } else {
              if (!unique[e.url]) {
                if (e.hardUrl) {
                  const { data } = await this.$store.dispatch('GET_REQUEST', {
                    url: e.hardUrl
                  })

                  if (Array.isArray(data)) {
                    const objectData = {}

                    data.forEach(e => {
                      objectData[e] = { id: e || '-', name: e || '-' }
                    })

                    unique[e.url] = objectData
                    serviceData = objectData
                  } else {
                    unique[e.url] = data
                    serviceData = data
                  }
                } else {
                  const result_url = (e.selectUrl || `objectInfo/${e.url}?config=${JSON.stringify(options)}`)

                  const { data } = await this.$store.dispatch('GET_REQUEST', {
                    url: result_url
                  })

                  unique[e.url] = data
                  serviceData = data
                }
              } else {
                serviceData = unique[e.url] || unique[e.id]
              }
            }

            vue.set(this.servicesData, e.name, {
              ...cloneDeep(e),
              data: Array.isArray(serviceData) ? serviceData : Object.values(serviceData)
            })
          })
        )
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    beforeSetReportUrl(exportOnly = false) {
      const datetime = {
        from_dt: '',
        to_dt: ''
      }
      const date = this.$ritmDate.toFormat(this.date, 'YYYY-MM-DD')
      const timeFrom = this.$ritmDate.toFormat(this.timeFrom, 'HH:mm')
      const timeTo = this.$ritmDate.toFormat(this.timeTo, 'HH:mm')
      const currentFromValue = `${date}T${timeFrom}`
      const currentToValue = `${date}T${timeTo}`

      switch (this.dateOption) {
        case 'date-and-time':
          datetime.from_dt = encodeURIComponent(this.$ritmDate.toIso(currentFromValue))
          datetime.to_dt = encodeURIComponent(this.$ritmDate.toIso(currentToValue))
          break
        case 'single-date':
          datetime.date = this.$ritmDate.toFormat(this.singleDate, 'YYYY-MM-DD')
          break
        case 'year':
          datetime.date = this.$ritmDate.toFormat(this.singleDate, 'YYYY')
          break
        case 'mm-yy-from':
          datetime.from = this.$ritmDate.toFormat(this.fromSingleDate, 'YYYY-MM-DD')
          break
        default:
          datetime.from_dt = encodeURIComponent(this.$ritmDate.toIso(this.from))
          datetime.to_dt = encodeURIComponent(this.$ritmDate.toIso(this.to))
          break
      }

      this.setReportUrl(datetime, exportOnly)
    },
    setReportUrl({ from_dt, to_dt, date, from }, exportOnly) {
      const reportBaseUrl = this.report?.url || ''

      if (!Array.isArray(reportBaseUrl)) {
        const services = this.servicesData
          ? Object.values(this.servicesData)
          : null

        let url = date
          ? `${reportBaseUrl}?date=${date}`
          : from
            ? `${reportBaseUrl}?from=${from}`
            : `${reportBaseUrl}?from_dt=${from_dt}&to_dt=${to_dt}`

        if (this.reportConfig.serverPagination) {
          if (this.pagy) {
            url = `${url}&page=${this.pagy.currentPage}&limit=${this.pagy.pageSize}`
          } else {
            url = `${url}&page=1&limit=25`
          }
        }

        if (this.report.value !== 'bdd_report') {
          if (services?.length) {
            services.forEach(e => {
              if (!e.value) return
              url = `${url}&${e.urlField}=${e.value}`
            })
          }
          if (this.reportConfig?.additionalProps) {
            url = `${url}&${this.reportConfig.additionalProps}`
          }
          if (this.dateOption === 'none') {
            url = `${reportBaseUrl}?${this.reportConfig.additionalProps}`
          }
        } else {
          url = this.getBddReportUrl(url, services)
        }

        if (exportOnly) {
          this.exportReport(url)
          return
        }

        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'servicesData',
          value: this.servicesData
        })
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'selectedDate',
          value: {
            from_dt: this.dateOption === 'none' ? null : from_dt,
            to_dt: this.dateOption === 'none' ? null : to_dt,
            date,
            from
          }
        })
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'reportUrl',
          value: url
        })
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'reportName',
          value: this.report.value
        })
      } else {
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'reportUrl',
          value: reportBaseUrl
        })
      }
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'activeObject',
        value: null
      })
    },
    getBddReportUrl(url, services) {
      if (this.reportConfig?.additionalProps) {
        url = `${url}&${this.reportConfig.additionalProps}`
      }
      const filters = []

      services.forEach(e => {
        if (!e.value?.length) return
        filters.push({
          field: e.urlField, op: 'in', value: e.value
        })
      })

      this.additionalFilters.forEach(e => {
        if (e.type === 'select') {
          if (!e.value) return

          filters.push({
            field: e.urlField, op: 'yes_no', value: e.value
          })
        } else if (e.type === 'number-interval') {
          if (!e.value1 && !e.value2) return

          if (e.value1 === e.value2) {
            filters.push({
              field: e.urlField, op: '=', value: e.value1
            })
          } else {
            if (e.value1) {
              filters.push({
                field: e.urlField, op: '>=', value: e.value1
              })
            }

            if (e.value2) {
              filters.push({
                field: e.urlField, op: '<=', value: e.value2
              })
            }
          }
        }
      })

      if (this.timeInterval) {
        const { value1, value2, urlField } = this.timeInterval
        if (!value1 && !value2) return
        const valueFrom = this.$ritmDate.toFormat(value1, 'HH:mm:ss')
        const valueTo = this.$ritmDate.toFormat(value2, 'HH:mm:ss')

        if (valueFrom === valueTo) {
          filters.push({
            field: urlField, op: '=', value: valueFrom
          })
        } else {
          if (value1) {
            filters.push({
              field: urlField, op: '>=', value: valueFrom
            })
          }

          if (value2) {
            filters.push({
              field: urlField, op: '<=', value: valueTo
            })
          }
        }
      }

      url = `${url}&filters=${JSON.stringify(filters)}`

      return url
    },
    async exportReport(url) {
      try {
        this.loading = true

        const { data } = await this.$store.dispatch('GET_BLOB_REQUEST', {
          url: url + '&format=xlsx'
        })

        saveAs(data, this.reportConfig.name)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.an-select-modal-report-options {
  position: absolute;
  width: 490px;
  top: 24px;
  left: 50%;
  transform: translate(-50%);
  border-radius: var(--border-radius);
  background-color: var(--modal_bg);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08);
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px 16px 16px;
  overflow: auto;

  .r-text {
    margin-bottom: 4px;
  }

  > * {
    margin-bottom: 8px !important;
  }

  &__controls {
    cursor: pointer;
    padding: 8px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid;
    border-color: var(--dividers_low_contrast);

    > * {
      &:first-child {
        margin-right: 4px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
        margin-top: 12px;
      }
    }

    &__row {
      display: flex;
      overflow: hidden;
      justify-content: space-between;

      .el-date-editor.el-input {
        width: 100% !important;
      }
    }
  }

  &__datepicker {
    width: 100%;

    &:nth-child(even) {
      margin-left: 16px;
    }
  }

  &__select {
    width: 100%;

    .el-select {
      width: 100%;
    }
  }

  &__filter {

    &-wrap  {
      display: flex;
      align-items: center;

      > * {
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }

    &-inputwrap {
      display: flex;
      align-items: center;

      > * {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
