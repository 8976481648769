<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    :icon="icon"
    :title="title"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import SimpleButton from '../buttons/simple-button'

import { jsonToGeojson } from '@/utils'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'overpasses-agpz',
      title: 'Эстакады АГПЗ',
      icon: 'overpass',
      url: 'objectInfo/74b17bce-580e-46b5-b60a-a8303474c90a',
      config: {
        mapLine: {
          type: 'line',
          layout: {},
          paint: {
            'line-width': 1.5,
            'line-color': 'rgb(0, 184, 212)'
          }
        }
      },
      layersIds: []
    }
  },
  methods: {
    async addHandler() {
      const { id, url } = this
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        if (!data) return
        const features = jsonToGeojson(Object.values(data))
        if (!features) return
        const source = this.mapgl.getSource(id)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: features
          })
          this.addLayers(id)
        } else {
          source.setData(features)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    addLayers(id) {
      const { mapLine } = this.config

      this.mapgl.addLayer({
        id: `${id}`,
        source: id,
        ...mapLine
      })
      this.layersIds = [id]
    }
  }
}
</script>
