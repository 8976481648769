<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="ksodd-map"
    title="Геозоны"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import { mapColors } from '@/config/colors'
import { getFirstSymbolId, jsonToGeojson } from '@/utils'
import SimpleButton from '../buttons/simple-button'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'bfbc88aa-6085-4a9e-9be2-c288c579c494',
      layersIds: []
    }
  },
  methods: {
    async addHandler() {
      const { id } = this

      try {
        const url = `objectInfoBodyConfig/${id}`
        const data = {
          config: { only: ['id', 'geom', 'name', 'aghk', 'agpz'] }
        }

        const response = await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })
        const geojson = jsonToGeojson(Object.values(response.data)?.filter(
          e => e.geom)
        )

        const source = this.mapgl.getSource(id)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: geojson
          })
          this.addLayers(id)
        } else {
          source.setData(geojson)
        }

        this.layersIds = [id, `${id}_border`]
      } catch (error) {
        console.warn(error)
      }
    },
    addLayers(id) {
      const index = getFirstSymbolId(this.mapgl)

      this.mapgl.addLayer(
        {
          id,
          source: id,
          type: 'fill',
          paint: {
            'fill-color': mapColors.baseColor,
            'fill-opacity': 0.2
          }
        },
        index
      )

      this.mapgl.addLayer(
        {
          id: `${id}_border`,
          source: id,
          type: 'line',
          paint: {
            'line-color': mapColors.baseColor,
            'line-width': 1
          }
        },
        index
      )
    }
  }
}
</script>
