<template>
  <div class="profiles">
    <div
      v-if="activeComponent !== 'profiles-list'"
      class="profiles__header"
    >
      <ul class="profiles__navigation">
        <li
          class="profiles__navigation-item"
          @click="changeComponent(previousComponent)"
        >
          <span class="r-icon-arrow left" />
          <r-text color-type="accent-primary">
            {{ $t(`profiles:nav-item:profiles-menu`) }}
          </r-text>
        </li>
      </ul>
    </div>
    <div class="profiles__title">
      <r-text>{{ $t('profiles:title') }}</r-text>
    </div>
    <component
      :is="activeComponent"
      v-if="activeComponent === 'profiles-list'"
      :node-click-handler="changeComponent"
    />
    <component
      :is="activeComponent"
      v-else
    />
    <el-tooltip
      v-if="activeComponent === 'profiles-list'"
      :open-delay="1500"
      :content="`${$t('profiles:create-button:tooltip')}`"
      placement="bottom"
    >
      <r-button
        class="profiles__create-button"
        icon="add-plus"
        @click="beforeCreateNewProfile"
      >
        {{ $t('profiles:create-button') }}
      </r-button>
    </el-tooltip>
  </div>
</template>

<script>
import profilesList from './components/profiles-list'
import profilesSettings from './components/profiles-settings'

import { notifyFactory } from '@/utils'

export default {
  components: {
    profilesList,
    profilesSettings
  },
  data() {
    return {
      activeComponent: '',
      previousComponent: ''
    }
  },
  created() {
    this.activeComponent = 'profiles-list'
  },
  methods: {
    changeComponent(i) {
      switch (i) {
        case 'profiles-list':
          this.previousComponent = ''
          break
        default:
          this.previousComponent = 'profiles-list'
          break
      }
      this.activeComponent = i
    },
    validateNewProfile(val) {
      if (!val || !val.trim().length) {
        return false
      } else {
        return true
      }
    },
    beforeCreateNewProfile() {
      const title = this.$t('profiles:create-message:title')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')
      const inputErrorMessage = this.$t('profiles:create-message:invalid')

      this.$prompt(title, '', {
        confirmButtonText,
        cancelButtonText,
        inputValidator: this.validateNewProfile,
        inputErrorMessage
      })
        .then(({ value }) => {
          this.createNewProfile(value)
        })
        .catch(() => {})
    },
    async createNewProfile(profileName) {
      this.isCreating = true
      const options = {
        url: 'user/profile?',
        data: { name: profileName }
      }
      try {
        await this.$store.dispatch('POST_REQUEST', options)
        const user = await this.$store.dispatch('GET_REQUEST', { url: 'user' })
        this.$store.commit('SET_PROFILES_TREE', user.data.user_profiles)
        const title = this.$t('profiles:notify:title')
        const message = this.$t('profiles:create-message:success')
        this.$notify(notifyFactory('info', title, message))
      } catch (e) {
        console.warn(e)
      }
      this.isCreating = false
    }
  }
}
</script>

<style lang="scss">
.profiles {
  position: relative;
  &__create-button {
    position: absolute;
    top: 0;
    right: 1rem;
  }
  &__header {
    margin: 0;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__navigation {
    margin-bottom: 16px;
    width: auto;
    display: flex;
    &-item {
      width: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      cursor: pointer;
      &:hover {
        filter: brightness(1.15);
      }
    }
    i {
      &.r-icon-arrow {
        &:before {
          border-color: var(--accent_primary) !important;
        }
      }
    }
  }
}
.profiles__component {
  position: relative;
  &-title {
    height: 44px;
  }
  &-content {
    width: 500px;
    position: relative;
    max-height: 50vh;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
  }
  &-subtitle {
    padding-bottom: 8px;
  }
  &-row {
    margin-bottom: 12px;
    .r-col {
      padding: 0 8px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "profiles:title": "Настройки профилей",
    "profiles:create-button": "Создать профиль",
    "profiles:create-button:tooltip": "Создать новый профиль",
    "profiles:nav-item:profiles-menu": "Меню профилей",

    "profiles:create-message:title": "Введите имя нового профиля",
    "profiles:create-message:invalid": "Некорректное имя профиля",
    "profiles:create-message:success": "Новый профиль успешно создан!",
    "profiles:notify:title": "Уведомление",

    "button-confirm": "Создать",
    "button-cancel": "Отмена"
  },
  "en": {
    "profiles:title": "Profiles settings",
    "profiles:create-button": "Create profile",
    "profiles:create-button:tooltip": "Create new profile",
    "profiles:nav-item:profiles-menu": "Profiles menu",
    "profiles:notify:title": "Notification",

    "profiles:create-message:title": "Enter profile name",
    "profiles:create-message:invalid": "Name is not valid",
    "profiles:create-message:success": "New profile created successfully!",

    "button-confirm": "Create",
    "button-cancel": "Cancel"
  }
}
</i18n>
