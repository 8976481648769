const include = {
  event_class: { only: ['name'] },
  contractor: { only: ['name'] }
}

const except = [
  'id',
  'created_by',
  'updated_by',
  'event_class_id',
  'event_type_id',
  'contractor_id',
  'link_id',
  'disabled',
  'geom'
]

const order = [{ created_at: 'desc' }]

export const buildUrl = (source_id, filters) => {
  const config = JSON.stringify({
    ...filters,
    order,
    include
  })
  return `objectInfo/${source_id}?array=true&config=${config}`
}

export const buildExportUri = (source_id, filters) => {
  const config = JSON.stringify({
    ...filters,
    order,
    include,
    except
  })

  return `objectInfo/${source_id}?config=${config}`
}
