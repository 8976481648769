import cloneDeep from 'lodash.clonedeep'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import { Constants, CommonSelectors } from '@/libs/map-draw/lib-utils'
import {
  getPointFeatures,
  createHoveredSPHelper,
  removeHoveredSPHelpers,
  createRouteHelper,
  clearAllHelpers,
  createRoutePoints,
  clearAllRouteGeometry,
  getWaypointsQuery
} from '@/libs/map-draw/helpers'

export const createEEDrawLinesMode = (MapboxDraw, editor, component) => {
  const DrawLines = {}
  const customState = {
    currentPoint: null,
    routePoints: [],
    currentRouteCoordinates: [],
    fixedRouteCoordinates: [],
    lineRouteItems: []
  }

  const clearCustomState = () => {
    customState.routePoints = []
    customState.currentPoint = null
    customState.currentRouteCoordinates = []
    customState.fixedRouteCoordinates = []
    customState.lineRouteItems = []
  }
  const showError = throttle(() => {
    component.$message({
      message:
        'Не удается построить дальнейший маршрут из последней указанной точки',
      type: 'error',
      duration: 1500,
      customClass: 'route-editor-warning'
    })
  }, 1000)

  DrawLines.onKeyUp = async function(state, e) {
    const cancelDrawing = () => {
      this.deleteFeature([state.line.id], { silent: true })
      editor.isDrawing = false
      clearCustomState()
      clearAllRouteGeometry(this.map)
      this.updateUIClasses({ mouse: 'add' })
      component.$store.commit('SET_PT_STATE_PROP', {
        name: 'activeCard',
        value: false
      })
      component.$store.commit('SET_PT_STATE_PROP', {
        name: 'overlay',
        value: false
      })

      return this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.line.id]
      })
    }
    if (CommonSelectors.isEscapeKey(e)) {
      cancelDrawing()
    }

    const { routePoints } = customState
    const { code, ctrlKey } = e
    if (code === 'KeyZ' && ctrlKey) {
      if (routePoints.length === 0) return
      else if (routePoints.length === 1) {
        cancelDrawing()
        return
      } else if (routePoints.length === 2) {
        customState.routePoints = [routePoints[0]]
        clearAllRouteGeometry(this.map)
        createRoutePoints(
          this.map,
          customState.routePoints.map(c => c.coordinates)
        )
        clearAllHelpers(this.map)
        return
      }

      const points = customState.routePoints.slice(0, -1)
      const locString = getWaypointsQuery(cloneDeep(points))
      const url = `pubtran_route/${
        editor.modelId
      }/${locString}?postprocessing=line_route_item`
      const { data } = await component.$store.dispatch('ROUTE_REQUEST', {
        url
      })
      const { geometry, line_route_items } = data

      line_route_items.forEach(item => {
        delete item.node
        delete item.stop_point
      })

      customState.routePoints = points
      customState.currentRouteCoordinates = geometry.coordinates
      customState.fixedRouteCoordinates = cloneDeep(geometry.coordinates)
      customState.lineRouteItems = line_route_items

      createRouteHelper(this.map, geometry.coordinates, false, true)
      createRoutePoints(
        this.map,
        customState.routePoints.map(c => c.coordinates)
      )
    }

    if (code === 'Enter') {
      if (routePoints.length < 2) return

      const { fixedRouteCoordinates } = customState

      customState.currentRouteCoordinates = cloneDeep(fixedRouteCoordinates)
      createRouteHelper(this.map, fixedRouteCoordinates, false, true)

      this.finishRoute(state)
    }
  }

  DrawLines.makeRoutingRequest = debounce(async function(currentPoints) {
    try {
      const locString = getWaypointsQuery(cloneDeep(currentPoints))
      const url = `pubtran_route/${
        editor.modelId
      }/${locString}?postprocessing=line_route_item`

      const { data } = await component.$store.dispatch('ROUTE_REQUEST', {
        url
      })
      const { geometry, line_route_items } = data

      line_route_items.forEach(item => {
        delete item.node
        delete item.stop_point
      })

      customState.currentRouteCoordinates = geometry.coordinates
      customState.lineRouteItems = line_route_items

      createRouteHelper(this.map, geometry.coordinates, false, true)
    } catch (error) {
      console.warn(error)
      showError(component)
    }
  }, 100)

  DrawLines.finishRoute = function(state) {
    const {
      routePoints,
      currentRouteCoordinates,
      lineRouteItems
    } = customState

    editor.isDrawing = false
    state.line.setCoordinates(currentRouteCoordinates)
    clearCustomState()

    state.line.setProperty('line_route_items', lineRouteItems)
    state.line.setProperty('waypoints', routePoints.map(({ id }) => id))

    // attributes editing helper
    editor.creatingCallback = () => {
      clearAllRouteGeometry(this.map)
      this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.line.id]
      })
    }
    removeHoveredSPHelpers(this.map)
    editor.activeObject = state.line
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCard',
      value: true
    })
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'overlay',
      value: true
    })
  }

  DrawLines.onMouseMove = function(state, e) {
    const nodeFeatures = getPointFeatures(this.map, editor, e, 'nodes', true)
    const { routePoints, fixedRouteCoordinates } = customState

    if (nodeFeatures.length) {
      const feature = nodeFeatures[0]
      const { properties } = feature
      const { coordinates } = feature.geometry

      if (
        !customState.currentPoint ||
        customState.currentPoint !== feature.properties.id
      ) {
        createHoveredSPHelper(this.map, coordinates, 'green')
        customState.currentPoint = feature.properties.id

        if (routePoints.length > 0) {
          const currentPoints = [
            ...cloneDeep(routePoints),
            cloneDeep({
              coordinates,
              id: properties.id
            })
          ]
          this.makeRoutingRequest(currentPoints)
        }
      }
    } else {
      if (routePoints.length > 1) { createRouteHelper(this.map, fixedRouteCoordinates, false, true) } else createRouteHelper(this.map, [], false, true)

      removeHoveredSPHelpers(this.map)
      customState.currentPoint = null
    }

    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    }
  }

  DrawLines.clickAnywhere = function(state, e) {
    const nodesFeatures = getPointFeatures(this.map, editor, e, 'nodes', true)
    const { routePoints, currentRouteCoordinates } = customState

    if (!nodesFeatures.length) return

    if (routePoints.length === 0) editor.isDrawing = true

    const point = nodesFeatures[0]
    const pointId = point.properties.id
    const pointCoordinates = point.geometry.coordinates
    const lastPoint = routePoints[routePoints.length - 1] || {}
    const lastCoords = lastPoint.coordinates

    if (routePoints.length > 0) {
      if (
        pointCoordinates[0] === lastCoords[0] &&
        pointCoordinates[1] === lastCoords[1]
      ) {
        this.finishRoute(state)

        return
      }
    }

    routePoints.push({ coordinates: pointCoordinates, id: pointId })
    customState.fixedRouteCoordinates = cloneDeep(currentRouteCoordinates)
    createRoutePoints(this.map, routePoints.map(c => c.coordinates))

    this.updateUIClasses({ mouse: 'add' })
  }

  return { ...MapboxDraw.modes.draw_line_string, ...DrawLines }
}
