import debounce from 'lodash.debounce'
import {
  getEditorRequestConfig,
  jsonToGeojson,
  getFirstSymbolId,
  modifyLinksGeometry
} from '@/utils'
import { layersConfig } from '../configs'
import { getSignsFeatures, getRackFeatures } from '../helpers/index'

const LAYERS = ['events', 'signs']

export class BaseController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
    this.handlers = {}
  }

  get layers() {
    const layers = []
    if (this.parent.showSings) {
      layers.push('signs_symbols')
    }

    if (this.parent.showEvents) {
      layers.push(...['events_points', 'events_lines'])
    }
    return layers
  }

  removeBaseLayers() {
    LAYERS.forEach(type => {
      switch (type) {
        case 'signs':
          this.removeSingsLayer()
          break
        case 'events':
          this.removeEventsLayer()
          break
      }
    })
  }

  removeSingsLayer() {
    const id = 'e010924a-220b-4ecf-b423-9e756ff1d18e'

    const layers = ['racks_connections', 'racks_points', 'signs_symbols', 'racks']

    layers.forEach(l => {
      if (this.mapgl.getLayer(l)) {
        this.mapgl.removeLayer(l)
      }
    })
    if (this.mapgl.getSource(id)) {
      this.mapgl.removeSource(id)
    }
    if (this.mapgl.getSource('racks')) {
      this.mapgl.removeSource('racks')
    }
  }

  removeEventsLayer() {
    const { ids } = this.$store.state.odd.model
    const id = ids.events || null

    if (!id) return

    const layers = ['events_points', 'events_lines', 'events_lines_icons', 'events_arrows']

    layers.forEach(l => {
      if (this.mapgl.getLayer(l)) {
        this.mapgl.removeLayer(l)
      }
    })
    if (this.mapgl.getSource(id)) {
      this.mapgl.removeSource(id)
    }
  }

  addBaseLayers() {
    const { ids } = this.$store.state.odd.model

    // set signs manually
    ids.signs = 'e010924a-220b-4ecf-b423-9e756ff1d18e'
    ids.sign_icons = '3c11eb14-f35f-4bc7-b03f-997aaa477959'

    LAYERS.forEach(type => {
      if (type === 'signs' && this.parent.showSings) {
        this.addLayerByType(type, ids)
      } else {
        this.removeSingsLayer()
      }
      if (type === 'events' && this.parent.showEvents) {
        this.addLayerByType(type, ids)
      } else {
        this.removeEventsLayer()
      }
    })

    // map mouse move and click handlers
    this.mapgl.on('mousemove', e => {
      const { name } = this.parent.$route
      if (name === 'odd-create' || name === 'odd-router-create') return
      const layers = this.layers

      const { x, y } = e.point
      const bbox = [
        [x - 5, y - 5],
        [x + 5, y + 5]
      ]
      const features = this.mapgl.queryRenderedFeatures(bbox, {
        layers
      })

      if (!features.length && this.parent.popupListSettings.display !== 'none') {
        this.parent.popupListSettings.display = 'none'
        this.parent.popupListSettings.values = []
      }
    })

    this.mapgl.on('click', e => {
      const { name } = this.parent.$route
      if (name === 'odd-create' || name === 'odd-router-create') return
      const layers = this.layers

      const { x, y } = e.point
      const bbox = [
        [x - 5, y - 5],
        [x + 5, y + 5]
      ]
      const features = this.mapgl.queryRenderedFeatures(bbox, {
        layers
      })

      if (!features.length) return
      if (features.length > 1) {
        this.parent.popupListSettings.top = y - 38
        this.parent.popupListSettings.left = x - 10
        this.parent.popupListSettings.display = 'block'
        this.parent.popupListSettings.values = features
      } else {
        const { properties } = features[0]
        const type = properties.layerType || null

        this.openCard(type, properties.id)
      }
    })
  }

  addLayerByType(type, ids) {
    const id = ids[type]
    this.toggleRacks()

    const loadModelLayer = async e => {
      if (e && e.noRequest) return
      const zoom = this.mapgl.getZoom()
      const config = getEditorRequestConfig(this.parent)

      // edit config
      if (type === 'signs') {
        config.only.push('name', 'sign_icon_id', 'projection', 'rack_position')
        config.include = {
          sign_icon: {
            only: ['id', 'resource_id']
          },
          rack: {
            only: ['id', 'geom', 'projection']
          }
        }
      }

      if (type === 'events') {
        config.include = {
          event_class: {
            only: ['id', 'name']
          }
        }
        config.only.push('name', 'event_class_id')

        const { where } = this.getEventsFilter()

        if (where) {
          config.where = where
        }
      }

      try {
        this.$store.commit('ADD_ODD_LOADING_LAYER', id)
        const url = `objectInfo/${id}?scope=active&config=${JSON.stringify(
          config
        )}&zoom=${zoom}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        let features = Object.values(data)

        if (type === 'signs') {
          if (!this.parent.showSings) return
          this.addSignsLayers(id, features)
        } else {
          if (type === 'events') {
            if (!this.parent.showEvents) return
            features = features.map(f => ({
              ...f,
              event_class_name: f.event_class ? f.event_class.name : null
            }))
          } else if (type === 'links') {
            features = modifyLinksGeometry(features)
          }

          const geojson = jsonToGeojson(features.map(e => {
            return {
              ...e,
              layerType: 'events'
            }
          }))

          if (!this.mapgl.getSource(id)) {
            this.mapgl.addSource(id, {
              type: 'geojson',
              data: geojson
            })

            if (type === 'nodes' || type === 'links') {
              const options = {
                id,
                source: id,
                ...layersConfig[type]
              }

              this.mapgl.addLayer(options, getFirstSymbolId(this.mapgl))
            } else if (type === 'events') {
              this.addEventsLayers(id)
            }
          } else {
            this.mapgl.getSource(id).setData(geojson)
          }
        }

        this.$store.commit('REMOVE_ODD_LOADING_LAYER', id)
      } catch (error) {
        this.$store.commit('REMOVE_ODD_LOADING_LAYER', id)
      }
    }

    // add handlers
    if (type === 'signs') {
      this.addLayerHandler(type, 'signs_symbols')
    }

    if (type === 'events') {
      this.addLayerHandler(type, 'events_points')
      this.addLayerHandler(type, 'events_lines')
      this.addLayerHandler(type, 'events_lines_icons')
      this.addLayerHandler(type, 'events_arrows')
    }

    this.handlers[id] = debounce(loadModelLayer, 200)
    this.handlers[id]()
    this.mapgl.on('moveend', this.handlers[id])
  }

  async toggleRacks() {
    if (!this.parent.showSings) return

    try {
      const config = {
        where: [
          {
            field: 'geom',
            op: '!=',
            value: null
          }
        ],
        include: {
          signs: {}
        }
      }
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: `objectInfo/telemetry.racks?scope=active&config=${JSON.stringify(config)}`
      })

      this.$store.commit('SET_ODD_FIELD', {
        field: 'racks',
        value: data
      })
      const modifiedFeatures = getRackFeatures(Object.values(data))

      const geojson = {
        type: 'FeatureCollection',
        features: modifiedFeatures
      }

      const id = 'racks'
      const index = getFirstSymbolId(this.mapgl)

      if (!this.mapgl.getSource(id)) {
        this.mapgl.addSource(id, {
          type: 'geojson',
          data: geojson
        })

        this.mapgl.addLayer({
          id: 'racks_connections',
          source: id,
          ...layersConfig.signs_connections,
          filter: ['==', '$type', 'LineString']
        }, index)
        this.mapgl.addLayer({
          id: 'racks_points',
          source: id,
          ...layersConfig.signs_points,
          filter: ['==', ['get', 'type'], 'points']
        }, index)

        this.mapgl.addLayer({
          id,
          source: id,
          filter: ['==', ['get', 'type'], 'symbols'],
          ...layersConfig.racks
        }, index)
      } else {
        this.mapgl.getSource(id).setData(geojson)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  getEventsFilter() {
    const config = {}
    const { category, interval } = this.parent.eventsFilter
    const { eventClasses } = this.$store.state.odd?.books || []

    if (interval?.active) {
      const { from, to } = interval.prop.interval
      const validFrom = this.parent.$ritmDate.toIso(from)
      const validTo = this.parent.$ritmDate.toIso(to)

      if (from || to) {
        if (!config.where) {
          config.where = []
        }

        if (from) {
          config.where.push(
            { field: 'end_time', value: validFrom, op: '>' }
          )
        }

        if (to) {
          config.where.push(
            { field: 'start_time', value: validTo, op: '<' }
          )
        }
      }
    }

    if (category && category !== 'Все') {
      const eventId = eventClasses.find(ev => ev.name === category)?.id
      if (eventId) {
        if (!config.where) {
          config.where = []
        }
        config.where.push(
          { field: 'event_class_id', value: eventId, op: '=' }
        )
      }
    }

    return config
  }

  addEventsLayers(id) {
    this.mapgl.addLayer({
      id: 'events_points',
      source: id,
      filter: ['==', '$type', 'Point'],
      ...layersConfig.events_points
    })
    this.mapgl.addLayer({
      id: 'events_lines',
      source: id,
      filter: ['==', '$type', 'LineString'],
      ...layersConfig.events_lines
    })
    this.mapgl.addLayer({
      id: 'events_lines_icons',
      source: id,
      filter: ['==', '$type', 'LineString'],
      ...layersConfig.events_lines_icons
    })
    this.mapgl.addLayer({
      id: 'events_arrows',
      source: id,
      filter: ['==', '$type', 'LineString'],
      ...layersConfig.events_arrows
    })
  }

  addSignsLayers(id, features) {
    const modifiedFeatures = getSignsFeatures(features)

    const geojson = {
      type: 'FeatureCollection',
      features: modifiedFeatures
    }

    if (!this.mapgl.getSource(id)) {
      this.mapgl.addSource(id, {
        type: 'geojson',
        data: geojson
      })
      this.mapgl.addLayer({
        id: 'signs_symbols',
        source: id,
        ...layersConfig.signs_symbols,
        filter: ['==', ['get', 'type'], 'symbols']
      })
    } else {
      this.mapgl.getSource(id).setData(geojson)
    }
  }

  updateLayers() {
    const layers = ['signs', 'events']
    const { ids } = this.$store.state.odd.model

    layers.forEach(type => {
      const id = ids[type]

      if (this.handlers[id]) {
        this.handlers[id]()
      }
    })

    this.toggleRacks()
  }

  addLayerHandler(type, layerId) {
    this.mapgl.on('mousemove', layerId, () => {
      const { name } = this.parent.$route

      if (name === 'odd-create' || name === 'odd-router-create') return

      this.mapgl.getCanvas().style.cursor = 'pointer'
    })

    this.mapgl.on('mouseleave', layerId, () => {
      const { name } = this.parent.$route

      if (name === 'odd-create' || name === 'odd-router-create') return

      this.mapgl.getCanvas().style.cursor = ''
    })
  }

  async openCard(type, id) {
    this.$store.commit('SET_ODD_FIELD', {
      field: 'cardId',
      value: id
    })
    this.$store.commit('SET_ODD_FIELD', {
      field: 'cardType',
      value: type
    })
  }
}
