import vue from 'vue'

const state = {
  tableData: {},
  selectedObjects: {},
  restoreDataById: {},
  changedRows: {},
  sortConfig: {},
  multiSortConfig: {},
  rows: {},
  columnsReorder: {},
  columnsStyle: {},
  updateColumnsStyle: {},
  saveChangesHandler: {}
}

const mutations = {
  TABLE_PRIME_SET_FIELD(state, { tableId, field, value }) {
    if (field.includes('.')) {
      const [first, second] = field.split('.')

      if (!state[first][tableId]) {
        vue.set(state[first], tableId, {})
      }

      vue.set(state[first][tableId], second, value)
    } else {
      vue.set(state[field], tableId, value)
    }
  },
  TABLE_PRIME_SET_SELECTED_OBJECT(state, { tableId, selections }) {
    vue.set(state.selectedObjects, tableId, selections)
  },
  TABLE_PRIME_SET_TABLE_DATA(state, { tableId, data }) {
    vue.set(state.tableData, tableId, data)
  },
  TABLE_PRIME_RESTORE_TABLE_DATA(state, tableId) {
    vue.set(state.restoreDataById, tableId, true)
    vue.set(state.changedRows, tableId, {})
  },
  TABLE_PRIME_SET_CHANGED_ROW(state, { tableId, row }) {
    if (!state.changedRows[tableId]) {
      vue.set(state.changedRows, tableId, {})
    }
    vue.set(state.changedRows[tableId], row.id, row)
  },
  TABLE_PRIME_REMOVE_CHANGED_ROW(state, { tableId, row }) {
    if (!state.changedRows[tableId]) return
    if (!state.changedRows[tableId][row.id]) return
    vue.delete(state.changedRows[tableId], row.id)
  }
}

export default {
  state,
  mutations
}
