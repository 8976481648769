<template>
  <div
    v-loading="loading"
    class="pt-sp-schedule"
  >
    <r-table-list
      v-if="data.length"
      :data="data"
      :fields-names="fieldsExplanation"
      @click-handler="rowClickHandler"
    />
    <div
      v-else
      class="pt-sp-schedule__nodata"
    >
      <r-text type="caption">
        Расписание отсутствует
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      data: [],
      fieldsExplanation: {
        route: 'Маршрут',
        stop_times: 'Отправление'
      }
    }
  },
  computed: {
    cardId() {
      return this.$store.state.publicTransport.cardId
    }
  },
  watch: {
    cardId() {
      this.getSchedule()
    }
  },
  created() {
    this.getSchedule()
  },
  methods: {
    rowClickHandler({ id }) {
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardId',
        value: id
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardType',
        value: 'lines'
      })
    },
    async getSchedule() {
      try {
        this.loading = true

        const { id } = this.$store.state.publicTransport.model
        const url = `stop_point/${id}?format=stop_point_schedule&id=${this.cardId}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.data = data.map(({ name, stop_times, id }) => ({
          id: id,
          route: name,
          stop_times: stop_times.length
            ? stop_times.map(d => d?.substring(0, 5)).join(', ')
            : 'Не указано'
        }))

        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.pt-sp-schedule {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
