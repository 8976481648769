const state = {
  configs: {}
}

const mutations = {
  MAP_POPUP_SET_CONFIG(state, [field, value]) {
    state.configs[field] = value
  },
  MAP_POPUP_REMOVE_CONFIG(state, name) {
    delete state.configs[name]
  }
}

export default {
  state,
  mutations
}
