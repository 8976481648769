import { ritmDate } from '@/utils'

export const filtersEncoder = filters => {
  const config = { where: [], type: 'AND' }

  filters
    .filter(f => f.active)
    .forEach(f => {
      switch (f.id) {
        case 'closed_line_count':
          config.where.push(...customRadio(f))
          break
        case 'event_class_id':
          config.where.push(simpleCheckbox(f))
          break
        case 'requested':
          config.where.push(simpleRadio(f))
          break
        case 'relevance': {
          const relevance = [customRelevance(f)].flat()
          relevance.forEach(f => config.where.push(f))
          break
        }
        case 'created_at':
          config.where.push(customInterval(f))
          break
        default:
          break
      }
    })
  return config
}

const customRadio = f => {
  const value = f.prop.find(p => p.value).id
  const filters = []

  if (value === 'all') {
    return filters
  } else {
    filters.push({
      field: f.id,
      value: value === 'no' ? null : 0,
      op: value === 'yes' ? '>' : '=',
      type: 'AND'
    })

    return filters
  }
}

const simpleCheckbox = f => {
  return {
    field: f.id,
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const simpleRadio = f => {
  const value = f.prop.find(p => p.value).id
  return value !== 'all'
    ? {
      field: f.id,
      value: value === 'yes',
      op: f.id === 'requested' ? '=' : 'in'
    }
    : {}
}

const customInterval = f => {
  let { from, to } = f.prop.interval

  if (!from || !to) {
    from = from || this.$ritmDate.date(0)
    to = to || this.$ritmDate.date()
  }

  from = ritmDate.toIso(from)
  to = ritmDate.toIso(to)

  return {
    field: 'created_at',
    op: 'between',
    value: `${from}/${to}`
  }
}

const customRelevance = f => {
  const value = f.prop.find(p => p.value).id
  const start = ritmDate.toIso(this.$ritmDate.date(0))
  const now = ritmDate.toIso(this.$ritmDate.date())

  switch (value) {
    case 'completed':
      return {
        field: 'end_time',
        value: `${start}/${now}`,
        op: 'between'
      }
    case 'ongoing':
      return [
        {
          field: 'start_time',
          value: `${start}/${now}`,
          op: 'between'
        },
        {
          field: 'end_time',
          value: now,
          op: '>'
        }
      ]
    case 'upcoming':
      return {
        field: 'start_time',
        value: now,
        op: '>'
      }
    default:
      return {}
  }
}
