<template>
  <div class="accident-injureds">
    <div class="accident-injureds__switch">
      <el-switch
        v-model="hasInjureds"
        class="r-switch"
        :disabled="!!injureds.length"
      />
      <r-text>
        Есть пострадавшие
      </r-text>
    </div>
    <r-text v-if="hasInjureds">
      {{ `Количество пострадавших: ${injureds.length}` }}
    </r-text>
    <r-modal-card-block
      v-if="hasInjureds"
      main
      icon="users"
      title="Пострадавшие"
      transparent
      no-border
      no-padding
      action-name="Добавить"
      action-icon="add-plus"
      @action="addInjured"
    >
      <r-modal-card-block
        v-for="(injured, i) in injureds"
        :key="i"
        :title="`Пострадавший ${i + 1}`"
        toggler
        :open="injured.open || false"
        delete-action
        @action="addInjured"
      >
        <div slot="title-button">
          <r-delete-button
            simple
            tiny
            @delete="deleteInjureds(injured)"
          />
        </div>
        <attributes-list
          :model="model"
          :source="injured"
          :related="related"
          is-editing
          @hasChanges="$emit('hasChanges')"
        />
      </r-modal-card-block>
    </r-modal-card-block>
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import model from '../config/injured-model'
import { ritmDate } from '@/utils'

export default {
  components: {
    attributesList,
    rModalCardBlock
  },
  props: {
    related: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hasInjureds: true,
      model
    }
  },
  computed: {
    injureds() {
      return this.source.trafficaccident?.injureds?.filter(
        i => i.disabled !== true
      )
    }
  },
  mounted() {
    this.hasInjureds = !!this.injureds.length
  },
  beforeDestroy() {
    this.source.trafficaccident.injured_amount = this.injureds.length
  },
  methods: {
    addInjured() {
      const template = {
        trafficaccident_id: this.source.trafficaccident.id,
        open: true,
        disabled: false,
        temp_id: ritmDate.date()
      }

      const blank = this.model.reduce((a, c) => {
        a[c.model] = null
        return a
      }, template)

      this.source.trafficaccident.injureds.push(blank)
    },
    deleteInjureds({ id, temp_id }) {
      const injured = this.source.trafficaccident.injureds.find(i =>
        temp_id ? i.temp_id === temp_id : i.id === id
      )
      injured.disabled = true
    }
  }
}
</script>

<style lang="scss">
.accident-injureds {
  display: grid;
  grid-gap: 1rem;

  &__switch {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;
  }
}
</style>
