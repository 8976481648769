<template>
  <div
    v-if="checklists && checklists.length"
    v-loading="loading"
    class="bdd-modal-checklist"
  >
    <a
      v-for="checklist in checklists"
      :key="checklist.id"
      :href="checklistUrl(checklist)"
      target="_blank"
      rel="noopener noreferrer"
      class="bdd-modal-checklist__item"
    >
      <r-title type="subtitle-1">
        {{ checklist.reg_number }}
      </r-title>
      <r-text>
        {{ $ritmDate.toFormat(checklist.inspection_dt_fact, 'DD.MM.YYYY • HH:mm') }}
      </r-text>
      <r-text>
        {{ checklist.status_name }}
      </r-text>
      <r-delete-button
        :id="checklist.id"
        :source_id="checklistsUrl"
        mini
        @click.native="stop"
        @afterDelete="afterDelete"
      />
    </a>
    <r-text color-type="secondary">
      Количество проведённых осмотров
    </r-text>
    <r-text>{{ checklists.length }}</r-text>
  </div>
  <div v-else>
    <r-text>Нет чеклистов</r-text>
  </div>
</template>

<script>
export default {
  props: {
    applicationId: {
      type: String,
      required: true
    },
    needToUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
      checklistsUrl: this.$store.state.bdd.checklistsUrl || null,
      checklists: null
    }
  },
  watch: {
    needToUpdate(v) {
      if (v) this.getChecklists()
    }
  },
  created() {
    this.getChecklists()
  },
  methods: {
    checklistUrl(checklist) {
      return `/bdd-checklists?id=${checklist.id}`
    },
    afterDelete() {
      this.getChecklists()
    },
    stop(e) {
      e.preventDefault()
    },
    async getChecklists() {
      if (!this.checklistsUrl || !this.applicationId) return
      this.loading = true

      const config = {
        where: [
          { field: 'application_id', op: '=', value: this.applicationId }
        ],
        include: {
          status: { only: ['name'] },
          application: {
            only: ['vehicle_id'],
            include: {
              vehicle: {
                only: ['driver_full_name', 'reg_number']
              },
              status: { only: ['name'] }
            }
          }
        }
      }

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.checklistsUrl}?config=${JSON.stringify(
            config
          )}`
        })

        this.checklists = Object.values(data).map(e => {
          e.reg_number = e.application?.vehicle?.reg_number || ''
          // e.status = e.application?.status?.name || ''
          e.status_name = e.status?.name || '—'
          return e
        })

        const active = this.checklists.find(c =>
          // c.status_name.includes('назначен повторный осмотр') ||
          c.status_name.includes('Назначен осмотр')
        )
        this.$emit('setActive', active || null)
      } catch (e) {
        console.warn(e)
      } finally {
        this.$emit('setUpdateStatus', false)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bdd-modal-checklist {
  display: grid;
  grid-gap: 1rem;

  &__item {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2fr 3fr 5fr auto;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: var(--accent_hover);
    }

    &:active {
      background-color: var(--accent_active);
    }
  }
}
</style>
