export const urlReplaceParams = (url, new_params) => {
  const params_string = url.split('?').pop()
  const params = {}
  params_string.split('&').forEach(p => {
    const sp = p.split('=')
    if (sp.length === 2) {
      params[sp[0]] = sp[1] ? sp[1] : ''
    }
  })

  Object.keys(new_params).forEach(key => {
    params[key] = new_params[key]
  })

  const params_s = Object.keys(params).map(p => `${p}=${params[p] ? params[p] : ''}`).join('&')
  return [url.split('?')[0], params_s].join('?')
}
