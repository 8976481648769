<template>
  <div class="ee-card-attribute time">
    <r-text
      type="caption"
      style="margin-bottom: 8px;"
    >
      {{ config.label }}
    </r-text>
    <el-time-picker
      v-model="feature[config.prop]"
      class="r-time-picker"
      popper-class="build-time-popper"
      size="mini"
      format="HH:mm"
      @change="changeHandler"
    />
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeHandler(val) {
      this.feature[this.config.prop] = this.$ritmDate.toIso(val)
    }
  }
}
</script>

<style lang="scss">
.ee-card-attribute.time {
  .el-date-editor {
    width: 100%;
  }
}
</style>
