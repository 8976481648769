export const related = {
  model: {
    id: '4f172a69-d656-4a36-8673-375fa04da821',
    data: [],
    config: { only: ['name', 'id'] }
  },
  contractor: {
    select_store: 'contractors'
  },
  contractor_tmc: {
    select_store: 'contractors_tmc'
  },
  contractor_not_tmc: {
    select_store: 'contractors_not_tmc'
  },
  sub_contractor: {
    id: 'ef021b44-a650-478e-a25f-ace1e9b545ca',
    data: [],
    config: { only: ['name', 'id'] }
  },
  type: {
    id: 'aaf863b8-3df0-4264-9b65-773fee7aaeef',
    data: [],
    config: { only: ['name', 'id'] }
  },
  applications: {
    id: 'e01d9bf1-4933-4e13-94ce-66de510916f4',
    data: [],
    config: { only: ['id'] }
  },
  permit: {
    id: '1db01462-94d5-4e6a-a35f-de424745945f',
    data: [],
    config: { only: ['number', 'id'] }
  },
  vehicle_type: {
    id: 'aaf863b8-3df0-4264-9b65-773fee7aaeef',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_type_group: {
    id: 'b549d7d6-9e06-49e0-9352-eddd8ecee923',
    data: [],
    config: { only: ['name', 'id'] }
  }
}
