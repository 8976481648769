/* eslint-disable */
export const scLayersConfig = {
  events_Point: {
    type: "circle",
    layout: {},
    paint: {
      "circle-radius": {
        base: 1.75,
        stops: [[12, 8], [18, 80]]
      },
      "circle-color": "rgba(255, 62, 62, 0.1)",
      "circle-pitch-alignment": "map",
      "circle-stroke-color": "#d63838",
      "circle-stroke-width": 1
    }
  },
  events_Polygon: {
    type: "fill",
    layout: {},
    paint: {
      "fill-color": "#d63838",
      "fill-opacity": 0.4
    }
  },
  events_LineString: {
    type: "line",
    layout: {},
    paint: {
      "line-width": 3,
      "line-color": "#d63838"
    }
  },

  track_layer: {
    type: "line",
    layout: {},
    paint: {
      "line-width": 3,
      "line-color": [
        "interpolate",
        ["linear"],
        ["get", "avg_speed"],
        0,
        "#d53e4f",
        10,
        "#fc8d59",
        20,
        "#fee08b",
        30,
        "#ffffbf",
        40,
        "#e6f598",
        50,
        "#99d594",
        60,
        "#3288bd"
      ]
    }
  },

  events_Point_s: {
    type: "symbol",
    layout: {
      "icon-image": [
        "match",
        ["to-string", ["get", "event_class_id"]],
        "ДТП",
        "roadAccident",
        "Ремонтные работы",
        "roadWork",
        "Мероприятия",
        "roadEvent",
        "roadEvent"
      ],
      "icon-size": {
        base: 1.8,
        stops: [[12, 0.1], [18, 1]]
      }
    }
  },
  'video_camera_kpp_Point': {
    type: "circle",
    layout: {},
    paint: {
      "circle-radius": {
        base: 1.75,
        stops: [[12, 8], [18, 80]]
      },
      "circle-color": "rgba(89, 214, 78, 0.1)",
      "circle-pitch-alignment": "map",
      "circle-stroke-color": "rgb(89, 214, 78)",
      "circle-stroke-width": 1
    }
  },
  'video_camera_kpp_Point_s': {
    type: "symbol",
    layout: {
      "icon-image": "cameraWork",
      "icon-size": {
        base: 1.8,
        stops: [[12, 0.1], [18, 1]]
      }
    }
  },
  'video_camera_road_Point': {
    type: "circle",
    layout: {},
    paint: {
      "circle-radius": {
        base: 1.75,
        stops: [[12, 8], [18, 80]]
      },
      "circle-color": "rgba(89, 214, 78, 0.1)",
      "circle-pitch-alignment": "map",
      "circle-stroke-color": "rgb(89, 214, 78)",
      "circle-stroke-width": 1
    }
  },
  'video_camera_road_Point_s': {
    type: "symbol",
    layout: {
      "icon-image": "cameraWork",
      "icon-size": {
        base: 1.8,
        stops: [[12, 0.1], [18, 1]]
      }
    }
  }
};
