<template>
  <div class="tasks-modal-list-item__info">
    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('task:id') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ task.task_id }}</r-text>
      </div>
    </div>

    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('task:status') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ $t(`task:status:${task.success}`) }}</r-text>
      </div>
    </div>

    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('task:start_time') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ getDate(task.start_time) }}</r-text>
      </div>
    </div>

    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('task:end_time') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ getDate(task.end_time) }}</r-text>
      </div>
    </div>

    <div
      v-if="task.params.type"
      class="tasks-modal-list-item__info-row"
    >
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('task:file_type') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ task.params.type }}</r-text>
      </div>
    </div>

    <div
      v-if="task.params.data_source_name"
      class="tasks-modal-list-item__info-row"
    >
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('task:ds_name') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ task.params.data_source_name }}</r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  methods: {
    getDate(date) {
      return this.$ritmDate.toFormat(date, 'HH:mm • DD.MM.YYYY')
    }
  }
}
</script>

<style lang="scss">
.tasks-modal-list-item__info {
  &-row {
    display: flex;
    align-items: center;
  }
  &-description,
  &-title {
    padding: 0 4px;
    overflow: hidden;
  }
  &-title {
    width: 40%;
  }
  &-description {
    width: 60%;
  }
}
</style>

<i18n>
{
  "ru": {
    "task:id": "ID задачи: ",
    "task:status": "Статус выполнения: ",
    "task:status:true": "Выполнена успешно",
    "task:status:false": "Произошла ошибка",
    "task:start_time": "Начало выполнения:",
    "task:end_time": "Окончание выполнения:",
    "task:type": "Тип задачи: ",
    "task:type:import_data": "Импорт данных",
    "task:file_type": "Тип файла:",
    "task:ds_name": "Название источника данных:"
  },
  "en": {
    "task:id": "Task ID: ",
    "task:status": "Task status: ",
    "task:status:true": "Success",
    "task:status:false": "Failed",
    "task:start_time": "Start time:",
    "task:end_time": "End time:",
    "task:type": "Task type: ",
    "task:type:import_data": "Import data",
    "task:file_type": "File type:",
    "task:ds_name": "Datasource name:"
  }
}
</i18n>
