import { reportOptions } from '../configs'
import { ritmDate } from '@/utils'

const getValue = (data, field) => {
  let val

  if (field.value.includes('.')) {
    const subField = field.value.split('.')

    val = data?.[subField[0]]?.[subField[1]] || null
  } else {
    val = data[field.value] || null
  }

  switch (field.type) {
    case 'date':
      return val ? ritmDate.toFormat(val, field.dateFormat || 'DD.MM.YYYY HH:mm:ss') : '-'
    case 'select':
      return field.values?.[val] || (field.default || 'нет данных')
    case 'array': {
      if (field.value.includes('.')) {
        const subField = field.value.split('.')

        if (subField?.length > 2) {
          return data?.[subField[0]]
            ?.map(s => (s?.[subField[1]]?.[subField[2]] || (field.default || '')))
            ?.join('; ') || (field.default || 'нет данных')
        } else {
          return data?.[subField[0]]
            ?.map(s => (s?.[subField[1]] || (field.default || '')))
            ?.join('; ') || (field.default || 'нет данных')
        }
      } else {
        return data?.[field.value]?.join('; ') || (field.default || 'нет данных')
      }
    }
    default:
      return val
  }
}

const getTableDataCustom = (data, options) => {
  const { tableFields, geomField, geomFieldExpression } = options

  return data
    ?.map((e, i) => {
      const item = {}

      e.cargoes = e.cargoes?.filter(ci => !ci.receiving_date_fact)

      tableFields.forEach(l => {
        if (l.id === 'forecast_delivery') {
          const forecast = e.arrival_forecast

          if (forecast) {
            item[l.title] = ritmDate.toFormat(forecast, l.dateFormat || 'DD.MM.YYYY HH:mm:ss')
            item.orderTable = ritmDate.toUnix(forecast)
          } else {
            item[l.title] = '-'
            item.orderTable = null
          }
        } else if (l.value?.includes('cargoes')) {
          item[l.title] = getValue(e, l)
        } else {
          item[l.title] = getValue(e, l)
        }
      })

      item.id = e.id || 'id:' + i

      if (geomFieldExpression) {
        item.geom = geomFieldExpression(e)
      } else {
        item.geom = e[geomField || 'geom'] || null
      }

      return item
    })?.sort((a, b) => {
      if (!a.orderTable && b.orderTable) return 0
      if (!a.orderTable && !b.orderTable) return 1
      if (a.orderTable && !b.orderTable) return -1
      return a.orderTable - b.orderTable
    })
}

export class ForecastArrival {
  constructor(state) {
    this.$store = state.$store
    this.state = state

    this.options = reportOptions.forecast_arrival
  }

  getTableData(data) {
    return getTableDataCustom(data, this.options)
  }
}
