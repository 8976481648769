<template>
  <div class="ee-card">
    <div class="ee-card-header">
      <r-text> Карточка события </r-text>
    </div>
    <div class="ee-card-container">
      <div class="ee-card-content">
        <div class="ee-card-attributes">
          <r-title
            style="margin-bottom: 8px"
            type="subtitle-2"
          >
            Атрибуты
          </r-title>
          <ul class="ee-card-list primary">
            <li
              v-for="config in primaryAttributes"
              :key="config.prop"
              class="ee-card-attribute"
            >
              <component
                :is="`card-${config.type}`"
                :feature="feature"
                :config="config"
              />
            </li>
          </ul>
          <ul
            v-if="secondaryShow"
            class="ee-card-list secondary"
          >
            <li
              v-for="config in secondaryAttributes"
              :key="config.prop"
              class="ee-card-attribute"
            >
              <component
                :is="`card-${config.type}`"
                :feature="feature"
                :config="config"
              />
            </li>
          </ul>
        </div>
        <div
          v-if="feature.id"
          class="ee-card-additional"
        >
          <div class="ee-card-files-comments">
            <files-comments
              :id="feature.id"
              wrap
              closed
              column
              :source_id="ids['events']"
            />
          </div>
        </div>
      </div>
      <card-buttons
        :feature="feature"
        :required="requiredProps"
        :save="save"
        :cancel="cancel"
      />
    </div>
  </div>
</template>

<script>
import CardButtons from './controls/card-buttons'
import CardDatetime from './controls/card-datetime'
import CardInput from './controls/card-input'
import CardInputNumber from './controls/card-input-number'
import CardSelect from './controls/card-select'
import { getPrimaryAttributes, getSecondaryAttributes } from './config'
import filesComments from '@/components/files-comments/files-comments'
import {
  clearAllHelpers,
  removeStopPointsHelpers,
  clearAllRouteGeometry
} from '@/libs/map-draw/helpers'

export default {
  components: {
    CardButtons,
    CardDatetime,
    CardInput,
    CardInputNumber,
    CardSelect,
    filesComments
  },
  props: {
    draw: {
      type: Object,
      required: true
    },
    mapgl: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      feature: {
        id: null,
        event_class_id: null,
        start_time: null,
        end_time: null,
        closed_line_count: 0,
        address: '',
        customer: '',
        performer: '',
        phone_number: ''
      },
      requiredProps: ['event_class_id'],
      primaryAttributes: getPrimaryAttributes(
        this.$store.state.eventEditor.model.ids
      ),
      secondaryAttributes: getSecondaryAttributes(
        this.$store.state.eventEditor.model.ids
      )
    }
  },
  computed: {
    ids() {
      return this.$store.state.eventEditor.model.ids
    },
    eventClasses() {
      return this.$store.state.eventEditor.eventClasses
    },
    secondaryShow() {
      const { event_class_id } = this.feature
      if (!this.eventClasses) return false
      if (!event_class_id) return false

      const accidentsType = this.eventClasses.find(t => t.id === event_class_id)
      const names = ['Мероприятия', 'Ремонтные работы']

      return names.includes(accidentsType.name)
    }
  },
  watch: {
    '$store.state.eventEditor.activeCardId': {
      handler: function(val) {
        if (!val) return
        this.getInfo()
      }
    }
  },
  async created() {
    if (!this.eventClasses) await this.getBookInfo()
    this.getInfo()

    window.addEventListener('keydown', this.escapeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.escapeHandler)
  },
  methods: {
    closeCard() {
      this.$store.commit('SET_EE_STATE_PROP', {
        name: 'activeCard',
        value: false
      })
      this.$store.commit('SET_EE_STATE_PROP', {
        name: 'activeCardId',
        value: null
      })
      this.$store.commit('SET_EE_STATE_PROP', {
        name: 'overlay',
        value: false
      })
    },
    async getBookInfo() {
      try {
        this.loading = true
        const { ids } = this.$store.state.eventEditor.model
        const eventTypesId = ids.event_classes
        const url = `objectInfo/${eventTypesId}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.$store.commit('SET_EE_STATE_PROP', {
          name: 'eventClasses',
          value: Object.values(data)
        })
        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    },
    getInfo() {
      const { activeObject, mode } = this.state
      const { properties } = activeObject
      const { id } = properties

      if (mode === 'create') {
        const { eventType } = this.$store.state.eventEditor
        this.$set(this.feature, 'event_class_id', eventType)

        return
      }

      if (!id) {
        this.setInitialValues(properties)
      } else {
        this.getObjectData(id)
      }
    },
    async getObjectData(id) {
      try {
        this.loading = true
        const { ids } = this.$store.state.eventEditor.model
        const config = {
          where: [
            {
              field: 'id',
              op: '=',
              value: id
            }
          ]
        }
        const url = `objectInfo/${ids.events}?config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.setInitialValues(data[id])

        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    },
    setInitialValues(initial) {
      Object.keys(this.feature).forEach(key =>
        this.$set(this.feature, key, initial[key])
      )
    },
    cancel() {
      const { activeObject, mode } = this.state
      if (mode === 'create') {
        this.draw.delete(activeObject.id)
        this.state.isDrawing = false
      }
      if (mode === 'edit') this.draw.changeMode('simple_select')

      clearAllHelpers(this.mapgl)
      removeStopPointsHelpers(this.mapgl)
      clearAllRouteGeometry(this.mapgl)
      this.closeCard()
    },
    save() {
      const {
        activeObject,
        editingCallback,
        creatingCallback,
        mode
      } = this.state
      const callback = mode === 'create' ? creatingCallback : editingCallback

      this.primaryAttributes.forEach(({ prop }) => {
        activeObject.setProperty(prop, this.feature[prop])
      })
      this.secondaryAttributes.forEach(({ prop }) => {
        const value = this.secondaryShow ? this.feature[prop] : null
        activeObject.setProperty(prop, value)
      })

      callback(activeObject)
      this.closeCard()
    },
    escapeHandler(e) {
      const { key } = e

      if (key === 'Escape') this.closeCard()
    }
  }
}
</script>

<style lang="scss">
.ee-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  &-header {
    margin-bottom: 16px;
  }

  &-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &-content {
    flex: 1;
  }

  &-attributes {
    display: flex;
    flex-direction: column;
  }

  &-list {
    &.secondary {
      margin-top: 24px;
    }
  }

  &-attribute {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &-additional {
    margin-top: 32px;
  }

  .r-block-title {
    margin-bottom: 12px;
  }

  &-files-comments {
    margin-top: 32px;
  }
}
</style>
