<template>
  <popover-button
    :ref="id"
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="car-moving"
    title="Транспортные потоки"
    :add-handler="addHandler"
  >
    <div
      v-loading="loading"
      :class="`ps-settings ${isLayerActive ? 'ps-settings--active' : ''}`"
    >
      <div class="ps-settings__settings">
        <div class="ps-settings__header">
          <r-icon
            name="car-moving"
            :size="20"
          />
          <r-text type="caption">
            Транспортные потоки
          </r-text>
        </div>
        <ul
          v-if="isLayerActive"
          class="ps-settings__settings-list"
        >
          <li
            v-for="item in timeFiltersList"
            :key="item.id"
            :class="[
              'ps-settings__settings-list__item',
              { active: item.value === filterValue }
            ]"
            @click="changeFilter(item.value)"
          >
            <r-text>
              {{ item.title }}
            </r-text>
          </li>
        </ul>
      </div>
      <div
        v-if="isLayerActive"
        class="ps-settings__legend"
      >
        <r-text type="caption">
          Загруженность
        </r-text>
        <ul class="ps-settings__legend-list">
          <li
            v-for="type in passingTypes"
            :key="type.id"
            class="ps-settings__legend-list__item"
          >
            <div
              class="ps-settings__legend-list__item__label"
              :style="`background-color: ${type.color}`"
            />
            <r-text type="caption">
              {{ type.title }}
            </r-text>
          </li>
        </ul>
      </div>
    </div>
  </popover-button>
</template>

<script>
import { PassingStatistic } from './controllers'
import PopoverButton from '../../buttons/popover-button'
import { timeFiltersList, passingTypes } from './configs'
import { mapData } from './helpers'

export default {
  components: {
    PopoverButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      controllers: {},
      id: 'passing_statistic',
      loading: false,
      timeFiltersList,
      filterValue: 60,
      data: null,
      passingTypes
    }
  },
  computed: {
    activeLayers() {
      const { mapConfigs } = this.$store.state
      const active = mapConfigs?.activeLayers?.[this.module] || []

      return Array.from(new Set(active))
    },
    isLayerActive() {
      if (!this.activeLayers) return false

      return this.activeLayers.includes(this.id)
    }
  },
  watch: {
    isLayerActive(val) {
      if (val) {
        this.getData()
      } else {
        this.controllers.ps.toggleLayer(this.isLayerActive)
      }
    }
  },
  mounted() {
    if (this.isLayerActive) this.getData()
    this.initLayer()
  },
  methods: {
    initLayer() {
      this.controllers.ps = new PassingStatistic(this)
    },
    changeFilter(val) {
      this.filterValue = val
      this.getData()
    },
    async getData() {
      if (!this.filterValue) return
      this.loading = true

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `passing_statistic?format=stat&period=${this.filterValue}`
        })
        this.data = mapData(data)
        this.controllers.ps.toggleLayer(this.isLayerActive)
        this.loading = false
      } catch (e) {
        console.warn(e)
        this.loading = false
      }
    },
    async addHandler() {
      if (!this.controllers.ps) {
        this.controllers.ps = new PassingStatistic(this)
      }
      if (this.isLayerActive) {
        this.getData()
      } else {
        this.controllers.ps.toggleLayer(this.isLayerActive)
      }
    }
  }
}
</script>

<style lang="scss">
.ps-settings {
  display: flex;

  &--active {
    .ps-settings__header {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }

  &__settings {
    flex-shrink: 0;
    width: 240px;

    &-list {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      padding: 4px 0;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: var(--border-radius);
        cursor: pointer;

        &:hover {
          background-color: var(--accent_hover);
        }

        &.active {
          background-color: var(--accent_active);
          cursor: initial;
        }
      }
    }
  }

  &__legend {
    flex-shrink: 0;
    width: 128px;
    padding: 8px;
    margin-left: 16px;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);

    &-list {
      &__item {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-top: 8px;
        }

        &__label {
          height: 5px;
          width: 16px;
          border-radius: var(--border-radius);
          margin-right: 8px;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    height: 28px;

    .r-icon {
      margin-right: 4px;
    }
  }
}
.passing-statistic-marker {
  padding: 2px;
  border-radius: var(--border-radius);
  background-color: var(--map_marker_bg);
  width: 100px;

  &-row {
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  &-car {
    background-color: var(--accent_success_bg);
    border-radius: var(--border-radius);
    color: var(--map_marker_text);
    font-size: 14px;
    padding: 2px 8px;
    display: flex;
    justify-content: center;

    svg {
      fill: var(--accent_success);
    }
  }

  &-car-cluster {
    background-color: var(--map_marker_bg);
    color: var(--text_primary);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-around;

    svg {
      fill: var(--icons_high_contrast);
      display: flex;
      align-items: center;
    }
  }

  &-in,
  &-out {
    svg {
      height: 12px;
      fill: var(--map_marker_text);
    }
    &.high {
      color: var(--map_marker_high);
    }
    &.increased {
      color: var(--map_marker_increased);
    }
    &.normal {
      color: var(--map_marker_normal);
    }
    &.low {
      color: var(--map_marker_low);
    }
  }

  &-total,
  &-header {
    color: var(--map_marker_text);
  }

  &-total {
    > * {
      &:first-child {
        margin: 0 2px 0 8px;
      }
    }
  }
}
.passing-statistic-marker-cluster {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: var(--border-radius);
  background-color: var(--map_marker_bg);
  width: max-content !important;

  > * {
    color: var(--text_primary);

    svg {
      fill: var(--icons_high_contrast);
      display: flex;
      align-items: center;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}
</style>
