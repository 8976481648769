<template>
  <div class="mt-track">
    <div class="mt-track__filters">
      <track-filters
        :interval="interval"
        :loading="loadingTrack"
        :show="showTrack"
        @load="changeFilters"
        @toggle="toggleTrack"
      />
    </div>
    <div
      v-show="showTrack && !isEtran"
      class="mt-track__compare"
    >
      <track-compare
        ref="trackCompare"
        :data="vehicles"
        :active-id="cardId"
        @change="changeSecondTrack"
      />
    </div>
    <div
      v-if="showTrack && chartData"
      class="mt-track__chart"
    >
      <r-block no-padding>
        <r-block no-padding>
          <r-text type="caption">
            Цвет первого трека
          </r-text>
          <el-color-picker
            :value="mainColor"
            class="r-color-picker__picker"
            popper-class="r-color-picker-dropdown"
            color-format="hex"
            @active-change="changeColor('main', $event)"
          />
        </r-block>
        <r-block
          v-if="secondTrack"
          no-padding
        >
          <r-text type="caption">
            Цвет второго трека
          </r-text>
          <el-color-picker
            :value="secondColor"
            class="r-color-picker__picker"
            popper-class="r-color-picker-dropdown"
            color-format="hex"
            @active-change="changeColor('second', $event)"
          />
        </r-block>
      </r-block>
      <charts
        no-control
        data-zoom
        time-axis
        :data="chartData"
        :colors="[mainColor, secondColor]"
        chart-id="mt_tmc_track_chart"
      />
    </div>
  </div>
</template>

<script>
import TrackFilters from './track/track-filters'
import TrackCompare from './track/track-compare'
import charts from '@/components/charts/charts'
import { notifyFactory, ritmDate } from '@/utils'
import { parseTrackData, hasTrackData } from './track/helpers'
import debounce from 'lodash.debounce'

export default {
  components: {
    TrackFilters,
    TrackCompare,
    charts
  },
  data() {
    return {
      interval: {
        from: ritmDate.dateDiff(null, -15, 'm'),
        to: ritmDate.date()
      },
      loadingTrack: false,
      showTrack: false,
      secondTrack: null,
      tracksChartData: {
        main: null,
        second: null
      }
    }
  },
  computed: {
    mainColor: {
      get() {
        return this.$store.state.monitoringTmc.trackColors.main
      },
      set(value) {
        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'trackColors.main',
          value
        })
      }
    },
    secondColor: {
      get() {
        return this.$store.state.monitoringTmc.trackColors.second
      },
      set(value) {
        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'trackColors.second',
          value
        })
      }
    },
    cardId() {
      return this.$store.state.monitoringTmc.cardId
    },
    vehicles() {
      return this.$store.state.monitoringTmc.vehicles
    },
    isEtran() {
      const feature = this.vehicles.find(e => e.id === this.cardId)

      return feature.type_name === 'ЖД'
    },
    chartData() {
      const { main, second } = this.tracksChartData
      const data = {
        data: []
      }

      if (main) data.data.push(main)
      if (second) data.data.push(second)

      if (main || second) return data

      return null
    }
  },
  watch: {
    cardId() {
      this.removeTrack()
    }
  },
  beforeDestroy() {
    this.removeTrack()
  },
  methods: {
    changeColor: debounce(function(type, color) {
      switch (type) {
        case 'main':
          this.mainColor = color
          break
        case 'second':
          this.secondColor = color
          break
      }
      this.changeFilters()
    }, 128),
    toggleTrack() {
      if (this.showTrack) {
        this.removeTrack('main')
        this.secondTrack = null
      } else {
        this.loadTrack(this.cardId, 'main')
      }
    },
    changeFilters() {
      this.loadTrack(this.cardId)

      if (this.secondTrack) {
        this.loadTrack(this.secondTrack, 'second')
      }
    },
    changeSecondTrack(id) {
      if (id) {
        this.loadTrack(id, 'second')
        this.secondTrack = id
      } else {
        this.removeTrack('second')
        this.secondTrack = null
        this.tracksChartData.second = null
      }
    },
    getTrackUrl(id) {
      const { from, to } = this.interval

      const fromInit = this.$ritmDate.date(from, null, null, 0)
      const toInit = this.$ritmDate.date(to, null, null, 0)
      const fromValue = this.$ritmDate.toIso(fromInit)
      const toValue = this.$ritmDate.toIso(toInit)

      if (this.isEtran) {
        const mainUrl = `ZdDislocationData?format=track&id=${id}`
        const filtersUrl = `&from=${fromValue}&to=${toValue}`
        const url = `${mainUrl}${filtersUrl}`

        return url
      } else {
        const mainUrl = `track?object_field=uid&id=${id}&time_field=gps_time`
        const filtersUrl = `&from=${fromValue}&to=${toValue}&limit=9999999`
        const url = `${mainUrl}${filtersUrl}`

        return url
      }
    },
    async loadTrack(id, type = 'main') {
      try {
        this.loadingTrack = true
        const url = this.getTrackUrl(id)

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        const hasData = hasTrackData(this.isEtran, data)

        if (hasData) {
          const parsedData = parseTrackData(this.isEtran, data)
          // set geojson
          this.$store.commit('MT_TMC_SET_FIELD', {
            field: `${type}TrackGeom`,
            value: this.getGeojsonData(parsedData)
          })

          // set chart data
          this.$set(this.tracksChartData, type, this.getChartData(data, type))

          this.showTrack = true
        } else {
          const title = 'Данные по треку отсутствуют'
          const message =
            'Информация о перемещении ТС за указанный период отсутствует'
          this.$notify(notifyFactory('info', title, message))

          this.$store.commit('MT_TMC_SET_FIELD', {
            field: `${type}TrackGeom`,
            value: null
          })
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loadingTrack = false
      }
    },
    removeTrack(type) {
      if (type === 'second') {
        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'secondTrackGeom',
          value: null
        })

        return
      }

      this.showTrack = false
      this.tracksChartData.main = null
      this.tracksChartData.second = null
      this.secondTrack = null

      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'mainTrackGeom',
        value: null
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'secondTrackGeom',
        value: null
      })

      this.$refs.trackCompare.clearCompare()
    },
    getChartData(data, type) {
      return {
        title: `Скорость (${type === 'main' ? '1' : '2'})`,
        data: data
          .sort((a, b) => {
            const aValue = this.$ritmDate.toUnix(a.to_time || a.from_time)
            const bValue = this.$ritmDate.toUnix(b.to_time || b.from_time)

            return aValue - bValue
          })
          .map(p => [
            this.$ritmDate.toFormat(p.to_time || p.from_time, 'YYYY-MM-DD HH:mm:ss'),
            Number(p.avg_speed.toFixed(1))
          ])
      }
    },
    getGeojsonData(data) {
      return data
        .filter(p => !!p.from_point && !!p.to_point)
        .map(p => ({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [p.from_point.coordinates, p.to_point.coordinates]
          },
          properties: {
            time: p.to_time || p.from_time,
            speed: Number(p.avg_speed.toFixed(1))
          }
        }))
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-track {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas: 'filters compare' 'chart chart';
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem 1rem;
  align-self: start;

  &__filters {
    grid-area: filters;
    justify-self: start;
  }

  &__compare {
    align-self: end;
    grid-area: compare;
  }

  &__chart {
    height: 196px;
    width: 100%;
    grid-area: chart;
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-gap: 1rem;

    .charts {
      height: 196px;
    }
  }
}
</style>
