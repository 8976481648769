import mapboxgl from 'mapbox-gl'
import extent from 'turf-extent'
import midpoint from '@turf/midpoint'
import { point } from '@turf/helpers'
import { ritmDate } from '@/utils'

const parseDateTime = date => ritmDate.toUnix(date)

export class TrackController {
  constructor(map) {
    this.map = map
    this.$store = map.$store
    this.mapgl = map.mapgl

    this.selected = null
    this.hovered = null

    this.mousemoveHandler = this.mousemoveHandler.bind(this)
    this.mouseleaveHandler = this.mouseleaveHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)

    this.pointPopup = null
  }

  toggleTrack(data, type) {
    if (data) {
      this.addTrack(type)
    } else {
      this.removeTrack(type)
    }
  }

  addTrack(type) {
    const trackSourceId = `mt-track-${type}`
    const color = this.map.trackColors?.[type] || '#ccc'
    const source = this.mapgl.getSource(trackSourceId)

    if (source) {
      source.setData(this.getData(type))
      this.mapgl.setPaintProperty(trackSourceId, 'line-color', [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#55b77e',
        color
      ])
      this.mapgl.setPaintProperty(`${trackSourceId}_arrows`, 'text-color', color)
    } else {
      this.mapgl.addSource(trackSourceId, {
        type: 'geojson',
        data: this.getData(type)
      })

      this.mapgl.addLayer(
        {
          id: trackSourceId,
          source: trackSourceId,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              '#55b77e',
              color
            ],
            'line-width': [
              'match',
              ['get', 'type'],
              'active',
              ['case', ['boolean', ['feature-state', 'hover'], false], 12, 6],
              3
            ],
            'line-opacity': ['match', ['get', 'type'], 'active', 0.9, 0.2]
          }
        },
        'vehicles'
      )

      this.mapgl.addLayer(
        {
          id: `${trackSourceId}_arrows`,
          source: trackSourceId,
          type: 'symbol',
          layout: {
            'symbol-placement': 'line',
            'text-field': '▶',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 10, 22, 12],
            'symbol-spacing': [
              'interpolate',
              ['linear'],
              ['zoom'],
              12,
              32,
              22,
              45
            ],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'icon-allow-overlap': true
          },
          paint: {
            'text-color': color,
            'text-halo-color': '#ffffff',
            'text-halo-width': 0.8
          }
        },
        'vehicles'
      )

      this.mapgl.on('mousemove', trackSourceId, this.mousemoveHandler)
      this.mapgl.on('mouseleave', trackSourceId, this.mouseleaveHandler)
      this.mapgl.on('click', trackSourceId, this.clickHandler)
    }
  }

  removeTrack(type) {
    const trackSourceId = `mt-track-${type}`

    this.mapgl.off('mousemove', trackSourceId, this.mousemoveHandler)
    this.mapgl.off('mouseleave', trackSourceId, this.mouseleaveHandler)
    this.mapgl.off('click', trackSourceId, this.clickHandler)

    if (this.mapgl.getLayer(trackSourceId)) {
      this.mapgl.removeLayer(trackSourceId)
    }
    if (this.mapgl.getLayer(`${trackSourceId}_arrows`)) {
      this.mapgl.removeLayer(`${trackSourceId}_arrows`)
    }
    if (this.mapgl.getSource(trackSourceId)) {
      this.mapgl.removeSource(trackSourceId)
    }
  }

  updateTracks() {
    const { mainTrackGeom, secondTrackGeom } = this.$store.state.monitoringTmc

    if (mainTrackGeom) this.addTrack('main')
    if (secondTrackGeom) this.addTrack('second')
  }

  parseDatetime(datetime) {
    const [date, time] = datetime.split(' ')
    const [d, m, y] = date.split('.')
    const init = `${m}-${d}-${y} ${time}`
    return init
  }

  getData(type) {
    const { charts, monitoringTmc } = this.$store.state
    const features = monitoringTmc[`${type}TrackGeom`]
    let start = parseDateTime(features[0].properties.time)
    let end = parseDateTime(features[features.length - 1].properties.time)
    const { mt_tmc_track_chart } = charts.list

    if (mt_tmc_track_chart) {
      const startInit = this.parseDatetime(mt_tmc_track_chart.datazoom.start)
      const endInit = this.parseDatetime(mt_tmc_track_chart.datazoom.end)
      start = ritmDate.toUnix(startInit)
      end = ritmDate.toUnix(endInit)
    }

    return {
      type: 'FeatureCollection',
      features: features.map((f, index) => {
        const currentValue = parseDateTime(f.properties.time)

        return {
          ...f,
          id: index,
          properties: {
            type:
              currentValue >= start && currentValue <= end
                ? 'active'
                : 'inactive',
            time: f.properties.time,
            speed: f.properties.speed,
            series: type === 'main' ? 0 : 1,
            index
          }
        }
      })
    }
  }

  clickHandler(e) {
    this.mapgl.getCanvas().style.cursor = 'pointer'

    if (e.features.length > 0) {
      const { properties, geometry } = e.features[0]
      const { time, speed, index, series } = properties

      this.selected = e.features[0].id

      this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
        id: 'mt_tmc_track_chart',
        field: 'highlightByTime',
        value: {
          datetime: ritmDate.toFormat(time, 'YYYY-MM-DD HH:mm:ss'),
          dataIndex: index,
          series
        }
      })

      console.log('clicked on map index --- ', index)

      this.showPopup({
        coordinates: this.getMidPoint(geometry.coordinates),
        time,
        speed
      })
    }
  }

  mousemoveHandler(e) {
    this.mapgl.getCanvas().style.cursor = 'pointer'

    if (e.features.length > 0) {
      const { source } = e.features[0]

      if (this.hovered !== null) {
        this.mapgl.setFeatureState(
          { source, id: this.hovered },
          { hover: false }
        )
      }
      this.hovered = e.features[0].id
      this.mapgl.setFeatureState({ source, id: this.hovered }, { hover: true })
    }
  }

  mouseleaveHandler() {
    this.mapgl.getCanvas().style.cursor = ''

    if (this.hovered !== null) {
      this.mapgl.setFeatureState(
        { source: 'mt-track-main', id: this.hovered },
        { hover: false }
      )
      if (this.mapgl.getSource('mt-track-second')) {
        this.mapgl.setFeatureState(
          { source: 'mt-track-second', id: this.hovered },
          { hover: false }
        )
      }
    }

    this.hovered = null
  }

  updateHighlighedTrack(series, index) {
    const source = series === 0 ? 'mt-track-main' : 'mt-track-second'

    this.selected = index
    this.mapgl.setFeatureState({ source, id: this.selected }, { hover: false })
  }

  showPointOnMap(clickData) {
    if (!clickData) return

    const { index, title, data } = clickData

    console.log('clicked on chart index --- ', index)

    const type = title === 'Скорость (1)' ? 'main' : 'second'
    const { monitoringTmc } = this.$store.state
    const features = monitoringTmc[`${type}TrackGeom`]
    const feature = features[index]
    const { coordinates } = feature.geometry

    const bounds = extent(feature.geometry)

    this.mapgl.fitBounds(bounds, {
      padding: 20,
      maxZoom: 17
    })

    this.showPopup({
      coordinates: this.getMidPoint(coordinates),
      time: data[0],
      speed: data[1]
    })
  }

  hidePopup() {
    if (this.pointPopup) {
      this.pointPopup.remove()
    }
  }

  showPopup({ coordinates, time, speed }) {
    if (this.pointPopup) {
      this.pointPopup.remove()
    }

    this.pointPopup = new mapboxgl.Popup({ closeOnClick: true })
      .setLngLat(coordinates)
      .setHTML(
        `
        <ul>
          <li>Время: <b>${ritmDate.toFormat(time, 'DD.MM.YYYY • HH:mm')}</b></li>
          <li>Скорость: <b>${speed}</b> км/ч</li>
        </ul>
      `
      )
      .addTo(this.mapgl)
  }

  getMidPoint(lineCoords) {
    const point1 = point(lineCoords[0])
    const point2 = point(lineCoords[1])

    return midpoint(point1, point2).geometry.coordinates
  }
}
