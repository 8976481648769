<template>
  <div
    v-loading="isLoading"
    class="railway-control"
  >
    <r-main-panel>
      <railway-control-list />
    </r-main-panel>
    <railway-control-content />
    <railway-control-card v-if="isCardActive" />
  </div>
</template>

<script>
import railwayControlList from './components/railway-control-list/railway-control-list'
import railwayControlContent from './components/railway-control-content/railway-control-content'
import railwayControlCard from './components/railway-control-card/railway-control-card'

import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    railwayControlList,
    railwayControlContent,
    railwayControlCard
  },
  data() {
    return {
      isLoading: false,
      currentDate: this.$ritmDate.date()
    }
  },
  computed: {
    dateFilter() {
      return this.$store.state.railwayControl.dateFilter || null
    },
    isCardActive() {
      return !!this.$store.state.railwayControl.cardId
    },
    carsListId() {
      return this.$store.state.railwayControl.carsListId
    },
    statusListId() {
      return this.$store.state.railwayControl.statusListId
    },
    historyCarsListId() {
      return this.$store.state.railwayControl.historyCarsListId
    }
  },
  watch: {
    dateFilter: {
      handler: function(val) {
        this.$store.commit('RAILWAY_CONTROL_SET_CARD_ID', '')
        this.$store.commit('RAILWAY_CONTROL_CANCEL_CHANGES')
        this.setCarsList(val)
      },
      deep: true
    },
    '$store.state.railwayControl.update': {
      handler: function(val) {
        if (!val) return
        this.setCarsList(this.dateFilter)
        this.$store.commit('RAILWAY_CONTROL_SET_FIELD', {
          field: 'update',
          value: false
        })
      }
    }
  },
  created() {
    this.setDate()
    this.setStatusList()
  },
  methods: {
    setDate() {
      const date = this.$ritmDate.toIso(this.currentDate)

      this.$store.commit('RAILWAY_CONTROL_SET_DATE_FILTER', date)

      this.setCarsList(this.dateFilter)
    },
    async setCarsList(date) {
      this.isLoading = true

      const current = this.$ritmDate.toFormat(this.currentDate, 'DD.MM.YYYY')
      const incoming = this.$ritmDate.toFormat(date, 'DD.MM.YYYY')

      if (current === incoming) {
        try {
          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url: `objectInfo/${this.carsListId}`
          })
          const carsList = Object.values(data)

          this.$store.commit('RAILWAY_CONTROL_SET_CARS_LIST', carsList)
          this.$store.commit('RAILWAY_CONTROL_SET_INITIAL_CARS_LIST', carsList)
          this.isLoading = false
        } catch (e) {
          console.warn(e)
          this.isLoading = false
        }
      } else {
        this.setHistoryCarsList(date)
      }
    },
    getDateConfig(date) {
      let yesterday = this.$rDate
        .zeroing(this.$ritmDate.date())
        .calc(-1, 'day')
        .format()
      yesterday = this.$ritmDate.toIso(yesterday)

      const initialDate = this.$rDate.zeroing(date).format('iso')
      const fromDt = cloneDeep(initialDate)
      const toDt = cloneDeep(
        this.$rDate.calc(1, 'day', initialDate).format('iso')
      )
      let dtFrom = this.$ritmDate.toIso(fromDt)
      const dtTo = this.$ritmDate.toIso(toDt)

      if (yesterday === dtFrom) {
        dtFrom = this.$rDate.calc(-1, 'day', dtFrom).format('iso')
      }

      return {
        where: [
          {
            field: 'created_at',
            op: 'between',
            value: `${dtFrom}/${dtTo}`
          }
        ]
      }
    },
    async setHistoryCarsList(date) {
      const config = this.getDateConfig(date)

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.historyCarsListId}?config=${JSON.stringify(
            config
          )}`
        })
        const carsList = Object.values(data)

        this.$store.commit('RAILWAY_CONTROL_SET_CARS_LIST', carsList)
        this.$store.commit('RAILWAY_CONTROL_SET_INITIAL_CARS_LIST', carsList)
        this.isLoading = false
      } catch (e) {
        console.warn(e)
        this.isLoading = false
      }
    },
    async setStatusList() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.statusListId}`
        })

        this.$store.commit('RAILWAY_CONTROL_SET_STATUS_LIST', data)
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss">
.railway-control {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .r-main-panel {
    z-index: 105;
  }
}
</style>
