<template>
  <app-table
    v-loading="loading"
    :filters="filters"
    :uri="uri"
  />
</template>

<script>
import { applicationsConfig, related, filters } from './configs'
import { parseApplications, getApplicationsConfig } from './helpers'
import { getApplicationUrlById } from './app-modal/helpers'
import appTable from './app-table/app-table'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: { appTable },
  data() {
    return {
      loading: false,
      source_id: this.$store.state.bdd.applicationsSourceId || null,
      applicationsStatusListSourceId: this.$store.state.bdd
        .applicationsStatusListSourceId,
      uri: `${this.source_id}`,
      applicationsConfig,
      related,
      filters: []
    }
  },
  computed: {
    update() {
      return this.$store.state.bdd.updateApplications
    },
    applications() {
      return this.$store.state.bdd.applications || null
    }
  },
  watch: {
    update(v) {
      if (!v) return
      this.getApplicationList()
      this.$store.commit('BDD_SET_FIELD', {
        field: 'updateApplications',
        value: false
      })
    }
  },
  async created() {
    this.loading = true
    this.setFilters()
    await this.loadRelated()
    this.formatFilters()
    await this.getApplicationList()

    const application_id = this.$route.query?.id

    if (application_id) {
      this.$nextTick(() => {
        this.openAppModal(application_id)
      })
    }
    this.loading = false
  },
  methods: {
    async openAppModal(id) {
      try {
        const url = getApplicationUrlById(id, this.source_id)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        const application = Object.values(data)?.[0]
        if (!application) return

        this.$store.commit('BDD_SET_FIELD', {
          field: 'activeApplication',
          value: application
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'modalName',
          value: 'appMainModal'
        })
        this.$store.commit('OPEN_MODAL_WINDOW', 'bdd-applications-modal')
        this.$router.replace({
          query: {}
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    setFilters() {
      const userFilters = this.$store.state.bdd.filters?.applications
      const initFilters = filters()

      this.filters = initFilters?.map(f => {
        if (!userFilters?.length) return f

        const userItem = userFilters?.find(uf => uf.id === f.id)

        return userItem || f
      })
    },
    formatFilters() {
      const data = this.related?.applications_status_list?.data
      if (!data) return this.filters
      const statusList = {
        propTitle: 'status_id',
        values: data?.map((s, i) => {
          return {
            id: s.id,
            order: i + 1,
            value: s.id,
            title: s.name,
            name: s.name
          }
        })
      }
      this.filters = this.filters.map(f => {
        if (f.id === 'status_id') {
          f.prop = cloneDeep(statusList.values).map(s => {
            const fValue = !!f?.prop?.find(fi => fi.id === s.id)?.value
            s.value = fValue
            return s
          })
        }
        return f
      })
    },
    async getApplicationList() {
      try {
        const config = getApplicationsConfig(this.filters)

        if (!JSON.stringify(config.where).includes('status_id')) {
          config.where.push({
            field: 'status_id',
            value: null,
            op: '!='
          })
        }
        config.order = [{ created_at: 'desc' }]

        this.uri = `objectInfo/${this.source_id}?array=true&config=${JSON.stringify(
          config
        )}`

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: this.uri
        })

        this.$store.commit('BDD_SET_FIELD', {
          field: 'applications',
          value: parseApplications(Object.values(data))
        })
      } catch (e) {
        console.warn(e)
      }
    },
    async loadRelated() {
      for (const r in this.related) {
        const urlName = this.related[r].urlName
        const source_id = this.$store.state.services[urlName]
        if (!source_id) return

        try {
          const config = JSON.stringify(this.related[r].config)
          const url = `objectInfo/${source_id}?config=${config}`

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url
          })

          this.related[r].data = Object.values(data)

          this.filters = this.filters.map(f => {
            let filter = f
            if (f.source === r) {
              filter = {
                ...f,
                prop: this.related[r].data.map(d => {
                  const fValue = !!f?.prop?.find(fi => fi.id === d.id)?.value

                  return {
                    ...d,
                    value: fValue
                  }
                })
              }
            }
            return {
              ...filter
            }
          })
        } catch (e) {
          console.warn(e)
        }
      }
      this.$store.commit('BDD_SET_FIELD', {
        field: 'applicationsRelated',
        value: this.related
      })
    }
  }
}
</script>

<style lang="scss">
.bdd {
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  background-color: var(--bg_surface);
}
</style>
