<template>
  <div class="interval-filter">
    <div class="popper-filter__content">
      <div class="interval-filter__item">
        <r-text type="caption">
          {{ $t(`interval-filter:start`) }}
        </r-text>
        <r-date-picker
          v-model="interval.from"
          class="r-date-picker"
          size="mini"
          name="datetime"
          :clearable="!noClearable"
          :type="pickerType"
          :format="format"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>

      <div class="interval-filter__item">
        <r-text type="caption">
          {{ $t(`interval-filter:end`) }}
        </r-text>
        <r-date-picker
          v-model="interval.to"
          class="r-date-picker"
          size="mini"
          name="datetime"
          :clearable="!noClearable"
          :type="pickerType"
          :format="format"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
    </div>

    <r-block no-padding>
      <r-button
        v-for="fastValue in fastValues"
        :key="fastValue.value"
        @click="setFastValue(fastValue.value)"
      >
        {{ fastValue.name }}
      </r-button>
    </r-block>

    <div class="interval-filter__controls popper-filter__controls">
      <r-button
        type="primary"
        @click="prepareData"
      >
        {{ $t(`interval-filter:accept`) }}
      </r-button>
      <r-button
        v-if="!noClearable"
        simple
        :disabled="isResetDisabled"
        @click="resetFilter"
      >
        {{ $t(`interval-filter:reset`) }}
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: Object,
      required: true
    },
    pickerType: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    id: {
      type: String,
      required: true
    },
    noClearable: {
      type: Boolean,
      default: false
    },
    fastValues: {
      type: Array,
      default: () => null
    }
  },
  data() {
    return {
      ...this.filterValue
    }
  },
  computed: {
    isResetDisabled() {
      const { to, from } = this.interval
      return !(from || to)
    }
  },
  methods: {
    resetFilter(resetAll) {
      this.interval.to = null
      this.interval.from = null
      this.prepareData(resetAll)
    },
    setFastValue(value) {
      switch (value) {
        case 'last_30_minutes': {
          const to = this.$ritmDate.toIso()
          const from = this.$ritmDate.toIso(null, -30, 'm')
          this.interval.to = to
          this.interval.from = from
          break
        }
      }
    },
    prepareData(resetAll) {
      const { interval, id } = this
      if (this.pickerType === 'date') {
        const initTo = interval.to
          ? this.$ritmDate.date(interval.to, 23, 59, 59)
          : interval.from
            ? this.$ritmDate.date(null, 23, 59, 59)
            : null

        if (initTo) {
          interval.to = this.$ritmDate.toIso(initTo)
        }

        if (interval.from) {
          interval.from = this.$ritmDate.toIso(interval.from)
        }
      }

      const data = {
        type: 'interval-filter',
        prop: { interval },
        id: id,
        active: !!(interval.from || interval.to)
      }
      if (!resetAll) {
        this.$emit('load', data)
      } else {
        this.$emit('reset-filter', data)
      }
    }
  }
}
</script>

<style lang="scss">
.interval-filter {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;

  &__item {
    display: grid;
    grid-gap: 0.25rem;
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<i18n>
{
  "ru": {
    "interval-filter:start": "Начало",
    "interval-filter:end": "Окончание",
    "interval-filter:accept": "Применить",
    "interval-filter:reset": "Сброс"
  },
  "en": {
    "interval-filter:start": "Start",
    "interval-filter:end": "End",
    "interval-filter:accept": "Accept",
    "interval-filter:reset": "Reset"
  }
}
</i18n>
