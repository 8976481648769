export const getColumnOrderConfig = fields => {
  const columns = fields?.map(f => {
    const { reflection } = f

    if (reflection) {
      return reflection.name + '.' + reflection.default_show_attribute
    }
    return f.origin_title || f.title
  })
  const labels = fields?.map(e => {
    const { reflection, title, alias } = e

    if (reflection) {
      return reflection.alias || title
    } else {
      return alias || title
    }
  })

  const columnOrder = `&column_order=${JSON.stringify(columns)}`
  const columnLabels = `&column_labels=${JSON.stringify(labels)}`
  return columnOrder + columnLabels
}

export const prepareBookUri = (uri, activeFields) => {
  const initial = uri.split('config=')[0]
  const column_order = getColumnOrderConfig(activeFields)
  const url = new URL(`http://dmtlp/${uri}`)
  let config = url.searchParams.get('config')

  if (config) {
    const parsedConfig = JSON.parse(config)
    delete parsedConfig.offset
    delete parsedConfig.limit

    parsedConfig.only = activeFields
      .filter(e => !e.reflection)
      .map(e => e.origin_title) || []

    activeFields.forEach(e => {
      if (e.reflection) {
        if (e.type !== 'has_one') {
          const attrName = attrsLinks?.[e.reflection?.name] || attrsLinks.default

          parsedConfig.only.push(`${e.title}.${attrName}`)
        } else {
          parsedConfig.only.push(`${e.title}`)
        }
      }
    })
    if (!parsedConfig.only?.length) {
      parsedConfig.only.push('id')
    }
    delete parsedConfig.include

    config = JSON.stringify(parsedConfig) + column_order
  }

  return config ? `${initial}config=${config}` : uri
}

export const attrsLinks = {
  vehicles: 'reg_number',
  carriages: 'carriage_no',
  contracts: 'number',
  contract: 'number',
  cargoes: 'name',
  packing_list_no: 'packing_list_no',
  geozones: 'name',
  categories: 'name',
  warehouse: 'name',
  checkpoint: 'name',
  model: 'name',
  colour: 'name',
  protocol: 'name',
  customer: 'name',
  pot_status: 'name',
  contractor: 'name',
  sub_contractor: 'name',
  vehicle_type: 'name',
  line_routes: 'name',
  smtp_status: 'name',
  bsmts_status: 'name',
  subproject: 'name',
  subsubproject: 'name',
  sender: 'name',
  forwarder: 'name',
  created_user: 'name',
  updated_user: 'name',
  last_track_data: 'gps_time',
  default: 'id'
}
