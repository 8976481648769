import { reportOptions } from '../configs'
import { jsonToGeojson, ritmDate } from '@/utils'
import { layersConfig } from '@/components/analytics/components/an-map/configs'

export class CrossingsGeozones {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl
    this.options = reportOptions.crossings_geozones
  }

  getMapData(data) {
    const mapData = data?.filter(e => e.geom)
    return jsonToGeojson(mapData)
  }

  getTableData(data) {
    const { tableFields } = this.options

    return data.map(e => {
      const item = {}

      tableFields.forEach(l => {
        switch (l.type) {
          case 'date':
            item[l.title] = ritmDate.toFormat(e[l.value], l.dateFormat || 'DD.MM.YYYY HH:mm:ss') || null
            break
          default:
            item[l.title] = e[l.value]
            break
        }

        if (e.geozone_name?.includes('Итого, посещений') || e.geozone_name?.includes('Итого объектов')) {
          item.filled = true
        }
      })

      return item
    })
  }

  getChartData(data) {
    const chartData = {
      data: [],
      fields: {
        data: [],
        title: 'Время фиксации'
      }
    }
    const itemData = []
    let itemTitle = ''
    data.data.forEach(e => {
      if (!e.event_attrs?.distance) return
      itemData.push(e.event_attrs.distance)
      chartData.fields.data.push(ritmDate.toFormat(e.event_time, 'DD.MM.YYYY HH:mm:ss'))
      itemTitle = e.reg_number
    })
    chartData.data.push({
      title: itemTitle,
      data: itemData
    })
    return chartData
  }

  async addMapData(data) {
    this.mapgl.addSource('crossings_geozones', {
      type: 'geojson',
      data
    })

    this.mapgl.addLayer({
      id: 'crossings_geozones_line',
      source: 'crossings_geozones',
      ...layersConfig.crossings_geozones_line
    })
  }
}
