<template>
  <ul class="mt-card-attributes">
    <li class="mt-card-attributes__item">
      <div class="mt-card-attributes__cell mt-card-attributes__cell--label">
        <r-text type="caption">
          Проект
        </r-text>
      </div>
      <div class="mt-card-attributes__cell mt-card-attributes__cell--value">
        <r-text>
          {{ getProjectLabel() }}
        </r-text>
      </div>
    </li>
    <li
      v-for="item in attributesConfig"
      :key="item.prop"
      class="mt-card-attributes__item"
    >
      <div class="mt-card-attributes__cell mt-card-attributes__cell--label">
        <r-text type="caption">
          {{ getAttributeLabel(item.prop) }}
        </r-text>
      </div>
      <div class="mt-card-attributes__cell mt-card-attributes__cell--value">
        <r-text>
          {{ getAttributeValue(item) }}
        </r-text>
      </div>
    </li>
  </ul>
</template>

<script>
import { ritmDate } from '@/utils'

const attributesConfig = [
  {
    prop: 'reg_number'
  },
  {
    prop: 'main_contractor_id',
    nestedProp: 'contractor',
    nesterPropName: 'name'
  },
  {
    prop: 'bsmts_model'
  },
  {
    prop: 'vehicle_type_id',
    nestedProp: 'vehicle_type',
    nesterPropName: 'name'
  },
  {
    prop: 'colour_id',
    nestedProp: 'colour',
    nesterPropName: 'name'
  },
  {
    prop: 'protocol_id',
    nestedProp: 'protocol'
  }
]

export default {
  props: {
    info: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      attributesConfig
    }
  },
  methods: {
    getProjectLabel() {
      const { agpz, aghk } = this.info

      if (agpz && aghk) return 'АГПЗ, АГХК'

      if (agpz) return 'АГПЗ'

      if (aghk) return 'АГХК'

      return 'Не указан'
    },
    getAttributeLabel(prop) {
      const alias = this.fields[prop]?.alias

      if (alias === prop && prop.includes('_id')) {
        const newProp = prop.replace('_id', '')
        return this.fields[newProp]?.reflection?.alias || prop
      }
      return alias || prop
    },
    getAttributeValue({ prop, type, nestedProp, nesterPropName }) {
      var val = ''
      if (nestedProp) {
        val = this.info[nestedProp]
          ? this.info[nestedProp][nesterPropName]
          : ''
      } else {
        val = this.info[prop]
      }
      switch (type) {
        case 'date':
          return ritmDate.toFormat(val, 'DD.MM.YYYY')
        case 'datetime':
          return ritmDate.toFormat(val, 'DD.MM.YYYY HH:mm:ss')
        default:
          return val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-card-attributes {
  &__item {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--dividers_low_contrast);
    height: 32px;

    &:last-child {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    height: 100%;
    width: 50%;
    padding: 4px 8px;
    border-left: 1px solid var(--dividers_low_contrast);

    &--label {
      width: 40%;
      flex-shrink: 0;
    }

    &--value {
      flex: 1;
    }

    &:last-child {
      border-right: 1px solid var(--dividers_low_contrast);
    }
  }
}
</style>
