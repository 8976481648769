<template>
  <div
    v-loading="loading"
    class="checklist-confirm"
  >
    <r-title color-type="subhead">
      Заключение
    </r-title>
    <div class="checklist-confirm__body">
      <r-title type="title-3">
        На основании проведённой проверки выносится следующее решение:
      </r-title>

      <el-select
        v-model="statusName"
        class="r-select"
        placeholder="Выбрать статус из списка"
        size="mini"
      >
        <el-option
          v-for="status in statusList"
          :key="status.id"
          :label="status.name"
          :value="status.name"
        />
      </el-select>
      <div
        v-if="reInspection"
        class="checklist-confirm__row"
      >
        <r-text type="caption">
          Дата осмотра
        </r-text>
        <r-date-picker
          v-model="date"
          class="r-date-picker"
          type="datetime"
          size="mini"
          name="datetime"
          format="dd.MM.yyyy HH:mm"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
      <div
        v-if="!isStatusSuccess && statusName"
        class="checklist-confirm__row"
      >
        <r-text type="caption">
          {{ statusInputLabel }}
        </r-text>
        <el-input
          v-model="comment"
          class="r-input"
          type="textarea"
          :rows="2"
          resize="none"
          placeholder="Комментарий к заявке"
        />
      </div>
      <r-button
        type="primary"
        :disabled="!statusName"
        @click="save"
      >
        Сохранить заявку
      </r-button>
    </div>
  </div>
</template>

<script>
import { prepareDataToSave } from '../../../../helpers'
import { ritmDate } from '@/utils'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    app_source: {
      type: Object,
      required: true
    },
    app_docs: {
      type: Array,
      default: () => []
    },
    checklistData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      statusName: '',
      comment: '',
      date: ritmDate.date(),
      permit: 'нарушений нет',
      loading: false,
      checklistId: this.$route.query.id,
      checklistsUrl: this.$store.state.bdd.checklistsUrl,
      applicationsSourceId: this.$store.state.bdd.applicationsSourceId,
      checklistAttrsValuesUrl:
        this.$store.state.bdd.checklistAttrsValuesUrl || ''
    }
  },
  computed: {
    applicationId() {
      return this.checklistData?.application_id
    },
    reInspection() {
      return this.statusName.includes(
        'есть нарушения, назначен повторный осмотр'
      )
    },
    newStatusId() {
      return this.$store.state.bdd?.statuses?.find(e =>
        e.name.includes('Назначен осмотр')
      )?.id
    },
    isStatusSuccess() {
      return this.statusName.includes(this.permit)
    },
    statusList() {
      return this.$store.state.bdd?.statuses?.filter(e => {
        const keyParts = [
          'отклонена по формальным признакам',
          this.permit,
          'есть нарушения, заявка отклонена',
          'допущен с замечаниями',
          'есть нарушения, назначен повторный осмотр'
        ]
        return keyParts.some(k => {
          const match = e.name.includes(k)
          const isPermit = e.name.includes(this.permit)

          return this.hasFoul && isPermit ? false : match
        })
      })
    },
    hasFoul() {
      const lists = []
      const allFields = []

      this.data
        .filter(e => e.type === 'inspection')
        .forEach(k => lists.push(...k.lists))
      lists
        .filter(n => {
          if (n.isShow) {
            return n.isShow.value
          }

          return true
        })
        .forEach(l => {
          if (l.items?.length) {
            allFields.push(...l.items)
          }
        })

      return allFields.some(e => !e.value && e.required)
    },
    statusInputLabel() {
      return this.statusName.includes('допущен с замечаниями')
        ? 'Комментарий'
        : 'Комментарий с причиной отклонения'
    }
  },
  watch: {
    hasFoul(v) {
      if (v && this.isStatusSuccess) {
        this.statusName = ''
      }
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        await this.saveChecklistAttrs()

        const appData = this.getAppData()
        await this.saveAppData(appData)
        await this.saveChecklistData(appData)
        this.$router.push('/bdd-checklists')
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    getAppData() {
      const status_id = this.statusList?.find(s => s.name === this.statusName)
        ?.id
      const id = this.checklistData.application_id
      const inspection_comment = this.comment
      const inspection_dt = this.$ritmDate.toIso(this.date)
      const inspection_dt_fact = this.$ritmDate.toIso(this.$ritmDate.date())

      const appDocs = this.app_docs.reduce((acc, curr) => {
        const { attr_name, parent } = curr

        acc[attr_name] = parent
          ? this.app_source[parent][attr_name]
          : this.app_source[attr_name]
        return acc
      }, {})

      return {
        ...appDocs,
        id,
        inspection_dt,
        inspection_dt_fact,
        status_id,
        inspection_comment
      }
    },
    async saveChecklistData(data) {
      // Save current checklist
      await this.$store.dispatch('PUT_REQUEST', {
        url: `objectInfo/${this.checklistsUrl}`,
        data: {
          application_id: this.applicationId,
          id: this.checklistData.id,
          // uncomment below if need to update inspection_date on saving
          inspection_dt_fact: this.$ritmDate.toIso(this.$ritmDate.date()),
          status_id: data.status_id
        }
      })
      // If reInspection, create new checklist
      if (this.reInspection) {
        await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${this.checklistsUrl}`,
          data: {
            application_id: this.applicationId,
            inspection_date: data.inspection_dt,
            status_id: this.newStatusId
          }
        })
      }
    },
    async saveAppData(data) {
      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.applicationsSourceId}`,
          data: [data]
        })
      } catch (e) {
        console.warn(e)
      }
    },
    async saveChecklistAttrs() {
      const data = prepareDataToSave(this.data, this.checklistId)
      try {
        await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${this.checklistAttrsValuesUrl}`,
          data
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-confirm {
  display: grid;
  grid-gap: 0.5rem;
  padding-bottom: 2rem;

  &__body {
    border-radius: var(--border-radius);
    border: 1px solid var(--field_border);
    display: grid;
    padding: 1rem;
    grid-gap: 1rem;

    .r-button {
      justify-self: start;
    }
  }

  &__row {
    display: grid;
    grid-gap: 0.5rem;
  }
}
</style>
