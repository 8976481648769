<template>
  <div class="pass-register-modal">
    <div class="pass-register-modal__controls">
      <r-button
        type="success"
        class="pass-register-modal__save-button"
        :disabled="!canSave"
        @click="save"
      >
        {{ $t('register_modal::save-button') }}
      </r-button>
      <r-button
        class="pass-register-modal__exit-button"
        simple
        @click="close"
      >
        {{ $t('register_modal::close-button') }}
      </r-button>
    </div>
    <div class="pass-register-modal__vehicle-info">
      <r-text>
        На основе заявки для
        <strong>{{ ` ${vehicle.reg_number}` }}</strong>
      </r-text>
      <r-text color-type="secondary">
        {{ getVehicleModelType() }}
      </r-text>
    </div>
    <div class="pass-register-modal__attribute-list">
      <div
        v-for="field in fields"
        :key="field.name"
        class="pass-register-modal__attribute"
      >
        <r-text color-type="secondary">
          {{ field.name }}
        </r-text>
        <el-switch
          v-if="field.type === 'boolean'"
          v-model="source[field.model]"
          class="r-switch"
        />
        <el-input
          v-if="field.type === 'text' || field.type === 'number'"
          v-model="source[field.model]"
          class="r-input"
          :type="field.type"
          clearable
        />
        <el-select
          v-else-if="field.type === 'select'"
          v-model="source[`${field.model}_id`]"
          class="r-select"
          size="mini"
        >
          <el-option
            v-for="item in source.related[field.model].data"
            :key="item.id"
            :value="item.id"
            :label="item.name || item.id"
          />
        </el-select>
        <r-date-picker
          v-else-if="field.type === 'datetime'"
          v-model="source[field.model]"
          clearable
          type="datetime"
          class="r-input"
          :placeholder="$t('register_modal::date_choose')"
          default-time="12:00:00"
          format="dd.MM.yyyy HH:mm"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { fields, sources } from './config/fields'

export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    },
    application: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sources,
      fields: fields.filter(f => f.read_only === undefined)
    }
  },
  computed: {
    canSave() {
      return !!this.source
    },
    regNumber() {
      return this.source.related.application.data[this.application].vehicle
    },
    vehicle() {
      return this.source.related.application.data[this.application].vehicle
    }
  },
  methods: {
    getVehicleModelType() {
      const model = this.vehicle.model?.name
      const type = this.vehicle.vehicle_type?.name
      let modelType = ''

      if (model) {
        modelType += `Модель: ${model}, `
      }
      if (type) {
        modelType += `Вид ТС: ${type}`
      }
      return modelType
    },
    async save() {
      const { source, source_id, application } = this
      const url = `objectInfo/${source_id}`
      const data = {
        application_id: application,
        number: source.number,
        date_from: source.date_from,
        date_to: source.date_to,
        permit_type_id: source.permit_type_id,
        subproject_id: source.subproject_id,
        status: source.status,
        is_valid: source.is_valid,
        withdrawal: source.withdrawal
      }
      try {
        await this.$store.dispatch('POST_REQUEST', { url, data })

        const message = this.$t('register_modal::save_text')
        this.$notify(notifyFactory('succcess', message))
      } catch (e) {
        console.warn(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: this.sources.updateField,
          value: true
        })
        this.close()
      }
    },
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "register_modal::close-button": "Закрыть",
    "register_modal::save-button": "Сохранить",
    "register_modal::save_text": "Сохранение выполнено успешно",
    "register_modal::date_choose": "Выберите дату"
  },
  "en": {
    "register_modal::close-button": "Close",
    "register_modal::save-button": "Save",
    "register_modal::save_text": "Saved successfully",
    "register_modal::date_choose": "Choose a date"
  }
}
</i18n>
