<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    :icon="icon"
    :title="title"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import SimpleButton from '../buttons/simple-button'

import { jsonToGeojson } from '@/utils'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'shahmatka',
      gridId: 'grid',
      title: 'Шахматка',
      icon: 'folder-layer',
      url: 'objectInfo/3fc2ce59-6654-4e78-aa58-77583cfae23b',
      urlGrid: 'chess',
      config: {
        mapPolygon: {
          type: 'fill',
          layout: {},
          paint: {
            'fill-color': 'rgb(38, 190, 179)',
            'fill-opacity': 0.1
          }
        },
        mapBorder: {
          type: 'line',
          paint: {
            'line-color': 'rgb(38, 190, 179)',
            'line-width': 2
          }
        },
        grid: {
          type: 'symbol',
          layout: {
            'text-field': ['get', 'text']
          },
          paint: {
            'text-color': 'rgb(38, 190, 179)'
          }
        }
      },
      layersIds: []
    }
  },
  methods: {
    async addHandler() {
      const { id, url, gridId, urlGrid } = this
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const { data: grid } = await this.$store.dispatch('GET_REQUEST', {
          url: urlGrid
        })
        if (!data) return
        const features = jsonToGeojson(Object.values(data))
        const gridFeatures = jsonToGeojson(grid)
        if (!features) return
        const source = this.mapgl.getSource(id)
        const gridSource = this.mapgl.getSource(gridId)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: features
          })
          this.addLayers(id)
          this.mapgl.addSource(gridId, {
            type: 'geojson',
            data: gridFeatures
          })
          this.addGridLayers(gridId)
        } else {
          source.setData(features)
          gridSource.setData(gridFeatures)
        }

        this.layersIds = [id, `${id}_border`, gridId]
      } catch (error) {
        console.warn(error)
      }
    },
    addLayers(id) {
      const { mapPolygon, mapBorder } = this.config

      this.mapgl.addLayer({
        id: `${id}`,
        source: id,
        ...mapPolygon
      })

      this.mapgl.addLayer(
        {
          id: `${id}_border`,
          source: id,
          ...mapBorder
        }
      )
    },
    addGridLayers(id) {
      const { grid } = this.config

      this.mapgl.addLayer({
        id: `${id}`,
        source: id,
        ...grid
      })
    }
  }
}
</script>
