<template>
  <div class="preventive-register-modal">
    <div class="preventive-register-modal__controls">
      <r-button
        type="success"
        class="preventive-register-modal__save-button"
        :disabled="!canSave"
        @click="save"
      >
        {{ $t('register_modal::save-button') }}
      </r-button>
      <r-button
        class="simple preventive-register-modal__exit-button"
        @click="close"
      >
        {{ $t('register_modal::close-button') }}
      </r-button>
    </div>

    <div class="preventive-register-modal__attribute-list">
      <div
        v-for="field in fields"
        :key="field.name"
        :style="getFieldPosition(field)"
        class="preventive-register-modal__attribute"
      >
        <r-text color-type="secondary">
          {{ field.name }}
        </r-text>
        <el-input
          v-if="field.type === 'text' || field.type === 'textarea'"
          v-model="source[field.model]"
          clearable
          :type="field.type"
          class="r-input"
        />
        <el-select
          v-else-if="field.type === 'select'"
          v-model="source[`${field.model}_id`]"
          filterable=""
          class="r-select"
          size="mini"
        >
          <el-option
            v-for="item in source.related[field.model].data"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
        <r-date-picker
          v-else-if="field.type === 'datetime'"
          v-model="source[field.model]"
          clearable
          type="datetime"
          class="r-input"
          :placeholder="$t('register_modal::date_choose')"
          default-time="12:00:00"
          format="dd.MM.yyyy HH:mm"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { fields, sources } from './config/fields'

export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sources,
      fields: fields.filter(f => f.read_only === undefined)
    }
  },
  computed: {
    canSave() {
      return !!this.source.assignment
    }
  },
  watch: {
    source: {
      handler: function() {
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
      },
      deep: true
    }
  },
  methods: {
    async save() {
      const { source, source_id } = this

      const url = `objectInfo/${source_id}`

      const data = {
        assignment: source.assignment,
        contractor_id: source.contractor_id,
        date_until: source.date_until,
        description: source.description,
        group_id: source.group_id,
        status_id: source.status_id,
        subgroup_id: source.subgroup_id,
        type_id: source.type_id
      }

      try {
        await this.$store.dispatch('POST_REQUEST', { url, data })

        const message = this.$t('register_modal::save_text')

        this.$notify(notifyFactory('succcess', message))
      } catch (e) {
        console.warn(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: this.sources.updateField,
          value: true
        })
        this.$store.commit('CLOSE_MODAL_WINDOW')
      }
    },
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    },
    getFieldPosition(field) {
      const width = {
        full: 2,
        half: 1
      }
      const grid = `span ${width[field.width] ?? 2}`
      return `grid-column: ${grid};`
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "register_modal::close-button": "Закрыть",
    "register_modal::save-button": "Сохранить",
    "register_modal::save_text": "Сохранение выполнено успешно",
    "register_modal::date_choose": "Выберите дату"
  },
  "en": {
    "register_modal::close-button": "Close",
    "register_modal::save-button": "Save",
    "register_modal::save_text": "Saved successfully",
    "register_modal::date_choose": "Choose a date"
  }
}
</i18n>
