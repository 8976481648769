<template>
  <div class="r-modal-card__content r-modal-card__content--columns">
    <div
      v-for="(item, i) in model"
      :key="i"
      :class="getClassList(item)"
      :style="getModelItemWidth(item)"
    >
      <template v-if="!item.divider">
        <div class="title-block">
          <div class="title-block__title">
            <div
              v-if="hasMark(item.model)"
              class="title-block__mark"
            />
            <r-title
              v-if="item.multiple && !isEditing"
              type="subtitle-2"
            >
              {{ `${item.title} ${item.required ? '*' : ''}` }}
            </r-title>
            <r-text
              v-else
              color-type="secondary"
            >
              {{ `${item.title} ${item.required ? '*' : ''}` }}
            </r-text>
          </div>
          <el-popover
            v-if="item.tooltip"
            placement="right-start"
            width="300"
            trigger="hover"
          >
            <r-text>
              {{ item.tooltip }}
            </r-text>
            <r-icon
              slot="reference"
              name="help"
              :size="22"
            />
          </el-popover>
        </div>
        <div
          v-if="!isEditing || item.read_only"
          class="r-modal-card__content"
        >
          <el-checkbox
            v-if="item.type === 'checkbox'"
            :value="source[item.model]"
            disabled
            class="r-checkbox"
          />
          <r-text
            v-else-if="!item.multiple && item.type"
            :color-type="disabled ? 'secondary' : ''"
          >
            {{ getValueByModel(item) }}
          </r-text>
          <div
            v-else-if="item.type"
            class="r-modal-card__sub-content"
          >
            <r-text v-if="!getValueByModel(item).length">
              —
            </r-text>
            <div
              v-for="(value, index) in getValueByModel(item)"
              :key="index"
            >
              <r-text color-type="secondary">
                {{ item.subtitle || 'ФИО, должность' }}
              </r-text>
              <r-text> {{ value }} </r-text>
            </div>
          </div>
        </div>

        <div
          v-else
          class="r-modal-card__attribute-input"
        >
          <el-input
            v-if="isInput(item) && item.parent"
            v-model="source[item.parent][item.model]"
            :rows="item.rows || 3"
            :resize="item.resize || 'none'"
            :type="item.type"
            :disabled="disabled"
            :clearable="!!item.clearable"
            :placeholder="item.placeholder || 'Введите текст'"
            class="r-input"
            @input="fixChanges"
          />
          <el-input
            v-if="isInput(item) && !item.parent"
            v-model="source[item.model]"
            :placeholder="item.placeholder"
            :resize="item.resize || 'none'"
            :disabled="disabled"
            :rows="item.rows || 3"
            :clearable="!!item.clearable"
            :type="item.type"
            class="r-input"
            @input="fixChanges"
          />
          <el-checkbox
            v-if="isCheckbox(item) && item.parent"
            v-model="source[item.parent][item.model]"
            :type="item.type"
            :disabled="disabled"
            class="r-checkbox"
            @input="fixChanges"
          />
          <el-checkbox
            v-if="isCheckbox(item) && !item.parent"
            v-model="source[item.model]"
            :disabled="disabled"
            :type="item.type"
            class="r-checkbox"
            @input="fixChanges"
          />
          <el-input-number
            v-if="isNumber(item) && item.parent"
            v-model="source[item.parent][item.model]"
            :disabled="disabled"
            :step="item.step || 1"
            :min="item.min || -Infinity"
            :max="item.max === 0 ? 0 : item.max || Infinity"
            :controls="!item.noControls"
            class="r-input"
            @change="fixChanges"
          />
          <el-input-number
            v-if="isNumber(item) && !item.parent"
            v-model="source[item.model]"
            :disabled="disabled"
            :step="item.step || 1"
            :min="item.min || -Infinity"
            :max="item.max === 0 ? 0 : item.max || Infinity"
            :controls="!item.noControls"
            class="r-input"
            @change="fixChanges"
          />
          <r-date-picker
            v-if="isDate(item) && item.parent"
            v-model="source[item.parent][item.model]"
            :type="item.type"
            :disabled="disabled"
            :placeholder="item.placeholder"
            :format="item.format"
            class="r-date-picker"
            :picker-options="{ firstDayOfWeek: 1 }"
            @input="fixChanges"
          />
          <r-date-picker
            v-if="isDate(item) && !item.parent"
            v-model="source[item.model]"
            :disabled="disabled"
            :placeholder="item.placeholder"
            :format="item.format"
            :type="item.type"
            class="r-date-picker"
            :picker-options="{ firstDayOfWeek: 1 }"
            @input="fixChanges"
          />
          <r-date-picker
            v-if="isDateTime(item) && item.parent"
            v-model="source[item.parent][item.model]"
            :type="item.type"
            :disabled="disabled"
            :placeholder="item.placeholder"
            :format="item.format"
            class="r-date-picker"
            :picker-options="{ firstDayOfWeek: 1 }"
            @input="fixChanges"
          />
          <r-date-picker
            v-if="isDateTime(item) && !item.parent"
            v-model="source[item.model]"
            :disabled="disabled"
            :placeholder="item.placeholder"
            :format="item.format"
            :type="item.type"
            class="r-date-picker"
            :picker-options="{ firstDayOfWeek: 1 }"
            @input="fixChanges"
          />
          <el-select
            v-else-if="isSelect(item) && item.parent"
            v-model="source[item.parent][item.model]"
            :clearable="item.clearable"
            :filterable="item.filterable"
            :disabled="disabled"
            :placeholder="item.placeholder"
            class="r-select"
            :popper-append-to-body="false"
            @input="fixChanges"
          >
            <el-option
              v-for="option in getList(item)"
              :key="option.id"
              :value="option.id"
              :label="getLabel(option, item)"
            />
          </el-select>
          <el-select
            v-else-if="isSelect(item) && !item.parent && !item.key"
            v-model="source[item.model]"
            :clearable="item.clearable"
            :filterable="item.filterable"
            :disabled="disabled"
            :placeholder="item.placeholder"
            class="r-select"
            :multiple="!!item.multiple"
            :popper-append-to-body="false"
            @input="fixChanges"
          >
            <el-option
              v-for="option in getList(item)"
              :key="option.id"
              :value="option.id"
              :label="getLabel(option, item)"
            />
          </el-select>
          <el-select
            v-else-if="isSelect(item) && !item.parent && item.key"
            v-model="source[item.model]"
            class="r-select"
            :disabled="disabled"
            :clearable="item.clearable"
            :filterable="item.filterable"
            :placeholder="item.placeholder"
            :multiple="!!item.multiple"
            :popper-append-to-body="false"
            @input="fixChanges"
          >
            <el-option
              v-for="option in getList(item)"
              :key="option.id"
              :value="option.id"
              :label="getLabel(option, item)"
            />
          </el-select>
          <el-switch
            v-else-if="item.type === 'boolean'"
            v-model="source[item.model]"
            :disabled="disabled"
            class="r-switch"
            @input="fixChanges($event, 'switch')"
          />
        </div>
        <div
          v-if="item.caption"
          class="r-modal-card__caption"
        >
          {{ item.caption }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getModelItemWidth } from './helpers'

export default {
  props: {
    model: {
      type: Array,
      required: true
    },
    related: {
      type: Object,
      default: () => {}
    },
    source: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    markItems: {
      type: Array,
      default: () => null
    }
  },
  data() {
    return {
      getModelItemWidth,
      changes: false
    }
  },
  watch: {
    changes(v) {
      if (v) this.$emit('hasChanges')
    }
  },
  methods: {
    hasMark(item) {
      return this.markItems?.includes(item)
    },
    fixChanges(val, type) {
      if (type !== 'switch') {
        this.changes = true
      } else if (val) {
        this.changes = true
      }
    },
    getClassList(item) {
      const list = []
      if (item.divider) list.push('with-divider')
      if (item?.multiple && !this.isEditing) {
        list.push('r-modal-card__block')
      } else {
        list.push('r-modal-card__input-field')
        if (item?.caption) list.push('with-caption')
      }
      if (this.isCheckbox(item)) {
        list.push('with-checkbox')
      }
      if (item?.column) {
        list.push('column')
      }
      if (item?.column_reverse) {
        list.push('column-reverse')
      }
      if (this.disabled) list.push('disabled')

      return list
    },
    getList(item) {
      if (!item.filter) {
        return this.related[item.select].data
      } else {
        const isNegative = item.filter[0] === '!'
        const filter = isNegative ? item.filter.replace('!', '') : item.filter

        return this.related[item.select].data.filter(d =>
          isNegative ? !d[filter] : d[filter]
        )
      }
    },
    getLabel(option, { label }) {
      return option.position
        ? `${option.name}, ${option.position}`
        : label
          ? option[label]
          : option.name || '—'
    },
    isSelect(item) {
      return item.type === 'select'
    },
    isInput(item) {
      return ['input', 'text', 'textarea'].includes(item.type)
    },
    isNumber(item) {
      return item.type === 'number'
    },
    isCheckbox(item) {
      return item?.type === 'checkbox'
    },
    isDateTime(item) {
      return item.type === 'datetime'
    },
    isDate(item) {
      return item.type === 'date' || item.type === 'year'
    },
    getValueByModel(item) {
      const {
        type,
        parent,
        model,
        select,
        key,
        additionalField,
        adjacent,
        handler,
        reflection,
        hardLink
      } = item

      if (hardLink) {
        const hardValue = model.split('.').reduce((a, c) => {
          a = a?.[c]
          return a
        }, this.source)

        return hardValue
      }

      const value = parent
        ? this.source[parent][model]
        : adjacent
          ? this.related[select]?.data.find(
            ({ id }) => id === this.source[adjacent]
          )?.[model]?.name
          : this.source[model]

      if (handler) {
        return handler(value)
      }

      switch (type) {
        case 'select': {
          if (reflection) {
            const { related, field, attr } = reflection
            const relatedItemId = this.related[select]?.data.find(
              ({ id }) => id === this.source[adjacent]
            )?.[field]
            const reflectionName = this.related[related]?.data.find(
              ({ id }) => id === relatedItemId
            )?.[attr]?.name

            return reflectionName || '—'
          } else if (key) {
            return this.related[select].data
              .filter(d => value.includes(d.id))
              .map(d => `${d.name}, ${d[additionalField]}`)
          } else if (adjacent) {
            return value || '—'
          } else {
            if (Array.isArray(value)) {
              return value?.map(va => {
                const sourceName = this.related[select]?.data.find(
                  d => d.id === va
                )
                return sourceName?.position
                  ? sourceName?.name + ', ' + sourceName?.position
                  : sourceName?.[item.label] ? sourceName?.[item.label]
                    : sourceName?.name || '—'
              })
            } else {
              const sourceName = this.related[select]?.data.find(
                d => d.id === value
              )
              return sourceName?.position
                ? `${sourceName?.name}, ${sourceName?.position}`
                : sourceName?.[item.label] ? sourceName?.[item.label]
                  : sourceName?.name || '—'
            }
          }
        }
        case 'boolean':
        case 'switch':
        case 'checkbox':
          return value ? 'Да' : (value ?? 'Не указано') || 'Нет'
        case 'datetime':
        case 'date': {
          const format = item.format ? item.format?.replace('yyyy', 'YYYY')?.replace('dd', 'DD') : 'DD.MM.YYYY'
          return value ? this.$ritmDate.toFormat(value, format) : '—'
        }
        default:
          return value || '—'
      }
    }
  }
}
</script>
