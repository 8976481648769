import vue from 'vue'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { mapToken } from '@/constants/tokens'
import { MapConfigEvents, getMapConfigs, ritmDate } from '@/utils'
import { eeEditorStyles } from '@/libs/map-draw/config'
// import { getScenarioModellingTileset } from "@/libs/map-draw/config";
import { ModesController } from '@/libs/map-draw/modes/modes-controller'
import {
  ModelController,
  LayerController,
  StyleController,
  DiagramController,
  EditorController
} from '../controllers'

import mapboxStyles from '@/constants/mapbox_styles'

export const initMap = component => {
  // map creating
  const { center, zoom, baselayer } = getMapConfigs(component, 'modelling')
  // const theme = component.$store.state.theme;
  // const disabledTiles = theme === "dark-theme" ? "light-theme" : "dark-theme";

  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'm-map',
    preserveDrawingBuffer: true,
    // style: getScenarioModellingTileset(theme),
    style: baselayer || mapboxStyles.dark,
    center,
    zoom
  })

  // component.mapgl.on("load", () =>
  //   component.mapgl.setLayoutProperty(disabledTiles, "visibility", "none")
  // );

  // view handlers
  component.mapgl.on('pitch', e => {
    component.is3d = e.target.getPitch() !== 0
  })
  component.mapgl.on('rotate', e => {
    component.mapBearing = e.target.getBearing()
  })

  // controllers
  component.controllers.events = new MapConfigEvents(component, 'modelling')

  component.mapgl.on('load', () => {
    component.controllers.events.addEventHandler()
  })

  // popup handlers
  component.mapgl.on('mousemove', e => {
    const { fullPath } = component.$route
    if (fullPath.includes('editor') || fullPath.includes('/main/list')) return

    const { activeLayers } = component.$store.state.modelling
    const mapLayers = activeLayers.filter(l => component.mapgl.getLayer(l))
    if (!mapLayers.length) return

    const modifiedLayers = [...mapLayers.map(n => `${n}_diagram`), ...mapLayers]
    const filteredLayers = modifiedLayers.filter(
      l => !!component.mapgl.getLayer(l)
    )

    const { x, y } = e.point
    const bbox = [
      [x - 1, y - 1],
      [x + 1, y + 1]
    ]
    const features = component.mapgl.queryRenderedFeatures(bbox, {
      layers: filteredLayers
    })

    if (!features.length) {
      component.mapgl.getCanvas().style.cursor = ''
      component.popupSettings.display = 'none'
      component.popupSettings.values = []
      return
    }

    component.mapgl.getCanvas().style.cursor = 'pointer'
    const feature = features[0]
    const { properties, layer } = feature
    const layerId = layer.id.includes('_') ? layer.id.split('_')[0] : layer.id
    const { stylesConfigs, objectFields } = component.$store.state.modelling
    const { fields, enabled } = stylesConfigs[layerId].popup
    const dateFields = ['start_time', 'end_time']
    if (fields.length && enabled) {
      component.popupSettings.values = fields.map(key => {
        const value = properties[key] === 'null' ? null : properties[key]
        return {
          key: objectFields[layerId].find(
            ({ title, alias }) => title === key && alias && alias !== key
          )?.alias || key,
          value: dateFields?.includes(key) ? ritmDate.toFormat(value, 'YYYY-MM-DD HH:mm') : value
        }
      })
      component.popupSettings.top = y - 38
      component.popupSettings.left = x + 10
      component.popupSettings.display = 'block'
    }
  })

  // controllers
  component.controllers.model = new ModelController(component)
  component.controllers.layers = new LayerController(component)
  component.controllers.styles = new StyleController(component)
  component.controllers.diagram = new DiagramController(component)

  // editor
  const mc = new ModesController(component, MapboxDraw, 'editorState')
  component.draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      line_string: true,
      point: true,
      polygon: true,
      trash: true
    },
    styles: eeEditorStyles,
    userProperties: true,
    modes: Object.assign(MapboxDraw.modes, {
      simple_select: mc.createMSimpleSelectMode(),
      draw_points: mc.createMDrawPointsMode()
    })
  })

  component.controllers.editor = new EditorController(component)
  component.controllers.editor.initDrawListeners()

  component.mapgl.addControl(component.draw)

  vue.prototype.$cControllers = component.controllers

  window.addEventListener('beforeunload', component.saveMapParams)
}
