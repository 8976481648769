<template>
  <div class="ee-card-attribute datetime">
    <r-text
      type="caption"
      style="margin-bottom: 8px;"
    >
      {{ config.label }}
    </r-text>
    <r-date-picker
      v-model="feature[config.prop]"
      class="r-date-picker"
      size="mini"
      format="dd.MM.yyyy HH:mm"
      type="datetime"
      align="right"
      placeholder="Выберите дату и время"
      :picker-options="{ firstDayOfWeek: 1 }"
      @change="changeHandler"
    />
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeHandler(val) {
      this.feature[this.config.prop] = this.$ritmDate.toIso(val)
    }
  }
}
</script>

<style lang="scss">
.ee-card-attribute.datetime {
  .el-date-editor {
    width: 100%;
  }
}
</style>
