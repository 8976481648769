import { reportOptions } from '../configs'
import { jsonToGeojson } from '@/utils'
import { layersConfig } from '../components/an-map/configs'
import { getTableData as GTB } from '../helpers'

export class MovementParkings {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl

    this.options = reportOptions.movement_and_parkings
  }

  getTableData(data) {
    return GTB(data, this.options)
  }

  getMapData(data) {
    const mapData = data?.map(e => {
      return {
        geom: e.geom,
        type: e.avg_speed > 0 ? 'move' : 'parking'
      }
    })?.filter(e => e.geom)
    const geojson = jsonToGeojson(mapData)

    return geojson
  }

  async addMapData(data) {
    this.mapgl.addSource('parking_movement', {
      type: 'geojson',
      data
    })

    this.mapgl.addLayer({
      id: 'parking_movement_line',
      source: 'parking_movement',
      ...layersConfig.parking_movement_line
    })

    this.mapgl.addLayer({
      id: 'parking_movement_point',
      source: 'parking_movement',
      ...layersConfig.parking_movement_point
    })

    this.mapgl.addLayer({
      id: 'parking_movement_arrows',
      source: 'parking_movement',
      ...layersConfig.parking_movement_arrows
    })
  }
}
