<template>
  <r-block
    v-if="isAdmin"
    no-padding
    col
  >
    <div
      v-if="isEditing"
      class="r-modal-card__controls r-modal-card__controls--edit-mode"
    >
      <r-button
        type="success"
        @click="$emit('clickHandler', 'save')"
      >
        Сохранить
      </r-button>
      <r-button
        simple
        @click="$emit('clickHandler', 'discard')"
      >
        Отменить все изменения
      </r-button>
    </div>
    <r-block
      v-else
      col
      no-padding
      space-between
    >
      <r-block
        no-padding
        start
        col
      >
        <r-button
          type="primary"
          @click="$emit('clickHandler', 'edit')"
        >
          Редактировать
        </r-button>
        <r-delete-button
          mini
          @delete="$emit('clickHandler', 'delete')"
        />
      </r-block>
      <r-button
        type="default"
        @click="printRoute"
      >
        Распечатать маршрут
      </r-button>
    </r-block>
  </r-block>
</template>

<script>
export default {
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    potItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.pot1.isAdmin
    }
  },
  methods: {
    printRoute() {
      window.open(`/print?m=pot-1&id=${this.potItem.id}`)
    }
  }
}
</script>
