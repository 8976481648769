const defaultConfig = {
  where: {
    field: 'geom',
    op: '!=',
    value: null
  }
}

export const getRequestList = layout => {
  const requestList = []
  const layoutList = [].concat(
    layout.left_block || [],
    layout.bottom_block || []
  )

  layoutList.forEach(e => {
    if (e.props && e.props.request && (e.props.request.url)) { requestList.push(e.props.request) }
  })

  return requestList.filter(e => e.url)
}

export const dataRequest = async function(
  state,
  request,
  comp,
  isEventLayer = false
) {
  const { type, url, propType, event_stat } = request

  try {
    const { data } = await state.$store.dispatch('GET_REQUEST', {
      url:
        type === 'objectInfo'
          ? `objectInfo/${url}?config=${JSON.stringify(defaultConfig)}`
          : url
    })
    let layerData

    if (type === 'statistic') {
      layerData = data
    } else {
      layerData = updateDataItemsId(isEventLayer ? 'events' : url, data)
    }

    const eventsClasses = this.$store.state.situationCenter.events.classes

    if (isEventLayer) {
      layerData = layerData.map(e => {
        e.event_class_id = eventsClasses[e.event_class_id]
          ? eventsClasses[e.event_class_id].name
          : 'Мероприятие'
        return e
      })
    }
    const eventStatId = `event_stat_${propType}`

    state.$store.commit('ADD_SI_CENTER_LAYER_DATA', {
      data: layerData,
      layer_id: isEventLayer ? 'events' : event_stat ? eventStatId : url,
      comp
    })
    const flag = url.includes('event_stat') ? eventStatId : url
    state.$store.commit('ADD_SI_CENTER_COMPLETED_REQUEST', flag)
    state.$store.commit('REMOVE_SI_CENTER_BAD_REQUEST', url)
  } catch (e) {
    console.warn(e)
    state.$store.commit('ADD_SI_CENTER_BAD_REQUEST', url)
  }
}

const updateDataItemsId = function(layer_id, data) {
  if (layer_id === 'api/py/iss/cameras') {
    return Object.keys(data)
      .map(id => data[id])
      .map((e, i) => {
        e.id = `${layer_id}?id:${i}`
        return e
      })
  } else {
    return Object.keys(data)
      .map(id => data[id])
      .map(e => {
        e.id = `${layer_id}?id:${e.id || e.created_at}`
        return e
      })
  }
}
