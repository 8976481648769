<template>
  <div class="mt-map">
    <div id="mt-map" />

    <div
      v-if="hasModule('Layers Top Panel') && mapReady"
      class="mt-map__layers"
    >
      <r-info-layers
        ref="infoLayers"
        :mapgl="mapgl"
        module="monitoring"
        :enabled-layers="[
          'graph',
          'transportSituation',
          'geozones',
          'signs',
          'events',
          'tilelayers'
        ]"
      />
    </div>

    <div class="mt-map__actions">
      <r-map-tools
        v-if="mapgl"
        :tools="['baselayers', '3d', 'reset', 'screenshot']"
        :map="mapgl"
        :map-bearing="mapBearing"
        :is3d="is3d"
        :baselayer-id="baselayerId"
        @change-prop="changeProp"
        @toggle-base-layer="toggleBaselayer"
      />
      <mt-legend />
    </div>
    <r-map-loader v-if="vehiclesLoading" />
    <mt-popup :settings="popupSettings" />
    <track-player
      v-if="mainTrackGeom"
      :mapgl="mapgl"
    />
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import throttle from 'lodash.throttle'
import extent from 'turf-extent'
import { initMap, loadIcons } from './core'
import MtLegend from './components/legend'
import MtPopup from './components/popup'
import TrackPlayer from '@/components/monitoring/track-player'
import { getMapConfigs, jsonToGeojson } from '@/utils'

export default {
  components: {
    MtLegend,
    MtPopup,
    TrackPlayer
  },
  data() {
    return {
      mapgl: null,
      mapBearing: null,
      is3d: false,
      controllers: {},
      timer: null,
      popupSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      },
      journalPopup: null,
      mapReady: false
    }
  },
  computed: {
    vehiclesLoading: {
      get() {
        return this.$store.state.monitoring.vehiclesLoading
      },
      set(value) {
        this.$store.commit('SET_MT_FIELD', {
          field: 'vehiclesLoading',
          value
        })
      }
    },
    trackColors() {
      return this.$store.state.monitoring.trackColors
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.monitoring || 5
    },
    mainTrackGeom() {
      return this.$store.state.monitoring.mainTrackGeom || null
    }
  },
  watch: {
    '$store.state.monitoring.cardId'(id) {
      this.mapgl.resize()

      this.controllers.vehicle.toggleActiveStyling(id)
      this.controllers.vehicle.toggleVehicleCard(id)
    },
    '$store.state.monitoring.telematicsData'(data) {
      const layerId = 'telematicsPoint'
      const source = this.mapgl.getSource(layerId)
      const layer = this.mapgl.getLayer(layerId)

      if (!data) {
        if (source) {
          if (layer) this.mapgl.removeLayer(layerId)
          this.mapgl.removeSource(layerId)
        }
        this.controllers.track.hidePopup()
        return
      }

      const { geom, speed, time } = data
      const { coordinates } = geom

      this.controllers.track.showPopup({ coordinates, speed, time })

      const pointData = jsonToGeojson([data])

      if (source) source.setData(pointData)
      else {
        this.mapgl.addSource(layerId, {
          type: 'geojson',
          data: pointData
        })
        this.mapgl.addLayer({
          id: layerId,
          source: layerId,
          type: 'circle',
          paint: {
            'circle-radius': 10,
            'circle-color': '#5398E0'
          }
        })
      }

      this.$store.commit('SET_MT_FIELD', {
        field: 'flyToGeom',
        value: data.geom
      })
    },
    '$store.state.monitoring.isVehicleListOpened'() {
      this.mapgl.resize()
    },
    mainTrackGeom(value) {
      this.controllers.track.toggleTrack(value, 'main')
    },
    '$store.state.monitoring.secondTrackGeom'(value) {
      this.controllers.track.toggleTrack(
        this.$store.state.monitoring.mainTrackGeom,
        'main'
      )
      this.controllers.track.toggleTrack(value, 'second')
    },
    '$store.state.monitoring.filterCategories': {
      handler: function() {
        this.controllers.vehicle.updateVehicles()
      },
      deep: true
    },
    '$store.state.monitoring.statusFilterUpdated'(value) {
      if (value) {
        this.controllers.vehicle.updateVehicles()

        this.$store.commit('SET_MT_FIELD', {
          field: 'statusFilterUpdated',
          value: false
        })
      }
    },
    '$store.state.monitoring.flyToGeom': function(geom) {
      if (!geom) return

      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 17
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'flyToGeom',
        value: null
      })
    },
    '$store.state.charts.list.mt_track_chart.datazoom': {
      handler: throttle(function() {
        this.controllers.track.updateTracks()
      }, 500),
      deep: true
    },
    '$store.state.charts.list.mt_track_chart.clickHandler': {
      handler: function(value) {
        this.controllers.track.showPointOnMap(value)
      },
      deep: true
    },
    '$store.state.monitoring.journalMapPoint'(value) {
      if (value) {
        this.showJournalPopup(value)
      }
    }
  },
  mounted() {
    this.$socket.emit('enter_module', 'monitoring')
    initMap(this)

    this.mapgl.once('load', () => {
      this.mapReady = true
    })
  },
  beforeDestroy() {
    this.$socket.emit('enter_module', 'monitoring_exit')
    window.removeEventListener('beforeunload', this.saveMapParams)
    this.saveMapParams()

    this.controllers.vehicle.stopVehicleAnimation()
    this.controllers.vehicle.stopSocketConnection()

    if (this.timer) {
      clearInterval(this.timer)
    }

    // close panels
    this.$store.commit('SET_MT_FIELD', {
      field: 'cardId',
      value: null
    })
    this.$store.commit('SET_MT_FIELD', {
      field: 'isVehicleListOpened',
      value: null
    })
  },
  methods: {
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    async getVehicleCategory() {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: 'objectInfo/telemetry.categories'
      })
      const { id } = Object.values(data).find(e => e.name === 'Внутренние ТС')

      this.$store.commit('SET_MT_FIELD', {
        field: 'categoryId',
        value: id
      })
    },
    saveMapParams() {
      const zoom = this.mapgl.getZoom()
      const center = this.mapgl.getCenter()

      localStorage.setItem('mtMapCenter', JSON.stringify(center))
      localStorage.setItem('mtMapZoom', JSON.stringify(zoom))
    },
    changeProp({ field, value }) {
      this[field] = value
    },
    toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'monitoring',
        value: id
      })
      const { baselayer } = getMapConfigs(this, 'monitoring')

      this.mapgl.setStyle(baselayer)
      this.mapgl.once('style.load', async() => {
        const { mainTrackGeom, secondTrackGeom } = this.$store.state.monitoring

        await loadIcons(this)
        await this.$refs.infoLayers.rerenderComponent()
        await this.controllers.vehicle.addVehicles()
        this.controllers.track.toggleTrack(mainTrackGeom, 'main')
        this.controllers.track.toggleTrack(secondTrackGeom, 'second')
      })
    },
    showJournalPopup(event) {
      if (this.journalPopup) {
        this.journalPopup.remove()
      }

      const { reg_number, event_type, event_time, event_geom } = event

      if (!event_geom) return

      const bounds = extent(event_geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 17
      })

      this.journalPopup = new mapboxgl.Popup({ closeOnClick: true })
        .setLngLat(event_geom.coordinates)
        .setHTML(
          `
          <ul>
            <li>ГРЗ: <b>${reg_number}</b></li>
            <li>Время события: <b>${event_time}</b></li>
            <li>Тип события: <b>${event_type}</b></li>
          </ul>
        `
        )
        .addTo(this.mapgl)
    }
  }
}
</script>

<style lang="scss">
.mt-map {
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1;

  &__layers {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .map-loader {
    top: auto;
    bottom: 16px;
  }

  #mt-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
  .mapboxgl-ctrl-top-right {
    display: none;
  }

  .mapboxgl-popup {
    &-content {
      padding: 8px;
      background-color: var(--bg_surface) !important;
      color: var(--text_primary) !important;

      li {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: var(--bg_surface) !important;
  }
}
</style>
