<template>
  <div class="restriction-modal">
    <div class="restriction-modal__controls">
      <r-button
        type="success"
        class="restriction-modal__save-button"
        :disabled="!hasChanges"
        @click="save"
      >
        Сохранить
      </r-button>
      <r-button
        class="simple restriction-modal__exit-button"
        simple
        @click="$emit('mode', 'view')"
      >
        Выйти без изменений
      </r-button>
    </div>

    <div class="restriction-modal__attribute-list">
      <div class="restriction-modal__attribute restriction-modal__attribute">
        <r-text type="caption">
          Дата создания
        </r-text>
        <r-text color-type="secondary">
          {{ $ritmDate.toFormat(event.created_at, 'DD.MM.YYYY • HH:mm') }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text type="caption">
          Актуальность
        </r-text>
        <r-text color-type="secondary">
          {{ event.relevance }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Название
        </r-text>
        <el-input
          v-model="event.name"
          clearable
          @input="hasChanges = true"
        />
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Тип
        </r-text>
        <el-select
          v-model="event.event_class_id"
          class="r-select"
          size="mini"
          @input="hasChanges = true"
        >
          <el-option
            v-for="item in event.eventClasses"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Адрес
        </r-text>
        <el-input
          v-model="event.address"
          clearable
          @input="hasChanges = true"
        />
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Перекрытие
        </r-text>
        <!-- <el-select
          v-model="event.closed_line_count"
          class="r-select"
          size="mini"
          @input="hasChanges = true"
        >
          <el-option
            v-for="a in restrictionArea"
            :key="a.id"
            :value="a.id"
            :label="a.label"
          />
        </el-select> -->
        <el-input-number
          v-model="event.closed_line_count"
          :step="0.1"
          :min="0"
          class="r-input"
          @input="toggleChanges"
        />
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Начало
        </r-text>
        <r-date-picker
          v-model="event.start_time"
          clearable
          type="datetime"
          class="r-input"
          placeholder="Выберите дату"
          format="dd.MM.yyyy HH:mm"
          :picker-options="{ firstDayOfWeek: 1 }"
          @input="hasChanges = true"
        />
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Окончание
        </r-text>
        <r-date-picker
          v-model="event.end_time"
          clearable
          type="datetime"
          class="r-input"
          placeholder="Выберите дату"
          format="dd.MM.yyyy HH:mm"
          :picker-options="{ firstDayOfWeek: 1 }"
          @input="hasChanges = true"
        />
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Заявка
        </r-text>
        <el-switch
          v-model="event.requested"
          class="r-switch"
          @input="hasChanges = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      hasChanges: false,
      restrictionArea: [
        { id: 1, label: 'Полное' },
        { id: 0, label: 'Нет' },
        { id: 0.5, label: 'Частичное' }
      ]
    }
  },
  watch: {
    hasChanges(val) {
      if (val) {
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
      }
    }
  },
  mounted() {
    this.ready = true
  },
  methods: {
    toggleChanges() {
      if (this.ready) {
        this.hasChanges = true
      }
    },
    async save() {
      const { event, source_id } = this

      const url = `objectInfo/${source_id}`

      const data = {
        id: event.id,
        name: event.name,
        start_time: event.start_time,
        end_time: event.end_time,
        closed_line_count: event.closed_line_count,
        address: event.address,
        event_class_id: event.event_class_id,
        requested: event.requested
      }

      try {
        await this.$store.dispatch('POST_REQUEST', { url, data })

        const title = 'Сохранение выполнено'
        const message = 'Объект успешно сохранен'
        this.$notify(notifyFactory('succcess', title, message))

        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateRestrictions',
          value: true
        })
      } catch (e) {
        console.warn(e)
      } finally {
        this.$emit('mode', 'view')
        this.$store.commit('CLOSE_MODAL_WINDOW')
      }
    }
  }
}
</script>

<style lang="scss">
.restriction-modal {
  &__attribute {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.25rem;
  }
}
</style>
