<template>
  <div class="ee-card-attribute select">
    <r-text
      type="caption"
      style="margin-bottom: 8px;"
    >
      {{ config.label }}
    </r-text>
    <div class="ee-card-attribute-content">
      <el-select
        v-if="values[config.values]"
        v-model="feature[config.prop]"
        class="r-select"
        size="mini"
      >
        <el-option
          v-for="item in values[config.values]"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      values: {}
    }
  },
  created() {
    if (this.config.request) {
      this.getData()
    }
  },
  methods: {
    async getData() {
      try {
        const url = `objectInfo/${this.config.request}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        this.$set(this.values, this.config.values, Object.values(data))
      } catch (error) {
        console.warn(error)
      }
    }
  }
}
</script>

<style lang="scss">
.ee-card-attribute.select {
  .ee-card-attribute-content {
    .el-select {
      width: 100%;
    }
  }
}
</style>
