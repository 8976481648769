<template>
  <div
    v-loading="loading"
    class="pt-route-transport"
  >
    <div class="pt-route-transport__on-route pt-route-transport__field">
      <r-title type="subtitle-2">
        Транспорт на маршруте
      </r-title>
      <div class="on-route__container transport-list">
        <div
          v-for="(transport, index) in onRouteList"
          :key="index"
          :class="[
            'transport-list__item',
            {
              'pt-route-transport__field': routeClipBoard.find(
                item => item.id === transport.id
              )
            }
          ]"
          @click="selectItem(transport.id)"
        >
          <r-icon
            name="bus-hollow"
            :size="32"
          />
          <r-text>
            {{ transport.reg_number }}
          </r-text>
          <r-text type="caption">
            {{ transport.contractor ? transport.contractor.name : '' }}
          </r-text>
        </div>
      </div>
    </div>

    <div class="pt-route-transport__controls">
      <r-button
        type="primary"
        :disabled="!routeClipBoard.length"
        icon="chevron-right"
        @click="moveTo('all')"
      />
      <r-button
        type="primary"
        :disabled="!allClipBoard.length"
        icon="chevron-left"
        @click="moveTo('route')"
      />
    </div>

    <div class="pt-route-transport__all pt-route-transport__field">
      <r-title type="subtitle-2">
        Весь транспорт
      </r-title>
      <r-search-input
        :filter-text="filterText"
        @change="e => searchChange(e)"
      />
      <div class="all-transport__container transport-list">
        <div
          v-for="(transport, index) in filteredList"
          :key="index"
          :class="[
            'transport-list__item',
            {
              'pt-route-transport__field': allClipBoard.find(
                item => item.id === transport.id
              )
            }
          ]"
          @click="selectItem(transport.id)"
        >
          <r-icon
            name="bus-hollow"
            :size="32"
          />
          <r-text>
            {{ transport.reg_number }}
          </r-text>
          <r-text type="caption">
            {{ transport.contractor ? transport.contractor.name : '' }}
          </r-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hashToArray } from '@/utils'
import cloneDeep from 'lodash.clonedeep'

export default {
  data() {
    return {
      loading: false,
      filterText: '',
      transportList: [],
      onRouteList: [],
      allClipBoard: [],
      unFilteredList: [],
      routeClipBoard: []
    }
  },
  computed: {
    allTransportList() {
      return this.transportList
    },
    cardInfo() {
      return this.$store.state.publicTransport.cardInfo
    },
    allOnRouteList() {
      return this.onRouteList
    },
    filteredList() {
      return this.transportList.filter(
        e =>
          (e.contractor?.name &&
            e.contractor?.name
              .toLowerCase()
              .indexOf(this.filterText.toLowerCase().trim()) > -1) ||
          e.reg_number
            .toLowerCase()
            .indexOf(this.filterText.toLowerCase().trim()) > -1
      )
    }
  },
  created() {
    this.getTransportList()
  },
  methods: {
    async getTransportList() {
      const sourceId = 'f09e59fb-ee58-482c-8b0f-9ac520cf2099'
      const config = {
        only: ['id', 'reg_number'],
        include: {
          line_vehicle: { only: ['id', 'line_id'] },
          categories: { only: ['name'] },
          contractor: { only: ['name'] }
        },
        where: [
          {
            field: 'categories.name',
            op: 'like',
            value: 'ПАТ'
          }
        ]
      }

      try {
        this.loading = true

        const url = `objectInfo/${sourceId}?config=${JSON.stringify(config)}`
        const res = await this.$store.dispatch('GET_REQUEST', { url })
        const data = hashToArray(res.data)

        const { id } = this.$store.state.publicTransport.editorState.properties

        if (id) {
          this.transportList = data.filter(t => !t.line_vehicle?.find(v => v.line_id === id))
          this.onRouteList = data.filter(t => t.line_vehicle?.find(v => v.line_id === id))

          this.$store.commit('SET_PT_EDITOR_PROP', {
            field: 'initialRouteTransport',
            value: cloneDeep(this.onRouteList)
          })
          this.$store.commit('SET_PT_EDITOR_PROP', {
            field: 'routeTransport',
            value: this.onRouteList
          })
        } else {
          this.transportList = data
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },
    searchChange(e) {
      this.filterText = e
    },
    selectItem(id) {
      let alreadyIn
      let index = this.allTransportList.findIndex(item => item.id === id)
      if (index >= 0) {
        alreadyIn = this.allClipBoard.findIndex(item => item.id === id)
        alreadyIn >= 0
          ? this.allClipBoard.splice(alreadyIn, 1)
          : this.allClipBoard.push(this.transportList[index])
      } else {
        index = this.allOnRouteList.findIndex(item => item.id === id)
        alreadyIn = this.routeClipBoard.findIndex(item => item.id === id)
        alreadyIn >= 0
          ? this.routeClipBoard.splice(alreadyIn, 1)
          : this.routeClipBoard.push(this.onRouteList[index])
      }
    },
    moveTo(destination) {
      if (destination === 'route') {
        this.onRouteList.push(...this.allClipBoard)
        this.allClipBoard.forEach(item => {
          const index = this.transportList.findIndex(
            mainItem => mainItem.id === item.id
          )
          this.transportList.splice(index, 1)
        })
        this.allClipBoard = []
      } else if (destination === 'all') {
        this.transportList.push(...this.routeClipBoard)
        this.routeClipBoard.forEach(item => {
          const index = this.onRouteList.findIndex(
            mainItem => mainItem.id === item.id
          )
          this.onRouteList.splice(index, 1)
        })
        this.routeClipBoard = []
      }

      this.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'routeTransport',
        value: this.onRouteList
      })
    }
  }
}
</script>

<style lang="scss">
.pt-route-transport {
  display: grid;
  grid-template-columns: 300px 40px auto;
  grid-auto-flow: row;
  height: 100%;
  width: 100%;
  grid-gap: 1rem;

  &__field {
    background-color: var(--bg_containers);
    padding: 0.5rem;
    border-radius: var(--border-radius);
  }

  &__on-route {
    overflow: hidden;
    display: grid;
    grid-gap: 0.5rem;
    align-content: start;
    .transport-list {
      height: calc(100% - 1rem) !important;
    }
  }
  .transport-list {
    display: grid;
    align-content: start;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    height: calc(100% - 4.5rem);
    overflow-y: auto;
    grid-gap: 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0;
    &__item {
      min-width: 150px;
      display: grid;
      height: 55px;
      box-sizing: border-box;
      padding: 0.5rem 0.25rem;
      grid-template-columns: 50px 1fr;
      grid-template-areas: 'icon reg' 'icon contr';
      cursor: pointer;
      .r-icon {
        grid-area: icon;
      }
      .default {
        grid-area: reg;
      }
      .caption {
        grid-area: contr;
      }
    }
  }
  &__all {
    height: 100%;
    overflow: hidden;
  }
  &__controls {
    display: grid;
    grid-gap: 1rem;
    width: 100%;
    grid-auto-flow: row;
    align-self: center;
    justify-content: center;
  }
}
</style>
