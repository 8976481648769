import { ritmDate } from '@/utils'
import { reportOptions } from '../configs'

export class ViolationsReport {
  constructor(state) {
    this.$store = state.$store
    this.state = state
  }

  getTableData(data) {
    const { columnsOrder } = reportOptions?.violations_report || []

    return data?.map(e => {
      const item = {}

      columnsOrder.forEach(k => {
        const attrName = k?.from || k.id

        if (attrName === 'Дата происшествия') {
          e[attrName] = e[attrName] ? ritmDate.toFormat(e[attrName], 'DD.MM.YY') : '-'
        }

        if (attrName.includes('.')) {
          const currectName = attrName.replaceAll('.', '')

          item[currectName] = e[attrName]

          if (Array.isArray(item[currectName])) {
            if (item[currectName]?.length) return item[currectName].map(a => a?.name).join(', ')
          }
          if (item[currectName] !== 0 && !item[currectName] && !item[currectName]?.length) item[currectName] = '-'
        } else {
          item[attrName] = e[attrName]
          if (Array.isArray(item[attrName])) {
            if (item[attrName]?.length) return item[attrName].map(a => a?.name).join(', ')
          }
          if (item[attrName] !== 0 && !item[attrName] && !item[attrName]?.length) item[attrName] = '-'
        }
      })

      return item
    })
  }
}
