<template>
  <div class="r-table-list-wrapper">
    <el-table
      ref="table"
      :class="[
        `r-table-list ${whiteSpace || ''}`,
        { bordered },
        { cellBordered }
      ]"
      :data="initialData"
      :span-method="spanMethod"
      :row-class-name="tableRowClassName"
      style="width: 100%"
      :highlight-current-row="selectable"
      @row-click="rowClick"
    >
      <el-table-column
        v-for="(prop, i) in columns"
        :key="prop"
        :prop="prop"
        :label="getLabel(prop)"
        :width="i === 0 && numCol ? '64' : ''"
        :min-width="getMinWidth(prop)"
      />
      <el-table-column
        v-if="!!actions"
        label="Действия"
        :min-width="getMinWidth()"
      >
        <template slot-scope="scope">
          <slot
            name="actions"
            :row="scope.row"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="isPagination"
      class="r-pagination"
      :current-page.sync="currentPage"
      :page-sizes="[5, 10, 25, 50, 100]"
      :page-size="pageSize"
      layout="prev, pager, next, sizes"
      :total="totalObject"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    sortBy: {
      type: Object,
      default: () => null
    },
    fieldsNames: {
      type: Object,
      default: () => null
    },
    excludedColumns: {
      type: Array,
      default: () => null
    },
    includeColumns: {
      type: Array,
      default: () => null
    },
    fieldsSortBy: {
      type: Object,
      default: () => null
    },
    bordered: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Boolean,
      default: false
    },
    numCol: {
      type: Boolean,
      default: false
    },
    hideEmptyGroups: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    paginator: {
      type: Boolean,
      default: false
    },
    whiteSpace: {
      type: String,
      default: null
    },
    objectSpanMethod: {
      type: Function,
      default: null
    },
    cellBordered: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: '80'
    },
    customWidth: {
      type: Object,
      default: () => null
    },
    pagyResponse: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 25
    }
  },
  computed: {
    isPagination() {
      if (this.pagyResponse) {
        return true
      } else {
        return this.data?.length > 50
      }
    },
    totalObject() {
      if (this.pagyResponse) {
        return this.pagyResponse?.count || 0
      } else {
        return this.tableData?.length || 0
      }
    },
    initialData() {
      if (!this.paginator || this.pagyResponse) {
        return this.tableData
      } else {
        const start =
          this.currentPage === 1 ? 0 : (this.currentPage - 1) * this.pageSize
        const end =
          this.currentPage === 1
            ? this.totalObject < this.pageSize
              ? this.totalObject
              : this.currentPage * this.pageSize
            : this.currentPage * this.pageSize > this.totalObject
              ? this.totalObject
              : this.currentPage * this.pageSize

        return this.tableData?.slice(start, end) || []
      }
    },
    columns() {
      if (!this.data?.length || this.includeColumns?.length) {
        return this.includeColumns || ['id']
      }
      const fields = []
      const exceptions = this.excludedColumns?.length
        ? this.excludedColumns
        : ['id']

      for (const prop in this.data[0]) fields.push(prop)
      if (this.sortBy) exceptions.push(this.sortBy.propTitle)
      if (!this.fieldsSortBy) {
        return fields.filter(e => exceptions.indexOf(e) === -1)
      } else {
        return fields
          .filter(e => exceptions.indexOf(e) === -1)
          .sort((a, b) => {
            const valueA = this.fieldsSortBy[a]?.order
            const valueB = this.fieldsSortBy[b]?.order

            if (valueA > valueB) return 1
            if (valueA < valueB) return -1
            return 0
          })
      }
    },
    tableData() {
      if (!this.sortBy) return this.data
      const data = []
      const sortValue = this.sortBy.propTitle
      const mappedData = this.data.map(e => {
        e.order = this.sortBy.values.find(n => n.value === e[sortValue])?.order
        return e
      })

      const condition = e =>
        this.hideEmptyGroups
          ? this.data.filter(d => d[sortValue] === e.value)?.length
          : true

      this.sortBy.values.forEach(e => {
        if (condition(e)) {
          const item = { id: `${e.title + e.id}`, rowSpan: true, filled: true }
          item[this.columns[0]] = `${e.order} • ${e.title}`.toUpperCase()
          item[sortValue] = e.value
          item.order = e.order
          data.push(item)
        }
      })
      data.push(...mappedData)
      if (!this.hideEmptyGroups) {
        this.sortBy.values.forEach(e => {
          if (data.filter(k => k[sortValue] === e.value)?.length <= 1) {
            const item = {
              id: `no-data:${e.id}`,
              rowSpan: true,
              textColor: 'secondary'
            }
            item[this.columns[0]] = 'Нет объектов в списке'
            item[sortValue] = e.value
            item.order = e.order
            data.push(item)
          }
        })
      }

      return data.sort((a, b) => {
        return a.order > b.order ? 1 : a.order < b.order ? -1 : 0
      })
    }
  },
  created() {
    if (this.pagyResponse) {
      this.pageSize = this.pagyResponse.limit
      this.currentPage = this.pagyResponse.page
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'pagy',
        value: {
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'pagy',
        value: {
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      })
    },
    getMinWidth(prop) {
      return this.customWidth?.[prop] || this.minWidth
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.objectSpanMethod) {
        return this.objectSpanMethod({ row, column, rowIndex, columnIndex })
      } else {
        if (row.rowSpan) return [1, this.columns.length]
      }
    },
    tableRowClassName({ row }) {
      let className = ''
      if (row.filled) className = className + ' row-header'
      if (row.textColor) className = className + ' text-secondary'
      return className
    },
    highlightRow(object) {
      this.$refs.table.setCurrentRow(object)
    },
    rowClick(object) {
      if (this.selectable) {
        this.highlightRow(object)
      }
      if (object.rowSpan) return
      this.$emit('click-handler', object)
    },
    getLabel(prop) {
      return this?.fieldsNames?.[prop] || prop
    }
  }
}
</script>

<style lang="scss">
.r-table-list-wrapper {
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: none !important;
  min-height: 100%;
}
.r-table-list {
  .cell {
    word-break: break-word !important;
  }
  &.break-spaces {
    .cell {
      white-space: break-spaces;
    }
  }
  &.pre-wrap {
    .cell {
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  &.el-table {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: none !important;

    .el-table__header-wrapper {
      flex-shrink: 0;
    }

    .el-table__body-wrapper {
      overflow-y: auto;
    }

    th {
      background-color: var(--table_header_bg) !important;
      cursor: initial;
      border-color: var(--dividers_low_contrast) !important;

      .cell {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        display: flex;
        color: var(--text_subhead) !important;
      }
    }

    .el-table__row.current-row {
      td {
        background-color: var(--accent_selected) !important;
      }
    }

    tr {
      background-color: transparent !important;
      cursor: pointer;

      .el-table__cell {
        background-color: transparent;
      }

      &:hover {
        td {
          background-color: var(--accent_hover) !important;
        }
      }

      &.row-header {
        background-color: var(--table_header_bg) !important;
        cursor: initial;

        td {
          border: none !important;

          .cell {
            font-size: 14px;
            font-weight: 600;
            color: var(--text_primary) !important;
          }
        }

        &:hover {
          td {
            background: none !important;
          }
        }
      }

      &.text-secondary {
        cursor: initial;
        td {
          .cell {
            color: var(--text_secondary) !important;
          }
        }
        &:hover {
          td {
            background: none !important;
          }
        }
      }
    }

    td {
      border: none !important;
      transition: all 0.1s ease !important;
      .cell {
        font-size: 14px;
        color: var(--text_primary) !important;
        font-weight: 400;
      }
    }

    &::before {
      height: 0;
    }

    &.bordered {
      td {
        border-bottom: 1px solid !important;
        border-color: var(--dividers_low_contrast) !important;
      }
    }

    &.cellBordered {
      td {
        border: 1px solid !important;
        border-color: var(--dividers_low_contrast) !important;
      }
    }
  }
}
</style>
