<template>
  <div class="r-info-layers__popover-button">
    <el-popover
      placement="bottom"
      width="auto"
      trigger="hover"
      transition="none"
      @show="toggleHandler(true)"
      @hide="toggleHandler(false)"
    >
      <div
        slot="reference"
        :class="['r-dropdown__item', { active: isLayerActive(layerId) }]"
        @click="toggleLayer"
      >
        <r-icon
          :name="icon"
          :size="24"
          style="cursor:pointer"
          :color-type="isLayerActive(layerId) ? 'accent-primary' : ''"
        />
      </div>

      <slot />
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    layerId: {
      type: String,
      required: true
    },
    layersIds: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    addHandler: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isDropdownActive: false
    }
  },
  computed: {
    activeLayers() {
      const { mapConfigs } = this.$store.state
      const active = mapConfigs?.activeLayers?.[this.module] || []

      return Array.from(new Set(active))
    }
  },
  created() {
    if (this.activeLayers.includes(this.layerId)) {
      this.addHandler(this.layerId)
    }
  },
  methods: {
    toggleHandler(val) {
      this.isDropdownShow = val
    },
    isLayerActive(id) {
      if (!this.activeLayers) return false

      return this.activeLayers.includes(id)
    },
    getLayerClassName(id) {
      if (this.isLayerActive(id)) {
        return 'r-info-layers__item active'
      }

      return 'r-info-layers__item'
    },
    toggleLayer() {
      const layerId = this.layerId

      if (this.isLayerActive(layerId)) {
        this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
          field: 'activeLayers',
          module: this.module,
          value: this.activeLayers.filter(item => item !== layerId && !!item)
        })
        this.layersIds.forEach(id => {
          if (this.mapgl.getLayer(id)) {
            this.mapgl.removeLayer(id)
          }
        })
        this.layersIds.forEach(id => {
          if (this.mapgl.getSource(id)) {
            this.mapgl.removeSource(id)
          }
        })
      } else {
        this.addHandler(layerId)
        this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
          field: 'activeLayers',
          module: this.module,
          value: [...this.activeLayers, layerId]
        })
      }
    }
  }
}
</script>

<style lang="scss">
.r-info-layers {
  &__popover-button {
    width: 36px;
    height: 36px;
    border-radius: var(--border-radius);
    cursor: pointer;

    .r-icon {
      height: 36px;
      width: 100%;
    }

    &.active {
      background-color: var(--accent_selected) !important;
    }

    &:hover {
      background-color: var(--accent_hover) !important;
    }
  }
}
</style>
