<template>
  <div class="ee-model-list">
    <r-title
      type="title-2"
      style="margin-bottom: 16px"
    >
      Выберите модель
    </r-title>
    <div
      v-loading="loading"
      class="ee-model-list-content"
    >
      <div class="ee-model-list-search">
        <el-input
          v-model="filter"
          prefix-icon="el-icon-search"
          class="r-input"
          placeholder="Поиск по названию города"
        />
      </div>
      <ul
        v-if="filteredModels.length"
        class="ee-model-list-list"
      >
        <li
          v-for="item in filteredModels"
          :key="item.id"
          class="ee-model-list-item"
          @click="changeModel(item)"
        >
          <div class="ee-model-list-item-icon">
            <r-icon
              name="model-layer"
              :size="18"
            />
          </div>
          <div class="pt-model-list-item-info">
            <r-text>{{ item.name }}</r-text>
            <r-text type="caption">
              {{ item.children.length }} внутр. ИД
            </r-text>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="ee-model-list-nodata"
      >
        <r-text type="caption">
          Нет доступных моделей
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      models: [],
      model: '',
      filter: '',
      loading: false
    }
  },
  computed: {
    filteredModels() {
      return this.models.filter(m =>
        m.name.toLowerCase().includes(this.filter.toLowerCase())
      )
    }
  },
  async created() {
    const { id } = this.$route.query

    await this.loadModelList()

    if (id) {
      const model = this.models.find(m => m.id === id)

      this.$router.replace({ query: {} })
      this.changeModel(model)
    }
  },
  methods: {
    changeModel(model) {
      this.$emit('change-model', model)
    },
    async loadModelList() {
      try {
        this.loading = true
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url: 'user/datasources'
        })

        this.models = data.filter(e => e.is_main_model)
        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.ee-model-list {
  &-content {
    min-height: 64px;
  }

  &-search {
    margin-bottom: 8px;
  }

  &-list {
    max-height: 35vh;
    overflow: auto;
    background-color: var(--modal_bg) !important;
    border-radius: var(--border-radius);
    padding: 8px 0;
  }

  &-item {
    cursor: pointer;
    border-radius: var(--border-radius);
    display: flex;
    padding: 2px 16px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &-icon {
      margin-right: 12px;
    }

    &:hover {
      background-color: var(--accent_hover) !important;
    }

    &:active {
      background-color: var(--accent_selected) !important;
    }
  }

  &-nodata {
    padding: 16px;
    text-align: center;
  }
}
</style>
