const include = {
  status: {
    only: ['name']
  },
  contractor: {
    only: ['name']
  },
  type: {
    only: ['name']
  }
}

const except = [
  'id',
  'created_by',
  'status_id',
  'contractor_id',
  'group_id',
  'type_id',
  'subgroup_id',
  'updated_by',
  'disabled'
]

const order = [{ created_at: 'desc' }]

export const buildUrl = (source_id, filters) => {
  const config = JSON.stringify({
    ...filters,
    order,
    include
  })
  return `objectInfo/${source_id}?array=true&config=${config}`
}

export const buildExportUri = (source_id, filters) => {
  const config = JSON.stringify({
    ...filters,
    order,
    include,
    except
  })

  return `objectInfo/${source_id}?config=${config}`
}
