export const instanceConfigs = {
  default: {
    city: 'svobodnyy',
    timeZone: '+9',
    map: {
      center: { lat: 51.46, lng: 128.11 },
      maxBounds: null,
      zoom: 10
    },
    layout: {
      config: {
        bottom_block: {
          position: 'center'
        }
      },
      layer_controller: true,
      left_block: [{ name: 'title-bar-widget', props: { type: 'default' } }],
      bottom_block: [
        {
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=categories',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=categories'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=status',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=status'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=project',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=project'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=cargo_types',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=cargo_types'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'ZdCarriage/d771ab92-6787-4aa6-ae65-e64d687f5234?format=widget&type=carriages',
            request: {
              type: 'statistic',
              url: 'ZdCarriage/d771ab92-6787-4aa6-ae65-e64d687f5234?format=widget&type=carriages'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=pot_status',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=pot_status'
            },
            type: 'list',
            size: 'mid',
            noIcons: true
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=app_status',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=app_status'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'event_stat_class',
            request: {
              event_stat: true,
              type: 'statistic',
              url: 'event_stat',
              propType: 'class'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'event_stat_speed',
            request: {
              event_stat: true,
              type: 'statistic',
              url: 'event_stat',
              propType: 'speed'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'event_stat_time',
            request: {
              event_stat: true,
              type: 'statistic',
              url: 'event_stat',
              propType: 'time'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          name: 'category-widget',
          props: {
            layer_id: 'bdd_violation/1b013139-c2d9-4641-a549-b162fce4eb57?format=widget&type=violations',
            request: {
              type: 'statistic',
              url: 'bdd_violation/1b013139-c2d9-4641-a549-b162fce4eb57?format=widget&type=violations'
            },
            type: 'list',
            size: 'mid',
            noIcons: true
          }
        }
      ]
    },
    mapLayers: [
      {
        layer_id: 'video_camera_kpp',
        type: 'default',
        events: true,
        request: {
          type: 'statistic',
          url: 'video_camera_kpp'
        },
        optionalRequest: {
          url: 'api/py/iss/cameras',
          filter: '/'
        },
        rtsp: {
          uri: 'hls_kpp/cam_#ID/index.m3u8'
        }
      },
      {
        layer_id: 'video_camera_road',
        type: 'default',
        events: true,
        request: {
          type: 'statistic',
          url: 'video_camera_road'
        },
        optionalRequest: {
          url: 'api/py/iss/cameras',
          filter: '/'
        },
        rtsp: {
          uri: 'ff_hls/cam_#ID/index.m3u8'
        },
        videoArchive: {
          url: 'api/py/iss/video_export'
        }
      }
    ],
    mapTileLayers: []
  }
}
