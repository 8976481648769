export function setCookie(name, value, options) {
  // set cookie to browser
  options = options || {}

  let expires = options.expires

  if (typeof expires === 'number' && expires) {
    const d = this.$ritmDate.date()
    d.setTime(d.getTime() + expires * 1000)
    expires = options.expires = d
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString()
  }

  value = encodeURIComponent(value)

  let updatedCookie = name + '=' + value

  for (const propName in options) {
    updatedCookie += '; ' + propName
    const propValue = options[propName]
    if (propValue !== true) {
      updatedCookie += '=' + propValue
    }
  }

  document.cookie = updatedCookie
}

export function deleteCookie(name) {
  // delete cookie from browser
  setCookie(name, '', {
    expires: -1
  })
}

export function getCookie(name) {
  // get cookie from browser
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}
