<template>
  <div class="checklist-files-list">
    <ul
      v-if="albumResources && albumResources.length && !isLoading"
      class="checklist-files-list__wrapper"
    >
      <li
        v-for="item in albumResources"
        :key="item.id"
        class="checklist-files-list__item"
      >
        <img
          class="checklist-files-list__item-thumbnail"
          :src="item.url"
          alt="photo1"
        >
        <r-icon
          name="close-delete"
          @click.native="saveChanges(item)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    attrId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      checklistsUrl: this.$store.state.bdd.checklistsUrl,
      id: this.$route.query.id
    }
  },
  computed: {
    albumResources() {
      return (
        this.$store.state.bdd?.albumResources?.filter(e => {
          return e.attr_ids.find(k => k === this.attrId)
        }) || []
      )
    }
  },
  watch: {
    albumResources: {
      handler: function() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      },
      deep: true
    }
  },
  methods: {
    async saveChanges(item) {
      const { id } = item
      const url = `objectResources/${this.checklistsUrl}/${this.id}/${id}`
      const data = { attr_ids: this.getAttrIds(item) }

      try {
        await this.$store.dispatch('PUT_REQUEST', {
          data,
          url
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateAlbum',
          value: true
        })
      } catch (error) {
        console.warn(error)
      }
    },
    getAttrIds(item) {
      const result = Array.isArray(item.attr_ids) ? item.attr_ids : []
      const index = result.findIndex(e => e === this.attrId)

      if (index === -1) {
        result.push(this.attrId)
      } else {
        result.splice(index, 1)
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-files-list {
  position: relative;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
  }

  &__item {
    display: grid;
    grid-auto-flow: column;
    height: 36px;

    &-thumbnail {
      width: 36px;
      height: 36px;
      border-radius: var(--border-radius);
      overflow: hidden;
      margin-right: 4px;
      object-fit: cover;
    }

    .r-icon {
      cursor: pointer;
    }
  }
}
</style>
