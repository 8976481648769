import axios from 'axios'
import constants from '@/constants/url'
import { encodeUrl, getHeaders } from '@/utils'

class SelectClientStore {
  constructor() {
    this._vehicles = []
    this._contractors = []
    this._contractors_tmc = []
    this._contractors_not_tmc = []
    this.setVehicles()
    this.setContractors()
  }

  getVehicles() {
    return this._vehicles
  }

  getContractors() {
    return this._contractors
  }

  getContractorsTmc() {
    return this._contractors_tmc
  }

  getContractorsNotTmc() {
    return this._contractors_not_tmc
  }

  setContractors() {
    this.setData('contractors')
    this.setData('contractors_tmc')
    this.setData('contractors_not_tmc')
  }

  setVehicles() {
    this.setData('vehicles')
  }

  getData(key) {
    switch (key) {
      case 'vehicles': return this.getVehicles()
      case 'contractors': return this.getContractors()
      case 'contractors_tmc': return this.getContractorsTmc()
      case 'contractors_not_tmc': return this.getContractorsNotTmc()
    }
  }

  setData(param) {
    const url = `select?format=${param}`
    axios
      .get(`${constants.URL}/${encodeUrl(url)}`, {
        headers: getHeaders()
      })
      .then(data => {
        this[`_${param}`] = data
      })
      .catch(error => {
        console.log('SelectClientStore ERROR: ', error)
      })
  }
}

export default {
  install(Vue) {
    console.log('SelectClientStore install')
    const clientStore = new SelectClientStore()
    Vue.prototype.$selectClientStore = clientStore
    Vue.$selectClientStore = clientStore
    Vue.$socket.on('f09e59fb-ee58-482c-8b0f-9ac520cf2099', ({ action, data }) => {
      clientStore.setVehicles()
    })
    Vue.$socket.on('ef021b44-a650-478e-a25f-ace1e9b545ca', ({ action, data }) => {
      clientStore.setContractors()
    })
  }
}
