<template>
  <r-bottom-card
    :loading="loading"
    :icon="cardIcon"
    :title="cardInfo.name || 'Загрузка...'"
    @close="closeCard"
  >
    <div
      slot="actions"
      class="pt-card__actions"
    >
      <r-button @click="editObject">
        <r-text> Редактировать </r-text>
      </r-button>

      <r-button
        icon="focus-zone"
        @click="flyTo"
      />
      <r-delete-button
        mini
        simple
        @delete="handleDelete"
      />
    </div>
    <div
      slot="attributes"
      class="pt-attributes"
    >
      <card-attributes />
    </div>

    <card-tabs slot="tabs" />
  </r-bottom-card>
</template>

<script>
import CardTabs from './card-tabs'
import CardAttributes from './card-attributes'
import { getRequestConfig } from './helpers'
import { notifyFactory } from '@/utils'

export default {
  components: {
    CardTabs,
    CardAttributes
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    cardId() {
      return this.$store.state.publicTransport.cardId
    },
    cardType() {
      return this.$store.state.publicTransport.cardType
    },
    cardInfo() {
      return this.$store.state.publicTransport.cardInfo
    },
    cardIcon() {
      return this.cardType === 'stop_points' ? 'bus-station' : 'line-layer'
    }
  },
  watch: {
    $route(route) {
      if (route.name === 'pt-editor') {
        this.closeCard()
      }
    },
    cardId() {
      this.getInfo()
    },
    '$store.state.publicTransport.needToUpdateSchedule'(value) {
      if (value) {
        this.getInfo()

        this.$store.commit('SET_PT_FIELD', {
          field: 'needToUpdateSchedule',
          value: false
        })
      }
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    handleDelete() {
      this.$confirm('Вы уверены, что хотите удалить данный объект?', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отменить',
        type: 'warning'
      })
        .then(async _ => {
          const layerId = this.$store.state.publicTransport.model.ids[
            this.cardType
          ]
          const url = `objectInfo/${layerId}?id=${this.cardId}`
          await this.$store.dispatch('DELETE_REQUEST', { url })
          const title = 'Удаление выполнено'
          const message = 'Объект успешно удален'
          this.$notify(notifyFactory('succcess', title, message))
          this.$store.commit('SET_PT_FIELD', {
            field: 'needToUpdateLists',
            value: true
          })
          this.closeCard()
        })
        .catch(_ => {})
    },
    flyTo() {
      const { cardGeom } = this.$store.state.publicTransport

      this.$store.commit('SET_PT_FIELD', {
        field: 'flyToGeom',
        value: cardGeom
      })
    },
    async getLineSchedule() {
      const { ids } = this.$store.state.publicTransport.model
      const { properties } = this.$store.state.publicTransport.cardInfo

      if (!properties) return

      const { line_routes } = properties
      const { id, line_route_items } = line_routes.find(({ rev }) => !rev)

      const config = getRequestConfig('schedule', id)
      const url = `objectInfo/${ids.line_routes}?config=${JSON.stringify(
        config
      )}`

      const { data } = await this.$store.dispatch('GET_REQUEST', { url })

      const lineRoute = {
        ...Object.values(data)[0],
        veh_journeys: Object.values(data)[0].veh_journeys.sort((a, b) => {
          const aa = +a.arr.slice(0, 5).replace(':', '.')
          const bb = +b.arr.slice(0, 5).replace(':', '.')
          return aa - bb
        })
      }

      this.$store.commit('SET_PT_FIELD', {
        field: 'lineRouteItems',
        value: line_route_items
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'lineRouteId',
        value: id
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'timeProfile',
        value: lineRoute.time_profiles[0]
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'vehicleJourneys',
        value: lineRoute.veh_journeys
      })
    },
    modifyVehicles(data) {
      return data?.map(t => ({
        reg_number: t.reg_number,
        online: t.retranslation_status ? 'На связи' : 'Н/д',
        contragent: t.vrc_full_name,
        model: t.model ? t.model.name : 'Не указана'
      }))
    },
    async getInfo() {
      try {
        this.loading = true

        // get card info
        const layerId = this.$store.state.publicTransport.model.ids[
          this.cardType
        ]
        const properties = await this.getAttributes(
          this.cardId,
          this.cardType,
          layerId
        )
        const cardInfo = {
          name:
            this.cardType === 'stop_points'
              ? `${properties.name || properties.no}`
              : `${properties.name}`,
          properties,
          layerId,
          vehicles: this.modifyVehicles(properties.vehicles)
        }

        this.$store.commit('SET_PT_CARD_INFO', cardInfo)

        // get schedule (for lines)
        if (this.cardType === 'lines') {
          await this.getLineSchedule()
        }

        // set card geometry
        const geometryValue =
          this.cardType === 'lines'
            ? properties.line_routes[0].geom
            : properties.geom

        this.$store.commit('SET_PT_FIELD', {
          field: 'cardGeom',
          value: geometryValue
        })
        this.flyTo()
        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    },
    async getAttributes(id, type, layerId) {
      try {
        const config = getRequestConfig(type, id)
        const url = `objectInfo/${layerId}?config=${JSON.stringify(config)}`

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        return Object.values(data)[0]
      } catch (error) {
        console.warn(error)
        return null
      }
    },
    closeCard() {
      this.$store.commit('CLEAR_PT_CARD_INFO')
    },
    editObject() {
      const { layerId, properties } = this.cardInfo

      let editorState

      if (this.cardType === 'lines') {
        const { line_routes } = properties
        const { geom, line_route_items } = line_routes[0]

        editorState = {
          id: this.cardId,
          properties: {
            ...properties,
            line_route_items
          },
          type: this.cardType,
          lineRouteItems: line_route_items,
          geometry: geom,
          layerId
        }
      } else {
        const { stop_geom, geom } = properties

        editorState = {
          id: this.cardId,
          properties: { ...properties },
          type: this.cardType,
          geometry: stop_geom || geom,
          layerId
        }
      }

      this.$store.commit('SET_PT_EDITOR_STATE', editorState)
      this.closeCard()
      this.$router.push('/app/public-transport/editor/edit')
    }
  }
}
</script>

<style lang="scss">
.pt-card {
  overflow: hidden;
  height: 100%;

  &__actions {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto auto;
    grid-gap: 0.5rem;
    align-items: center;
  }
}
</style>
