import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'

import { BaseEditor } from '@/libs/map-draw/controllers'
import {
  clearAllHelpers,
  createDependenciesHelpers,
  removeDependenciesHelpers,
  removeDeletedDepHelpers,
  createDeletedDepHelpers
} from '@/libs/map-draw/helpers'
import {
  getEditorRequestConfig,
  jsonToGeojson,
  getDatasourceById
} from '@/utils'
import {
  modelTypes,
  mapboxPropsConfig,
  specialStylingTypes
} from '@/libs/map-draw/config'

import mapboxStyles from '@/constants/mapbox_styles'

export class EditorController extends BaseEditor {
  constructor(map) {
    super(map)

    this.$store = map.$store
    this.state = map.editorState
    this.draw = map.draw
    this.linkSeparationMode = false
  }

  // common helpers
  clearEditorData() {
    super.clearEditorData()

    this.state.dependenciesData = {}
    this.state.deletedDependenciesIds = []
    removeDependenciesHelpers(this.mapgl)
    removeDeletedDepHelpers(this.mapgl, this.state.modelLayers)
  }

  changeHistoryStep(data) {
    super.changeHistoryStep(data)

    const step = this.state.history[this.state.historyIndex]
    this.state.dependenciesData = cloneDeep(step.dependenciesData)
    this.state.deletedDependenciesIds = cloneDeep(step.deletedDependenciesIds)
    createDependenciesHelpers(this.mapgl, this.state.dependenciesData)
    createDeletedDepHelpers(
      this.mapgl,
      this.state.modelLayers,
      this.state.deletedDependenciesIds
    )
  }

  // visible and editing layers switcher
  toggleEditingModelLayer(layerId) {
    const { id, modelLayers } = this.state
    if (layerId === id) return

    const layer = modelLayers.find(l => l.id === layerId)
    const { visible, geom_type, datatype } = layer
    if (visible) {
      this.mapgl.removeLayer(layerId)
      this.mapgl.removeSource(layerId)
      this.mapgl.off('moveend', this.handlers[layerId])
      delete this.handlers[layerId]
      layer.visible = false
    }

    const setNewEditingLayer = () => {
      this.state.id = layerId
      this.state.geom_type = datatype || geom_type
      this.state.geometry = geom_type
      clearAllHelpers(this.mapgl)
      this.setEditorHandler()
      this.toggleVisibleModelLayer(id)
    }
    const successCb = async() => {
      await this.requestForSaving()
      setNewEditingLayer()
    }
    const errorCb = action => {
      if (action === 'cancel') setNewEditingLayer()
    }

    if (this.state.history.length) {
      this.showConfirmModal(successCb, errorCb)
    } else setNewEditingLayer()
  }

  toggleEditingLinkSeparation(val) {
    this.linkSeparationMode = val
    this.setEditorHandler()
  }

  toggleVisibleModelLayer(layerId) {
    const { modelLayers, id } = this.state
    if (layerId === id) return
    const layer = modelLayers.find(l => l.id === layerId)
    const { geom_type, datatype } = layer

    if (layer.loading) return

    if (layer.visible) {
      // remove handler
      this.mapgl.removeLayer(layerId)
      if (this.mapgl.getLayer(`${layerId}_center_geom`)) {
        this.mapgl.removeLayer(`${layerId}_center_geom`)
      }
      this.mapgl.removeSource(layerId)
      if (this.mapgl.getSource(`${layerId}_center_geom`)) {
        this.mapgl.removeSource(`${layerId}_center_geom`)
      }
      this.mapgl.off('moveend', this.handlers[layerId])
      delete this.handlers[layerId]
      layer.visible = false
    } else {
      layer.loading = true
      const loadVisibleLayer = async() => {
        const zoom = this.mapgl.getZoom()
        const config = getEditorRequestConfig(this.map, geom_type)

        try {
          layer.loading = true
          const url = `objectInfo/${layerId}?config=${JSON.stringify(
            config
          )}&zoom=${zoom}`
          const { data } = await this.map.$store.dispatch('GET_REQUEST', {
            url
          })
          const geojson = jsonToGeojson(Object.keys(data).map(id => data[id]))

          if (datatype === 'zones') {
            const center_geom = {
              type: 'FeatureCollection',
              features: [
                ...geojson.features.map(f => ({
                  type: 'Feature',
                  properties: {
                    id: f.properties.id,
                    zoneno: f.properties.no,
                    center_geom: true
                  },
                  geometry: f.properties.center_geom
                }))
              ]
            }
            const id = `${layerId}_center_geom`

            if (!this.mapgl.getLayer(id)) {
              this.mapgl.addLayer({
                id,
                source: {
                  type: 'geojson',
                  data: center_geom
                },
                ...mapboxPropsConfig.center_geom
              })
            } else {
              this.mapgl.getSource(id).setData(center_geom)
            }
          }

          if (!this.mapgl.getLayer(layerId)) {
            this.mapgl.addLayer(
              {
                id: layerId,
                source: {
                  type: 'geojson',
                  data: geojson
                },
                ...mapboxPropsConfig[
                  specialStylingTypes.indexOf(datatype) > -1
                    ? datatype
                    : geom_type
                ]
              },
              'gl-draw-polygon-fill-inactive.cold'
            )
          } else {
            this.mapgl.getSource(layerId).setData(geojson)
          }

          layer.loading = false
        } catch (error) {
          console.warn(error)
          layer.loading = false
        }
      }

      this.handlers[layerId] = debounce(loadVisibleLayer, 400)
      this.handlers[layerId]()
      this.mapgl.on('moveend', this.handlers[layerId])
      layer.visible = true
    }
  }

  // editor state switcher
  async toggleEditor(layer) {
    if (!layer || layer.source_id === this.state.id) {
      const { activeLayers, modelLayers } = this.state
      this.state.id = ''
      this.state.enabled = false
      this.state.geom_type = ''
      this.state.mode = 'edit'
      this.state.history = []
      this.state.historyIndex = 0
      this.state.modelLayers = []
      this.draw.deleteAll()
      this.mapgl.removeControl(this.draw)
      this.state.name = ''
      this.state.modelId = null
      clearAllHelpers(this.mapgl)

      if (modelLayers.length) {
        const active = modelLayers.filter(l => l.visible)

        active.forEach(({ id }) => {
          this.mapgl.removeLayer(id)
          this.mapgl.removeSource(id)
          this.mapgl.off('moveend', this.handlers[id])
          delete this.handlers[id]
        })
        this.state.modelLayers = []
      }

      this.map.toggleBaselayer({
        id: this.map.baselayerId,
        name: this.$store.state.map.baselayers.find(
          l => l.id === this.map.baselayerId
        ).name
      })
      this.map.toggleMode('vizualization')
      for (let i = 0; i < activeLayers.length; i++) {
        const id = activeLayers[i]
        await this.map.controllers.layers.toggleLayer({ id })
      }
      this.state.activeLayers = []
      this.mapgl.off('moveend', this.loadLayerData)

      return
    }

    const { source_id } = layer
    const data = await getDatasourceById(this.map, source_id)
    const { id, datatype, parent_id, name, geom_type } = data

    this.state.id = id
    this.state.geom_type = datatype || geom_type
    this.state.geometry = geom_type
    this.state.name = name

    if (parent_id) {
      const parent = await getDatasourceById(this.map, parent_id, {
        children: {}
      })
      const { datatype } = parent

      if (datatype === 'model') {
        this.state.modelLayers = parent.children
          .filter(({ datatype }) => modelTypes.indexOf(datatype) > -1)
          .map(ch => ({
            id: ch.id,
            datatype: ch.datatype,
            geom_type: ch.geom_type,
            name: ch.name,
            visible: false,
            loading: false
          }))
        this.state.name = parent.name
        this.state.modelId = parent.id
      }
    }

    this.state.enabled = true
    this.mapgl.setStyle(mapboxStyles.dark)
    this.map.toggleMode('editor')

    const callback = async() => {
      this.state.activeLayers = [...this.map.$store.state.map.activeLayers]
      for (let i = 0; i < this.state.activeLayers.length; i++) {
        const id = this.state.activeLayers[i]
        await this.map.controllers.layers.toggleLayer({ id })
      }
      this.mapgl.addControl(this.draw)

      this.setEditorHandler()
    }

    if (this.map.baselayerId !== 5) {
      this.mapgl.once('style.load', callback)
    } else {
      callback()
    }
  }

  // editing layer data request handler
  async setEditorHandler() {
    const loadLayerData = async() => {
      if (!this.draw) return

      const selected = this.draw.getSelectedIds()
      const { id, geom_type, isDrawing, enabled } = this.state
      if (isDrawing) return

      const zoom = this.mapgl.getZoom()
      const config = getEditorRequestConfig(this.map, geom_type)

      try {
        this.map.mapLoading = true
        const url = `objectInfo/${id}?config=${JSON.stringify(
          config
        )}&zoom=${zoom}&simplify=off`
        const { data } = await this.map.$store.dispatch('GET_REQUEST', {
          url
        })
        const geojson = jsonToGeojson(Object.keys(data).map(id => data[id]))
        const editedFeatures = [...this.created, ...this.updated]
        geojson.features = geojson.features
          .filter(f => {
            return (
              !this.deleted.find(
                item => item.properties.id === f.properties.id
              ) &&
              !this.updated.find(item => item.properties.id === f.properties.id)
            )
          })
          .map(f => ({ ...f, id: f.properties.id }))

        if (!enabled) return
        let features = [...editedFeatures, ...geojson.features]

        if (geom_type === 'zones') {
          features = [
            ...features,
            ...features.map(f => ({
              type: 'Feature',
              properties: { id: f.properties.id, is_center_geom: true },
              geometry: f.properties.center_geom
            }))
          ]
        }

        this.draw.set({
          type: 'FeatureCollection',
          features
        })

        if (this.state.mode === 'create') {
          this.changeModeToCreate()
        } else if (this.state.mode === 'edit' && this.linkSeparationMode) {
          this.draw.changeMode('link_splice', { featureIds: [] })
        } else {
          this.draw.changeMode('simple_select', { featureIds: selected })
        }
        this.map.mapLoading = false
      } catch (error) {
        console.warn(error)
        this.map.mapLoading = false
      }
    }

    await this.getLayerObjectFields()
    if (this.loadLayerData) this.mapgl.off('moveend', this.loadLayerData)
    this.loadLayerData = debounce(loadLayerData, 400)
    this.clearEditorData()
    this.loadLayerData()
    this.mapgl.on('moveend', this.loadLayerData)
  }
}
