<template>
  <div
    class="r-info-layers__simple-button"
    @click="toggleLayer"
  >
    <el-tooltip
      :open-delay="1500"
      :content="title"
      placement="bottom"
    >
      <r-icon
        :name="icon"
        :size="24"
        :color-type="isThisLayerActive ? 'accent-primary' : ''"
      />
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { popupConfigs } from '../configs'

export default {
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    layerId: {
      type: String,
      default: () => ''
    },
    layersIds: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    addHandler: {
      type: Function,
      required: true
    },
    removeHandler: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      isThisLayerActive: false
    }
  },
  computed: {
    ...mapGetters([
      'isLayerIdActive',
      'activeLayersList',
      'layersToRenderList',
      'lockedLayersList'
    ]),
    activeLayers() {
      return this.layersToRenderList(this.module)
    },
    lockedLayers() {
      const { mapConfigs } = this.$store.state
      return mapConfigs.lockedLayers?.[this.module] || []
    },
    isLayerLocked() {
      return this.lockedLayers.includes(this.layerId)
    }
  },
  mounted() {
    if (this.activeLayers.includes(this.layerId)) {
      this.isThisLayerActive = true
      if (!this.mapgl?._loaded) return
      this.addHandler()

      const config = popupConfigs[this.layerId]

      if (config) {
        this.$store.commit('MAP_POPUP_SET_CONFIG', [this.layerId, config])
      }
    }
  },
  methods: {
    isLayerActive() {
      const { module, layerId } = this
      return this.isLayerIdActive(module, layerId)
    },

    async toggleLayer() {
      const { layerId } = this

      if (this.isLayerLocked) return

      if (this.isLayerActive()) {
        this.isThisLayerActive = false
        this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
          field: 'activeLayers',
          module: this.module,
          value: this.activeLayers.filter(f => f !== layerId)
        })

        if (this.removeHandler) {
          this.removeHandler()
        } else {
          this.layersIds.forEach(id => {
            if (this.mapgl.getLayer(id)) {
              this.mapgl.removeLayer(id)
            }
          })
          this.layersIds.forEach(id => {
            if (this.mapgl.getSource(id)) {
              this.mapgl.removeSource(id)
            }
          })
        }

        this.$store.commit('MAP_POPUP_REMOVE_CONFIG', layerId)
      } else {
        this.isThisLayerActive = true
        await this.addHandler()
        const config = popupConfigs[layerId]

        if (config) {
          this.$store.commit('MAP_POPUP_SET_CONFIG', [layerId, config])
        }

        this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
          field: 'activeLayers',
          module: this.module,
          value: [...new Set([...this.activeLayers, layerId])].filter(f => !!f)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.r-info-layers {
  &__simple-button {
    width: 36px;
    height: 36px;
    border-radius: var(--border-radius);
    cursor: pointer;

    &.active {
      background-color: var(--accent_selected) !important;
    }

    &:hover {
      background-color: var(--accent_hover) !important;
    }
  }
}
</style>
