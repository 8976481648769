<template>
  <div class="m-scenario">
    <div class="m-scenario__left">
      <r-panel-header
        title="Визуализация расчета"
        :with-back-button="true"
        @back="back"
      />
      <div
        v-loading="loading"
        class="m-scenario__container"
      >
        <div class="m-scenario__title">
          <el-input
            v-model="currentScenarioName"
            class="r-input"
            disabled
          />
          <r-button
            :loading="deleting"
            type="danger"
            icon="trash"
            @click="deleteScenario"
          />
        </div>
        <div class="m-scenario__layers">
          <r-text
            type="caption"
            style="margin-bottom: 8px"
          >
            Список слоев
          </r-text>
          <layers-list />
        </div>
        <div
          v-if="calculation"
          class="m-scenario__calc-info"
        >
          <calculation-info :calculation="calculation" />
        </div>
      </div>
    </div>

    <r-second-panel
      v-if="settingsId"
      @close="closeSettings"
    >
      <layer-settings />
    </r-second-panel>
  </div>
</template>

<script>
import LayersList from '@/components/modelling/layers-list/layers-list'
import LayerSettings from '@/components/modelling/layer-settings/layer-settings'
import CalculationInfo from '@/components/modelling/calculation/calculation-info'

export default {
  components: {
    LayersList,
    LayerSettings,
    CalculationInfo
  },
  data() {
    return {
      loading: false,
      deleting: false,
      calculation: null
    }
  },
  computed: {
    currentScenarioId() {
      return this.$store.state.modelling.currentScenarioId
    },
    currentScenarioName() {
      return this.$store.state.modelling.currentScenarioName
    },
    settingsId() {
      return this.$store.state.modelling.settingsId
    }
  },
  created() {
    this.getData()
  },
  beforeDestroy() {
    this.$store.commit('SET_CALC_FIELD', {
      field: 'settingsId',
      value: null
    })
  },
  methods: {
    back() {
      if (this.$store.state.modelling.loadingLayers.length) return
      this.$store.commit('SET_CALC_FIELD', {
        field: 'currentScenarioId',
        value: null
      })
      this.$store.commit('SET_CALC_FIELD', {
        field: 'currentScenarioName',
        value: null
      })

      this.$router.push('/app/modelling/main/list')
    },
    async getData() {
      try {
        this.loading = true

        // get calculation data
        const { model } = this.$store.state.modelling
        const { scenarioId } = this.$route.params

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `branch/${model.id}?branch_id=${scenarioId}`
        })

        this.calculation = data.calculation

        await this.getTimesData(data.calculation_type)

        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    },
    async getTimesData(type) {
      let times = null

      if (type === 'DUE') {
        times = [...new Array(288)].map((_, i) => (i + 1) * 300)
      } else if (type === 'DNL') {
        const { model } = this.$store.state.modelling
        const { scenarioId } = this.$route.params
        const { ids } = model
        const id = ids.link_calculation_results
        const config = {
          only: ['time_value'],
          where: [
            {
              field: 'calculation_id',
              op: '=',
              value: scenarioId
            }
          ]
        }
        const url = `aggregation/${id}?config=${JSON.stringify(
          config
        )}&branch_id=${scenarioId}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        const { min, max } = data.time_value

        const dnlTimes = []

        for (let i = min; i <= max; i += 300) {
          dnlTimes.push(i)
        }

        times = dnlTimes
      }

      if (times) {
        this.$store.commit('SET_CALC_FIELD', {
          field: 'times',
          value: times
        })
        this.$store.commit('SET_CALC_FIELD', {
          field: 'currentTime',
          value: times[0]
        })
      }
    },
    async deleteScenario() {
      try {
        this.deleting = true

        const url = `branch?id=${this.currentScenarioId}`
        await this.$store.dispatch('DELETE_REQUEST', { url })

        this.deleting = false
        this.$router.push('/app/modelling/main/list')
      } catch (error) {
        console.warn(error)
        this.deleting = false
      }
    },
    closeSettings() {
      this.$store.commit('SET_CALC_FIELD', {
        field: 'settingsId',
        value: null
      })
    }
  }
}
</script>

<style lang="scss">
.m-scenario {
  height: calc(100vh - 48px);
  display: flex;

  &__left {
    display: flex;
    flex-direction: column;
    width: 260px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 260px;
    background-color: var(--bg_panel_secondary) !important;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0px;
  }

  &__title {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
  }

  &__layers {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px;
  }

  &__calc-info {
    padding: 8px;
  }
}
</style>
