<template>
  <div class="day-filter">
    <div class="popper-filter__content">
      <div class="day-filter__item">
        <r-text type="caption">
          {{ $t(`day-filter:title`) }}
        </r-text>
        <r-date-picker
          v-model="interval.from"
          class="r-date-picker"
          size="mini"
          name="date"
          :type="pickerType"
          :format="format"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
    </div>

    <div class="day-filter__controls popper-filter__controls">
      <r-button
        type="primary"
        @click="prepareData"
      >
        {{ $t(`day-filter:accept`) }}
      </r-button>
      <r-button
        simple
        :disabled="isResetDisabled"
        @click="resetFilter"
      >
        {{ $t(`day-filter:reset`) }}
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: Object,
      required: true
    },
    pickerType: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ...this.filterValue
    }
  },
  computed: {
    isResetDisabled() {
      const { to, from } = this.interval
      return !(from || to)
    }
  },
  methods: {
    resetFilter(resetAll) {
      this.interval.to = null
      this.interval.from = null
      this.prepareData(resetAll)
    },
    prepareData(resetAll) {
      const { interval, id } = this
      const zeroDate = this.$rDate.zeroing(interval.from).format('iso')
      const intervalValue = {
        from: zeroDate,
        to: this.$rDate.calc(23, 'hour', zeroDate).calc(59, 'minute').calc(59, 'second').format('iso')
      }

      const data = {
        type: 'day-filter',
        prop: { interval: intervalValue },
        id: id,
        active: !!interval.from
      }

      if (!resetAll) {
        this.$emit('load', data)
      } else {
        this.$emit('reset-filter', data)
      }
    }
  }
}
</script>

<style lang="scss">
.day-filter {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;

  &__item {
    display: grid;
    grid-gap: 0.25rem;
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<i18n>
{
  "ru": {
    "day-filter:title": "Выберите дату",
    "day-filter:accept": "Применить",
    "day-filter:reset": "Сброс"
  }
}
</i18n>
