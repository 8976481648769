<template>
  <div
    v-loading="loading"
    class="journal-short"
  >
    <div class="journal-short__title">
      <div
        v-if="page !== 'main'"
        class="journal-short__back-button"
        @click="togglePage('main')"
      >
        <r-icon
          name="arrow-back"
          :size="24"
        />
      </div>
      <r-title>
        {{ page === 'main' ? 'Уведомления о событиях' : 'Настройка уведомлений' }}
      </r-title>
      <div
        v-if="page === 'main'"
        class="journal-short__settings-button"
        @click="togglePage('settings')"
      >
        <r-icon
          name="settings"
          :size="24"
        />
      </div>

      <r-button
        v-if="page === 'main'"
        icon="pin"
        type="secondary"
        :active="pinned"
        @click="pinned = !pinned"
      />
    </div>
    <div class="journal-short__container">
      <journal-main
        v-show="page === 'main'"
        ref="main"
        @toggle-loading="toggleLoading"
        @close="$emit('close')"
      />
      <journal-settings
        v-show="page === 'settings'"
        ref="settings"
        @toggle-loading="toggleLoading"
        @update-settings="loadEvents"
      />
    </div>
  </div>
</template>

<script>
import JournalMain from './blocks/journal-main'
import JournalSettings from './blocks/journal-settings/journal-settings'

export default {
  components: {
    JournalMain,
    JournalSettings
  },
  data() {
    return {
      loading: false,
      page: 'main'
    }
  },
  computed: {
    pinned: {
      get() {
        return this.$store.state.journal.pinned
      },
      set(val) {
        this.$store.commit('SET_JOURNAL_FIELD', ['pinned', val])
      }
    }
  },
  async mounted() {
    try {
      this.loading = true

      await this.$refs.settings.getData()
      await this.loadEvents()
    } catch (error) {
      console.warn(error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async loadEvents() {
      await this.$refs.main.loadEvents('car_event')
      await this.$refs.main.loadEvents('system_event')
      await this.$refs.main.loadEvents('cameras')
    },
    togglePage(value) {
      this.page = value
    },
    toggleLoading(value) {
      this.loading = value
    }
  }
}
</script>

<style lang="scss">
.journal-short {
  display: grid;
  grid-gap: 0.5rem;
  height: 100%;
  align-items: flex-start;
  align-content: flex-start;
  overflow: hidden;

  &-popover {
    .el-popover__title {
      display: none;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    .r-title {
      margin: 0 auto;
    }

    .r-icon {
      cursor: pointer;
    }
  }

  &__container {
    display: grid;
    overflow: auto;
    height: 100%;
  }
}
</style>
