<template>
  <div class="odd-card-controls">
    <template v-if="$route.name === 'odd-objects'">
      <r-block
        col
        no-padding
        stretch
      >
        <r-button
          type="success"
          :loading="saving"
          @click="$emit('save')"
        >
          Сохранить объект
        </r-button>
        <r-delete-button
          mini
          @delete="$emit('delete')"
        />
      </r-block>
      <r-block
        col
        no-padding
        stretch
      >
        <r-button
          v-if="hasRack"
          icon="edit"
          :loading="saving"
          @click="$emit('open-rack')"
        >
          Карточка стойки
        </r-button>
        <r-button
          icon="focus-zone"
          bold
          title="Приблизить на карте"
          @click="$emit('flyTo')"
        />
        <r-button
          icon="upload"
          title="Экспорт"
          @click="$emit('export')"
        />
      </r-block>
    </template>
    <r-delete-button
      v-else
      mini
      @delete="$emit('delete')"
    />
  </div>
</template>

<script>
export default {
  props: {
    saving: {
      type: Boolean,
      default: false
    },
    hasRack: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.odd-card-controls {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid var(--dividers_low_contrast) !important;
}
</style>
