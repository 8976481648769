<template>
  <div class="an-content-header">
    <r-button
      simple
      icon="arrow-back"
      @click="clickHandler('return')"
    >
      К списку отчётов
    </r-button>
    <r-title type="title-2">
      {{ `${reportTitle} (Количество объектов: ${dataLength})` }}
    </r-title>
    <an-export v-if="!report.noExport" />
  </div>
</template>

<script>
import anExport from '../an-export/an-export'

export default {
  components: {
    anExport
  },
  computed: {
    activeId() {
      return this.$store.state.analytics.activeId
    },
    pagyResponse() {
      return this.$store.state.analytics.pagyResponse || null
    },
    report() {
      return this.$store.state.analytics.report || null
    },
    dataLength() {
      if (this.pagyResponse) {
        return this.pagyResponse.count || 0
      } else {
        return this.$store.state.analytics.dataLength || 0
      }
    },
    reportTitle() {
      return this.report.title || ''
    }
  },
  methods: {
    clickHandler(action) {
      switch (action) {
        case 'export':
          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'showExport',
            value: true
          })
          break
        default:
          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'reportUrl',
            value: null
          })
          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'workspaceType',
            value: 'table'
          })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.an-content-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast);

  > * {
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }

  .r-title {
    margin-right: auto;
  }
}
</style>
