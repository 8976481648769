import { ritmDate } from '@/utils'

export const filtersEncoder = filters => {
  const config = { where: [], include: {} }
  filters
    .filter(f => f.active)
    .forEach(f => {
      switch (f.id) {
        case 'permit_type_id':
          config.where.push(simpleCheckbox(f))
          break
        case 'is_valid':
          config.where.push(simpleRadio(f))
          break
        case 'date_from':
          customInterval(f).forEach(i => config.where.push(i))
          break
        default:
          break
      }
    })
  return config
}

const simpleCheckbox = f => {
  return {
    field: f.id,
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const simpleRadio = f => {
  const value = f.prop.find(p => p.value).id
  return value !== 'all'
    ? {
      field: f.id,
      value: value === 'yes',
      op: '='
    }
    : {}
}

const customInterval = f => {
  let { from, to } = f.prop.interval

  if (!from || !to) {
    from = from || this.$ritmDate.date(0)
    to = to || this.$ritmDate.date()
  }

  from = ritmDate.toIso(from)
  to = ritmDate.toIso(to)

  return [
    {
      field: f.id,
      op: 'between',
      type: 'OR',
      value: `${from}/${to}`
    },
    {
      field: f.additional_id,
      op: 'between',
      type: 'OR',
      value: `${from}/${to}`
    }
  ]
}
