<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    :icon="icon"
    :title="title"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import SimpleButton from '../buttons/simple-button'

import { jsonToGeojson } from '@/utils'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'abk-agpz',
      title: 'АБК АГПЗ',
      icon: 'administration',
      url: 'objectInfo/4212773f-b391-486e-aecc-810ea485cab1',
      config: {
        mapPoint: {
          type: 'circle',
          layout: {},
          paint: {
            'circle-radius': {
              base: 1.75,
              stops: [[12, 8], [18, 80]]
            },
            'circle-color': 'rgba(46, 252, 211, 0.6)',
            'circle-pitch-alignment': 'map',
            'circle-stroke-color': 'rgb(0,0,0)',
            'circle-stroke-width': 1
          }
        },
        mapSymbol: {
          type: 'symbol',
          layout: {
            'icon-image': 'folder-layer',
            'icon-size': {
              base: 1.75,
              stops: [[12, 0.3], [18, 3]]
            }
          },
          paint: {
            'icon-color': 'rgb(20, 20, 20)'
          }
        }
      },
      layersIds: []
    }
  },
  methods: {
    async addHandler() {
      const { id, url } = this
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        if (!data) return
        const features = jsonToGeojson(Object.values(data))
        if (!features) return
        const source = this.mapgl.getSource(id)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: features
          })
          this.addLayers(id)
        } else {
          source.setData(features)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    addLayers(id) {
      const { mapPoint, mapSymbol } = this.config

      this.mapgl.addLayer({
        id: `${id}`,
        source: id,
        ...mapPoint
      })
      this.mapgl.addLayer({
        id: `${id}_s`,
        source: id,
        ...mapSymbol
      })
      this.layersIds = [id, `${id}_s`]
    }
  }
}
</script>
