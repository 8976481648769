import mapboxgl from 'mapbox-gl'
import $store from '@/store'
import { ritmDate } from '@/utils'

export class MapPopup {
  constructor(map) {
    this.map = map

    this.clickPopupShow = false

    this.features = null

    this.clickPopup = null
    this.globalClickHandler = this.globalClickHandler.bind(this)
    this.itemClickHandler = this.itemClickHandler.bind(this)
  }

  get popupConfigs() {
    return $store.state.mapPopups.configs
  }

  initMapPopup() {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15,
      anchor: 'left',
      className: 'map-popup'
    })
    this.clickPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: true,
      offset: 15,
      anchor: 'left',
      className: 'map-popup'
    })

    this.map.on('mousemove', e => {
      popup.remove()
      if (this.clickPopupShow) return
      const popupLayers = Object.keys(this.popupConfigs)

      if (popupLayers?.length) {
        const features = this.getFeatures(e, popupLayers)

        if (features?.length) {
          this.addPopup(e, features, popup)
          this.map.getCanvas().style.cursor = 'pointer'
        }
      }
    })

    this.map.on('click', e => {
      const popupLayers = Object.keys(this.popupConfigs)

      if (popupLayers?.length) {
        const features = this.getFeatures(e, popupLayers)
        this.features = features || null

        if (features?.length) {
          setTimeout(() => this.featuresClick(e, features, popup), 8)
        }
      }
    })
  }

  getFeatures(e, layers, offset = 4) {
    const { x, y } = e.point

    const bbox = [
      [x - offset, y - offset],
      [x + offset, y + offset]
    ]

    const existingLayers = layers.filter(
      layerId => !this.map._removed && this.map.getLayer(layerId)
    )

    const features = this.map.queryRenderedFeatures(bbox, {
      layers: existingLayers
    })

    return features
  }

  addPopup(e, features, popup) {
    let html

    if (features?.length > 1) {
      html = '<dl><div class="map-popup__count"><dt>Кол-во объектов: </dt><dd>' + features?.length + '</dd></div></dl>'
    } else {
      html = features?.map(f => {
        const config = this.popupConfigs[f.layer.id]

        let item = '<dl><div class="map-popup__item-row"><dt>Слой: </dt><dd>' + (config?.layer || '–') + '</dd></div>'

        config.fields.forEach(c => {
          let val

          if (c.type === 'date') {
            val = f.properties?.[c.value] ? ritmDate.toFormat(f.properties?.[c.value], c.format || 'DD.MM.YYYY') : '–'
          } else if (c.func) {
            val = c.func(f.properties?.[c.value])
          } else if (c.value?.includes('.')) {
            const [first, second] = c.value.split('.')
            const parsed = JSON.parse(f.properties?.[first])
            val = parsed?.[second]
          } else {
            val = f.properties?.[c.value]
          }

          if (typeof val === 'boolean') {
            val = val ? 'Да' : 'Нет'
          } else if (!val || val === 'null' || val === 'indefined') {
            val = '–'
          }

          item += '<div class="map-popup__item-row"><dt>' + c.name + ':  </dt>' + '<dd>' + (val || '–') + '</dd></div>'
        })

        item += '</dl>'

        return item
      })?.join('<hr size="1" noshade>')
    }

    popup
      .setLngLat(e.lngLat)
      .setHTML(html)
      .addTo(this.map)
  }

  featuresClick(e, features, hoverPopup) {
    if (features?.length > 1) {
      hoverPopup.remove()

      this.clickPopupShow = true

      const html = features?.map(f => {
        return this.getListItemHtml(f)
      })?.join('<hr size="1" noshade>')

      const listHtml = `<div class="map-popup__list">${html}</div>`

      this.clickPopup.once('open', e => {
        e.target._content.addEventListener('click', this.itemClickHandler)
      })
      this.clickPopup
        .setLngLat(e.lngLat)
        .setHTML(listHtml)
        .addTo(this.map)

      this.clickPopup.once('close', e => {
        e.target._content.removeEventListener('click', this.itemClickHandler)
        this.clickPopupShow = false
      })

      window.addEventListener('click', this.globalClickHandler)
    } else if (features?.length === 1) {
      const [feature] = features

      if (feature) {
        const config = this.popupConfigs[feature.layer.id]

        if (config.clickHandler) {
          config.clickHandler({ feature, id: feature.properties.id, layerId: feature.layer.id })
        }
      }
    }
  }

  getListItemHtml(f) {
    const config = this.popupConfigs[f.layer.id]

    let item = '<div class="map-popup__item-row"><dt>Слой: </dt><dd>' + (config?.layer || '–') + '</dd></div>'

    config.fields.forEach(c => {
      let val

      if (c.type === 'date') {
        val = f.properties?.[c.value] ? ritmDate.toFormat(f.properties?.[c.value], c.format || 'DD.MM.YYYY') : '–'
      } else if (c.func) {
        val = c.func(f.properties?.[c.value])
      } else if (c.value?.includes('.')) {
        const [first, second] = c.value.split('.')
        const parsed = JSON.parse(f.properties?.[first])
        val = parsed?.[second]
      } else {
        val = f.properties?.[c.value]
      }

      if (typeof val === 'boolean') {
        val = val ? 'Да' : 'Нет'
      } else if (!val || val === 'null' || val === 'indefined') {
        val = '–'
      }

      item += '<div class="map-popup__item-row"><dt>' + c.name + ':  </dt>' + '<dd>' + (val || '–') + '</dd></div>'
    })

    return `<dl class="map-popup__item" data-layer="${f.layer.id}" data-id="${f.properties.id}">${item}</dl>`
  }

  globalClickHandler(e) {
    if (!e.target.closest('.map-popup')) {
      this.clickPopup.remove()
      window.removeEventListener('click', this.globalClickHandler)
    }
  }

  itemClickHandler(e) {
    const listItem = e.target.closest('.map-popup__item')
    const layerId = listItem.dataset?.layer
    const featureId = listItem.dataset.id

    if (layerId) {
      const config = this.popupConfigs[layerId]
      const feature = this.features.find(e => e.layer.id === layerId && String(e.properties.id) === String(featureId))

      if (config.clickHandler) {
        config.clickHandler({ feature, id: listItem.dataset.id, layerId })
      }
    }
  }
}
