import { ritmDate } from '@/utils'

const state = {
  flyToGeom: null,
  cardId: null,
  cardLocation: null,
  categoryId: null,
  vehiclesCount: 0,
  vehiclesWithoutGeomCount: 0,
  eventTime: { interval: { from: ritmDate.dateDiff(null, -15, 'm'), to: ritmDate.date() } },
  mainTrackGeom: null,
  mainTrackEvents: null,
  secondTrackGeom: null,
  secondTrackEvents: null,
  selectedSecondTrackId: null,
  trackDataId: null,
  trackColors: {
    main: '#2E93FE',
    second: '#E87835'
  },
  trackPlayerOn: false,
  isVehicleListOpened: false,
  telematicsData: null,
  vehicles: [],
  vehiclesLoading: false,
  telematicsModal: null,
  filters: [],
  filterCategories: {
    customer_id: [],
    main_contractor_id: [],
    type_group_id: [],
    colour_id: []
  },
  statusFilter: {
    type: 'retranslation_status',
    title: 'Статус',
    items: [
      {
        id: 'green',
        count: 0,
        name: 'На связи',
        active: true,
        values: ['online']
      },
      {
        id: 'yellow',
        count: 0,
        name: 'Нет связи (отметка более 5 мин назад)',
        active: true,
        values: [
          'without_coords_halfline',
          'with_coords_halfline',
          'with_coords_offline'
        ]
      },
      {
        id: 'blue',
        count: 0,
        name: 'На связи (нет координат более 30 мин)',
        active: true,
        values: ['without_coords_offline']
      },
      {
        id: 'red',
        count: 0,
        name: 'Нет данных',
        active: true,
        values: ['nodata', null]
      }
    ],
    show: true
  },
  statusFilterUpdated: false,
  // journal map point (toggle from Journal Card Tab)
  journalMapPoint: null
}

const mutations = {
  SET_MT_FIELD(state, { field, value }) {
    if (field.includes('.')) {
      const [first, second] = field.split('.')

      state[first][second] = value
    } else {
      state[field] = value
    }
  },
  SET_MT_STATUS_COUNT(state, { online, offline }) {
    const { items } = state.statusFilter

    items[0].cnt = online
    items[1].cnt = offline
  }
}

export default {
  state,
  mutations
}
