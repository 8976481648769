import Vue from 'vue'
import Vuex from 'vuex'

// main
import actions from './main/actions'
import getters from './main/getters'
import mutations from './main/mutations'
import state from './main/state'

// modules
import analytics from './modules/analytics'
import attributesEditor from './modules/attributes-editor'
import auth from './modules/auth'
import book from './modules/book'
import charts from './modules/charts'
import commonData from './modules/common-data'
import createObject from './modules/create-object'
import dsManager from './modules/ds-manager'
import profiles from './modules/profiles'
import railwayControl from './modules/railway-control'
import unload from './modules/unload'
import map from './modules/map'
import mapPopups from './modules/map-popup'
import modelling from './modules/modelling'
import monitoring from './modules/monitoring'
import monitoringTmc from './modules/monitoring-tmc'
import navbar from './modules/navbar'
import pot1 from './modules/pot-1'
import publicTransport from './modules/public-transport'
import odd from './modules/odd'
import userTasks from './modules/user-tasks'
import modalWindow from './modules/modal-window'
import reports from './modules/reports'
import situationCenter from './modules/situation-center'
import userRoles from './modules/user-roles'
import rolePermission from './modules/role-permission'
import eventEditor from './modules/event-editor'
import permissions from './modules/permissions'
import bdd from './modules/bdd'
import journal from './modules/journal'
import tablePrime from './modules/table-prime'
import tablePrimeSimple from './modules/table-prime-simple'
import print from './modules/print'
import mapConfigs from './modules/map-configs'
import services from './modules/services'

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  getters,
  mutations,
  state,
  modules: {
    analytics,
    attributesEditor,
    auth,
    book,
    charts,
    commonData,
    createObject,
    profiles,
    railwayControl,
    unload,
    map,
    mapPopups,
    mapConfigs,
    modelling,
    monitoring,
    monitoringTmc,
    navbar,
    pot1,
    publicTransport,
    odd,
    dsManager,
    userTasks,
    modalWindow,
    reports,
    situationCenter,
    userRoles,
    rolePermission,
    eventEditor,
    permissions,
    bdd,
    journal,
    tablePrime,
    tablePrimeSimple,
    print,
    services
  }
})
