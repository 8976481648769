<template>
  <div class="odd-router-list">
    <div class="odd-router-list__header">
      <r-title type="subtitle-2">
        Сохраненные маршруты
      </r-title>
    </div>
    <div class="odd-objects__filters">
      <r-search-input
        :filter-text="filterText"
        @change="filterText = $event"
      />
      <r-button
        icon="filter"
        :active="isFiltersVisible"
        type="secondary"
        @click="isFiltersVisible = !isFiltersVisible"
      />
      <odd-object-filter
        v-if="isFiltersVisible"
        :active="isFiltersVisible"
      />
    </div>
    <ul
      v-if="routes.length"
      class="odd-router-list__routes"
    >
      <li
        v-for="route in filteredRoutes"
        :key="route.id"
        :class="getRouteClassName(route)"
        @click="clickHandler(route)"
      >
        <div
          class="odd-router-list__route-color"
          :style="getStyle(route.color)"
        />
        <div class="odd-router-list__route-info">
          <r-text>
            {{ route.name }}
          </r-text>
          <r-text type="caption">
            Создан: {{ parseDate(route.created_at) }}
          </r-text>
        </div>
        <div class="odd-router-list__route-controls">
          <r-button
            icon="trash"
            simple
            @click.native.stop="handleDelete(route)"
          />
          <r-button
            icon="focus-zone"
            simple
            @click.native.stop="flyTo(route)"
          />
        </div>
      </li>
    </ul>
    <div
      v-else
      class="odd-router-list__nodata"
    >
      <r-text type="caption">
        Нет ни одного маршрута
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    OddObjectFilter: () => import('../filter/object-filter')
  },
  props: {
    routes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filterText: '',
      isFiltersVisible: false
    }
  },
  computed: {
    activeRoutes() {
      return this.$store.state.odd.routerState.activeRoutes
    },
    filteredRoutes() {
      const query = this.filterText?.trim()?.toLowerCase()

      if (!query) return this.routes

      return this.routes?.filter(r => r.name?.toLowerCase()?.includes(query))
    }
  },
  methods: {
    getStyle(color) {
      return `background-color: ${color}`
    },
    getRouteClassName(route) {
      return this.isActive(route)
        ? 'odd-router-list__route odd-router-list__route--active'
        : 'odd-router-list__route'
    },
    isActive(route) {
      return !!this.activeRoutes.find(r => r.id === route.id)
    },
    clickHandler(route) {
      if (this.isActive(route)) {
        this.$store.commit('REMOVE_ODD_ROUTER_ACTIVE_ITEM', route)
      } else {
        this.$store.commit('ADD_ODD_ROUTER_ACTIVE_ITEM', route)
      }
    },
    parseDate(date) {
      return this.$ritmDate.toFormat(date, 'DD.MM.YYYY • HH:mm')
    },
    handleDelete(route) {
      this.$confirm('Вы уверены, что хотите удалить данный маршрут?', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отменить',
        type: 'warning'
      })
        .then(_ => {
          this.deleteRoute(route)
        })
        .catch(_ => {})
    },
    async deleteRoute(route) {
      try {
        if (this.isActive(route)) {
          this.$store.commit('REMOVE_ODD_ROUTER_ACTIVE_ITEM', route)
        }

        const url = `objectInfo/telemetry.user_routes?id=${route.id}`

        await this.$store.dispatch('DELETE_REQUEST', { url })

        this.$emit('update')
      } catch (error) {
        console.warn(error)
      }
    },
    flyTo(route) {
      if (!route?.geom) return

      this.$store.commit('SET_ODD_FIELD', {
        field: 'flyToGeom',
        value: route.geom
      })
    }
  }
}
</script>

<style lang="scss">
.odd-router-list {
  padding: 8px;
  flex: 1;
  overflow: hidden;
  height: 100%;
  display: grid;
  align-content: start;
  grid-gap: 0.5rem;

  &__routes {
    display: grid;
    grid-gap: 0.5rem;
    overflow: auto;
    height: 100%;
  }

  &__route {
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: var(--bg_containers) !important;
    border-radius: var(--border-radius);
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: var(--accent_hover) !important;
    }

    &--active {
      background-color: var(--accent_selected) !important;

      &:hover {
        .odd-router-list__route-controls {
          display: grid;
        }
      }
    }

    &-info {
      margin-right: 8px;
    }

    &-color {
      width: 18px;
      height: 18px;
      border-radius: var(--border-radius);
      margin-right: 8px;
    }

    &-controls {
      position: absolute;
      display: none;
      grid-auto-flow: column;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      right: 0.25rem;
      background-color: var(--modal_bg) !important;
      border-radius: var(--border-radius);
    }
  }
}
</style>
