<template>
  <div v-if="field">
    <div
      v-if="field.type === 'string'"
      class="user-info__wrapper"
    >
      <r-text type="caption">
        {{ $t(`user-info:input-${field.value}`) }}
        <r-text
          v-if="field.value !== 'middle_name' && field.value !== 'position'"
          type="caption"
          style="display: inline"
        >
          *
        </r-text>
      </r-text>
      <div class="user-info__block-row__content">
        <el-input
          v-model="user[field.value]"
          :disabled="field.value === 'name' && !isCreateUser"
          size="mini"
          :placeholder="$t(`user-info:input-${field.value}`)"
          class="r-input"
        />
      </div>
    </div>
    <div
      v-else-if="field.type === 'email'"
      class="user-caption-2"
    >
      <r-text type="caption">
        {{ $t(`user-info:input-${field.value}`) }}
        <r-text
          type="caption"
          style="display: inline"
        >
          *
        </r-text>
      </r-text>
      <div class="user-info__block-row__content">
        <el-input
          v-model="user[field.value]"
          type="email"
          size="mini"
          name="email"
          :placeholder="$t(`user-info:input-${field.value}`)"
          class="r-input"
        />
      </div>
    </div>
    <div
      v-else-if="field.type === 'date'"
      class="user-info__wrapper"
    >
      <r-text type="caption">
        {{ $t(`user-info:input-${field.value}`) }}
      </r-text>
      <div class="user-info__block-row__content">
        <r-date-picker
          v-model="user[field.value]"
          class="r-date-picker"
          type="datetime"
          size="mini"
          name="datetime"
          clearable
          format="dd.MM.yyyy HH:mm"
          :placeholder="$t(`user-info:input-${field.value}`)"
          :disabled="module === 'account'"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
    </div>
    <div
      v-else-if="field.type === 'checkbox'"
      class="user-info__wrapper"
    >
      <div class="user-info__block-row__content">
        <el-checkbox
          v-model="user[field.value]"
          class="r-checkbox"
          size="mini"
        >
          <r-text type="caption">
            {{ $t(`user-info:input-${field.value}`) }}
          </r-text>
        </el-checkbox>
      </div>
    </div>
    <div
      v-else-if="field.type === 'password'"
      class="user-info__wrapper"
    >
      <r-text type="caption">
        {{ $t(`user-info:input-${field.value}`) }}
      </r-text>
      <div class="user-info__block-row__content">
        <form autocomplete="off">
          <el-input
            v-model="user[field.value]"
            show-password
            size="mini"
            name="password"
            :placeholder="$t(`user-info:input-${field.value}`)"
            auto-complete="new-password"
            class="r-input"
          />
        </form>
      </div>
    </div>
    <div
      v-else-if="field.type === 'role_name' && instance === 'tagil'"
      class="user-info__wrapper"
    >
      <r-text type="caption">
        {{ $t(`user-info:input-${field.value}`) }}
      </r-text>
      <div class="user-info__block-row__content">
        <el-select
          v-model="user[field.value]"
          class="r-select"
          :placeholder="$t(`user-info:input-${field.value}`)"
          filterable
          clearable
          size="mini"
        >
          <el-option
            v-for="item in userRolesList"
            :key="item.id"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { userRolesList } from '../configs/'

export default {
  props: {
    field: {
      type: Object,
      default: () => null
    },
    user: {
      type: Object,
      default: () => null
    },
    module: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userRolesList
    }
  },
  computed: {
    isCreateUser() {
      return this.$store.state.userRoles.isCreateUser
    },
    instance() {
      return this.$store.state.instance
    }
  }
}
</script>

<style lang="scss">
.user-info {
  .r-input {
    &.is-disabled {
      input {
        background-color: var(--bg_panel_primary) !important;
        opacity: 1 !important;
      }
    }
  }
  .r-select {
    width: 100%;
  }
  .r-text {
    margin-bottom: 4px;
  }
}
</style>

<i18n>
{
  "ru": {
    "user-info:input-name": "Логин",
    "user-info:input-email": "Электронная почта",
    "user-info:input-last_name": "Фамилия",
    "user-info:input-first_name": "Имя",
    "user-info:input-middle_name": "Отчество",
    "user-info:input-company": "Компания",
    "user-info:input-position": "Должность",
    "user-info:input-valid_until": "Срок действия аккаунта",
    "user-info:input-admin": "Права системного администратора",
    "user-info:input-password": "Пароль",
    "user-info:input-role_name": "Роль"
  },
  "en": {
    "user-info:input-name": "Login",
    "user-info:input-email": "Email",
    "user-info:input-last_name": "Last name",
    "user-info:input-first_name": "First name",
    "user-info:input-middle_name": "Middle name",
    "user-info:input-company": "Company",
    "user-info:input-position": "Position",
    "user-info:input-valid_until": "Account expiration date",
    "user-info:input-admin": "System admin rights",
    "user-info:input-password": "Password",
    "user-info:input-role_name": "Role"
  }
}
</i18n>
