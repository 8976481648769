import cloneDeep from 'lodash.clonedeep'
import throttle from 'lodash.throttle'
import {
  CommonSelectors,
  Constants,
  doubleClickZoom,
  createSupplementaryPoints
} from '@/libs/map-draw/lib-utils'
import {
  undraggableTypes,
  getFeaturesByLayerId,
  createHoveredNodeHelper,
  clearAllHelpers,
  createLineGeoJson,
  getLineRouteItemsCoordinates,
  getWaypointsQuery,
  createRouteHelper,
  clearAllRouteGeometry,
  clearReverseRouteStyling,
  clearActiveStopPointsStyling,
  getNearestFeature,
  clearFixedRouteItems
  // setActiveStopPointsStyling
} from '@/libs/map-draw/helpers'

export const createPTDirectSelectMode = (MapboxDraw, map) => {
  const DirectSelect = {}
  const showError = throttle(() => {
    map.$message({
      message:
        'Не удается построить дальнейший маршрут из последней указанной точки',
      type: 'error',
      duration: 1500,
      customClass: 'route-editor-warning'
    })
  }, 1000)

  const { model, editorState } = map.$store.state.publicTransport
  const { ids } = model

  const getWaypoints = (
    id,
    currentObjectId,
    lineRouteItems,
    fixedRouteItems
  ) => {
    const index = lineRouteItems.findIndex(
      item => item.stop_point_id === currentObjectId
    )
    const sortedFixed = [...fixedRouteItems].sort((a, b) => a.index - b.index)
    const sortedFixedIds = sortedFixed.map(item => item.stop_point_id)
    const lriIds = lineRouteItems.map(item => ({
      id: item.stop_point_id || item.node_id
    }))

    switch (index) {
      case 0:
        return [
          { id },
          ...lriIds.filter(
            (item, index) =>
              sortedFixedIds.includes(item.id) ||
              index === lineRouteItems.length - 1
          )
        ]
      case lineRouteItems.length - 1:
        return [
          ...lriIds.filter(
            (item, index) => index === 0 || sortedFixedIds.includes(item.id)
          ),
          { id }
        ]
      default: {
        // get fixed point and current dragged
        let midFiltered = lineRouteItems
          .filter(
            item =>
              sortedFixedIds.includes(item.stop_point_id) ||
              item.stop_point_id === currentObjectId
          )
          .sort((a, b) => a.index - b.index)

        const currentItemIndex = midFiltered.findIndex(
          item => item.stop_point_id === currentObjectId
        )

        midFiltered = midFiltered.map(item => ({ id: item.stop_point_id }))
        midFiltered[currentItemIndex] = { id }

        return [lriIds[0], ...midFiltered, lriIds[lriIds.length - 1]]
      }
    }
  }

  DirectSelect.onSetup = function(opts) {
    const featureId = opts.featureId
    const feature = this.getFeature(featureId)

    if (!feature) {
      throw new Error(
        'You must provide a featureId to enter direct_select mode'
      )
    }

    if (feature.type === Constants.geojsonTypes.POINT) {
      throw new TypeError("direct_select mode doesn't handle point features")
    }

    const { properties } = feature
    const { line_route_items } = properties
    const state = {
      featureId,
      feature,
      dragMoveLocation: opts.startPos || null,
      dragMoving: false,
      canDragMove: false,
      selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
      lineRouteItems: line_route_items,
      filteredRouteItems: cloneDeep(line_route_items),
      coordinates: feature.getCoordinates(),
      waypoints: [],
      serverWaypoints: [],
      prevLineRouteItems: cloneDeep(line_route_items),
      draggingPointIndex: 0,
      currentCoordinates: [],
      prevCoordinates: feature.getCoordinates(),
      currentPoint: null
    }

    this.setSelectedCoordinates(
      this.pathsToCoordinates(featureId, state.selectedCoordPaths)
    )
    this.setSelected(featureId)
    doubleClickZoom.disable(this)

    this.setActionableState({
      trash: true
    })

    if (editorState.type === 'lines') {
      createRouteHelper(this.map, feature.getCoordinates(), true)
    }

    return state
  }

  DirectSelect.onMouseMove = function(state, e) {
    const [selected] = this.getSelectedIds()

    if (selected) {
      this.doRender(selected)
    }

    // On mousemove that is not a drag, stop vertex movement.
    const isFeature = CommonSelectors.isActiveFeature(e)
    const onVertex = CommonSelectors.isVertex(e)
    const noCoords = state.selectedCoordPaths.length === 0
    if (isFeature && noCoords) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    } else if (onVertex && !noCoords) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    } else this.updateUIClasses({ mouse: Constants.cursors.NONE })

    if (editorState.type !== 'lines') this.stopDragging(state)
  }

  DirectSelect.onDrag = async function(state, e) {
    if (state.canDragMove !== true) return
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    const { lng, lat } = e.lngLat
    // get point features
    const stopPointFeatures = getFeaturesByLayerId(
      this.map,
      e,
      ids.stop_points,
      25
    )
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes, 25)
    const pointFeatures = [...stopPointFeatures, ...nodesFeatures]

    if (pointFeatures.length) {
      // get neareast point
      const point = getNearestFeature(pointFeatures, [lng, lat])
      const { geometry, properties } = point
      const { coordinates } = geometry

      // get dragging index
      const { draggingPointIndex, lineRouteItems } = state
      const isEndOfRoute =
        draggingPointIndex === 0 ||
        draggingPointIndex === lineRouteItems.length - 1

      if (isEndOfRoute && point.layer.id !== ids.stop_points) {
        // if end of route and target === node - do nothing
        createHoveredNodeHelper(
          this.map,
          [e.lngLat.lng, e.lngLat.lat],
          'stop_points'
        )
      } else {
        if (!state.currentPoint || state.currentPoint !== properties.id) {
          try {
            createHoveredNodeHelper(this.map, coordinates, 'stop_points')
            state.currentPoint = properties.id

            const waypoints = getWaypoints(
              properties.id,
              state.filteredRouteItems[draggingPointIndex].stop_point_id,
              lineRouteItems,
              editorState.fixedRouteItems
            )

            const locString = getWaypointsQuery(waypoints)
            const url = `pubtran_route/${model.id}/${locString}?postprocessing=line_route_item`
            const { data } = await map.$store.dispatch('ROUTE_REQUEST', {
              url
            })
            const { geometry, line_route_items } = data

            state.lineRouteItems = line_route_items.map((item, i) => ({
              ...item,
              geom: { coordinates: geometry.coordinates[i] },
              node: undefined
            }))
            state.waypoints = waypoints.map(w => w.id)
            state.coordinates = geometry.coordinates
            state.serverWaypoints = data.waypoints
            createRouteHelper(this.map, geometry.coordinates)
            state.selectedCoordPaths = []
          } catch (error) {
            console.warn(error)
            showError(map)
          }
        } else {
          // if cursor on current point (prev point) - just hover it
          createHoveredNodeHelper(this.map, coordinates, 'stop_points')
        }
      }
    } else {
      // if no points - clear geometry and return state to prev state
      createHoveredNodeHelper(
        this.map,
        [e.lngLat.lng, e.lngLat.lat],
        'stop_points'
      )
      state.currentPoint = null
      if (state.prevLineRouteItems.length) {
        state.lineRouteItems = cloneDeep(state.prevLineRouteItems)
        state.coordinates = state.prevCoordinates
        // state.feature.setCoordinates(state.prevCoordinates)
        createRouteHelper(this.map, state.feature.getCoordinates())
        state.feature.changed()
      }
      this.clearSelectedCoordinates()
    }

    state.dragMoveLocation = e.lngLat
  }

  DirectSelect.startDragging = function(state, e) {
    this.map.dragPan.disable()
    const { meta } = e.featureTarget.properties
    state.canDragMove =
      meta === 'vertex' || undraggableTypes.indexOf(editorState.type) === -1
    state.dragMoveLocation = e.lngLat
    state.draggingPointIndex = Number(e.featureTarget.properties.coord_path)
  }

  DirectSelect.stopDragging = function(state) {
    this.map.dragPan.enable()
    state.dragMoving = false
    state.canDragMove = false
    state.dragMoveLocation = null

    if (editorState.type === 'lines') {
      clearAllHelpers(this.map)

      state.feature.setCoordinates(state.coordinates)
      state.feature.setProperty('line_route_items', state.lineRouteItems)
      state.feature.setProperty('waypoints', state.waypoints)
      clearAllRouteGeometry(this.map)

      state.prevCoordinates = state.coordinates

      map.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'lineRouteItems',
        value: state.lineRouteItems
      })
      map.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'waypoints',
        value: state.serverWaypoints
      })

      map.draw.changeMode('simple_select', {
        featureIds: [state.featureId]
      })

      if (state.currentPoint) {
        this.fireUpdate()
      }
    }
  }

  DirectSelect.onTouchEnd = DirectSelect.onMouseUp = function(state) {
    if (state.dragMoving && editorState.type !== 'lines') {
      this.fireUpdate()
    }

    this.stopDragging(state)
  }

  DirectSelect.clickNoTarget = function() {
    clearAllRouteGeometry(this.map)
    clearReverseRouteStyling(this.map)
    clearActiveStopPointsStyling(this.map, map.$store)
    clearFixedRouteItems(this.map, map.$store)

    this.changeMode(Constants.modes.SIMPLE_SELECT)
  }

  DirectSelect.toDisplayFeatures = function(state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = 'true'
      push(geojson)
      let supplementaryPoints

      if (editorState.type === 'lines') {
        const { fixedRouteItems } = editorState
        const filteredItems = state.prevLineRouteItems.filter(
          item =>
            item.stop_point_id &&
            fixedRouteItems.findIndex(
              f => f.stop_point_id === item.stop_point_id
            ) === -1
        )

        const coordinates = getLineRouteItemsCoordinates(filteredItems)
        const options = {
          map: this.map,
          selectedPaths: state.selectedCoordPaths
        }

        supplementaryPoints = createSupplementaryPoints(
          createLineGeoJson(coordinates),
          options
        )
        state.filteredRouteItems = filteredItems

        const source = this.map.getSource('fix-route-items')

        if (fixedRouteItems.length) {
          source.setData({
            type: 'FeatureCollection',
            features: fixedRouteItems.map(f => ({
              type: 'Feature',
              geometry: {
                ...f.geom,
                type: 'Point'
              }
            }))
          })
        } else {
          source.setData({
            type: 'FeatureCollection',
            features: []
          })
        }
      } else {
        supplementaryPoints = createSupplementaryPoints(geojson, {
          map: this.map,
          midpoints: true,
          selectedPaths: state.selectedCoordPaths
        })
      }

      supplementaryPoints.forEach(push)
    } else {
      geojson.properties.active = 'false'
      push(geojson)
    }
    this.fireActionable(state)
  }

  DirectSelect.onTrash = function(state) {
    // Uses number-aware sorting to make sure '9' < '10'. Comparison is reversed because we want them
    // in reverse order so that we can remove by index safely.
    state.selectedCoordPaths
      .sort((a, b) => b.localeCompare(a, 'en', { numeric: true }))
      .forEach(id => state.feature.removeCoordinate(id))
    this.fireUpdate()
    state.selectedCoordPaths = []
    this.clearSelectedCoordinates()
    this.fireActionable(state)

    clearFixedRouteItems(this.map, map.$store)

    if (state.feature.isValid() === false) {
      this.deleteFeature([state.featureId])
      this.changeMode(Constants.modes.SIMPLE_SELECT, {})
    }
  }

  return { ...MapboxDraw.modes.direct_select, ...DirectSelect }
}
