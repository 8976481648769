import debounce from 'lodash.debounce'
import { jsonToGeojson, getFirstSymbolId, modifyLinksGeometry } from '@/utils'
import { mapColors } from '@/config/colors'
import { layersConfig } from '../configs'
import { modifyLines, getSPFeatures } from '../helpers'

const getIncludeConfig = geom_type => {
  switch (geom_type) {
    case 'stop_points':
      return {
        only: ['id', { field: 'stop_geom', func: 'st_as_geojson' }, 'geom']
      }
    case 'lines':
      return {
        only: ['id', 'geom', 'name', 'color'],
        include: {
          line_routes: {
            include: {
              line_route_items: {
                only: ['id', 'node_id', 'stop_point_id', 'index'],
                include: {
                  node: {
                    only: ['id', 'no', 'geom']
                  },
                  stop_point: {
                    only: ['id', 'no', 'geom']
                  }
                },
                where: [
                  {
                    field: 'node_id',
                    op: '!null',
                    type: 'OR',
                    value: ''
                  },
                  {
                    field: 'stop_point_id',
                    op: '!null',
                    type: 'OR',
                    value: ''
                  }
                ]
              }
            }
          }
        }
      }
    default:
      return {}
  }
}
const getEditorRequestConfig = (map, geom_type) => {
  return {
    only: ['id', 'geom'],
    where: [
      {
        field: 'geom',
        op: '!null',
        type: 'AND',
        value: ''
      }
    ],
    ...getIncludeConfig(geom_type)
  }
}

export class BaseController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
    this.handlers = {}
  }

  addBaseLayers() {
    const { ids } = this.$store.state.publicTransport.model

    const layers = ['stop_points', 'lines']
    layers.forEach(type => this.addLayerByType(type, ids))

    // add layer for active card geom
    const id = 'pt-active-card'

    this.mapgl.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })
    this.mapgl.addLayer({
      id,
      source: id,
      type: 'line',
      paint: {
        'line-color': mapColors.activeColor,
        'line-width': 4
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      }
    })
  }

  addLayerByType(type, ids) {
    const id = ids[type]

    const loadModelLayer = async e => {
      if (e && e.noRequest) return
      const zoom = this.mapgl.getZoom()
      let config = getEditorRequestConfig(this.parent)

      // modify config
      if (type === 'stop_points' || type === 'lines') {
        config.only.push('name', 'no')
      }

      if (type === 'stop_points') {
        config.only.push('stop_geom')
      }

      if (type === 'lines') {
        config = {
          only: ['id', 'name', 'color'],
          limit: config.limit,
          include: {
            line_routes: {
              only: ['id', 'name', 'geom'],
              include: {
                line_route_items: {
                  only: ['id', 'index', 'node_id', 'stop_point_id'],
                  order: [{ index: 'asc' }],
                  include: {
                    stop_point: {
                      only: ['id', 'no', 'name']
                    }
                  }
                }
              },
              where: config.where
            }
          }
        }
      }

      try {
        this.$store.commit('ADD_PT_LOADING_LAYER', id)
        const url = `objectInfo/${id}?config=${JSON.stringify(
          config
        )}&zoom=${zoom}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        let features = Object.values(data)

        if (type === 'stop_points') {
          this.addSPLayers(id, features)
        } else {
          if (type === 'lines') {
            features = modifyLines(features)
          } else if (type === 'links') {
            features = modifyLinksGeometry(features)
          }

          const geojson = jsonToGeojson(features)

          if (!this.mapgl.getLayer(id)) {
            const options = {
              id,
              source: {
                type: 'geojson',
                data: geojson
              },
              ...layersConfig[type]
            }

            if (type === 'nodes' || type === 'links') {
              this.mapgl.addLayer(options, getFirstSymbolId(this.mapgl))
            } else if (
              type === 'lines' &&
              this.mapgl.getLayer(ids.stop_points)
            ) {
              this.mapgl.addLayer(options, 'pt-active-card')
            } else {
              this.mapgl.addLayer(options)
            }

            if (type === 'stop_points') {
              this.mapgl.addLayer({
                id: `${id}_icon`,
                source: id,
                ...layersConfig[`${type}_icon`]
              })
            }
          } else {
            this.mapgl.getSource(id).setData(geojson)
          }
        }

        this.$store.commit('REMOVE_PT_LOADING_LAYER', id)
      } catch (error) {
        console.warn(error)
        this.$store.commit('REMOVE_PT_LOADING_LAYER', id)
      }
    }

    this.addLayerHandler(type)

    this.handlers[id] = debounce(loadModelLayer, 200)
    this.handlers[id]()
    // this.mapgl.on('moveend', this.handlers[id])
  }

  addSPLayers(id, features) {
    const modifiedFeatures = getSPFeatures(features)

    const geojson = {
      type: 'FeatureCollection',
      features: modifiedFeatures
    }

    if (!this.mapgl.getSource(id)) {
      this.mapgl.addSource(id, {
        type: 'geojson',
        data: geojson
      })

      this.mapgl.addLayer({
        id: `${id}_connections`,
        source: id,
        ...layersConfig.stop_points_connections,
        filter: ['==', '$type', 'LineString']
      })
      this.mapgl.addLayer({
        id,
        source: id,
        ...layersConfig.stop_points_projections,
        filter: ['==', ['get', 'type'], 'points']
      })
      this.mapgl.addLayer({
        id: `${id}_points`,
        source: id,
        ...layersConfig.stop_points,
        filter: ['==', ['get', 'type'], 'symbols']
      })
      this.mapgl.addLayer({
        id: `${id}_icons`,
        source: id,
        ...layersConfig.stop_points_icon,
        filter: ['==', ['get', 'type'], 'symbols']
      })

      // add layer for fix route items
      const fixItemsLayerId = 'fix-route-items'

      this.mapgl.addSource(fixItemsLayerId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      })
      this.mapgl.addLayer({
        id: fixItemsLayerId,
        source: fixItemsLayerId,
        ...layersConfig.fixed_stop_points
      })
      this.mapgl.addLayer({
        id: `${fixItemsLayerId}_icon`,
        source: fixItemsLayerId,
        ...layersConfig.fixed_stop_points_icon
      })
    } else {
      this.mapgl.getSource(id).setData(geojson)
    }
  }

  updateLayers() {
    const layers =
      this.parent.$route.name === 'pt-router'
        ? ['stop_points']
        : ['stop_points', 'lines']

    const { ids } = this.$store.state.publicTransport.model

    layers.forEach(type => {
      const id = ids[type]

      if (this.handlers[id]) {
        this.handlers[id]()
      }
    })
  }

  addLayerHandler(type) {
    const { ids } = this.$store.state.publicTransport.model
    const layers = ['stop_points', 'lines']

    if (!layers.includes(type)) return

    const layerId = type === 'lines' ? ids[type] : `${ids[type]}_points`

    this.mapgl.on('mousemove', layerId, () => {
      if (this.parent.$route.name === 'pt-editor') return

      this.mapgl.getCanvas().style.cursor = 'pointer'
    })

    this.mapgl.on('mouseleave', layerId, () => {
      if (this.parent.$route.name === 'pt-editor') return

      this.mapgl.getCanvas().style.cursor = ''
    })

    this.mapgl.on('click', layerId, e => {
      const { features, point } = e
      const { properties } = features[0]
      const { x, y } = point
      if (this.parent.$route.name === 'pt-view') {
        if (features.length > 1) {
          this.parent.popupSettings.top = y - 38
          this.parent.popupSettings.left = x - 10
          this.parent.popupSettings.display = 'block'
          this.parent.popupSettings.values = features
        } else {
          this.openCard(type, properties.id)
          this.parent.popupSettings.display = 'none'
          this.parent.popupSettings.values = []
        }
      }
    })
  }

  async openCard(type, id) {
    this.$store.commit('SET_PT_FIELD', {
      field: 'cardId',
      value: id
    })
    this.$store.commit('SET_PT_FIELD', {
      field: 'cardType',
      value: type
    })
  }
}
