import axios from 'axios'

import vue from '@/main'
import router from '@/router'
import constants from '@/constants/url'
import { errorParser, encodeUrl, getHeaders, getGroupId } from '@/utils'

const validAuthHandler = e => {
  const data = e.response ? e.response.data : {}

  if (e?.response?.status === 403) {
    router.push({ path: '/access-denied' })
  }
  switch (data.no) {
    case 1002:
    case 1003:
    case 1004:
      vue.$store.commit('CLEAR_USER_DATA')
      router.push({ path: '/login' })
      errorParser.call(vue, e)
      break
    default:
      errorParser.call(vue, e)
      break
  }
}

export default {
  GET_MOB_REQUEST({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.MOB_URL}/${encodeUrl(url)}`, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  GET_REQUEST({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${encodeUrl(url)}`, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  LOGIN({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${encodeUrl(url)}`, {})
        .then(data => resolve(data))
        .catch(error => {
          if (error.response.status === 411) {
            resolve(error.response)
          } else {
            reject(error)
          }
        })
    })
  },
  GET_BLOB_REQUEST({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${encodeUrl(url)}`, {
          responseType: 'blob',
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  PUT_REQUEST({ state }, { url, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${constants.URL}/${encodeUrl(url)}`, data, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  POST_REQUEST({ state }, { url, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${constants.URL}/${encodeUrl(url)}`, data, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  POST_BLOB_REQUEST({ state }, { url, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${constants.URL}/${encodeUrl(url)}`, data, {
          responseType: 'blob',
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  DELETE_REQUEST({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${constants.URL}/${encodeUrl(url)}`, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  SEND_FORM_DATA({ state }, data) {
    // SEND FORM DATA (param - data)
    return new Promise((resolve, reject) => {
      const url = `${constants.URL}/${data.url}`
      axios
        .post(url, data.data, {
          headers: {
            ...getHeaders(),
            'content-type': 'multipart/form-data'
          }
        })
        .then(data => resolve(data))
        .catch(error => reject(error))
    })
  },
  ROUTE_REQUEST({ state }, options) {
    // GET REQUEST (options - url)
    return new Promise((resolve, reject) => {
      const url = `${constants.URL}/${options.url}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          reject(error)
        })
    })
  },
  GET_MODULE_USER_CONFIG({ state }, { module, id }) {
    if (!state.auth.user) return
    if (module === 'chart' || module === 'map') {
      id = state.profiles.currentProfile.id
    }
    return new Promise((resolve, reject) => {
      const url = `${constants.URL}/user/get_source_config?module=${module}&profile_tree_id=${id}`
      axios
        .post(url, '', {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  SAVE_MODULE_USER_CONFIG({ state }, { module, id }) {
    if (!state.auth.user || !module) return
    const book = {
      filters: state.book.filters,
      activeFields: state.book.activeFields,
      fastFilter: state.book.fastFilter,
      sortConfig: state.tablePrime.multiSortConfig.book_table,
      rows: state.tablePrime.rows.book_table,
      columnsStyle: state.tablePrime.columnsStyle?.book_table || {}
    }
    const map = {
      bi_style_config: state.map.styleConfig,
      bi_requested_fields: state.map.requestedFields,
      bi_object_fields_configs: state.map.objectFieldsConfigs,
      bi_baselayer_id: state.user_config.baselayerId
    }
    let user_config

    switch (module) {
      case 'map':
        id = state.profiles.currentProfile.id
        user_config = { ...map }
        break
      case 'book':
        user_config = { ...book }
        break
      default:
        break
    }

    const xhr = new XMLHttpRequest()
    xhr.open(
      'POST',
      `${constants.URL}/user/set_source_config?module=${module}&profile_tree_id=${id}`,
      false
    )
    xhr.setRequestHeader('REMOTE_USER', 'admin')
    xhr.setRequestHeader('Groupid', getGroupId())
    xhr.send(JSON.stringify({ ...user_config }))
  },
  SAVE_MAIN_USER_CONFIG({ state }, type) {
    if (!state.auth.user) return
    if (!state.profiles.currentProfile.id) return
    const user_config = {
      id: state.profiles.currentProfile.id,
      locale: 'ru',
      default_path: state.defaultPath,
      theme: state.theme,
      book: {
        activeBook: {
          source_id: state.book.activeBook.source_id,
          id: state.book.activeBook.id,
          name: state.book.activeBook.name,
          geomType: state.book.activeBook.geomType,
          dataLength: state.book.activeBook.dataLength
        },
        treeExpandedNodes: state.profiles.treeExpandedNodes,
        treeScrollNode: state.profiles.treeScrollNode
      },
      map: {
        bi_style_config: state.map.styleConfig,
        bi_requested_fields: state.map.requestedFields,
        bi_object_fields_configs: state.map.objectFieldsConfigs,
        bi_baselayer_id: state.user_config.baselayerId,
        activeLayers: state.mapConfigs.activeLayers?.map,
        sourceIdById: state.profiles.sourceIdById
      },
      sit_center: {
        disabledLayers: state.situationCenter.disabledLayers,
        objectCard: state.situationCenter.objectCard,
        layout: state.situationCenter.layout,
        toggleVehicles: state.situationCenter.toggleVehicles,
        activeLayers: state.mapConfigs.activeLayers?.sit_center
      },
      external_monitoring: {
        filterCategories: state.monitoringTmc.filterCategories,
        config: state.mapConfigs.configs?.monitoringTmc,
        baselayer: state.mapConfigs.baselayers?.monitoringTmc,
        activeLayers: state.mapConfigs.activeLayers?.monitoringTmc
      },
      internal_monitoring: {
        statusFilter: state.monitoring.statusFilter,
        filterCategories: state.monitoring.filterCategories,
        config: state.mapConfigs.configs?.monitoring,
        baselayer: state.mapConfigs.baselayers?.monitoring,
        activeLayers: state.mapConfigs.activeLayers?.monitoring
      },
      traffic_counts: {
        config: state.mapConfigs.configs?.traffic_counts,
        baselayer: state.mapConfigs.baselayers?.traffic_counts,
        activeLayers: state.mapConfigs.activeLayers?.traffic_counts
      },
      vehicle_inspection_sheets: {
        config: state.mapConfigs.configs?.vehicle_inspection_sheets,
        baselayer: state.mapConfigs.baselayers?.vehicle_inspection_sheets,
        activeLayers: state.mapConfigs.activeLayers?.vehicle_inspection_sheets
      },
      modelling: {
        config: state.mapConfigs.configs?.modelling
      },
      situation_center_display: {
        config: state.mapConfigs.configs?.situation_center_display
      },
      journal: {
        activeType: state.journal.activeType
      },
      pot1_dispatcher: state.pot1.filters,
      vehicle_inspection_applications: state.bdd.filters,
      acts_register: state.bdd.acts
    }

    state.globalLoading = 'loading'

    if (type === 'unload') {
      localStorage.setItem('mapConfig', JSON.stringify(user_config.map))
      delete user_config.map
      const blob = new Blob([JSON.stringify({ ...user_config })], {
        type: 'text/plain; charset=UTF-8'
      })

      console.log(
        navigator.sendBeacon(`${constants.URL}/user/profile?`, blob)
          ? 'Config saved'
          : 'Config not saved'
      )
    }

    return new Promise((resolve, reject) => {
      const url = `${constants.URL}/user/profile?`
      axios
        .post(url, user_config, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  }
}
