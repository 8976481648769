import { render, staticRenderFns } from "./railway-control-content-delete-block.vue?vue&type=template&id=1e58e040&scoped=true"
import script from "./railway-control-content-delete-block.vue?vue&type=script&lang=js"
export * from "./railway-control-content-delete-block.vue?vue&type=script&lang=js"
import style0 from "./railway-control-content-delete-block.vue?vue&type=style&index=0&id=1e58e040&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e58e040",
  null
  
)

export default component.exports