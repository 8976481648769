<template>
  <div
    v-loading="loading"
    class="ed-create"
  >
    <div class="ed-create__notice">
      <r-icon
        :size="20"
        name="info"
        color="var(--accent_warning)"
      />
      <r-text>
        В дальнейшем вы не сможете изменить содержимое, т.к. это электронное
        письмо
      </r-text>
    </div>
    <div class="ed-create__form">
      <div class="ed-create__form-item">
        <r-text color-type="subhead">
          Тема письма
        </r-text>
        <el-input
          v-model="mail.subject"
          class="r-input"
          size="mini"
          placeholder="Новое перекрытие"
        />
      </div>
      <div class="ed-create__form-item">
        <r-text color-type="subhead">
          Заголовок
        </r-text>
        <el-input
          v-model="mail.title"
          class="r-input"
          size="mini"
          placeholder="Введите текст"
        />
      </div>
      <div class="ed-create__form-item">
        <r-text color-type="subhead">
          Сообщение
        </r-text>
        <el-input
          v-model="mail.body"
          class="r-input"
          maxlength="750"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 12 }"
          show-word-limit
          placeholder="Введите текст"
        />
      </div>
      <div class="ed-create__form-item">
        <r-text color-type="subhead">
          Подпись
        </r-text>
        <el-input
          v-model="mail.signature"
          class="r-input"
          maxlength="100"
          type="textarea"
          :rows="2"
          show-word-limit
          placeholder="Подпись"
        />
      </div>
    </div>
    <div class="ed-create__form-item">
      <file-uploader @fileList="fileListHandler" />
    </div>
    <div class="ed-create__form-item">
      <r-text color-type="subhead">
        Группы получателей
      </r-text>
      <el-select
        v-model="recipientGroups"
        class="r-select"
        size="mini"
        multiple
      >
        <el-option
          v-for="item in subscribers.groups"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </div>
    <div class="ed-create__form-item">
      <r-text color-type="subhead">
        Отдельные получатели
      </r-text>
      <el-select
        v-model="singleRecipients"
        class="r-select"
        size="mini"
        multiple
      >
        <el-option
          v-for="item in subscribers.singles"
          :key="item.id"
          :value="item.id"
          :label="`${item.name} (${item.email})`"
        />
      </el-select>
    </div>
    <div class="ed-create__controls">
      <r-button
        class="ed-create__exit-button"
        simple
        @click="$emit('mode', 'view')"
      >
        Выйти
      </r-button>
      <r-button
        type="success"
        class="ed-create__save-button"
        :disabled="!isReady"
        @click="send"
      >
        Сохранить и отправить
      </r-button>
    </div>
  </div>
</template>

<script>
import fileUploader from './file-uploader.vue'
import { hashToArray, notifyFactory } from '@/utils'

export default {
  components: { fileUploader },
  data() {
    return {
      loading: false,
      text: '',
      title: '',
      signature: '',
      source_id: '08772af2-f329-48fa-b5a0-087a8cb5ed78',
      subscribers: {
        groups: [],
        singles: []
      },
      recipientGroups: [],
      singleRecipients: [],
      fileList: [],
      mail: {
        body: '',
        title: '',
        subject: '',
        signature: ''
      }
    }
  },
  computed: {
    isReady() {
      const { body, title, subject, signature } = this.mail
      return body.length && title.length && subject.length && signature.length
    }
  },
  mounted() {
    this.getSubscriberGroups()
    this.getSubscriberSingles()
  },
  methods: {
    async getSubscriberGroups() {
      this.loading = true

      const source_id = '08772af2-f329-48fa-b5a0-087a8cb5ed78'
      const url = `objectInfo/${source_id}`

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        this.subscribers.groups = hashToArray(data)
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    fileListHandler(list) {
      this.fileList = list.map(f => f.response.resources_ids[0])
    },
    async getSubscriberSingles() {
      this.loading = true

      const source_id = '781cf941-7578-493f-a582-7dd3af8d5680'
      const config = {
        order: { id: 'asc' },
        include: { groups: {}, subscriber_groups: {} }
      }
      const url = `objectInfo/${source_id}?config=${JSON.stringify(config)}`

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        this.subscribers.singles = hashToArray(data)
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    async send() {
      this.loading = true
      const url = 'send_email'

      try {
        const data = {
          type: 'test',
          email_to_subscribers: this.singleRecipients,
          email_to_groups: this.recipientGroups,
          subject: this.mail.subject,
          template: 'test.html',
          values: {
            code: this.$ritmDate.date(),
            name: this.mail.title,
            desc: this.mail.body,
            signature: this.mail.signature
          },
          file_ids: this.fileList
        }
        await this.$store.dispatch('POST_REQUEST', { url, data })

        const message = 'Выбранные пользователи получат уведомления по E-Mail'
        this.$notify(notifyFactory('success', 'Рассылка создана', message))

        this.$emit('mode', 'view')
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss">
.ed-create {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  align-items: start;
  align-content: start;
  height: 100%;
  overflow: hidden;

  &__notice {
    background-color: var(--bg_containers);
    border-radius: var(--border-radius);
    padding: 0.6rem;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto 1fr;
  }

  &__save-button {
    font-weight: 400 !important;
    justify-self: start;
  }

  &__exit-button {
    font-weight: 400 !important;
    justify-self: start;
  }

  &__controls {
    justify-self: end;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
  }

  &__form {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;

    &-item {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.5rem;

      .el-select {
        &__tags-text {
          line-height: 1;
        }

        .el-tag {
          background-color: var(--accent_selected);
          border: none;
          color: var(--text_primary);
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.25rem 0 0.3rem;
          grid-gap: 0.25rem;

          &__close {
            height: 0.9rem;
            width: 0.9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0 !important;
            margin: 0;
            border-radius: var(--border-radius);
            transform: scale(1);
            background-color: var(--modal_bg);
            color: var(--text_primary);
          }
        }
      }

      .el-textarea {
        &__inner {
          font-size: 14px;
          line-height: 1.5;
          padding: 0.5rem 1rem 1rem 1rem;
        }

        .el-input__count {
          background: transparent;
        }
      }
    }
  }

  &__validity-switcher {
    .el-switch__label {
      color: var(--text_primary) !important;
      font-weight: 400;
    }
  }

  &__interval-picker {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    justify-content: start;
  }
}
</style>
