import { ritmDate } from '@/utils'

export const getDatazoomPercentByIndex = function(state, index) {
  if (!state.chartRef) return

  const dataLength = state.chartRef.option.series[0].data.length

  if (!dataLength) {
    return { start: 0, end: 30 }
  }

  const percent = index * 100 / dataLength

  return Math.floor(percent)
}

export const getDataZoomRangeByPercent = function(percent) {
  if (!percent) {
    return { start: 0, end: 30 }
  }
  const start = (percent - 15) >= 0 ? (percent - 15) : 0
  const end = (percent + 15) <= 100 ? (percent + 15) : 100

  return {
    start,
    end
  }
}

export const getDatazoomPercentByTime = function(state, datetime) {
  if (!state.chartRef) return

  let times = []
  state.chartRef.option.series.forEach(e => {
    if (e.data) {
      times.push(e.data[0])
      times.push(e.data[e.data.length - 1])
    }
  })
  times = times.map(e => ritmDate.toUnix(e[0])).sort((a, b) => {
    return a > b ? 1 : a < b ? -1 : 0
  })
  const min = times.shift()
  const max = times.pop()
  const index = ritmDate.toUnix(datetime)

  const percent = (index - min) * 100 / (max - min)

  return Math.floor(percent)
}

export const getDatazoomValueByPercent = function(data, { start, end }) {
  const length = data?.length
  if (!length) return { start: null, end: null }
  const timeArray = data.map((e) => {
    return ritmDate.toUnix(e[0])
  })
  const timeStart = timeArray[0]
  const timeEnd = timeArray[length - 1]
  const diff = timeEnd - timeStart

  const startTime = Math.floor(start * diff / 100) + timeStart
  const endTime = timeEnd - Math.ceil((100 - end) * diff / 100)

  return {
    start: ritmDate.toFormat(startTime, 'DD.MM.YYYY HH:mm:ss'),
    end: ritmDate.toFormat(endTime, 'DD.MM.YYYY HH:mm:ss')
  }
}
