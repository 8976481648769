import cloneDeep from 'lodash.clonedeep'
import { dataZoomConfig } from '../configs'
import { rDate } from '@/utils'

export * from './datazoom'

const colors = {
  true: '#59D64E',
  false: '#FF3E3E',
  all: '#2E93FE',
  stroimekhanizatsiya: '#e41a1c',
  stroytech: '#377eb8',
  ural_syberian_company: '#4daf4a',
  stroi_servis: '#a65628',
  1: '#377eb8',
  2: '#59D64E',
  3: '#ffac00',
  4: '#B33EA9'
}

export const setChartStyle = (state, variables) => {
  const colors = {}

  colors.bg = variables['--bg_panel_primary']
  colors.text = variables['--text_secondary']

  state.chartOption.textStyle = { color: colors.text }
  if (state.chartOption.legend) {
    state.chartOption.legend.textStyle = {
      color: colors.text,
      fontSize: 10
    }
  }

  if (state.chartOption.series[0].itemStyle && state.chartType === 'type-4') {
    state.chartOption.series[0].itemStyle = {
      borderWidth: 3,
      borderColor: colors.bg
    }
  }
}

export const prepareChartData = (initialData, type) => {
  const cloneData = cloneDeep(initialData).reverse()
  const dataOptions = dataTypes[type]
  if (!dataOptions) return
  const data = []
  const xAxis = { data: [] }
  for (let i = 0; i < cloneData.length; i++) {
    if (cloneData[i]) {
      xAxis.data.unshift(
        reformatTime(
          rDate.zone(-5).format(cloneData[i].to, 'MM.DD.YY HH:mm')
        )
      )
    }
  }
  dataOptions.forEach(e => {
    const values = []
    for (let i = 0; i < cloneData.length; i++) {
      if (cloneData[i]) {
        if (cloneData[i][e]) {
          values.unshift(cloneData[i][e])
        } else {
          values.unshift(0)
        }
      }
    }
    data.push({ dataTitle: e, color: colors[e], data: values })
  })

  return { data, xAxis }
}

const dataTypes = {
  'chsmeo_cams?format=stat': ['false', 'true'],
  chsmeo_traffic_light_count: ['false', 'true'],
  'chsmeo_wialon_tracks?format=stat': [
    'stroytech',
    'stroimekhanizatsiya',
    'stroi_servis',
    'ural_syberian_company'
  ],
  chsmeo_ngpt_count: [1, 2, 3, 4]
}

const reformatTime = time => {
  const parseTime = time.split(':')
  const delta = parseTime[1] % 15
  const minutes = parseTime[1] - delta

  return `${parseTime[0]}:${minutes >= 10 ? minutes : `0${minutes}`}`
}

export const historyRequest = async(state, request) => {
  const { url } = request

  try {
    const config = getHistoryConfig(state)
    const { data } = await state.$store.dispatch('GET_REQUEST', {
      url: `${url}${config}`
    })

    state.isLoading = false
    return data
  } catch (e) {
    console.warn(e)
  }
}

const getHistoryConfig = state => {
  const from = state.currentHistoryConfig.from
  const to = state.currentHistoryConfig.to
  const step = state.currentHistoryConfig.step

  return `&from=${from}&to=${to}&step=${step}`
}

export const setChartOptions = state => {
  switch (state.chartType) {
    case 'type-1':
      setBasicChart(state)
      break
    case 'type-2':
      setBarChart(state)
      break
    case 'type-3':
      setBarXChart(state)
      break
    case 'type-4':
      setPieChart(state)
      break
    case 'type-5':
      setBarStackChart(state)
      break
    case 'type-6':
      setBarLinearChart(state)
      break
  }
}

const setBasicChart = state => {
  const options = Object.assign({}, state.basic)

  if (state.data) {
    // TODO: remove! hack by yaitsky
    if (state.timeAxis) {
      options.xAxis[0] = {
        type: 'time',
        boundaryGap: false,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            fontSize: 10
          }
        }
      }
    } else {
      options.xAxis[0].data = state.data.fields.data
    }
    options.series = Object.assign([])
    options.legend.data = Object.assign([])
    state.data.data.forEach(e => {
      const series = Object.assign({}, state.basic.series[0])
      series.data = e.data
      series.name = e.title
      options.series.push(series)
      options.legend.data.push(e.title)
    })
    if (state.colors?.length) {
      options.color = state.colors
    }
  }
  if (state.dataZoom) options.dataZoom = dataZoomConfig.horizontal
  state.chartOption = Object.assign({}, options)
}

const setBarChart = state => {
  const options = cloneDeep(state.bar)
  if (state.data) {
    options.xAxis.data = state.data.fields.data
    options.series = cloneDeep([])
    options.legend.data = cloneDeep([])
    state.data.data.forEach((e, i) => {
      const series = cloneDeep(state.bar.series[0])
      series.data = e.data
      series.name = e.title
      series.stack = i
      options.series.push(series)
      options.legend.data.push(e.title)
    })
    if (state.colors?.length) {
      options.color = state.colors
    }
  }
  if (state.dataZoom) options.dataZoom = dataZoomConfig.horizontal
  state.chartOption = options
}

const setBarStackChart = state => {
  const options = cloneDeep(state.bar)

  if (state.data) {
    options.xAxis.data = state.data.fields.data
    options.series = cloneDeep([])
    options.legend.data = cloneDeep([])
    state.data.data.forEach((e, i) => {
      const series = cloneDeep(state.bar.series[0])
      series.data = e.data
      series.name = e.title
      series.stack = '1'
      series.emphasis = {
        focus: 'series'
      }
      options.series.push(series)
      options.legend.data.push(e.title)
    })
    if (state.colors?.length) {
      options.color = state.colors
    }
  }
  if (state.dataZoom) options.dataZoom = dataZoomConfig.horizontal
  state.chartOption = options
}

const setBarLinearChart = state => {
  const options = cloneDeep(state.bar)
  if (state.data) {
    const xAxis = []
    const seriesData = []
    state.data.data.forEach(d => {
      xAxis.push(d.title)
      seriesData.push(...d.data)
    })
    options.xAxis.data = xAxis
    options.series = cloneDeep([])
    options.legend.data = cloneDeep([])
    const series = cloneDeep(state.bar.series[0])
    series.data = seriesData
    series.name = state.data.fields.title || ''
    options.series.push(series)
    if (state.colors?.length) {
      options.color = state.colors
    }
  }
  if (state.dataZoom) options.dataZoom = dataZoomConfig.horizontal
  state.chartOption = options
}

const setBarXChart = state => {
  const options = cloneDeep(state.barX)
  if (state.data) {
    options.yAxis.data = state.data.fields.data
    options.series = cloneDeep([])
    options.legend.data = cloneDeep([])
    state.data.data.forEach((e, i) => {
      const series = cloneDeep(state.barX.series[0])
      series.data = e.data
      series.name = e.title
      series.stack = i
      options.series.push(series)
      options.legend.data.push(e.title)
    })
    if (state.colors?.length) {
      options.color = state.colors
    }
  }
  if (state.dataZoom) options.dataZoom = dataZoomConfig.vertical
  state.chartOption = options
}

const setPieChart = state => {
  const options = cloneDeep(state.pie)
  if (state.data) {
    options.series[0].data = state.data.data.map(e => {
      const sum = e.data.reduce((result, current) => {
        return result + current
      }, 0)
      return {
        name: e.title,
        value: sum
      }
    })
    if (state.colors?.length) {
      options.color = state.colors
    }
  }
  options.series[0].name = state.data.fields.title || ''
  state.chartOption = options
}
