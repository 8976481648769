<template>
  <div
    v-if="canRender && !loading"
    v-loading="loading"
    :class="['r-filter-set', { noBackground }]"
  >
    <el-popover
      v-for="filter in filters"
      :key="filter.id"
      v-model="popoverVisible[`id_${filter.id}`]"
      placement="bottom"
      trigger="click"
      class="r-filter-set__item filter-item"
      popper-class="r-filter-set__popper"
    >
      <r-button
        slot="reference"
        :active="filter.active"
        type="secondary"
        dropdown
        uppercase
        bold
      >
        <r-icon
          v-if="filter.icon"
          :name="filter.icon"
          :size="20"
        />
        <span class="filter-item__title">
          {{ `${filter.title}${getFilterValues(filter)}` }}
        </span>
        <span class="arrow-down" />
      </r-button>
      <component
        :is="filter.initType || filter.type"
        :id="filter.id"
        :ref="filter.id"
        :filter-value="filter.prop"
        :format="filter.format"
        :picker-type="filter.pickerType"
        :no-clearable="filter.noClearable"
        :fast-values="filter.fastValues"
        @load="pipeData"
        @reset-filter="resetFilter"
      />
    </el-popover>
    <r-button
      v-if="hasActiveFilters(filters).length && !noResetButton"
      class="r-filter-set__reset-button"
      simple
      bold
      mini
      @click="resetAllFilters"
    >
      Сбросить фильтры
    </r-button>
  </div>
</template>

<script>
import intervalFilter from './r-filter-set/interval-filter'
import checkboxFilter from './r-filter-set/checkbox-filter'
import radioFilter from './r-filter-set/radio-filter'
import dateFilter from './r-filter-set/date-filter'
import dayFilter from './r-filter-set/day-filter'
import stringFilter from './r-filter-set/string-filter'
import rangeFilter from './r-filter-set/range-filter'

export default {
  components: {
    intervalFilter,
    checkboxFilter,
    radioFilter,
    dateFilter,
    dayFilter,
    stringFilter,
    rangeFilter
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    groupBySelected: {
      type: Boolean,
      default: false
    },
    noResetButton: {
      type: Boolean,
      default: false
    },
    noBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverVisible: {},
      initFilters: this.filters,
      loading: false
    }
  },
  computed: {
    canRender() {
      const { filters } = this
      return filters.length && Object.keys(filters[0]).length !== 0
    }
  },
  methods: {
    hasActiveFilters(filters) {
      return filters.filter(f => !!f.active && !f.noClearable)
    },
    resetAllFilters() {
      this.$emit('reset')
      const activeFilters = this.hasActiveFilters(this.filters)
      if (activeFilters.length) {
        activeFilters.forEach(f => {
          const component = this.$refs[f.id][0]

          component.resetFilter(true)
        })
      }
      this.resetAll()
    },
    resetFilter(filter) {
      const target = this.filters.find(f => f.id === filter?.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item
    },
    resetAll() {
      this.$emit('reset-all')
      this.$forceUpdate()
    },
    pipeData(data) {
      this.loading = true
      this.popoverVisible[`id_${data.id}`] = false
      this.$emit('load', data)
      if (this.groupBySelected) this.sortBySelected(this.filters)
      this.loading = false
    },
    sortBySelected(filters) {
      filters.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1))
    },
    getFilterValues(filter) {
      if (!filter.active) return ''

      switch (filter.type) {
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'string-filter':
          return `: ${filter.prop}`
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'checkbox-filter':
          return `: ${filter.prop
            .filter(f => f.value)
            .map(f => f.name)
            .join(', ')}`
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'radio-filter':
          return `: ${filter.prop.find(f => f.value).label}`
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'interval-filter': {
          const { from, to } = filter.prop.interval
          const fFrom = from
            ? ` С ${
          this.$ritmDate.toFormat(from, filter.dateFormat || 'DD.MM.YYYY')}`
            : null
          const fTo = to ? ` ПО ${
          this.$ritmDate.toFormat(to, filter.dateFormat || 'DD.MM.YYYY')}` : null
          const colon = filter.title ? ':' : ''

          return `${fFrom && fTo ? `${colon}${fFrom}, ${fTo}` : fFrom || fTo || ''}`
        }
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'day-filter': {
          const { from } = filter.prop.interval
          const fFrom = from
            ? ` ${this.$ritmDate.date(from)}`
            : null

          return `${fFrom || ''}`
        }
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'range-filter': {
          const { from, to } = filter.prop.interval
          const fFrom = from || from === 0
            ? ` С ${from}`
            : null
          const fTo = to || to === 0 ? ` ПО ${to}` : null

          return `${fFrom && fTo ? `:${fFrom}, ${fTo}` : fFrom || fTo || ''}`
        }
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
$selected: var(--accent_selected);
$active: var(--button_primary_bg);
$hover: var(--accent_hover);
$default: var(--text_subhead);
$panel: var(--bg_panel_primary);
$divider: var(--dividers_low_contrast);
$radius: var(--border-radius);

.r-filter-set {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem 0.5rem 1rem;
  background-color: $panel;
  display: flex;
  flex-wrap: wrap;

  &.noBackground {
    background-color: transparent;
  }

  &__popper {
    .popper-filter__content {
      max-height: 360px;
      overflow: auto;
      display: grid;
      grid-gap: 0.5rem;
    }
  }

  &__reset-button {
    margin: 0.5rem 0 0 auto !important;
  }

  .filter-item {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    max-width: 400px;
    overflow: hidden;

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
