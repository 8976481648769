<template>
  <div
    v-loading="isLoading"
    class="sc-chart-widget__wrapper"
  >
    <div class="sc-chart-widget">
      <div class="sc-chart-widget__controls-title">
        <r-text type="caption">
          {{ $t('period') }}
        </r-text>
      </div>
      <ul class="sc-chart-widget__controls">
        <li
          v-for="item in controlsItem"
          :key="item"
          :class="[
            `sc-chart-widget__controls-item`,
            { active: dataStep === item }
          ]"
          @click="changeDataStep(item)"
        >
          <r-text type="caption">
            {{ $t(item) }}
          </r-text>
        </li>
      </ul>
    </div>
    <sc-charts-builder
      v-if="chartData"
      slot="second-footer"
      :chart-data="chartData"
      :title="title"
      :type="chartType"
      :change-chart-type="changeChartType"
    />
  </div>
</template>

<script>
import scChartsBuilder from '../charts/charts'
import { prepareChartData, historyRequest } from '../charts/helpers'

const controlsItem = ['hour', 'day', 'week', 'month']
const chartTypes = ['type-1', 'type-2', 'type-3']

export default {
  components: { scChartsBuilder },
  props: {
    type: {
      type: String,
      default: 'widget'
    },
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      dataStep: 'day',
      chartType: 'type-1',
      chartData: null,
      chartTypes,
      controlsItem
    }
  },
  computed: {
    currentHistoryConfig() {
      const to = this.$ritmDate.toIso(this.$ritmDate.date())

      const from =
        this.dataStep === 'hour'
          ? this.$rDate.calc(2, this.dataStep, this.$ritmDate.date()).format('iso')
          : this.$rDate.calc(1, this.dataStep, this.$ritmDate.date()).format('iso')
      let step
      switch (this.dataStep) {
        case 'hour':
          step = 15
          break
        case 'day':
          step = 120
          break
        case 'week':
          step = 540
          break
        case 'month':
          step = 1600
          break
        default:
          break
      }

      return { from, to, step }
    }
  },
  watch: {
    currentHistoryConfig() {
      this.getChartData()
    }
  },
  created() {
    this.getChartData()
  },
  methods: {
    async getChartData() {
      this.isLoading = true
      try {
        const data = await historyRequest(this, { url: this.url })
        this.chartData = prepareChartData(data, this.title)
        this.isLoading = false
      } catch (e) {
        console.warn(e)
        this.isLoading = false
      }
    },
    changeChartType(e) {
      this.chartType = e
    },
    changeDataStep(val) {
      if (val === this.dataStep) return

      this.dataStep = val
    }
  }
}
</script>

<style lang="scss">
.sc-chart-widget__wrapper {
  overflow: hidden;
  .sc-chart-widget {
    min-width: 640px;
    height: auto;
    display: flex;
    &__controls {
      margin: 0;
    }
  }
  .sc-chart-builder__wrapper {
    height: 320px;
    .echarts {
      margin-top: 0;
      margin-left: 0;
    }
    .sc-chart-builder {
      &__controllers {
        top: -24px;
        left: auto;
        right: 0;
      }
    }
  }
}
</style>
