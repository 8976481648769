<template>
  <div
    v-loading="loading"
    class="pn-edit"
  >
    <div class="pn-edit__form">
      <div class="pn-edit__form-item">
        <r-text color-type="subhead">
          Тема уведомления
        </r-text>
        <el-input
          v-model="title"
          class="r-input"
          size="mini"
          placeholder="Введите заголовок"
        />
      </div>
      <div class="pn-edit__form-item">
        <r-text color-type="subhead">
          Сообщение
        </r-text>
        <el-input
          v-model="text"
          class="r-input"
          maxlength="500"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 7 }"
          show-word-limit
          placeholder="Введите текст"
        />
      </div>
      <div class="pn-edit__validity-switcher">
        <el-switch
          v-model="validity"
          :width="32"
          class="r-switch"
          active-text="Добавить срок действия уведомления"
        />
      </div>
      <div
        v-if="validity"
        class="pn-edit__interval-picker"
      >
        <div class="pn-edit__date-picker">
          <r-text type="caption">
            Начало
          </r-text>
          <r-date-picker
            v-model="from"
            class="r-date-picker"
            type="datetime"
            size="mini"
            name="datetime"
            format="dd.MM.yyyy HH:mm"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
        </div>
        <div class="pn-edit__date-picker">
          <r-text type="caption">
            Окончание
          </r-text>
          <r-date-picker
            v-model="to"
            class="r-date-picker"
            type="datetime"
            size="mini"
            name="datetime"
            format="dd.MM.yyyy HH:mm"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
        </div>
      </div>
    </div>
    <div class="pn-edit__controls">
      <r-button
        class="simple pn-edit__exit-button"
        @click="$emit('mode', ['view'])"
      >
        Выйти
      </r-button>
      <r-button
        type="success"
        class="pn-edit__save-button"
        :disabled="!isReady"
        @click="saveNotification"
      >
        Сохранить и отправить
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    selected: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      text: '',
      title: '',
      validity: false,
      from: '',
      to: ''
    }
  },
  computed: {
    isReady() {
      const { text, title, validity, from, to } = this
      return text.length && title.length && (!validity || (from && to))
    }
  },
  watch: {
    selected(selected) {
      this.setSelectedInfo(selected)
    }
  },
  mounted() {
    this.setSelectedInfo(this.selected)
  },
  methods: {
    setSelectedInfo(selected) {
      if (selected) {
        this.text = selected.text
        this.title = selected.subject
        if (selected.open_at || selected.close_at) this.validity = true
        this.from = selected.open_at
        this.to = selected.close_at
      }
    },
    async saveNotification() {
      this.loading = true
      const url = `objectInfo/${this.source_id}`

      try {
        const data = {
          subject: this.title,
          text: this.text,
          status: true,
          id: this.selected.id,
          close_at: this.to || null,
          open_at: this.from || null
        }
        await this.$store.dispatch('POST_REQUEST', { url, data })

        const message = 'Пользователи увидят его в мобильном приложении ПАТ'
        this.$notify(
          notifyFactory('success', 'Уведомление отредактировано', message)
        )

        this.$emit('mode', ['view'])
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss">
.pn-edit {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  align-items: start;
  align-content: start;
  height: 100%;
  overflow: hidden;

  &__save-button {
    font-weight: 400 !important;
    justify-self: start;
  }

  &__exit-button {
    font-weight: 400 !important;
    justify-self: start;
  }

  &__controls {
    justify-self: end;
  }

  &__form {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;

    &-item {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.5rem;

      .el-textarea {
        &__inner {
          font-size: 14px;
          line-height: 1.5;
          padding: 0.5rem 1rem 1rem 1rem;
        }

        .el-input__count {
          background: transparent;
        }
      }
    }
  }

  &__validity-switcher {
    .el-switch__label {
      color: var(--text_primary) !important;
      font-weight: 400;
    }
  }

  &__interval-picker {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    justify-content: start;
  }
}
</style>
