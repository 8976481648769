import {
  getRequestConfig,
  jsonToGeojson,
  getFeaturesInBboxLength,
  modifyLinksGeometry
} from '@/utils'
import {
  polygonStyleConfig,
  layerTypesConfig,
  clusterLayerConfig,
  clusterCountLayerConfig
} from '../configs'

const clusterMinCount = 2999

function setLayerSource(map, layerId, source) {
  const oldLayers = map.getStyle().layers
  const layerIndex = oldLayers.findIndex(l => l.id === layerId)
  const layerDef = oldLayers[layerIndex]
  const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id
  layerDef.source = source

  map.removeLayer(layerId)
  map.addLayer(layerDef, before)
}

const getLayerData = async(self, id, geom_type, geom_field) => {
  const zoom = self.map.mapgl.getZoom()
  const config = getRequestConfig(self.map, id, geom_type, geom_field)
  const source_id = self.map.$store.state.profiles.sourceIdById[id]
  const { datatype } = self.map.$store.state.datasources[source_id]

  if (geom_type === 'point') {
    const hasAngle = self.map.objectFields[id].find(f => f.title === 'angle')

    if (hasAngle) {
      config.only = [...config.only, 'angle']
    }
  }

  const url = `objectInfoBodyConfig/${source_id}?zoom=${zoom}`
  const { data } = await self.map.$store.dispatch('POST_REQUEST', {
    url,
    data: { config: config }
  })
  let features = Object.values(data)

  if (datatype === 'links') {
    features = modifyLinksGeometry(features)
  }

  return jsonToGeojson(
    features,
    geom_field
  )
}

export const getPointLayerLoader = (self, id, geom_type, geom_field) => async type => {
  const mapgl = self.map.mapgl

  try {
    self.map.mapLoading = true

    const mapSource = mapgl.getSource(id)
    const mapLayer = mapgl.getLayer(id)

    let data

    if (!mapSource) {
      // request points data
      data = await getLayerData(self, id, geom_type, geom_field)

      // add source with points data
      mapgl.addSource(id, {
        type: 'geojson',
        data: data
      })

      if (data.features.length > clusterMinCount) {
        mapgl.addSource(`${id}_clusters`, {
          type: 'geojson',
          data,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 25
        })
      }
    } else {
      if (type === 'filters') {
        data = await getLayerData(self, id, geom_type, geom_field)
        mapSource.setData(data)

        const clustersSource = mapgl.getSource(`${id}_clusters`)

        if (clustersSource) {
          clustersSource.setData(data)
        }
      } else {
        data = mapSource._data
      }
    }

    // get features in bbox count
    const featuresInBBoxLength = getFeaturesInBboxLength(
      data.features,
      self.map
    )
    self.map.featuresInBboxCounts[id] = data.features.length

    // clusters condition
    const isClusters = data.features.length > clusterMinCount
    const isClustersInBbox = featuresInBBoxLength > clusterMinCount

    const layerType = layerTypesConfig[geom_type]
    const styleConfig = self.map.styleConfig[id]

    if (!mapLayer) {
      // add points layer
      const options = {
        id,
        type: layerType,
        source: isClustersInBbox ? `${id}_clusters` : id,
        paint: {
          ...styleConfig.style.paint,
          ...self.map.controllers.style.getInitialStyling(id, layerType)
        },
        layout: { ...styleConfig.style.layout }
      }

      mapgl.addLayer(options)

      // add clusters layer
      if (isClusters) {
        mapgl.addLayer({
          id: `${id}_clusters`,
          source: `${id}_clusters`,
          ...clusterLayerConfig
        })

        mapgl.addLayer({
          id: `${id}_clusters_count`,
          source: `${id}_clusters`,
          ...clusterCountLayerConfig
        })

        // set clusters layer handler
        const clustersHandler = e => {
          const features = mapgl.queryRenderedFeatures(e.point, {
            layers: [`${id}_clusters`]
          })
          const clusterId = features[0].properties.cluster_id
          mapgl
            .getSource(id)
            .getClusterExpansionZoom(clusterId, function(err, zoom) {
              if (err) return

              mapgl.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom + 2
              })
            })
        }
        self.clustersHandlers[id] = clustersHandler

        mapgl.on('click', `${id}_clusters`, self.clustersHandlers[id])
      }
    }

    const clustersLayer = mapgl.getLayer(`${id}_clusters`)
    const iconLayer = mapgl.getLayer(`${id}_icons`)

    if (isClustersInBbox) {
      setLayerSource(mapgl, id, `${id}_clusters`)
      mapgl.setFilter(id, ['!', ['has', 'point_count']])

      if (iconLayer) {
        setLayerSource(mapgl, `${id}_icons`, `${id}_clusters`)
      }

      if (clustersLayer) {
        mapgl.setLayoutProperty(`${id}_clusters`, 'visibility', 'visible')
        mapgl.setLayoutProperty(
          `${id}_clusters_count`,
          'visibility',
          'visible'
        )
      }
    } else {
      setLayerSource(mapgl, id, id)
      mapgl.setFilter(id, null)

      if (iconLayer) {
        setLayerSource(mapgl, `${id}_icons`, id)
      }

      if (clustersLayer) {
        mapgl.setLayoutProperty(`${id}_clusters`, 'visibility', 'none')
        mapgl.setLayoutProperty(`${id}_clusters_count`, 'visibility', 'none')
      }
    }

    self.map.mapLoading = false
    self.map.isUpdate = !self.map.isUpdate
  } catch (error) {
    console.warn(error)
    self.map.mapLoading = false
  }
}

const getOtherLayerLoader = (self, id, geom_type, geom_field) => async() => {
  const mapgl = self.map.mapgl

  try {
    self.map.mapLoading = true

    const layerData = await getLayerData(self, id, geom_type, geom_field)
    const mapSource = mapgl.getSource(id)
    const mapLayer = mapgl.getLayer(id)
    const layerType = layerTypesConfig[geom_type]
    const styleConfig = self.map.styleConfig[id]

    if (layerType === 'fill' && !styleConfig.borderStyle) {
      self.map.$set(styleConfig, 'borderStyle', polygonStyleConfig.borderStyle)
    }

    self.map.featuresInBboxCounts[id] = layerData.features.length

    if (mapSource) {
      mapSource.setData(layerData)
    } else {
      mapgl.addSource(id, {
        type: 'geojson',
        data: layerData
      })
    }

    if (!mapLayer) {
      const options = {
        id,
        type: layerType,
        source: id,
        paint: {
          ...styleConfig.style.paint,
          ...self.map.controllers.style.getInitialStyling(id, layerType)
        },
        layout: { ...styleConfig.style.layout }
      }

      mapgl.addLayer(options)

      if (layerType === 'fill' && styleConfig.fillExtrusion.enabled) {
        self.map.controllers.style.toggleFillExtrusion(id)
      }
    }

    // add border to polygon layer
    if (layerType === 'fill') {
      if (!mapgl.getLayer(`${id}_border`)) {
        mapgl.addLayer({
          id: `${id}_border`,
          type: 'line',
          source: id,
          paint: { ...styleConfig.borderStyle.paint },
          layout: { ...styleConfig.borderStyle.layout }
        })
      }
    }

    // toggle line layer diagram
    if (layerType === 'line' && styleConfig.diagram.field) {
      self.map.controllers.diagram.setDiagramLayer(id, layerData.features)
    }

    self.map.mapLoading = false
    self.map.isUpdate = !self.map.isUpdate
  } catch (error) {
    console.warn(error)
    self.map.mapLoading = false
  }
}

export const getLayerLoader = (layerController, id, geomType, geomField) => {
  switch (geomType) {
    case 'point':
    case 'geometry':
      return getPointLayerLoader(layerController, id, geomType, geomField)
    default:
      return getOtherLayerLoader(layerController, id, geomType, geomField)
  }
}
