import { parseObjectFields } from '@/utils'
import { attrsLinks } from '@/components/export-button/helpers'

export const getFields = async(state, source_id) => {
  try {
    const initialFields = await state.$store.dispatch('GET_REQUEST', {
      url: `objectFields/${source_id}`
    })

    const parsed = parseObjectFields(initialFields, false).filter(f => {
      return !(f.reflection !== null && f.type === 'uuid')
    })
    return parsed?.map(p => {
      if (p.type === 'belongs_to_special') {
        p.read_only = true
      }
      if (!p.alias) {
        return p
      } else {
        return {
          ...p,
          origin_title: p.title,
          source_name: p.alias,
          title: p.title
        }
      }
    })
  } catch (e) {
    return null
  }
}

export const getDataLength = async(state, source_id, filter, init) => {
  try {
    const result = {}
    const config = { where: filter }
    const current = await state.$store.dispatch('GET_REQUEST', {
      url: `aggregation/${source_id}?config=${JSON.stringify(config)}`
    })

    result.current = current?.data?.metadata?.count || 0

    if (init) {
      const full = await state.$store.dispatch('GET_REQUEST', {
        url: `aggregation/${source_id}`
      })
      result.full = full?.data?.metadata?.count || 0
    }

    return result
  } catch (e) {
    return {
      full: 0,
      current: 0
    }
  }
}

export const getRequestFilters = state => {
  const where = []

  if (state.filtersConfigs.length) {
    state.filtersConfigs.forEach(e => {
      if (e.active) {
        const filterItem = {
          field: e.field,
          op: e.op,
          type: e.type,
          value: e.value
        }

        where.push(filterItem)
      }
    })
  }

  const fastFilter = state.fastFilter?.filterField

  if (fastFilter) {
    let field = fastFilter

    if (state.reflections[fastFilter]) {
      const key = attrsLinks[fastFilter] || attrsLinks.default

      field += `.${key}`
    }

    where.push({
      field,
      op: 'like',
      type: 'AND',
      value: state.fastFilter.filterText
    })
  }

  return where
}

export const getRequestSort = (config, fields) => {
  const sortField = config?.field
  const order = config?.order
  const field = fields.find(e => e.title === sortField)
  const sortFieldName = field?.origin_title || sortField
  const fieldType = field?.type

  if (
    fieldType &&
    order &&
    sortFieldName !== 'geom_area'
  ) {
    switch (fieldType) {
      case 'belongs_to':
      case 'has_one':
      case 'has_many_through':
        if (field.source_id === 'f09e59fb-ee58-482c-8b0f-9ac520cf2099') {
          if (sortFieldName === 'vehicles') {
            return {
              'vehicles.reg_number': order === -1 ? 'desc' : order === 1 ? 'asc' : 0
            }
          } else {
            return {
              [`vehicles.${sortFieldName}`]: order === -1 ? 'desc' : order === 1 ? 'asc' : 0
            }
          }
        } else {
          return {
            [`${sortFieldName}.name`]: order === -1 ? 'desc' : order === 1 ? 'asc' : 0
          }
        }
      case 'has_many':
        return {
          [`${sortFieldName}.${reflectionSortConfigs[sortFieldName] || 'id'}`]: order === -1
            ? 'desc'
            : order === 1
              ? 'asc'
              : 0
        }
      default:
        return {
          [sortFieldName]: order === -1 ? 'desc' : order === 1 ? 'asc' : 0
        }
    }
  } else {
    return {}
  }
}

const reflectionSortConfigs = {
  vehicles: 'reg_number'
}

export const getRequestConfig = (state, fields) => {
  const config = {}

  config.only = fields?.map(f => {
    if (f.reflection) {
      const { reflection } = f
      const originTitle = reflection.name + '.' + reflection.default_show_attribute || 'id'
      return originTitle
    } else {
      return f.origin_title
    }
  })

  config.where = getRequestFilters(state)
  config.order = state.sortConfig ? Object.values(state.sortConfig)?.filter(c => c?.field && c.order !== 0)?.map(c => {
    return getRequestSort(c, fields)
  }) : []

  if (!config.only.find(e => e === 'id')) {
    config.only.push('id')
  }

  const cuFields = ['created_user', 'updated_user']?.filter(
    e => fields?.find(f => f.source_name === e)
  )

  if (cuFields?.length) {
    cuFields.forEach(r => {
      if (!config.include) config.include = {}
      config.include[r] = { only: ['id', 'name'] }
    })
  }

  config.limit = state.pageSize
  config.offset =
    state.currentPage === 1 ? 0 : state.pageSize * (state.currentPage - 1)

  return config
}

export const parseDsData = function(state, data, fields) {
  const reflections = Object.keys(state.reflections)
  if (!reflections?.length) return data
  return data.map(e => {
    reflections.forEach(k => {
      const field = fields.find(l => l.source_name === k)

      if (field.deReflect) return e

      if (field.reflectParse) {
        if (Array.isArray(e[k]) && e[k]?.length) {
          if (k === 'pot_status') {
            e[k] = e[k]?.filter(e => e)?.[0]?.name
          }
        } else {
          e[k] = ''
        }
      } else if (Array.isArray(e[k]) && e[k]?.length) {
        e[k] = e[k]?.filter(e => e).map(l => l?.id)
      }
    })
    return e
  })
}

export const fieldVerification = function(configFields, initialFields) {
  if (!configFields) return null

  const fields = configFields.filter(f => {
    return initialFields.find(i => {
      return i.title === f.title && f.type === i.type
    })
  })?.map(f => {
    if (!f.reflection && !f.origin_title) {
      const initial = initialFields.find(i => {
        return i.alias === f.alias && f.type === i.type
      })
      return initial ? {
        ...initial,
        datatype: initial.datatype,
        origin_title: initial.title,
        source_name: initial.alias,
        title: initial.alias
      } : f
    } else {
      const initial = initialFields.find(i => {
        return i.title === f.title && f.type === i.type
      })
      const readOnly = ['created_user', 'updated_user']

      return {
        ...f,
        read_only: readOnly.includes(f.origin_title) || f.read_only,
        datatype: initial.datatype
      }
    }
  })

  return fields || null
}
