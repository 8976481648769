<template>
  <div class="reports-modal">
    <div class="reports-modal-content">
      <div class="reports-modal-content-row">
        <r-title>
          {{ item.title }}
        </r-title>
        <r-icon
          name="close-delete"
          :size="24"
          @click.native="closeModal()"
        />
      </div>
      <div class="reports-modal-content-row">
        <div
          class="reports-modal-content-col"
          style="width: 800px"
        >
          <div class="reports-modal-content-row">
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('start') }}
              </r-text>
              <r-date-picker
                v-model="start"
                class="r-date-picker"
                type="datetime"
                size="mini"
                name="datetime"
                format="dd.MM.yyyy HH:mm"
                :picker-options="{ firstDayOfWeek: 1 }"
              />
            </div>
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('end') }}
              </r-text>
              <r-date-picker
                v-model="end"
                class="r-date-picker"
                type="datetime"
                size="mini"
                name="datetime"
                format="dd.MM.yyyy HH:mm"
                :picker-options="{ firstDayOfWeek: 1 }"
              />
            </div>
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('test') }}
              </r-text>
              <el-input
                v-model="input"
                type="text"
                placeholder="Данные"
              />
            </div>
          </div>
        </div>
        <div
          class="reports-modal-content-col"
          style="width: 220px"
        >
          <r-button type="primary">
            {{ $t('export') }}
          </r-button>
          <r-button>
            <r-text>{{ $t('send') }}</r-text>
          </r-button>
        </div>
      </div>
      <div class="reports-modal-content-row">
        <div
          class="reports-modal-content-col"
          style="width: 800px"
        >
          <div class="reports-modal-content-row">
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('test') }}
              </r-text>
              <el-input
                v-model="input"
                type="text"
                placeholder="Данные"
              />
            </div>
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('test') }}
              </r-text>
              <el-input
                v-model="input"
                type="text"
                placeholder="Данные"
              />
            </div>
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('test') }}
              </r-text>
              <el-input
                v-model="input"
                type="text"
                placeholder="Данные"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="reports-modal-content-row">
        <div
          class="reports-modal-content-col"
          style="width: 800px"
        >
          <div class="reports-modal-content-row">
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('test') }}
              </r-text>
              <el-input
                v-model="input"
                type="text"
                placeholder="Данные"
              />
            </div>
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('test') }}
              </r-text>
              <el-input
                v-model="input"
                type="text"
                placeholder="Данные"
              />
            </div>
            <div class="reports-modal-content-col">
              <r-text color-type="subhead">
                {{ $t('test') }}
              </r-text>
              <el-input
                v-model="input"
                type="text"
                placeholder="Данные"
              />
            </div>
          </div>
        </div>
      </div>
      <reports-modal-preview />
    </div>
  </div>
</template>

<script>
import reportsModalPreview from './reports-modal-preview.vue'
import { ritmDate } from '@/utils'

export default {
  components: { reportsModalPreview },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      start: ritmDate.dateDiff(null, -10000),
      end: ritmDate.date(),
      input: ''
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('REPORTS_SET_ACTIVE_ID', '')
    }
  }
}
</script>

<style lang="scss">
.reports-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modal_overlay);
  z-index: 1000;

  &-content {
    position: absolute;
    top: 15vh;
    left: 50%;
    transform: translate(-50%);
    border-radius: var(--border-radius);
    background-color: var(--modal_bg);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.08);
    padding: 16px;
    min-height: 360px;
    max-height: 70vh;
    overflow: hidden;
    width: 1100px;
    max-width: 80vw;
    > * {
      margin-bottom: 8px !important;
      &:first-child {
        margin-bottom: 32px !important;
      }
    }
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px 16px;
      &:first-child {
        justify-content: space-between;
      }
      .r-icon {
        cursor: pointer;
      }
    }
    &-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px 16px;
    }
    input {
      width: 256px;
    }
    .r-button {
      width: 100%;
      margin: 0 !important;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "export": "Скачать отчёт на компьютер",
    "send": "Отправить по Email",
    "start": "Начало",
    "end": "Окончание",
    "test": "Поле ввода с заголовком"
  },
  "en": {
    "export": "Export report",
    "send": "Send Email",
    "start": "Start",
    "end": "End",
    "test": "Test title"
  }
}
</i18n>
