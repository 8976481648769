<template>
  <el-select
    v-model="value"
    class="r-select"
    placeholder="Выбрать"
    filterable
    clearable
    :multiple="multiple"
    :filter-method="filterOptions"
    @input="handleInput"
  >
    <el-option
      v-for="item in visibleOptions"
      :key="item.id"
      :label="labelValue(item)"
      :value="item.id"
    />
  </el-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Number, String],
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    labelField: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: ''
    }
  },
  computed: {

    visibleOptions() {
      const values = Array.isArray(this.value) ? this.value : [this.value]

      const selectedValues = this.options.filter(option => values.includes(option.id))

      return selectedValues.concat(this.options
        .filter(option =>
          !selectedValues.map(item => item.id).includes(option.id) &&
          (option[this.labelKey] || '')
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ))
        .slice(0, 100)
    },
    labelKey() {
      return this.labelField || 'name'
    }
  },
  created() {
  },
  methods: {
    labelValue(item) {
      return item[this.labelKey] || item.id
    },
    handleInput(e) {
      this.$emit('input', this.value)
    },
    filterOptions(query) {
      this.searchQuery = query
    }
  }
}
</script>

<style lang="scss"></style>
