import { reportOptions } from '../configs'
import { ritmDate } from '@/utils'

export class Inspections {
  constructor(state) {
    this.$store = state.$store
    this.state = state

    this.options = reportOptions.inspections
  }

  getTableData(data) {
    const { tableFields } = this.options

    return data.map(e => {
      const item = { }

      tableFields.forEach(l => {
        item[l.title] = e[l.value] || '-'
      })

      return item
    })
  }

  getChartData(data) {
    const chartData = {
      data: [],
      fields: {
        data: [],
        title: 'Время фиксации'
      }
    }
    const itemData = []
    let itemTitle = ''
    data.forEach(e => {
      if (!e.distance) return
      itemData.push(e.distance)
      chartData.fields.data.push(ritmDate.toFormat(e.event_time, 'DD.MM.YYYY HH:mm:ss'))
      itemTitle = e.reg_number
    })
    chartData.data.push({
      title: itemTitle,
      data: itemData
    })
    return chartData
  }
}
