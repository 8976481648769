<template>
  <div
    v-loading="isLoading"
    class="book"
  >
    <r-main-panel>
      <ritm-tree
        no-animations
        no-tools
        :tree-data="treeData"
        :loading="treeLoading"
        :node-click="beforeChangeOpenDs"
        :icon-by-item="iconByItem"
        :active-nodes="activeBookId ? [activeBookId] : []"
        :loading-nodes="loadingNodeId && isLoading ? [loadingNodeId] : []"
      />
    </r-main-panel>
    <div
      v-if="tableData && activeBookId"
      class="book-content"
    >
      <book-content-header />
      <book-content-tools />
      <table-prime
        v-if="!isLoading && activeFields.length"
        table-id="book_table"
        clickable
        multisort
        :data="tableData"
        :columns="activeFields"
        :selected-columns="activeFieldsName"
        @click-handler="openObjectInfo"
      />
      <el-pagination
        v-if="!isLoading && activeFields.length"
        class="r-pagination"
        :current-page.sync="currentPage"
        :page-sizes="[10, 25, 50, 100]"
        :page-size="pageSize"
        layout="prev, pager, next, sizes"
        :total="dataLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <div
        v-if="!activeFields.length && !isLoading"
        class="book-content__no-attrs"
      >
        <r-text>
          Отключены все атрибуты
        </r-text>
      </div>
    </div>
    <div
      v-else
      class="book-content"
    >
      <book-content-welcome />
    </div>
    <book-create-icons />
    <el-dialog
      class="r-book-modal"
      width="max-content"
      top="60px"
      :close-on-click-modal="true"
      :visible="showModal"
      :before-close="beforeCloseWindow"
      destroy-on-close
    >
      <book-modal
        v-if="showModal"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  getFields,
  getDataLength,
  getRequestConfig,
  parseDsData,
  fieldVerification
} from './helpers'

import { iconByItem } from '@/utils'

export default {
  components: {
    tablePrime: () => import('@/components/table-prime/table-prime'),
    bookContentWelcome: () => import('./components/book-content/book-content-welcome'),
    bookContentHeader: () => import('./components/book-content/book-content-header'),
    bookContentTools: () => import('./components/book-content/book-content-tools'),
    bookCreateIcons: () => import('./components/book-content/book-create-icons'),
    bookModal: () => import('./components/book-modal/book-modal'),
    ritmTree: () => import('@/components/ritm-tree')
  },
  data() {
    return {
      fields: null,
      currentPage: 1,
      pageSize: 50,
      iconByItem,
      treeData: [],
      treeLoading: false,
      loadingNodeId: null
    }
  },
  computed: {
    currentProfileTree() {
      return this.$store.state.profiles.currentProfileTree || []
    },
    isLoading() {
      return this.$store.state.book.isLoading
    },
    iconsSourceId() {
      return this.$store.state.book.iconsSourceId
    },
    activeCards() {
      return this.$store.state.commonData.activeCards.book || []
    },
    filtersConfigs() {
      return this.$store.state.book.filters?.filtersConfigs || []
    },
    activeFields() {
      return this.$store.state.book.activeFields?.fields || []
    },
    sortConfig() {
      return this.$store.state.tablePrime.multiSortConfig?.book_table || null
    },
    activeFieldsName() {
      return this.activeFields?.map(e => e.title) || []
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    dataLength() {
      return this.activeBook?.dataLength || 0
    },
    activeBookId() {
      return this.activeBook.id || null
    },
    tableData() {
      return this.activeBook.data || []
    },
    updateTable() {
      return this.$store.state.book.update
    },
    updateTableFields() {
      return this.$store.state.book.updateFields
    },
    reflections() {
      return this.$store.state.book.reflections || null
    },
    columnsReorder() {
      return this.$store.state.tablePrime.columnsReorder?.book_table || null
    },
    columnsStyle() {
      return this.$store.state.tablePrime.columnsStyle?.book_table || null
    },
    updateColumnsStyle() {
      return this.$store.state.tablePrime.updateColumnsStyle?.book_table || null
    },
    beforeClose() {
      return this.$store.state.navbar.beforeClose || null
    },
    fastFilter() {
      return this.$store.state.book.fastFilter || null
    },
    dsUrl() {
      return this.$store.state.book.dsUrl || null
    },
    showExport() {
      return this.$store.state.book.showExport || null
    },
    showModal() {
      return this.$store.state.book.showModal
    },
    currentProfileId() {
      return this.$store.state.profiles.currentProfile.id
    },
    modalHasChanges() {
      return this.$store.state.book.modalHasChanges || false
    },
    userDsIds() {
      return this.$store.state.auth.dsIds || {}
    }
  },
  watch: {
    '$store.state.profiles.updatedTreeTS': {
      handler(v) {
        if (v) this.loadTreeData()
      }
    },
    updateTable(val) {
      if (!val) return
      this.currentPage = 1
      this.beforeOpenDS(this.activeBook)
      this.$store.commit('UPDATE_ACTIVE_TABLE', false)
    },
    currentPage() {
      this.updateDsData(this.activeBook)
    },
    pageSize() {
      this.updateDsData(this.activeBook)
    },
    updateTableFields(val) {
      if (!val) return
      this.updateDsData(this.activeBook)
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'updateFields',
        value: false
      })
    },
    sortConfig: {
      handler: function() {
        if (this.isLoading) return
        this.openDs(this.activeBook)
      },
      deep: true
    },
    columnsReorder: {
      handler: function(val) {
        if (!val || this.isLoading) return
        this.saveActiveFields(val)
      },
      deep: true
    },
    updateColumnsStyle: {
      handler: function(val) {
        if (!val || this.isLoading) return
        this.saveActiveFields(this.activeFieldsName)
        this.$store.commit('TABLE_PRIME_SET_FIELD', {
          tableId: 'book_table',
          field: 'updateColumnsStyle',
          value: false
        })
      },
      deep: true
    }
  },
  async created() {
    try {
      await this.loadTreeData()

      if (this.activeBook.source_id) {
        this.beforeOpenDS(this.activeBook, true)
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    async loadTreeData() {
      try {
        this.treeLoading = true

        const sourceFilter = tree => {
          return tree.filter(t => {
            if (!t.children) return t.datatype !== 'tile_layer'
            t.children = sourceFilter(t.children)
            return t.children
          })
        }

        if (this.currentProfileTree?.length) {
          this.treeData = sourceFilter(this.currentProfileTree)
        } else {
          const url = `profile_tree?profile_id=${this.currentProfileId}`
          const { data } = await this.$store.dispatch('GET_REQUEST', { url })

          this.$store.commit('SET_CURRENT_PROFILE_TREE', data.profile_trees)
          this.treeData = sourceFilter(data.profile_trees)
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.treeLoading = false
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    openObjectInfo(val) {
      if (!val?.data) return
      this.$store.commit('BOOK_TOGGLE_MODAL', true)
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalActive',
        value: val.data
      })
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalName',
        value: 'book-info'
      })
    },
    beforeCloseWindow() {
      if (this.modalHasChanges) {
        const title = this.$t('before:title')
        const message = this.$t('before-cancel:text')
        const confirmButtonText = this.$t('button-confirm')
        const cancelButtonText = this.$t('button-cancel')

        this.$confirm(message, title, {
          customClass: 'r-message-box',
          closeOnPressEscape: true,
          closeOnClickModal: false,
          type: 'warning',
          confirmButtonText,
          cancelButtonText
        })
          .then(() => {
            this.$store.commit('BOOK_TOGGLE_MODAL', false)
            this.$store.commit('BOOK_SET_FIELD', {
              field: 'modalHasChanges',
              value: false
            })
          })
          .catch(() => {})
      } else {
        this.$store.commit('BOOK_TOGGLE_MODAL', false)
        this.$store.commit('BOOK_SET_FIELD', {
          field: 'modalHasChanges',
          value: false
        })
      }
    },
    saveActiveFields(order) {
      if (this.activeFields) {
        const fields = order.map(e => {
          const index = this.activeFields.findIndex(v => v.title === e)
          const value = this.activeFields[index]
          if (this.columnsStyle[value.title]) {
            value.style = this.columnsStyle[value.title]
          }
          return value
        })
        this.$store.commit('BOOK_SET_FIELD', {
          field: 'activeFields',
          value: {
            update: false,
            fields
          }
        })
        this.saveModuleConfig()
      }
    },
    async saveModuleConfig() {
      if (!this.activeBookId) return

      try {
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    beforeChangeOpenDs(object) {
      if (this.beforeClose) {
        const title = 'Предупреждение'
        const message = 'Все изменения будут отменены. Продолжить?'
        const confirm = 'Подтвердить'
        const cancel = 'Отмена'

        this.$confirm(message, title, {
          customClass: 'r-message-box',
          type: 'warning',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirm,
          cancel
        })
          .then(() => {
            this.beforeOpenDS(object, true)
            this.$store.commit('NAVBAR_SET_FIELD', {
              field: 'beforeClose',
              value: null
            })
          })
          .catch(() => {})
      } else {
        this.beforeOpenDS(object, true)
      }
    },
    async beforeOpenDS(object, init) {
      const { id, source_id, datatype } = object

      if (!source_id || datatype === 'model') return

      try {
        this.$store.commit('BOOK_SET_IS_LOADING', true)
        this.$store.commit('BOOK_CLEAR_CONFIGS')
        this.$store.commit('TABLE_PRIME_SET_FIELD', {
          tableId: 'book_table',
          field: 'columnsStyle',
          value: {}
        })
        this.$store.commit('TABLE_PRIME_SET_FIELD', {
          tableId: 'book_table',
          field: 'selectedObjects',
          value: []
        })
        this.$store.commit('BOOK_SET_FIELD', {
          field: 'activeFields',
          value: {
            fields: null,
            update: false
          }
        })
        await this.loadDsConfigById(id)
        await this.openDs(object, init)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('BOOK_SET_IS_LOADING', false)
      }
    },
    async loadDsConfigById(id) {
      try {
        const { data } = await this.$store.dispatch('GET_MODULE_USER_CONFIG', {
          module: 'book',
          id
        })
        const {
          activeFields,
          filters,
          rows,
          fastFilter,
          sortConfig,
          columnsStyle
        } = data

        if (activeFields) {
          activeFields.fields = activeFields?.fields?.filter(k => k?.title)
          this.$store.commit('BOOK_SET_FIELD', {
            field: 'activeFields',
            value: activeFields
          })
        }
        this.$store.commit('BOOK_SET_FIELD', {
          field: 'fastFilter',
          value: fastFilter || null
        })
        if (filters) {
          this.$store.commit('BOOK_SET_FIELD', {
            field: 'filters',
            value: filters
          })
        }
        this.$store.commit('TABLE_PRIME_SET_FIELD', {
          tableId: 'book_table',
          field: 'multiSortConfig',
          value: sortConfig || null
        })
        this.$store.commit('TABLE_PRIME_SET_FIELD', {
          tableId: 'book_table',
          field: 'rows',
          value: rows || 50
        })
        if (columnsStyle) {
          this.$store.commit('TABLE_PRIME_SET_FIELD', {
            tableId: 'book_table',
            field: 'columnsStyle',
            value: columnsStyle || {}
          })
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateDsData({ source_id, id, name }) {
      try {
        if (!this.activeFields?.length) {
          const { full, current } = await getDataLength(this, source_id, [])
          const activeBook = {
            data: [],
            fields: this.fields,
            dataLength: current,
            fullDataLength: full,
            source_id,
            id,
            name
          }

          this.$store.commit('BOOK_SET_ACTIVE_BOOK', activeBook)
        } else {
          this.$store.commit('BOOK_SET_IS_LOADING', true)
          let url = ''

          const options = getRequestConfig(this, this.activeFields)

          if (source_id === '8b74eb26-a094-44dd-924e-7cc00d0e8b8f') {
            options.where.push({ field: 'id', op: '!null', value: '' })
          }
          if (!options.only) {
            options.only = []
          }
          url = `objectInfo/${source_id}?config=${JSON.stringify(
            options
          )}&array=true`
          const { data } = await this.$store.dispatch('GET_REQUEST', { url })
          this.$store.commit('BOOK_SET_FIELD', {
            field: 'dsUrl',
            value: url
          })
          const { full, current } = await getDataLength(
            this,
            source_id,
            options.where
          )
          const parsedData = await parseDsData(this, data, this.fields)

          const activeBook = {
            data: parsedData,
            fields: this.fields,
            dataLength: current,
            fullDataLength: full || this.activeBook.fullDataLength,
            source_id,
            id,
            name
          }

          this.$store.commit('BOOK_SET_ACTIVE_BOOK', activeBook)
        }
      } catch (e) {
        const activeBook = {
          fields: this.fields,
          dataLength: 0,
          fullLength: 0,
          data: [],
          source_id,
          id,
          name
        }
        this.$store.commit('BOOK_SET_ACTIVE_BOOK', activeBook)
      } finally {
        this.$store.commit('BOOK_SET_IS_LOADING', false)
      }
    },
    async openDs(object, init) {
      const { source_id, id, name } = object

      this.loadingNodeId = id

      try {
        const fields = await getFields(this, source_id)

        await this.setActiveFields(fields)

        this.fields = fields

        let url = ''

        const options = getRequestConfig(this, this.activeFields)

        url = `objectInfo/${source_id}?config=${JSON.stringify(
          options
        )}&array=true`

        if (!this.activeFields?.length) {
          const { full, current } = await getDataLength(this, source_id, [], init)
          const activeBook = {
            data: [],
            dataLength: current,
            fullDataLength: init ? full : this.activeBook.fullDataLength,
            source_id,
            id,
            fields,
            name
          }

          this.$store.commit('BOOK_SET_ACTIVE_BOOK', activeBook)
        } else {
          const { data } = await this.$store.dispatch('GET_REQUEST', { url })
          this.$store.commit('BOOK_SET_FIELD', {
            field: 'dsUrl',
            value: url
          })
          const { full, current } = await getDataLength(
            this,
            source_id,
            options.where,
            init
          )
          const parsedData = await parseDsData(this, data, fields)

          const activeBook = {
            data: parsedData,
            dataLength: current,
            fullDataLength: init ? full : this.activeBook.fullDataLength,
            source_id,
            id,
            fields,
            name
          }

          this.$store.commit('BOOK_SET_ACTIVE_BOOK', activeBook)
        }
      } catch (e) {
        const activeBook = {
          fields: this.fields,
          dataLength: 0,
          fullDataLength: 0,
          data: [],
          source_id,
          id,
          name
        }
        this.$store.commit('BOOK_SET_ACTIVE_BOOK', activeBook)
      } finally {
        this.loadingNodeId = null
      }
    },
    setActiveFields(fields) {
      const correctFields = fieldVerification(this.activeFields, fields)

      let activeFields = null

      if (!this.activeFields) {
        let activeFields = fields.filter(
          e =>
            !e.system_field &&
            e.type !== 'has_many' &&
            e.type !== 'belongs_to' &&
            e.type !== 'has_many_through'
        )

        if (activeFields.length > 10) activeFields = activeFields.splice(0, 9)
      } else if (correctFields) {
        activeFields = correctFields
      } else {
        activeFields = this.activeFields
      }

      this.$store.commit('BOOK_SET_FIELD', {
        field: 'activeFields',
        value: {
          fields: activeFields,
          update: true
        }
      })
    },
    async saveBookConfig() {
      try {
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.book {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;

  .book-tree__container {
    padding: 0.25rem;
    height: 100%;
  }

  .book-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    position: relative;
    min-width: 215px;

    &__no-attrs {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 4rem;
    }
  }
}
</style>
