<template>
  <div
    v-loading="loading"
    class="attributes-editor"
  >
    <div class="attributes-editor__header">
      <r-title>Редактировать объект</r-title>
      <r-search-input
        placeholder="Поиск по атрибуту"
        :filter-text="searchText"
        @change="filterText = $event"
      />
    </div>
    <ul class="attributes-editor__list">
      <li
        v-for="field in filteredFields"
        :key="field.i"
        class="attributes-editor__list-item"
      >
        <r-text>
          {{ `${field.i}. ${field.alias || '—'}` }}
        </r-text>
        <r-text v-if="field.read_only">
          {{ `${field.value || '—'}` }}
        </r-text>
        <div v-else>
          <el-input
            v-if="field.type === 'text' || field.type === 'number'"
            v-model="field.value"
            class="r-input"
            :type="field.type"
            size="mini"
            placeholder="Введите текст"
            @input="fixChanges(field)"
          />
          <r-date-picker
            v-else-if="field.type === 'datetime' || field.type === 'date'"
            v-model="field.value"
            clearable
            type="datetime"
            class="r-input"
            placeholder="Выберите дату"
            default-time="12:00:00"
            format="dd.MM.yyyy HH:mm"
            :picker-options="{ firstDayOfWeek: 1 }"
            @input="fixChanges(field)"
          />
          <el-select
            v-else-if="field.type === 'select' || field.type === 'multiselect'"
            v-model="field.value"
            class="r-select"
            placeholder="Выбрать"
            size="mini"
            collapse-tags
            :multiple="field.type === 'multiselect'"
            clearable
            filterable
            @change="fixChanges(field)"
            @clear="fixChanges(field)"
          >
            <el-option
              v-for="item in field.nested"
              :key="item.id"
              :label="getLabel(field, item)"
              :value="item.id"
            />
          </el-select>
          <el-switch
            v-else-if="field.type === 'switch'"
            v-model="field.value"
            class="r-switch"
            @change="fixChanges(field)"
          />
        </div>
      </li>
    </ul>
    <div class="attributes-editor__controls">
      <r-button
        simple
        @click="close"
      >
        Отменить
      </r-button>
      <r-button
        type="success"
        class="attributes-editor__save-button"
        :disabled="!hasChanges"
        @click="save"
      >
        Сохранить
      </r-button>
    </div>
  </div>
</template>

<script>
import { types, reflectionsKeys, systemFields } from './configs'
import { getConfig, getChangedData } from './helpers'
import { parseObjectFields, hashToArray, notifyFactory } from '@/utils'

export default {
  data() {
    return {
      hasChanges: false,
      fields: null,
      filterText: '',
      sourcesHash: {},
      loading: false,
      changedFields: {}
    }
  },
  computed: {
    searchText() {
      return this.filterText
    },
    source_id() {
      return this.$store.state.modalWindow.attributesEditor.source_id
    },
    hash() {
      return this.$store.state.modalWindow.attributesEditor.hash
    },
    id() {
      return this.$store.state.modalWindow.attributesEditor.id
    },
    filteredFields() {
      const searchBy = ['alias', 'foreign_key', 'source_name']
      const query = this.filterText?.trim()?.toLowerCase()
      if (!query) return this.fields || []

      return this.fields.filter(e => {
        return searchBy
          .map(f => e[f])
          .join('')
          ?.trim()
          .toLowerCase()
          .includes(query)
      })
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      this.loading = true
      await this.getFields()
      await this.getInfo()
      this.loading = false
    },
    async getInfo() {
      const config = getConfig(this.id, this.fields)

      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: `objectInfo/${this.source_id}?config=${JSON.stringify(
          config
        )}`
      })

      if (!data) return

      const itemData = data[this.id]

      this.fields.forEach(f => {
        if (f.through) {
          f.value = itemData[f.through]?.map(e => e?.[f.foreign_key])
          f.valueCache = itemData[f.through]?.map(e => ({
            geozone_id: e?.[f.foreign_key],
            id: e?.id
          }))
        } else {
          f.value = itemData[f.alias] || null
        }
      })
    },
    getLabel(field, item) {
      const key = reflectionsKeys[field.source_name]

      if (key) {
        return item[key] || '—'
      } else {
        return item.name || item.number || '—'
      }
    },
    async getFields() {
      try {
        const fields = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${this.source_id}`
        })

        const parsed = parseObjectFields(fields).filter(
          (d, _, s) => {
            if (systemFields?.includes(d.title)) return false
            return !!d && !d.system_field && !s.find(f => `${d.title}_id` === f.title)
          }
        )

        this.fields = parsed.map((d, i) => {
          if (d.reflection) {
            this.sourcesHash[d.reflection.source_id] = {
              ...d.reflection
            }
          }

          return {
            ...d,
            i: i + 1,
            alias: d.alias || d.reflection?.alias,
            through: d.reflection?.through || null,
            value: null,
            type: this.getFieldType(d),
            nested: null
          }
        })

        await this.getNestedFields()
      } catch (e) {
        console.warn(e)
      }
    },
    async getNestedFields() {
      for (const key in this.sourcesHash) {
        try {
          let nested = this.hash[key]
          if (!nested) {
            const { data } = await this.$store.dispatch('GET_REQUEST', {
              url: `objectInfo/${key}`
            })
            nested = hashToArray(data)
            this.$store.commit('SET_ATTRIBUTES_HASH', [key, nested])
          }

          this.fields = this.fields.map(f => {
            if (f.reflection?.source_id === key) {
              return { ...f, nested }
            } else {
              return f
            }
          })
        } catch (e) {
          console.warn(e)
        }
      }
    },
    async save() {
      try {
        const data = getChangedData(this.id, this.fields, this.changedFields)

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.source_id}`,
          data
        })

        const message = this.$t('Сохранение выполнено')
        this.$notify(notifyFactory('succcess', message))
        this.$store.commit('ATTRIBUTES_EDITOR_SET', ['updated', true])
      } catch (e) {
        console.warn(e)
      } finally {
        this.close()
      }
    },
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    },
    fixChanges(field) {
      this.changedFields[field.source_name] = {}
      this.hasChanges = true
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
    },
    getFieldType(field) {
      const type = field?.reflection?.type || field.type
      return types[type]
    }
  }
}
</script>

<style lang="scss">
.attributes-editor {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: 100%;
  grid-gap: 0.5rem;
  overflow: hidden;
  grid-template-rows: min-content auto min-content;

  &__header {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    grid-auto-flow: row;
  }

  &__controls {
    display: grid;
    justify-content: end;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    padding: 1rem 0.75rem 0 0;
  }

  &__list {
    padding-right: 0.5rem;
    overflow-y: auto;
    display: grid;
    grid-auto-flow: row;
    align-content: start;
    grid-gap: 0.5rem;

    &-item {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1rem;
      justify-content: start;
      grid-template-columns: 200px minmax(200px, 1fr);
      align-items: center;
    }
  }
}
</style>
