import cloneDeep from 'lodash.clonedeep'
import { getHistoryLabel, clearAllHelpers } from '@/libs/map-draw/helpers'
import { notifyFactory, getEditableObjectFields, errorParser, ritmDate } from '@/utils'
import { geomTypesConfig } from '@/libs/map-draw/config'

export class BaseEditor {
  constructor(map) {
    this.map = map
    this.mapgl = map.mapgl
    this.draw = map.draw
    this.maxHistoryCount = 10
    this.deleted = []
    this.created = []
    this.updated = []
    this.handlers = {}
  }

  // common helpers
  clearEditorData() {
    this.deleted = []
    this.created = []
    this.updated = []
    this.state.history = []
    this.state.historyIndex = 0
  }

  // history
  updateHistory(type, length) {
    if (this.state.history.length === this.maxHistoryCount) {
      this.state.history.shift()
    }
    this.state.history.push({
      id: ritmDate.date(),
      label: getHistoryLabel(type, length),
      data: {
        updated: cloneDeep(this.updated),
        deleted: cloneDeep(this.deleted),
        created: cloneDeep(this.created)
      },
      dependenciesData: cloneDeep(this.state.dependenciesData),
      deletedDependenciesIds: cloneDeep(this.state.deletedDependenciesIds)
    })
    this.state.historyIndex = this.state.history.length - 1
  }

  changeHistoryStep(data) {
    if (typeof data === 'string') {
      this.state.historyIndex =
        data === 'inc'
          ? this.state.historyIndex + 1
          : this.state.historyIndex - 1
    } else this.state.historyIndex = data

    const step = this.state.history[this.state.historyIndex]
    this.created = cloneDeep(step.data.created)
    this.deleted = cloneDeep(step.data.deleted)
    this.updated = cloneDeep(step.data.updated)
    this.loadLayerData()
  }

  // draw listeners
  initDrawListeners() {
    this.mapgl.on('draw.modechange', () => {
      if (!this.state.enabled) return
      this.state.isDrawing = false
      clearAllHelpers(this.mapgl)
      if (this.state.mode === 'edit') return
      this.changeModeToCreate()
    })

    this.mapgl.on('draw.create', e => {
      if (!this.state.enabled) return
      const { features } = e
      this.created = [...this.created, ...features]
      console.log('created --- ', this.created)
      this.updateHistory('create', features.length)
    })
    this.mapgl.on('draw.update', e => {
      if (!this.state.enabled) return
      const { features, action } = e
      features.forEach(f => {
        if (f.properties.id) {
          const index = this.updated.findIndex(item => item.id === f.id)
          if (index > -1) this.updated.splice(index, 1, f)
          else this.updated.push(f)
        } else {
          const index = this.created.findIndex(item => item.id === f.id)
          this.created.splice(index, 1, f)
        }
      })
      console.log('updated --- ', this.updated)
      this.updateHistory(action, features.length)
    })
    this.mapgl.on('draw.delete', e => {
      if (!this.state.enabled) return
      const { features } = e
      this.deleted = [...this.deleted, ...features.filter(f => f.properties.id)]
      this.updated = this.updated.filter(
        f => !features.find(feature => feature.id === f.id)
      )
      this.created = this.created.filter(
        f => !features.find(feature => feature.id === f.id)
      )
      console.log('deleted --- ', this.deleted)
      this.updateHistory('delete', features.length)
    })
  }

  // mode helpers
  changeModeToCreate() {
    const { geom_type } = this.state
    const mode = `draw_${geomTypesConfig[geom_type]}`
    this.draw.changeMode(mode)
    this.state.mode = 'create'
  }

  toggleEditorMode(newMode) {
    const { mode, geom_type } = this.state
    if (newMode === mode) return

    if (newMode === 'create') {
      const mode = `draw_${geomTypesConfig[geom_type]}`
      this.draw.changeMode(mode)
      this.state.mode = 'create'
    } else {
      this.draw.changeMode('simple_select')
      this.state.mode = 'edit'
    }
  }

  // confirm modal helpers
  showConfirmModal(successCb, errorCb) {
    this.map
      .$confirm(
        'Результаты редактирования объектов не сохранены',
        'Изменения не сохранены',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Сохранить и продолжить',
          cancelButtonText: 'Продолжить без сохранения'
        }
      )
      .then(successCb)
      .catch(errorCb)
  }

  // saving helpers
  async requestForSaving() {
    const url = `objectInfo/${this.state.id}`
    const { geometry, multiPolygon } = this.state
    const parseGeometry = ({ type, coordinates }) => {
      if (geometry === 'multi_polygon' && type !== 'MultiPolygon') {
        return { type: 'MultiPolygon', coordinates: [coordinates] }
      }
      if (geometry === 'multi_line_string' && type !== 'MultiLineString') {
        return { type: 'MultiLineString', coordinates: [coordinates] }
      }
      return { type, coordinates }
    }

    const created = this.created.filter(c => !c.properties?.type)
    const splitLink = this.created.filter(c => c.properties?.type === 'splitLink')

    try {
      this.state.loading = true

      if (created.length) {
        let data

        if (multiPolygon && geometry === 'multi_polygon') {
          const coordinates = this.created?.reduce((a, c) => {
            a.push(c.geometry.coordinates)

            return a
          }, [])

          data = [{ geom: { type: 'MultiPolygon', coordinates } }]
        } else {
          data = this.created.map(f => ({
            geom: parseGeometry(f.geometry),
            ...f.properties
          }))
        }

        await this.map.$store.dispatch('POST_REQUEST', { data, url })
      }
      if (splitLink.length) {
        await Promise.all(
          splitLink.map(async sl => {
            const [x, y] = sl.geometry.coordinates

            await this.map.$store.dispatch('POST_REQUEST', {
              url: `splitLink/network.links?link_id=${sl.properties.link_id}&x=${x}&y=${y}`
            })
          })
        )
      }
      if (this.updated.length) {
        await this.map.$store.dispatch('PUT_REQUEST', {
          data: this.updated.map(f => {
            const feature = {
              ...f.properties,
              id: f.properties.id,
              geom: parseGeometry(f.geometry)
            }
            if (this.state.geom_type === 'zones') {
              feature.center_geom = f.properties.center_geom
            }
            if (
              this.state.geom_type === 'line_routes' ||
              this.state.geom_type === 'lines'
            ) {
              feature.line_route_items = f.properties.line_route_items
            }

            return feature
          }),
          url
        })
      }
      if (this.deleted.length) {
        await this.map.$store.dispatch('DELETE_REQUEST', {
          url: `${url}?id=${this.deleted.map(f => f.properties.id).join(',')}`
        })
      }

      if (this.savingCallback) this.savingCallback()

      this.state.loading = false
      const title = 'Сохранение'
      const message = 'Сохранение данных выполнено успешно'
      this.map.$notify(notifyFactory('success', title, message))
      this.map.$store.commit('MAP_SET_FIELD', ['updateGraph', true])
    } catch (error) {
      const title = 'Ошибка'
      const message = 'Произошла ошибка в процессе сохранения данных'
      this.state.loading = false
      errorParser.call(this.map, error, message, title)
    }
  }

  // save and close handlers
  async saveData() {
    await this.requestForSaving()
    this.clearEditorData()
    this.loadLayerData()
    this.state.modelLayers
      .filter(l => l.visible)
      .forEach(l => {
        if (this.handlers[l.id]) this.handlers[l.id]()
      })
  }

  closeButtonHandler(data) {
    if (this.state.history.length) {
      const successCb = () => {
        this.requestForSaving()
        this.toggleEditor(data)
      }
      const errorCb = action => {
        if (action === 'cancel') this.toggleEditor(data)
      }
      this.showConfirmModal(successCb, errorCb)
    } else {
      this.toggleEditor(data)
    }
  }

  clearEditingState() {
    this.clearEditorData()
    this.loadLayerData()
  }

  // objects fields request
  async getLayerObjectFields() {
    const { id } = this.state

    try {
      const url = `objectFields/${id}`
      const { data } = await this.map.$store.dispatch('GET_REQUEST', { url })

      this.state.objectFields = getEditableObjectFields(data)
    } catch (error) {
      console.warn(error)
    }
  }
}
