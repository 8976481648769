<template>
  <div class="m-editor-controls">
    <div class="m-editor-controls__header">
      <div
        v-if="scenarioStatus === 'draft' && tooManyInProgressCalcs"
        class="m-editor-controls__in-progress-info"
      >
        <r-text type="caption">
          Запущено допустимое количество расчетов. Пожалуйста, подождите.
        </r-text>
      </div>
      <div
        v-if="scenarioId"
        class="m-editor-controls__button"
      >
        <el-dropdown
          class="r-dropdown"
          size="mini"
          @command="startCalc"
        >
          <r-button
            type="primary"
            :disabled="
              scenarioStatus !== 'draft' ||
                !!activeEventId ||
                tooManyInProgressCalcs
            "
            :loading="calculating"
          >
            {{ getStarterTitle }}
          </r-button>
          <el-dropdown-menu
            slot="dropdown"
            class="r-dropdown-menu m-calculations-dropdown"
          >
            <el-dropdown-item command="due">
              <div class="r-dropdown-menu__item-wrapper">
                Расчет DUE
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="dnl">
              <div class="r-dropdown-menu__item-wrapper">
                Расчет DNL
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="m-editor-controls__button">
        <r-button
          :disabled="!scenarioName || !!activeEventId"
          :loading="saving"
          type="success"
          @click="saveChanges"
        >
          Сохранить изменения
        </r-button>
        <r-delete-button
          v-if="scenarioId"
          simple
          mini
          @delete="deleteScenario"
        />
      </div>
      <div
        v-if="isEdited"
        class="m-editor-controls__button"
      >
        <r-button
          simple
          :disabled="!!activeEventId"
          @click="clearChanges"
        >
          Отменить изменения
        </r-button>
      </div>
    </div>
    <div class="m-editor-controls__attributes">
      <div class="m-editor-controls__attribute">
        <r-text
          type="caption"
          style="margin-bottom: 8px"
        >
          Название сценария
        </r-text>
        <el-input
          v-model="scenarioName"
          class="r-input"
          :disabled="!!activeEventId"
        />
      </div>
      <div
        v-if="scenarioStatus"
        class="m-editor-controls__attribute"
      >
        <r-text
          type="caption"
          style="margin-bottom: 8px"
        >
          Статус расчета
        </r-text>
        <div class="m-editor-controls__attribute-value">
          <r-icon
            v-if="statusInfo"
            :name="statusInfo.icon"
            :size="14"
          />
          <r-text>
            {{ statusInfo.label }}
          </r-text>
        </div>
      </div>
    </div>
    <div class="m-editor-controls__events">
      <div class="m-editor-controls__modes">
        <r-text
          type="caption"
          style="margin-bottom: 8px"
        >
          Управление событиями
        </r-text>
        <r-radio-group
          :button-list="modes"
          :active="mode"
          :disabled="!!activeEventId"
          @change="changeMode"
        />
      </div>
      <event-list ref="eventList" />
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import EventList from './event-list'

const modes = [
  { id: 'create', label: 'Создание' },
  { id: 'edit', label: 'Редактирование' }
]

export default {
  components: {
    EventList
  },
  data() {
    return {
      draw: this.$cControllers.editor.parent.draw,
      scenarioId: null,
      scenarioStatus: 'draft',
      scenarioName: '',
      saving: false,
      deleting: false,
      calcParams: {
        start: 0,
        end: 86400,
        step_dnl: 300,
        step_due: 300,
        step_od: 300
      },
      model: this.$store.state.modelling.model,
      matrices: [],
      modes
    }
  },
  computed: {
    isEdited() {
      return this.scenarioName && this.$store.state.modelling.isEdited
    },
    getStarterTitle() {
      if (this.calculating) return 'Подготовка данных...'

      return 'Запустить расчет'
    },
    calculating: {
      get() {
        return this.$store.state.modelling.calculating
      },
      set(value) {
        this.$store.commit('SET_CALC_FIELD', {
          field: 'calculating',
          value
        })
      }
    },
    mode() {
      return this.$store.state.modelling.editorState.mode
    },
    activeEventId() {
      return this.$store.state.modelling.activeEventId
    },
    statusInfo() {
      switch (this.scenarioStatus) {
        case 'draft':
          return { label: 'Черновик', icon: 'edit-zone' }
        case 'in_progress':
          return { label: 'В процессе', icon: 'in-process' }
        case 'done':
          return { label: 'Завершен', icon: 'success' }
        default:
          return null
      }
    },
    tooManyInProgressCalcs() {
      const { scenarios } = this.$store.state.modelling

      return (
        scenarios.filter(({ status }) => status === 'in_progress').length > 2
      )
    }
  },
  created() {
    const { id } = this.$route.query

    if (id) {
      this.getCurrentBranch(id)
    }

    if (id) {
      this.getMatrices()
    }
  },
  methods: {
    changeMode(mode) {
      this.$cControllers.editor.toggleEditorMode(mode)
    },
    async getMatrices() {
      try {
        const { ids } = this.model
        const id = ids.matrices
        const url = `objectInfo/${id}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.matrices = Object.values(data)
      } catch (error) {
        console.warn(error)
      }
    },
    getCalculationInfo(type) {
      switch (type) {
        case 'due':
          return {
            url: 'DueDnl/DueProcess',
            name: 'DUE'
          }
        case 'dnl':
          return {
            url: 'DueDnl/DnlProcess',
            name: 'DNL'
          }
        default:
          return null
      }
    },
    async startCalc(type) {
      try {
        this.calculating = true

        const data = new FormData()
        const source_id = this.model.id
        const calcInfo = this.getCalculationInfo(type)

        if (!calcInfo) return

        const { url, name } = calcInfo

        data.append('source_id', source_id)
        data.append('branch_id', this.scenarioId)
        data.append('name', this.scenarioName)

        await this.$store.dispatch('SEND_FORM_DATA', {
          url: `${url}?branch_id=${this.scenarioId}`,
          data
        })

        const title = `Расчет ${name}`
        const message = 'Расчет успешно запущен'
        this.$notify(notifyFactory('success', title, message))

        this.calculating = false
        this.$router.push('/app/modelling/main/list')
      } catch (error) {
        console.warn(error)
        this.calculating = false
      }
    },
    async getCurrentBranch(branch_id) {
      const modelId = this.$store.state.modelling.model.id
      const url = `branch/${modelId}?branch_id=${branch_id}`

      const { data } = await this.$store.dispatch('GET_REQUEST', { url })

      if (data) {
        const { id, name, status } = data

        this.scenarioId = id
        this.scenarioName = name
        this.scenarioStatus = status
      }
    },
    async createBranch() {
      const { id } = this.$store.state.modelling.model
      const url = `branch/${id}`
      const data = {
        name: this.scenarioName
      }

      const branchResponse = await this.$store.dispatch('POST_REQUEST', {
        url,
        data
      })

      return branchResponse.data.id
    },
    async saveEvents() {
      const { ids } = this.$store.state.modelling.model
      const id = ids.events
      const { features } = this.draw.getAll()

      const url = `objectInfo/${id}?branch_id=${this.scenarioId}`
      const data = features
        .filter(f => f.geometry.coordinates.length)
        .map(f => ({ ...f.properties, geom: f.geometry }))

      await this.$store.dispatch('POST_REQUEST', { url, data })
      this.$cControllers.editor.clearEditingState()
      this.$refs.eventList.updateEvents()
    },
    async saveChanges() {
      try {
        this.saving = true

        if (!this.scenarioId) {
          const branchId = await this.createBranch()
          await this.getCurrentBranch(branchId)
          await this.$router.push({ query: { id: this.scenarioId } })
        }

        await this.saveEvents()
        await this.getMatrices()

        this.$store.commit('SET_CALC_FIELD', {
          field: 'isEdited',
          value: false
        })

        this.saving = false
      } catch (error) {
        console.warn(error)
        this.saving = false
      }
    },
    clearChanges() {
      this.$cControllers.editor.clearEditingState()
      this.$cControllers.editor.draw.deleteAll()
      this.$cControllers.editor.loadLayerData()
      this.$refs.eventList.updateEvents()

      this.$store.commit('SET_CALC_FIELD', {
        field: 'isEdited',
        value: false
      })
    },
    async deleteScenario() {
      try {
        this.deleting = true

        const url = `branch?id=${this.scenarioId}`
        await this.$store.dispatch('DELETE_REQUEST', { url })

        this.deleting = false
        this.$router.push('/app/modelling/main/list')
      } catch (error) {
        console.warn(error)
        this.deleting = false
      }
    }
  }
}
</script>

<style lang="scss">
.m-editor-controls {
  &__header {
    padding: 8px;
    border-bottom: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    display: grid;
    grid-gap: 0.5rem;
  }

  &__button {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;

    .r-button,
    .r-dropdown {
      width: 100%;
    }
  }

  &__attributes {
    padding: 8px;
    border-bottom: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
  }

  &__attribute {
    &-value {
      display: flex;
      align-items: center;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__modes {
    margin-bottom: 16px;
  }

  &__events {
    padding: 8px;
  }
}

.m-calculations-dropdown {
  width: 244px;
}
</style>
