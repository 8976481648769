<template>
  <div class="m-attributes">
    <el-select
      v-model="config[field]"
      class="r-select dark"
      size="mini"
      :loading="loading"
      placeholder="Выберите атрибут"
      clearable
      @change="updateData"
    >
      <el-option
        v-for="item in fields"
        :key="item.title"
        :value="item.title"
        :label="`${item.alias || item.title}`"
      >
        <div style="display: flex; align-items: center">
          <div :class="`m-attributes__icon ${item.type}`" />
          <r-text style="margin-left: 4px">
            {{ item.alias || item.title }}
          </r-text>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { calcFields } from '../../map/config'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    datatype: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    fields() {
      const initialFields =
        this.$store.state.modelling.objectFields[this.id] || []

      if (this.datatype === 'links') {
        return initialFields.filter(
          ({ title, type }) => calcFields.includes(title) && type === 'float'
        )
      }

      return initialFields
    }
  },
  methods: {
    async updateData(value) {
      if (!value) {
        this.$emit('update', value)

        return
      }

      try {
        this.loading = true

        await this.$cControllers.layers.updateFieldData(this.id, value)

        this.$emit('update', value)

        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.m-attributes {
  &__icon {
    height: 16px;
    margin-right: 10px;
    width: 16px;
    background-size: 75%;
    background-position: 50%;
    opacity: 0.8;
    background-repeat: no-repeat;
    border-radius: var(--border-radius);

    &.string {
      background-image: url('../../../../assets/images/attributes/types/string.svg');
      background-color: var(--string);
    }
    &.number {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--number);
    }
    &.numeric {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--numeric);
    }
    &.integer {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--numeric);
    }
    &.decimal {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--numeric);
    }
    &.float {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--numeric);
    }
    &.boolean {
      background-image: url('../../../../assets/images/attributes/types/boolean.svg');
      background-color: var(--boolean);
    }
    &.date {
      background-image: url('../../../../assets/images/attributes/types/datetime.svg');
      background-color: var(--date);
    }
    &.time {
      background-image: url('../../../../assets/images/attributes/types/interval.svg');
      background-color: var(--time);
    }
  }
}
</style>
