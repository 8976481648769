<template>
  <div
    v-loading="isLoading || !vehicleData"
    class="pot-1-print-sheet"
  >
    <pot-1-print-sheet-header />
    <div class="pot-1-print-sheet-map">
      <pot-1-map
        v-if="isReady"
        :data="vehicleData"
      />
    </div>
    <pot-1-print-sheet-info
      v-if="isReady"
      :data="vehicleData"
    />
  </div>
</template>

<script>
import pot1PrintSheetHeader from './components/header/pot-1-print-sheet-header.vue'
import pot1Map from './components/map/map'
import pot1PrintSheetInfo from './components/info/pot-1-print-sheet-info.vue'
import { getRequestConfig } from './configs'
import { prepareVehicleData } from './helpers'

export default {
  components: {
    pot1PrintSheetHeader,
    pot1Map,
    pot1PrintSheetInfo
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isReady: false,
      vehicleData: null
    }
  },
  computed: {
    vehicleListId() {
      return this.$store.state.pot1.vehicleListId || null
    },
    checkpointId() {
      return this.$store.state.pot1.checkpointId || null
    },
    warehouseId() {
      return this.$store.state.pot1.warehouseId || null
    },
    mainModelId() {
      return this.$store.state.pot1.mainModelId || null
    }
  },
  created() {
    this.initPrintSheet()
  },
  methods: {
    async initPrintSheet() {
      try {
        await this.loadMainModel()
        await this.loadVehicleList()
      } catch (e) {
        console.warn(e)
      }
    },
    async loadMainModel() {
      try {
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url: 'user/datasources'
        })
        const mainModel = data.filter(e => e.is_main_model)

        this.$store.commit('SET_POT_FIELD', {
          field: 'mainModelId',
          value: mainModel[0]?.id
        })
      } catch (e) {
        console.warn(e)
      }
    },
    async loadVehicleList() {
      this.isLoading = true

      try {
        const config = getRequestConfig(this.id)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.vehicleListId}?config=${JSON.stringify(
            config
          )}`
        })
        const checkpoints = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.checkpointId}`
        })
        const warehouses = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.warehouseId}`
        })
        const preparedVehicleData = prepareVehicleData(data, checkpoints.data, warehouses.data)

        if (preparedVehicleData?.coordinates?.length > 1 && this.mainModelId) {
          const route = await this.$store.dispatch('GET_REQUEST', {
            url: `route/${this.mainModelId}?coordinates=${JSON.stringify(preparedVehicleData.coordinates)}`
          })
          preparedVehicleData.route = route.data
          preparedVehicleData.geom = route.data?.routes[0]?.geometry
        } else {
          preparedVehicleData.route = null
          preparedVehicleData.geom = null
        }

        this.vehicleData = preparedVehicleData
        this.isReady = true
        this.isLoading = false
      } catch (e) {
        console.warn(e)
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.pot-1-print-sheet {
  position: relative;
  width: 200mm;
  padding: 12px;

  &-map {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
  }

  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
