import mapboxgl from 'mapbox-gl'
import { reportOptions } from '../configs'
import { jsonToGeojson } from '@/utils'
import { layersConfig } from '../components/an-map/configs'

const EXCLUDED = ['тип не указан', 'баржа', 'баржи']
const EXCLUDED_CHART = ['тип не указан', 'баржа', 'баржи', '%']

export class PassingReportForPeriodByType {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl

    this.options = reportOptions.passing_report_for_period_by_type
  }

  getTableData(data) {
    return data?.map((e, i) => {
      const item = {
        id: e.name
      }

      item['КПП'] = e.name

      if (e?.result) {
        e.result.forEach(s => {
          const exluded = !!EXCLUDED.find(ex => s.name?.trim()?.toLowerCase().includes(ex))

          if (!exluded) {
            item[s.name] = s.value || 0
          }
        })
      }

      return item
    })
  }

  getChartData(data) {
    const chartData = {
      data: [],
      fields: {
        data: [],
        title: 'Тип'
      }
    }

    chartData.fields.data = data.filter(
      e => e.name !== 'Всего'
    ).map(e => e.name)

    data.forEach(d => {
      d.result.filter(
        e => {
          const exluded = !!EXCLUDED_CHART.find(ex => e.name?.trim()?.toLowerCase().includes(ex))
          return !exluded
        }
      ).forEach(f => {
        const index = chartData?.data?.findIndex(d => d.title === f.name)

        if (index === -1) {
          chartData.data.push({
            title: f.name,
            data: [f.value]
          })
        } else {
          chartData.data[index].data.push(f.value)
        }
      })
    })

    return chartData
  }

  async getMapData() {
    try {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: 'objectInfo/15064f3c-2218-4276-94ce-c0b775895387'
      })
      const filteredData = Object.values(data)
        .filter(e => e.cam_type === 'kpp_cam' || e.cam_type === 'astra')
        .map(e => ({ ...e, id: e.name }))
      const geojson = jsonToGeojson(filteredData)

      return geojson
    } catch (e) {
      console.warn(e)
      return null
    }
  }

  async addMapData(data) {
    this.mapgl.addSource('passing-statistic', {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 36,
      data
    })

    // add clusters
    this.mapgl.addLayer({
      id: 'passing-statistic-clusters',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters
    })
    this.mapgl.addLayer({
      id: 'passing-statistic-cluster-count',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters_count
    })

    this.mapgl.addLayer({
      id: 'passing-statistic',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic
    })

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15,
      anchor: 'left',
      className: 'info-layer__custom-popup'
    })

    this.mapgl.on('mouseenter', 'passing-statistic', (e) => {
      this.mapgl.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const name = e.features[0].properties?.name

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      if (name) {
        popup
          .setLngLat(e.lngLat)
          .setHTML(
          `<dl>
              <dd>${name}</dd>
          </dl>`
          )
          .addTo(this.mapgl)
      }
    })

    this.mapgl.on('mouseleave', 'passing-statistic', () => {
      this.mapgl.getCanvas().style.cursor = ''
      popup.remove()
    })
  }
}
