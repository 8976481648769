import { ritmDate } from '@/utils'

export const filtersEncoder = filters => {
  const config = {
    where: [],
    include: { employee: {}, vehicle: {} },
    type: 'AND'
  }

  filters
    .filter(f => f.active)
    .forEach(f => {
      switch (f.id) {
        case 'type_id':
        case 'contractor_id':
        case 'subcontractor_id':
        case 'subproject_id':
        case 'detection_employee_id':
          config.where.push(simpleCheckbox(f))
          break
        case 'datetime':
        case 'elimination_date':
        case 'created_at':
          config.where.push(customInterval(f, f.id))
          break
        case 'violation_act_no':
          config.where.push(simpleRadioString(f))
          break
        case 'eliminated':
          config.where.push(simpleRadio(f))
          break
        case 'guilty_name':
          config.where.push(stringFilter(f))
          break
        default:
          break
      }
    })
  return config
}

const stringFilter = f => {
  return {
    field: f.id,
    value: f.prop,
    op: 'like'
  }
}

const simpleCheckbox = f => {
  return {
    field: f.id,
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const simpleRadio = f => {
  const value = f.prop.find(p => p.value).id
  return value !== 'all'
    ? {
      field: f.id,
      value: value === 'yes' ? [true] : [null, false],
      op: 'in'
    }
    : {}
}

const simpleRadioString = f => {
  const value = f.prop.find(p => p.value).id
  return value !== 'all'
    ? {
      field: f.id,
      value: null,
      op: value === 'yes' ? '!=' : '='
    }
    : {}
}

const customInterval = (f, field) => {
  let { from, to } = f.prop.interval

  if (!from || !to) {
    from = from || this.$ritmDate.date(0)
    to = to || this.$ritmDate.date()
  }

  from = ritmDate.toIso(from)
  to = ritmDate.toIso(to)

  return {
    field,
    op: 'between',
    value: `${from}/${to}`
  }
}
