<template>
  <popover-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="speed-intense"
    title="Транспортная ситуация"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  >
    <div
      v-loading="loading"
      :class="`ts-settings ${isLayerActive ? 'ts-settings--active' : ''}`"
    >
      <div class="ts-settings__block">
        <div class="ts-settings__header">
          <r-icon
            name="speed-intense"
            :size="20"
          />
          <r-text type="caption">
            Состояние транспортного потока
          </r-text>
        </div>
        <types-list
          v-if="isLayerActive"
          :value="value"
          @change="changeType"
        />
      </div>
      <div
        v-if="isLayerActive"
        class="ts-settings__block"
      >
        <div class="ts-settings__header">
          <r-text type="caption">
            Легенда
          </r-text>
        </div>
        <ts-legend />
      </div>
    </div>
  </popover-button>
</template>

<script>
import PopoverButton from '../buttons/popover-button'
import TypesList from './transport-situation/types'
import TsLegend from './transport-situation/legend'
import {
  getDatasourcesByDatatype,
  getModelChildrenIds,
  jsonToGeojson,
  getFirstSymbolId,
  modifyLinksGeometry
} from '@/utils'

const getFieldNames = typeId => {
  let color
  let width

  switch (typeId) {
    case 0:
      color = 'cur_track_speed'
      width = 'vehonlink'
      break
    case 15:
      color = 'cur_track_speed_15'
      width = 'vehonlink_15'
      break
    case 30:
      color = 'cur_track_speed_30'
      width = 'vehonlink_30'
      break
    case 45:
      color = 'cur_track_speed_45'
      width = 'vehonlink_45'
      break
    default:
  }

  return {
    color,
    width
  }
}
const getConfig = typeId => {
  const fields = getFieldNames(typeId)

  return {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': [
        'step',
        ['get', fields.width],
        'rgba(153,213,148, 1)',
        5,
        'rgba(254,224,139, 1)',
        15,
        'rgba(252,141,89, 1)',
        30,
        'rgba(213,62,79, 1)'
      ],
      'line-width': 1,
      'line-opacity': 0.9
    }
  }
}

export default {
  components: {
    PopoverButton,
    TypesList,
    TsLegend
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'ev6y-hty7-3gn5-hy76-v413y',
      data: null,
      handlers: {},
      layers: ['links'],
      layersIds: [],
      modelIds: null,
      value: 0,
      loading: false
    }
  },
  computed: {
    activeLayers() {
      const { mapConfigs } = this.$store.state
      const active = mapConfigs?.activeLayers?.[this.module] || []

      return Array.from(new Set(active))
    },
    isLayerActive() {
      if (!this.activeLayers) return false

      return this.activeLayers.includes(this.id)
    }
  },
  methods: {
    async addHandler() {
      const models = await getDatasourcesByDatatype(this, 'model')
      const model = models.find(m => !!m.is_main_model) || models[0]
      const { children } = model

      this.modelIds = getModelChildrenIds(children)

      this.layersIds = []

      const layerId = `${this.modelIds.links}_ts`

      await this.addLinksLayers(layerId)
      this.layersIds.push(layerId)
    },
    async addLinksLayers(id) {
      try {
        this.loading = true

        // get data & build geojson
        const config = {
          only: [
            'id',
            'geom',
            'vehonlink',
            'vehonlink_15',
            'vehonlink_30',
            'vehonlink_45',
            'cur_track_speed',
            'cur_track_speed_15',
            'cur_track_speed_30',
            'cur_track_speed_45'
          ],
          where: [
            {
              field: 'geom',
              op: '!null',
              type: 'AND',
              value: ''
            }
          ]
        }
        const url = `objectInfo/${this.modelIds.links}?config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        const features = modifyLinksGeometry(Object.values(data))
        const geojson = jsonToGeojson(features)
        // Setup layer & source
        const options = {
          id,
          source: id,
          ...getConfig(this.value)
        }
        const source = this.mapgl.getSource(id)
        const layer = this.mapgl.getLayer(id)

        if (source) {
          source.setData(geojson)
        } else {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: geojson
          })
        }
        if (!layer) this.mapgl.addLayer(options, getFirstSymbolId(this.mapgl))

        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    },
    changeType(id) {
      this.value = id

      const layerId = `${this.modelIds.links}_ts`
      const config = getConfig(id)

      if (this.mapgl.getSource(layerId)) {
        this.mapgl.setPaintProperty(
          layerId,
          'line-color',
          config.paint['line-color']
        )
        this.mapgl.setPaintProperty(
          layerId,
          'line-width',
          config.paint['line-width']
        )
      }
    }
  }
}
</script>

<style lang="scss">
.ts-settings {
  display: flex;

  &--active {
    .ts-settings__header {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }

  &__block {
    flex-shrink: 0;
    width: 240px;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    height: 28px;

    .r-icon {
      margin-right: 4px;
    }
  }

  &__nodata {
    margin-top: 8px;
    text-align: center;
  }
}
</style>
