import mapboxgl from 'mapbox-gl'
import { jsonToGeojson } from '@/utils'
import { passingTypes } from '../configs'

export class PassingStatistic {
  constructor(map) {
    this.map = map
    this.$store = map.$store
    this.mapgl = map.mapgl
    this.carMovingIcon = `<svg class="icon-car-moving" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 5.5C0.585786 5.5 0.25 5.83579 0.25 6.25C0.25 6.66421 0.585786 7 1 7H2.75V10.5H1C0.585786 10.5 0.25 10.8358 0.25 11.25C0.25 11.6642 0.585786 12 1 12H3.5H8.5H9.5C9.83152 12 10.1495 12.1317 10.3839 12.3661C10.6183 12.6005 10.75 12.9185 10.75 13.25V16.5H10.1465C9.82002 15.3457 8.75878 14.5 7.5 14.5C6.24122 14.5 5.17998 15.3457 4.85352 16.5H1C0.585786 16.5 0.25 16.8358 0.25 17.25C0.25 17.6642 0.585786 18 1 18H4.85352C5.17998 19.1543 6.24122 20 7.5 20C8.75878 20 9.82002 19.1543 10.1465 18H11.5C11.9142 18 12.25 17.6642 12.25 17.25V13.25C12.25 12.5207 11.9603 11.8212 11.4445 11.3055C10.9288 10.7897 10.2293 10.5 9.5 10.5H8.86047L5.08565 5.78148C4.94332 5.60357 4.72784 5.5 4.5 5.5H3.5H1ZM4.25 10.5V7.13809L6.93953 10.5H4.25ZM6.25 17.25C6.25 16.5596 6.80964 16 7.5 16C8.19036 16 8.75 16.5596 8.75 17.25C8.75 17.9404 8.19036 18.5 7.5 18.5C6.80964 18.5 6.25 17.9404 6.25 17.25ZM20.2197 13.5303C19.9268 13.2374 19.9268 12.7626 20.2197 12.4697C20.5126 12.1768 20.9874 12.1768 21.2803 12.4697L23.5303 14.7197C23.8232 15.0126 23.8232 15.4874 23.5303 15.7803L21.2803 18.0303C20.9874 18.3232 20.5126 18.3232 20.2197 18.0303C19.9268 17.7374 19.9268 17.2626 20.2197 16.9697L21.1893 16H17C16.5858 16 16.25 15.6642 16.25 15.25C16.25 14.8358 16.5858 14.5 17 14.5H21.1893L20.2197 13.5303ZM16.7803 7.03033C17.0732 6.73744 17.0732 6.26256 16.7803 5.96967C16.4874 5.67678 16.0126 5.67678 15.7197 5.96967L13.4697 8.21967C13.1768 8.51256 13.1768 8.98744 13.4697 9.28033L15.7197 11.5303C16.0126 11.8232 16.4874 11.8232 16.7803 11.5303C17.0732 11.2374 17.0732 10.7626 16.7803 10.4697L15.8107 9.5L20 9.5C20.4142 9.5 20.75 9.16421 20.75 8.75C20.75 8.33579 20.4142 8 20 8H15.8107L16.7803 7.03033Z" />
    </svg>`
    this.routeInIcon = `<svg class="icon-route-in" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8815 2.36572C12.4673 2.36572 12.1315 2.70151 12.1315 3.11572C12.1315 3.52994 12.4673 3.86572 12.8815 3.86572H17.6019V20.1343H12.8815C12.4673 20.1343 12.1315 20.4701 12.1315 20.8843C12.1315 21.2985 12.4673 21.6343 12.8815 21.6343H18.3519C18.7661 21.6343 19.1019 21.2985 19.1019 20.8843V3.11572C19.1019 2.70151 18.7661 2.36572 18.3519 2.36572H12.8815ZM9.9383 7.53077C10.2312 7.23787 10.7061 7.23787 10.999 7.53077L14.7135 11.2453L14.6236 11.3353L14.7135 11.2453C15.1303 11.6621 15.1303 12.3379 14.7135 12.7547L10.999 16.4693C10.7061 16.7622 10.2312 16.7622 9.9383 16.4693C9.6454 16.1764 9.6454 15.7015 9.9383 15.4086L12.5969 12.75L2.85315 12.75C2.43894 12.75 2.10315 12.4142 2.10315 12C2.10315 11.5858 2.43894 11.25 2.85315 11.25L12.5969 11.25L9.93831 8.59143C9.64541 8.29853 9.64541 7.82366 9.9383 7.53077Z" />
    </svg>`
    this.routeOutIcon = `<svg class="icon-route-out" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8815 2.36572C12.4673 2.36572 12.1315 2.70151 12.1315 3.11572C12.1315 3.52994 12.4673 3.86572 12.8815 3.86572H17.6018V20.1343H12.8815C12.4673 20.1343 12.1315 20.4701 12.1315 20.8843C12.1315 21.2985 12.4673 21.6343 12.8815 21.6343H18.3518C18.7661 21.6343 19.1018 21.2985 19.1018 20.8843V3.11572C19.1018 2.70151 18.7661 2.36572 18.3518 2.36572H12.8815ZM7.19105 7.53074C7.48394 7.82363 7.48394 8.2985 7.19105 8.59139L4.53239 11.25H14.2762C14.6904 11.25 15.0262 11.5858 15.0262 12C15.0262 12.4143 14.6904 12.75 14.2762 12.75H4.53248L7.19104 15.4086C7.48393 15.7015 7.48393 16.1764 7.19104 16.4693C6.89815 16.7622 6.42327 16.7622 6.13038 16.4693L2.4158 12.7547C1.999 12.3379 1.999 11.6621 2.4158 11.2453L6.13039 7.53073C6.42328 7.23784 6.89816 7.23784 7.19105 7.53074Z" />
    </svg>`
    this.markers = {}
    this.markersOnScreen = {}
    this.clusterMarkers = {}
    this.clusterMarkersOnScreen = {}
  }

  toggleLayer(isActive) {
    this.removeLayer()

    if (isActive) {
      this.addLayer()
    }
  }

  removeLayer() {
    const sourceId = 'passing-statistic'
    this.removeMarkers()

    if (this.mapgl.getLayer(sourceId)) {
      this.mapgl.removeLayer(sourceId)
    }
    if (this.mapgl.getSource(sourceId)) {
      this.mapgl.removeSource(sourceId)
    }
  }

  async removeMarkers() {
    try {
      const markerKeys = Object.keys(this.markers)

      await Promise.all(
        markerKeys.map(async e => {
          this.markers[e].remove()
        })
      )
      this.markers = {}
      const clusterMarkerKeys = Object.keys(this.clusterMarkers)

      await Promise.all(
        clusterMarkerKeys.map(async e => {
          this.clusterMarkers[e].remove()
        })
      )
      this.clusterMarkers = {}
    } catch (e) {
      console.warn(e)
    }
  }

  addLayer() {
    const sourceId = 'passing-statistic'
    const source = this.mapgl.getSource(sourceId)
    const data = this.map.data?.length ? jsonToGeojson(this.map.data) : []

    if (!source) {
      this.mapgl.addSource(sourceId, {
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 60,
        clusterProperties: {
          name: ['format', ['get', 'name']],
          entry: ['+', ['get', 'entry_count']],
          exit: ['+', ['get', 'exit_count']],
          total: ['+', ['get', 'total_count']]
        }
      })

      this.mapgl.addLayer({
        id: sourceId,
        type: 'circle',
        source: sourceId,
        filter: ['!=', 'cluster', true],
        paint: {
          'circle-color': '#fff',
          'circle-opacity': 0.0,
          'circle-radius': 1
        }
      })

      this.mapgl.on('render', () => {
        if (!this.mapgl.getLayer(sourceId)) return
        this.updateMarkers(sourceId)
        this.updateClusterMarkers(sourceId)
      })
    }
  }

  updateMarkers(sourceId) {
    const newMarkers = {}
    const features = this.mapgl.querySourceFeatures(sourceId)

    for (const feature of features) {
      const coords = feature.geometry.coordinates
      const props = feature.properties
      if (props.cluster) continue
      const id = props.id

      let marker = this.markers[id]
      if (!marker) {
        const el = this.createMarker(props)
        marker = this.markers[id] = new mapboxgl.Marker({
          element: el
        }).setLngLat(coords)
      }
      newMarkers[id] = marker

      if (!this.markersOnScreen[id]) marker.addTo(this.mapgl)
    }

    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) this.markersOnScreen[id].remove()
    }
    this.markersOnScreen = newMarkers
  }

  updateClusterMarkers(sourceId) {
    const newMarkers = {}
    const features = this.mapgl.querySourceFeatures(sourceId)

    for (const feature of features) {
      const coords = feature.geometry.coordinates
      const props = feature.properties
      if (!props.cluster) continue
      const id = props.cluster_id

      let marker = this.clusterMarkers[id]
      if (!marker) {
        const el = this.createClusterMarker(props)
        marker = this.clusterMarkers[id] = new mapboxgl.Marker({
          element: el
        }).setLngLat(coords)
      }
      newMarkers[id] = marker

      if (!this.clusterMarkersOnScreen[id]) marker.addTo(this.mapgl)
    }

    for (const id in this.clusterMarkersOnScreen) {
      if (!newMarkers[id]) this.clusterMarkersOnScreen[id].remove()
    }
    this.clusterMarkersOnScreen = newMarkers
  }

  createMarker(props) {
    const inColor = passingTypes.find(e => {
      return props?.entry_count > e.value || !e.value
    })?.colorType
    const outColor = passingTypes.find(e => {
      return props?.exit_count > e.value || !e.value
    })?.colorType
    const html = `<div class="passing-statistic-marker">
    ${props.name ? '<div class="passing-statistic-marker-header">' + props.name.replace('&', '') + '</div>' : ''}
    <div class="passing-statistic-marker-row passing-statistic-marker-car">${this.carMovingIcon}</div>
    <div class="passing-statistic-marker-row passing-statistic-marker-in ${inColor}">${this.routeInIcon}${props?.entry_count || 'н/д'}</div>
    <div class="passing-statistic-marker-row passing-statistic-marker-out ${outColor}">${this.routeOutIcon}${props?.exit_count || 'н/д'}</div>
    <div class="passing-statistic-marker-row passing-statistic-marker-total"><div>=</div><div>${props?.total_count || 'н/д'}</div></div>
    </div>`

    const el = document.createElement('div')
    el.innerHTML = html
    return el.firstChild
  }

  createClusterMarker(props) {
    const inColor = passingTypes.find(e => {
      return props.entry > e.value || !e.value
    })?.colorType
    const outColor = passingTypes.find(e => {
      return props.exit > e.value || !e.value
    })?.colorType

    const names = JSON.parse(props.name)?.sections?.map(e => e.text)?.join('&')
    const goodNames = names.split('&')?.filter(e => e).join(' / ')
    const html = `<div class="passing-statistic-marker">
    ${goodNames ? '<div class="passing-statistic-marker-header">' + `${goodNames}` + '</div>' : ''}
    <div class="passing-statistic-marker-row passing-statistic-marker-car-cluster"><span>${props.point_count}</span>${this.carMovingIcon}</div>
    <div class="passing-statistic-marker-row passing-statistic-marker-in ${inColor}">${this.routeInIcon}${props.entry || 'н/д'}</div>
    <div class="passing-statistic-marker-row passing-statistic-marker-out ${outColor}">${this.routeOutIcon}${props.exit || 'н/д'}</div>
    <div class="passing-statistic-marker-row passing-statistic-marker-total"><div>=</div><div>${props.total || 'н/д'}</div></div>
    </div>`

    const el = document.createElement('div')
    el.innerHTML = html
    return el.firstChild
  }
}
