export const generateUUID = () => {
  function s4() {
    const crypto = window.crypto || window.msCrypto
    var array = new Uint32Array(1)
    const rand = crypto.getRandomValues(array)

    return Math.floor((1 + rand) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}
