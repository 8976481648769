<template>
  <el-dialog
    title="Экспорт таблицы"
    :visible.sync="visible"
    width="350px"
    append-to-body
    :before-close="close"
    class="r-modal-window"
  >
    <div
      v-loading="loading"
      class="export-modal"
    >
      <div class="export-modal__header">
        <r-title type="title-2">
          {{ tableName }}
        </r-title>
      </div>

      <div
        v-if="!hardUri"
        class="export-modal__body"
      >
        <r-text color-type="secondary">
          Выберите тип файла
        </r-text>
        <el-select
          v-model="fileType"
          class="r-select"
          placeholder="Выбрать типы"
        >
          <el-option
            v-for="item in fileTypes"
            :key="item.id"
            :value="item.value"
            :label="`.${item.value}`"
          />
        </el-select>
      </div>
      <r-button
        type="primary"
        :disabled="!fileType || loading"
        @click="exportData"
      >
        Загрузить
      </r-button>
    </div>
  </el-dialog>
</template>

<script>
import { fileTypes } from './configs/'
import { prepareBookUri } from './helpers/'
import { translit } from '@/utils'
import { saveAs } from 'file-saver'

export default {
  props: {
    uri: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      default: () => ''
    },
    bookExport: {
      type: Boolean,
      default: () => false
    },
    hardUri: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
      fileType: 'xlsx',
      activeTypes: [],
      fileTypes,
      translit
    }
  },
  computed: {
    activeFields() {
      return this.$store.state.book.activeFields?.fields || null
    },
    tableName() {
      const { name } = this
      return name || 'Таблица'
    },
    currentFileName() {
      return `${translit(this.tableName)}_${this.$ritmDate.date()
        .split(' ')
        .join('')}.${this.fileType}`
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    prepareUri() {
      const isBook = this.bookExport
      const uri = this.uri.replace('objectInfo', 'objectInfoBodyConfig')
      const isObjectInfo = uri.includes('objectInfo')

      let resultUri = isBook ? prepareBookUri(uri, this.activeFields) : uri
      const parameters = { format: this.fileType }

      if (isObjectInfo) {
        const [url, params] = resultUri.split('?')

        params.split('&').forEach(p => {
          const [key] = p.split('=')
          const options = p.replace(`${key}=`, '')

          parameters[key] = JSON.parse(options)
        })

        return {
          url,
          data: parameters
        }
      } else {
        resultUri += `&format=${this.fileType}`

        return { url: resultUri }
      }
    },
    async exportData() {
      try {
        this.loading = true

        const params = this.prepareUri()
        const method = params.url.includes('objectInfo')
          ? 'POST_BLOB_REQUEST'
          : 'GET_BLOB_REQUEST'

        const { data } = await this.$store.dispatch(method, params)

        saveAs(data, this.currentFileName)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.export-modal {
  display: grid;
  grid-gap: 0.5rem;

  &__body {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
}
</style>
