<template>
  <div class="railway-control-content-delete-block">
    <div
      :class="
        [
          'railway-control-content-delete-block-wrapper',
          { 'mouseover': isOver }
        ]
      "
      @dragleave="onDragLeave()"
      @dragover="onDragOver($event)"
      @drop="onCarDrop($event)"
    >
      <r-icon
        name="trash"
        :color-type="isOver ? 'error' : ''"
        :size="48"
      />
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  data() {
    return {
      isOver: null
    }
  },
  methods: {
    onDragOver(e) {
      this.isOver = true
      e.preventDefault()
    },
    onCarDrop(e) {
      e.preventDefault()
      const id = e.dataTransfer.getData('text')
      if (!id) return

      this.beforeDelete(id)
      this.isOver = null
    },
    onDragLeave() {
      this.isOver = null
    },
    beforeDelete(id) {
      const message = 'Вагон будет удален, вы уверены?'
      const title = 'Удаление вагона'
      const confirm = 'Да'
      const cancel = 'Отмена'

      this.$confirm(message, title, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirm,
        cancel
      })
        .then(() => {
          this.deleteCar(id)
        })
        .catch(() => {})
    },
    async deleteCar(id) {
      try {
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `ZdCarriage?carriage_id=${id}`
        })

        const title = 'Удаление вагона'
        const message = 'Удаление вагона выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('RAILWAY_CONTROL_SET_FIELD', {
          field: 'update',
          value: true
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.railway-control-content-delete-block {
  position: absolute;
  border-radius: var(--border-radius);
  right: 60px;
  top: 80px;
  &-wrapper {
    border-radius: var(--border-radius);
    overflow: hidden;
    padding: 20px;
    transition: all 0.2s ease;
    &.mouseover {
      background-color: var(--accent_error_light);
    }
  }
}
</style>
