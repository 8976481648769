<template>
  <ul
    v-loading="disabled"
    class="account-menu__list"
  >
    <li
      v-for="item in list"
      :key="item.name"
      :class="['account-menu__item', { devided: item.devided }]"
      @click="actionClickHandler(item.name)"
    >
      <div class="account-menu__item-wrap">
        <r-text>{{ $t(`account:${item.value}`) }}</r-text>
        <el-switch
          v-if="item.name === 'theme'"
          v-model="themeSwitcher"
          class="r-switch"
          disabled
        />
      </div>
    </li>
  </ul>
</template>

<script>
import { toggleBodyTheme, getCSSCustomPropIndex } from '@/utils'
const supportLink = ''
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      currentProfileId: this.$store.state.profiles.currentProfile.id,
      disabled: false
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
    user() {
      return this.$store.state.auth.user
    },
    themeSwitcher() {
      return this.theme === 'dark-theme'
    }
  },
  methods: {
    actionClickHandler(item) {
      switch (item) {
        case 'about':
        case 'account':
        case 'settings-reset':
          this.openModalBox(item)
          this.$emit('hide-popover')
          break
        case 'theme':
          this.changeTheme()
          break
        default:
          break
      }
    },
    openModalBox(val) {
      this.$store.commit('OPEN_MODAL_WINDOW', val)
    },
    openSupportTab() {
      const userAttrs = ['email', 'last_name', 'first_name']

      const filledUserAttrs = userAttrs.reduce((a, c) => {
        const attr = this.$store.state.auth.user[c]
        if (attr) a.push(`${c}=${attr}`)
        return a
      }, [])

      window.open(`${supportLink}?${filledUserAttrs.join('&')}`)
    },
    async changeTheme() {
      const value = this.theme === 'dark-theme' ? 'light-theme' : 'dark-theme'
      this.disabled = true
      const options = {
        id: this.currentProfileId,
        theme: value
      }

      try {
        this.$store.commit('SET_THEME', value)
        toggleBodyTheme(value)
        this.$store.commit('SET_THEME_COLORS', getCSSCustomPropIndex())
        this.$store.commit('UPDATE_PROFILES_TREE', options)
        if (this.disabled) setTimeout(() => (this.disabled = false), 300)
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        console.warn(e)
        if (this.disabled) setTimeout(() => (this.disabled = false), 300)
      }
    }
  }
}
</script>

<style lang="scss">
.account-menu {
  &__list {
    padding-top: 0.25rem;
    display: grid;
    grid-gap: 0.33rem;
  }

  &__item {
    &-wrap {
      padding: 0.33rem;
      cursor: pointer;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: var(--border-radius);

      &:hover {
        background-color: var(--accent_hover);
      }
    }

    &.devided {
      padding-top: 0.33rem;
      border-top: 1px solid;
      border-color: var(--dividers_low_contrast) !important;
    }

    div.r-switch {
      opacity: 1 !important;

      .el-switch__core {
        cursor: pointer !important;
      }
    }
  }

  &__logout-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
}
</style>

<i18n>
{
  "ru": {
    "account:title" : "Аккаунт",
    "account:about" : "О системе",
    "account:theme" : "Темное оформление",
    "account:lang" : "English language",
    "account:settings-reset" : "Сброс настроек профиля",
    "account:exit" : "Выход"
  },
  "en": {
    "account:title" : "Account",
    "account:about" : "About",
    "account:theme" : "Dark theme",
    "account:lang" : "English language",
    "account:settings-reset" : "Reset profile config",
    "account:exit" : "Exit"
  }
}
</i18n>
