import crypto from 'crypto'
import authKeys from '@/constants/auth'
import { ritmDate } from '@/utils'

export const encrypt = text => {
  const iv = '0'.repeat(16)
  const cipher = crypto.createCipheriv('AES-256-GCM', authKeys.AUTH_KEY, iv)
  let crypted = cipher.update(text, 'utf-8', 'base64')
  crypted += cipher.final('base64')

  return crypted
}

export const dataURLtoBlob = dataurl => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mime })
}

export const downloadFile = file => {
  const link = document.createElement('a')
  link.setAttribute('download', file.name)
  link.setAttribute('target', '_blank')
  link.setAttribute('href', `${file.href}`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
const crypto1 = window.crypto || window.msCrypto
var array = new Uint32Array(1)

const getRandomValue = max => Math.floor(crypto1.getRandomValues(array) * max)

export const getRandomColor = alpha =>
  `rgba(${getRandomValue(255)},${getRandomValue(255)},${getRandomValue(
    255
  )},${alpha})`

export const isEmpty = value => {
  if (!value && value !== 0) return true
  return !JSON.stringify(value)?.trim()
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const arrayMoveByIndex = (arr, from, to) => {
  const elem = arr[from]
  arr.splice(from, 1)
  arr.splice(to, 0, elem)
}

export const sortDataByField = (data, field, type, reverse = false, locale) => {
  switch (type) {
    case 'date': {
      const clt = new Intl.Collator(locale || 'ru', {
        numeric: true
      })
      return data.sort((a, b) => {
        const dateA = dateParseForSort(a[field])
        const dateB = dateParseForSort(b[field])

        if (reverse) {
          return clt.compare(dateB, dateA)
        } else {
          return clt.compare(dateA, dateB)
        }
      }
      )
    }
    default: {
      const clt = new Intl.Collator(locale || 'ru', { sensetivity: 'base' })
      return data.sort((a, b) =>
        reverse
          ? clt.compare(b[field], a[field])
          : clt.compare(a[field], b[field])
      )
    }
  }
}

export const translit = (name) => {
  var converter = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'sch',
    ь: '',
    ы: 'y',
    ъ: '',
    э: 'e',
    ю: 'yu',
    я: 'ya'
  }

  name = name.toLowerCase()

  let answer = ''
  for (let i = 0; i < name.length; ++i) {
    if (converter[name[i]] === undefined) {
      answer += name[i]
    } else {
      answer += converter[name[i]]
    }
  }

  answer = answer.replace(/[^-0-9a-z]/g, '_')
  answer = answer.replace(/[-]+/g, '_')
  return answer
}

// HACK BECAUSE OF OLD RITM-DATE LIBARY
const dateParseForSort = (date) => {
  if (!date) return 0
  const parsed = date.split('•').map((e, i) => {
    if (i === 0) return e.trim().split('.').reverse().join('-')
    else return e.trim()
  }).join('T')

  return ritmDate.toUnix(parsed)
}
