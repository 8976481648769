<template>
  <div
    id="pot-1-map"
    class="pot-1-map"
  />
</template>

<script>
import extent from 'turf-extent'
import { initMap } from './core/'
import { jsonToGeojson } from '@/utils'
import { layersConfig } from './configs'

const mapLayers = [
  { id: 1, value: 'pot_info' },
  { id: 2, value: 'warehouse_info' },
  { id: 3, value: 'checkpoint_info' }
]

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapgl: null,
      mapLayers
    }
  },
  mounted() {
    initMap(this)
  },
  methods: {
    addMapLayers() {
      if (this.data?.geom?.coordinates?.length > 1) {
        this.mapLayers.forEach(e => this.addLayer(e.value))
        this.addRouteLayer()
      } else {
        this.$store.commit('PRINT_SET_FIELD', {
          field: 'isReady',
          value: true
        })
      }
    },
    addLayer(val) {
      if (!this.data) return

      const initial = this.data[val]
      const data = jsonToGeojson([initial])

      this.mapgl.addSource(val, {
        type: 'geojson',
        data
      })

      this.mapgl.addLayer({
        id: val,
        source: val,
        ...layersConfig[val]
      })
    },
    addRouteLayer() {
      if (!this.data) {
        this.$store.commit('PRINT_SET_FIELD', {
          field: 'isReady',
          value: true
        })
        return
      }

      const data = jsonToGeojson([{ geom: this.data.geom }])

      this.mapgl.addSource('route', {
        type: 'geojson',
        data
      })

      this.mapgl.addLayer({
        id: 'route',
        source: 'route',
        ...layersConfig.route
      })

      this.flyTo(data)
    },
    flyTo(geom) {
      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 40
      })
      this.$store.commit('PRINT_SET_FIELD', {
        field: 'isReady',
        value: true
      })
    }
  }
}
</script>

<style lang="scss">
.pot-1-map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
}
</style>
