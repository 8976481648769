<template>
  <div class="doc-item-img">
    <div
      v-if="file.id"
      class="file-item-attribute__file-item file-item"
      @click="openImg"
    >
      <img
        :src="imageSrc"
        class="file-item__preview"
        alt="document_preview"
      >
      <r-icon
        :size="24"
        name="image"
        class="file-item__icon"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="true"
      append-to-body
    >
      <img
        width="100%"
        :src="imageSrc"
        alt="photo4"
      >
    </el-dialog>

    <r-delete-button
      simple
      cross
      mini
      @delete="$emit('remove')"
    />
  </div>
</template>

<script>
import { getHeaders } from '@/utils'

export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      imageSrc: null
    }
  },
  computed: {
    imageUrl() {
      return `${this.url}/download_resource/${this.file.id}`
    }
  },
  async created() {
    if (!this.imageUrl) return

    const res = await fetch(this.imageUrl, { headers: getHeaders() })
    const blob = await res.blob()

    this.imageSrc = await URL.createObjectURL(blob)
  },
  methods: {
    openImg() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss">
.el-dialog__body {
  padding-top: 1.5rem !important;

  img {
    border-radius: var(--border-radius);
  }
}

.doc-item-img {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: start;

  .file-item {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    position: relative;
    cursor: pointer;

    &__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease;
      z-index: 2;
      opacity: 0;
    }

    &__preview {
      z-index: 1;
      object-fit: cover;
      width: 2.25rem;
      height: 2.25rem;
      border-radius: var(--border-radius);
      transition: all 0.5s ease;
    }

    &:hover {
      .file-item__icon {
        opacity: 1;

        .r-icon__svg {
          fill: var(--modal_bg);
        }
      }

      .file-item__preview {
        filter: grayscale(75%) brightness(50%);
      }
    }
  }
}
</style>
