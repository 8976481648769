export const popupConfigs = {
  warehouse: {
    layer: 'Склады',
    fields: [
      { name: 'Наименование', value: 'name' },
      { name: 'Телефон', value: 'phone_number' }
    ]
  },
  shahmatka: {
    layer: 'Шахматка',
    fields: [
      { name: 'Наименование', value: 'name' }
    ]
  },
  'overpasses-agpz': {
    layer: 'Эстакады АГПЗ',
    fields: [
      { name: 'Номер эстакады', value: 'name' }
    ]
  },
  'abk-agpz': {
    layer: 'АБК АГПЗ',
    fields: [
      { name: 'Наименование', value: 'name' }
    ]
  },
  'bfbc88aa-6085-4a9e-9be2-c288c579c494': {
    layer: 'Геозоны',
    fields: [
      { name: 'Наименование', value: 'name' },
      { name: 'АГХК', value: 'aghk' },
      { name: 'АГПЗ', value: 'agpz' }
    ]
  },
  kpp_aghk: {
    layer: 'КПП АГХК',
    fields: [
      { name: 'Наименование', value: 'name' },
      { name: 'Номер', value: 'no' }
    ]
  },
  'kpp-agpz': {
    layer: 'КПП АГПЗ',
    fields: [
      { name: 'Наименование', value: 'name' },
      { name: 'Номер', value: 'no' }
    ]
  },
  vehicles: {
    clusters: true,
    layer: 'ТС',
    fields: [
      { name: 'Гос. номер', value: 'reg_number' },
      { name: 'Подрядчик', value: 'contractor.name' },
      { name: 'Время GPS', value: 'gps_time', type: 'date', format: 'DD.MM.YYYY • HH:mm:ss' },
      { name: 'Скорость', value: 'speed', func(val) { return `${val || '–'} км/ч` } }
    ]
  }
}
