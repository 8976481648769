<template>
  <div class="wrapper">
    <login-loader v-if="loading" />
    <template v-else-if="ready">
      <navbar />
      <div class="app-middle">
        <router-view />
        <div
          v-if="journalPinned"
          class="app-right-block"
        >
          <JournalShort />
        </div>
      </div>
      <modal-window v-if="modal" />
    </template>
  </div>
</template>

<script>
import throttle from 'lodash.debounce'
import navbar from '@/components/navbar/navbar'
import modalWindow from '@/components/modal-window/modal-window'
import LoginLoader from '@/components/login/login-loader'
import JournalShort from '@/components/journal-short/journal-short'
import { notifyFactory, toggleBodyTheme } from '@/utils'
import { getEventsData } from '@/components/journal-short/helpers'
import {
  getEventType,
  getEventColor
} from '@/components/journal/helpers/data-formatting'
import { moduleLinks } from '@/components/navbar/config'

export default {
  components: {
    navbar,
    modalWindow,
    LoginLoader,
    JournalShort
  },
  data() {
    return {
      loading: false,
      ready: false,
      refreshToken: null,
      ticker: 0
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
    isAuthorised() {
      return !!this.$store.state.auth.token
    },
    modal() {
      const { name, isShow } = this.$store.state.modalWindow
      return name && isShow
    },
    journalPinned() {
      return this.$store.state.journal.pinned
    }
  },
  watch: {
    '$store.state.profiles.profileUpdated': {
      handler: function(val) {
        this.loading = val
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.closeHandler)
    this.$root.$i18n.locale = 'ru'
    await this.loadToken(true)
    this.startSessionWithToken() // TODO: socket connection move ot monitoring

    this.refreshToken = setInterval(this.loadToken, 300000)
  },
  beforeDestroy() {
    clearInterval(this.refreshToken)
  },
  methods: {
    goToDefaultPath() {
      const { path } = this.$router.currentRoute
      const hasModules = !!this.$store.state.auth.modules?.length
      if (!hasModules) {
        if (path === '/access-denied') return
        this.$router.push('/access-denied')
        return
      }
      const { defaultPath } = this.$store.state
      const allRoutes = []
      moduleLinks.default.forEach(e => {
        allRoutes.push(...e.routesLinks)
      })
      const defaultModuleName = allRoutes.find(e => e.to === defaultPath)?.name
      const hasDefaultPath = this.$store.getters.hasModule(
        defaultModuleName
      )
      if (!hasDefaultPath) {
        const allModules = this.$store.state.auth.modules.map(e => e.name)
        const routePath = allRoutes.find(e => allModules.includes(e.name))?.to
        if (routePath) {
          if (path === routePath) return
          this.$router.push(routePath)
        } else {
          if (path === '/access-denied') return
          this.$router.push('/access-denied')
        }
      } else {
        if (path === `${defaultPath}/view` || defaultPath) return
        this.$router.push(defaultPath)
      }
    },
    closeHandler() {
      this.$store.dispatch('SAVE_MAIN_USER_CONFIG', 'unload')
    },
    startSessionWithToken() {
      this.$socket.open()

      // add listener for journal (car and system events)
      this.$socket.on('events', data => {
        if (document.visibilityState !== 'visible') return

        console.log('car events chanell --- ', data)
        const { lists } = this.$store.state.journal
        const journalList = lists.car_event
        const eventsData = getEventsData(data.splice(0, 5), 'car_event')
        const newJournal = [...eventsData, ...journalList]

        const parsed = eventsData.map(({ title, desc, description }) => {
          let message = description

          if (desc) {
            message += `<p>${desc}</p>`
          }
          return {
            title,
            message
          }
        })
        this.sendSocketNotify(parsed)

        this.$store.commit('SET_JOURNAL_LIST', [
          'car_event',
          newJournal.slice(0, 50)
        ])
      })

      this.$socket.on('cameras', data => {
        if (document.visibilityState !== 'visible') return

        console.log('cameras chanell --- ', data)

        const { lists } = this.$store.state.journal
        const journalList = lists.cameras
        const eventsData = getEventsData(data.splice(0, 5), 'cameras')
        const newJournal = [...eventsData, ...journalList]

        const parsed = eventsData.map(({ title, description }) => {
          return {
            title,
            message: description
          }
        })
        this.sendSocketNotify(parsed)

        this.$store.commit('SET_JOURNAL_LIST', [
          'cameras',
          newJournal.slice(0, 50)
        ])
      })

      this.$socket.on('worker', data => {
        if (document.visibilityState !== 'visible') return

        console.log('worker chanell --- ', data)

        const { lists } = this.$store.state.journal
        const journalList = lists.system_event
        const newObject = {
          title: getEventType(data.type),
          datetime: this.$ritmDate.toFormat(data.end_time, 'DD.MM.YYYY • HH:mm'),
          description:
            data.status === 'OK' ? 'Выполнено успешно' : 'Завершено с ошибкой',
          color: getEventColor(data.type)
        }
        const newJournal = [newObject, ...journalList]

        const { title, description } = newObject

        this.$notify(notifyFactory('info', title, description))

        this.$store.commit('SET_JOURNAL_LIST', [
          'system_event',
          newJournal.slice(0, 50)
        ])
      })
    },
    sendSocketNotify: throttle(function(data) {
      for (const event of data) {
        const { title, message } = event
        setTimeout(() => this.$notify(notifyFactory('info', title, message)), 8)
      }
    }, 8),
    async changeProfile(id, userId) {
      const dataUserObj = {
        id: userId,
        current_profile_id: id
      }
      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'user',
          data: dataUserObj
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadToken(init) {
      try {
        if (this.ticker > 3) {
          this.$router.push('/access-denied')
        }

        this.ticker += 1

        const { data, status } = await this.$store.dispatch('LOGIN', {
          url: 'login'
        })

        if (status === 411) {
          await this.loadToken()
        } else {
          localStorage.setItem('group_id', JSON.stringify(data.group_id))
          localStorage.setItem('username', JSON.stringify(data.username))
        }

        if (init) {
          this.loadConfig()
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.ticker = 0
      }
    },
    async loadConfig() {
      try {
        this.loading = true

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'user'
        })

        this.$store.commit('SET_JOURNAL_FIELD', [
          'userNoticesTypes',
          data.user_notices_types.filter(item => !!item.notice_type_id)
        ])
        this.$store.commit('SET_JOURNAL_FIELD', [
          'userNoticesCars',
          data.user_notices_cars
        ])
        const profilesTree = data.user_profiles
        let currentProfile = data.current_profile
        if (!currentProfile) {
          await this.changeProfile(profilesTree[0].id, data.id)
          const response = await this.$store.dispatch('GET_REQUEST', {
            url: 'user'
          })
          currentProfile = response.data.current_profile
        }
        currentProfile.instance = data.instance

        const modules = await this.$store.dispatch('POST_REQUEST', {
          url: 'user/modules'
        })
        if (data.unfilled_fields) {
          const findIndex = data.unfilled_fields.indexOf('middle_name')
          if (findIndex > -1) data.unfilled_fields.splice(findIndex, 1)
        }

        if (data.unfilled_fields.length) this.isInfoNotFilled = true
        this.$store.commit('SET_USER_DATA_BY_TOKEN', {
          user: {
            first_name: data.first_name,
            last_name: data.last_name,
            middle_name: data.middle_name,
            name: data.name,
            role_name: data.role_name,
            user_id: data.id,
            id: data.id,
            position: data.position ? data.position : null,
            email: data.email,
            avatar: data.avatar,
            company: data.company,
            valid_until: data.valid_until
          }
        })

        toggleBodyTheme(currentProfile.theme)

        this.$store.commit('SET_USER_MODULES', modules.data)
        const datasources = await this.$store.dispatch('POST_REQUEST', {
          url: 'user/datasources'
        })

        this.$store.commit('SET_USER_DATASOURCES', datasources.data)
        this.$store.commit('SET_USER_CONFIG', {
          user_config: currentProfile,
          user: data
        })
        this.$store.commit('SET_CURRENT_PROFILE', currentProfile)
        this.$store.commit('SET_PROFILES_TREE', profilesTree)
        this.$store.commit('SET_USER_TASKS_LIST', data.tasks)
        this.loading = false
      } catch (error) {
        throw new Error(error)
      } finally {
        this.goToDefaultPath()
        this.loading = false
        this.ready = true
      }
    },
    showInfoWarning() {
      const title = 'Информация о пользователе не заполнена'
      const message =
        'Заполните, пожалуйста, все поля в Личном кабинете (ФИО, эл. почта, компания)'
      this.$notify(notifyFactory('warning', title, message))
      this.$store.commit('OPEN_MODAL_WINDOW', 'account')
      this.isInfoNotFilled = false
    }
  }
}
</script>

<style lang="scss">
.app-right-block {
  display: grid;
  padding: 0.5rem;
  border-left: 1px solid var(--dividers_low_contrast);
  height: 100%;
  width: 200px;
  min-width: 320px;
  overflow: auto;
}
</style>
