import { ritmDate, getDatasourcesByDatatype, getModelChildrenIds, flyToOptions } from '@/utils'

const initialLayersDatatypes = ['links']

export class ModelController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
  }

  async loadModel() {
    try {
      const models = await getDatasourcesByDatatype(this, 'model')
      const { id, children, name } = models.find(m => !!m.is_main_model)

      const ids = getModelChildrenIds(children)
      const value = {
        id,
        ids,
        name,
        children
      }

      this.$store.commit('SET_CALC_FIELD', { field: 'model', value })
    } catch (error) {
      console.warn(error)
    }
  }

  loadScenarios(timeoutValue) {
    try {
      this.$store.commit('SET_CALC_FIELD', {
        field: 'scenariosLoading',
        value: true
      })

      setTimeout(async() => {
        const { id } = this.$store.state.modelling.model
        const url = `branch/${id}?format=list`

        await this.$store.dispatch('POST_REQUEST', {
          url: `branch/${id}`,
          data: { branch_id: null }
        })

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        const scenarios = Object.values(data).map(s => ({
          ...s,
          title: s.name,
          subtitle: this.getScenarioSubtitle(s),
          icon: this.getScenarioIcon(s.status)
        }))

        this.$store.commit('SET_CALC_FIELD', {
          field: 'scenarios',
          value: scenarios
        })
        this.$store.commit('SET_CALC_FIELD', {
          field: 'scenariosLoading',
          value: false
        })
      }, timeoutValue)
    } catch (error) {
      console.warn(error)

      this.$store.commit('SET_CALC_FIELD', {
        field: 'scenariosLoading',
        value: false
      })
    }
  }

  toggleModelOn() {
    const { id } = this.$store.state.modelling.model

    if (!id) return

    this.flyToModel(id)
    this.toggleOnInitialLayers()
  }

  toggleModelOff() {
    const { activeLayers } = this.$store.state.modelling;

    [...activeLayers].forEach(id =>
      this.parent.controllers.layers.toggleLayerOff(id)
    )
  }

  // helpers
  toggleOnInitialLayers() {
    const { model } = this.$store.state.modelling
    const { children } = model

    children
      .filter(l => initialLayersDatatypes.includes(l.datatype))
      .forEach(({ id }) =>
        this.parent.controllers.layers.toggleLayerOn(id, true)
      )
  }

  async flyToModel(id) {
    if (!this.$store.state.map.extent[id]) {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: `geom_extent/${id}?`
      })
      const extent = data

      this.$store.commit('SET_LAYER_EXTENT', { id, extent })
    }

    const extent = this.$store.state.map.extent[id]
    const { center, zoom } = this.mapgl.cameraForBounds(
      [extent.slice(0, 2), extent.slice(2)],
      { padding: { top: 10, bottom: 10, left: 10, right: 10 } }
    )
    this.mapgl.flyTo({
      center,
      zoom,
      ...flyToOptions
    })
  }

  getScenarioSubtitle(scenario) {
    const {
      status,
      branch_timestamp,
      calculation_start_time,
      calculation_end_time,
      calculation_type
    } = scenario

    switch (status) {
      case 'draft':
        return `Создан ${this.parseTime(branch_timestamp)}`
      case 'in_progress':
        return `${calculation_type} - ${this.parseTime(calculation_start_time)}`
      default:
        return `${calculation_type} - ${this.parseTime(calculation_end_time)}`
    }
  }

  getScenarioIcon(status) {
    switch (status) {
      case 'draft':
        return 'edit-zone'
      case 'in_progress':
        return 'in-process'
      default:
        return 'success'
    }
  }

  parseTime(time) {
    return ritmDate.toFormat(time, 'YYYY.MM.DD HH:mm:ss')
  }
}
