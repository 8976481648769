<template>
  <div class="pot-arrivals-list-item">
    <div class="pot-arrivals-list-item__info">
      <r-title type="title-1">
        {{ item.reg_number || item.carriage_no }}
      </r-title>
      <r-text type="caption">
        {{ targetStatusName }}
      </r-text>

      <r-text
        v-if="item.driver_full_name"
        type="caption"
      >
        {{ item.driver_full_name }}
      </r-text>
      <r-text
        v-if="item.driver_phone"
        type="caption"
      >
        {{ item.driver_phone }}
      </r-text>
      <r-text
        v-if="item.current_station"
        type="caption"
      >
        {{ item.current_station }}
      </r-text>
    </div>
    <div class="pot-arrivals-list-item__actions">
      <r-button
        v-if="additionalAction"
        mini
        type="primary"
        :disabled="isNotifyDisabled"
        @click="notify"
      >
        {{ isAdmin ? 'Уведомить складскую логистику' : 'Разрешить въезд' }}
      </r-button>
      <r-button
        mini
        @click="openCard"
      >
        Открыть карточку ТС
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    targetStatusName: {
      type: String,
      required: true
    },
    additionalAction: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.pot1.isAdmin
    },
    vehicleListId() {
      const { urlName } = this.$store.state.pot1.related.vehicles
      return this.$store.state.services[urlName]
    },
    newStatusId() {
      const statusList = this.$store.state.pot1.statusList
      const newStatusId = statusList.find(
        this.isAdmin
          ? e => e.name === 'Ожидает разрешение'
          : e => e.name === 'Разрешён въезд'
      )?.id
      return newStatusId
    },
    isNotifyDisabled() {
      return !this.item.warehouse_id || !this.item.checkpoint_id
    }
  },
  methods: {
    async notify() {
      try {
        const data = {
          id: this.item.id,
          pot_status_id: this.newStatusId
        }
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.vehicleListId}`,
          data
        })
        const title = this.$t('edit-notify:title')
        const message = this.$t('edit-notify:text')

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: true
        })
      } catch (e) {
        console.warn(e)
      }
    },
    openCard() {
      this.$store.commit('SET_POT_FIELD', {
        field: 'modalName',
        value: null
      })
      this.$store.commit('SET_POT_FIELD', {
        field: 'cardId',
        value: this.item.id
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'pot-modal-card')
    }
  }
}
</script>

<style lang="scss">
.pot-arrivals-list-item {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0.25rem 1rem;
  // justify-content: auto;
  grid-gap: 1rem;
  width: 100%;
  background-color: var(--bg_containers);
  border-radius: var(--border-radius);
  grid-template-columns: auto 1fr;
  // grid-template-columns: repeat(4, minmax(100px, max-content)) auto;

  &__info {
    display: grid;
    // grid-gap: 0.5rem;
    grid-auto-flow: column;

    div {
      border-color: var(--dividers_low_contrast);
      border-width: 0 1px 0 0;
      border-style: solid;
      padding: 0 1rem;
    }
  }

  &__actions {
    display: grid;
    grid-gap: 0.5rem;
    justify-self: end;
    grid-auto-flow: column;
  }
}
</style>

<i18n>
{
  "ru": {
    "edit-notify:title": "Редактирование",
    "edit-notify:text": "Статус успешно изменен"
  },
  "en": {
    "edit-notify:title": "Editing",
    "edit-notify:text": "Editing completed successfully"
  }
}
</i18n>
