<template>
  <div :class="[`si-center__datetime ${$mq}`]">
    <div class="si-center__date">
      <r-text>{{ date }}</r-text>
    </div>
    <div class="si-center__time">
      <r-text :size="24">
        {{ time }}
      </r-text>
    </div>
  </div>
</template>

<script>
import { ritmDate } from '@/utils/'

export default {
  props: {
    timeZone: {
      type: String,
      default: '+9'
    }
  },
  data() {
    return {
      refreshData: null,
      date: '',
      time: ''
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    }
  },
  created() {
    this.setDatetime()

    this.refreshData = setInterval(this.setDatetime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.refreshData)
  },
  methods: {
    setDatetime() {
      this.date = ritmDate.toFormat(null, 'DD.MM.YYYY')
      this.time = ritmDate.toFormat(null, 'HH:mm:ss')
    }
  }
}
</script>

<style lang="scss">
.si-center__datetime {
  padding: 8px 0;
}
</style>
