<template>
  <div class="railway-control__content-header__calendar-item">
    <r-text v-if="isActive">
      {{ getActiveDate }}
    </r-text>
    <r-text
      v-if="!isActive"
      type="caption"
    >
      {{ getDay }}
    </r-text>
    <r-text v-if="!isActive">
      {{ getDate }}
    </r-text>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getDay() {
      return this.$ritmDate.toFormat(this.date, 'dd')
    },
    getDate() {
      return this.$ritmDate.toFormat(this.date, 'DD')
    },
    getActiveDate() {
      const today = this.$ritmDate.toFormat(null, 'dd • DD MMM YYYY')
      const incoming = this.$ritmDate.toFormat(this.date, 'dd • DD MMM YYYY')
      return today === incoming ? `Сегодня ${today}` : incoming
    }
  }
}
</script>
