<template>
  <div
    v-loading="loading"
    class="r-modal-card bdd-modal application-create-vehicle"
  >
    <r-title color-type="subhead">
      Создать новое транспортное средство
    </r-title>
    <div class="r-modal-card__controls">
      <r-button
        type="primary"
        :disabled="saveDisabled"
        @click="create"
      >
        Сохранить ТС и создать заявку
      </r-button>
      <r-button
        simple
        @click="close"
      >
        Выйти без сохранения
      </r-button>
    </div>
    <attributes-list
      :model="model"
      :related="related"
      :source="source"
      is-editing
      @hasChanges="hasChanges"
    />
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import { applicationModel } from '../configs'
import { notifyFactory } from '@/utils'

export default {
  components: { attributesList },
  data() {
    return {
      loading: false,
      vehiclesUrl: this.$store.state.services.vehicles,
      info: null,
      source: {}
    }
  },
  computed: {
    newStatus() {
      return this.related.applications_status_list.data?.find(e =>
        e.name.includes('Новая')
      )?.id
    },
    activeApplication() {
      return this.$store.state.bdd.activeApplication
    },
    state() {
      return this.$store.state.bdd
    },
    related() {
      return this.$store.state.bdd.applicationsRelated
    },
    applicationsSourceId() {
      return this.$store.state.bdd.applicationsSourceId
    },
    saveDisabled() {
      return !this.source?.vehicle?.reg_number
    },
    model() {
      return applicationModel.filter(f => f.parent === 'vehicle')
    }
  },
  created() {
    const vehicle = {}
    this.model.forEach(m => {
      vehicle[m.model] = null
    })
    this.source = { vehicle }
  },
  mounted() {
    this.setWidth(560)
  },
  beforeDestroy() {
    this.setWidth(640)
  },
  methods: {
    hasChanges() {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
    },
    setWidth(width) {
      const dialog = document.querySelector('[aria-label="dialog"]')
      if (dialog) {
        dialog.style.width = `${width}px`
      }
    },
    async create() {
      this.loading = true

      try {
        const vehicleData = this.source.vehicle
        const { data } = await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.vehiclesUrl}`,
          data: vehicleData
        })
        const vehicle_id = data[0]?.id
        if (!vehicle_id) return

        const appResonse = await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${this.applicationsSourceId}`,
          data: { vehicle_id, status_id: this.newStatus }
        })
        const applicationId = appResonse.data[0]?.id

        this.$notify(
          notifyFactory(
            'success',
            'Уведомление',
            'Назначена дата и время осмотра'
          )
        )

        this.$store.commit('BDD_SET_FIELD', {
          field: 'activeApplication',
          value: appResonse.data[0]
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'openAfterUpdate',
          value: applicationId
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateApplications',
          value: true
        })
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    }
  }
}
</script>
