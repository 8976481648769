<template>
  <div
    v-loading="loading"
    class="pass-register"
  >
    <passes-header
      :title="$t('pass-register:title')"
      :button-text="$t('pass-register:add-button')"
      :uri="uri"
      @add="openModal"
    />
    <div class="pass-register__filters">
      <r-search-input
        placeholder="Поиск по ГРЗ или № пропуска"
        :filter-text="filterText"
        @change="searchChange"
      />
      <r-filter-set
        :filters="filters"
        @reset-all="resetFilters"
        @load="updateFilters"
      />
    </div>

    <div
      v-loading="loading"
      class="pass-register__content"
    >
      <r-table-list
        v-if="passes.length || !loading"
        ref="table"
        :include-columns="columns"
        :fields-names="fields"
        bordered
        num-col
        :data="tableData"
        :paginator="passes.length > 10"
        @click-handler="openModal"
      />
    </div>
  </div>
</template>

<script>
import passesHeader from './components/header/header'
import { related, filters, fields, columns } from './configs'
import { buildUrl, buildExportUri, filtersEncoder } from './helpers'

export default {
  components: { passesHeader },
  data() {
    return {
      loading: false,
      filters: [],
      filterText: '',
      uri: `objectInfo/${this.source_id}`,
      source_id: '1db01462-94d5-4e6a-a35f-de424745945f',
      passes: [],
      fields,
      columns,
      related
    }
  },
  computed: {
    tableData() {
      const searchFields = ['reg_number', 'number']
      const query = this.filterText?.trim()?.toLowerCase()
      if (!query) return this.formatted || []

      return this.formatted.filter(e => {
        const filtered = searchFields
          .map(f => e[f])
          .join('')
          ?.trim()
          .toLowerCase()
        return filtered.includes(query)
      })
    },
    formatted() {
      return this.passes.map((pass, i) => {
        return {
          ...pass,
          no: i + 1,
          created_at: this.$ritmDate.toFormat(pass.created_at, 'DD.MM.YYYY • HH:mm'),
          date_from: this.$ritmDate.toFormat(pass.date_from, 'DD.MM.YYYY • HH:mm'),
          valid: pass.is_valid ? 'Да' : 'Нет',
          date_to: this.$ritmDate.toFormat(pass.date_to, 'DD.MM.YYYY • HH:mm'),
          permit_type: pass.permit_type?.name,
          subproject: this.getValueByField('subproject_id', pass, 'subproject'),
          ...this.getVehicleFields(pass)
        }
      })
    },
    updatePass() {
      return this.$store.state.bdd.updatePass
    },
    passBasedOnAppId() {
      return this.$store.state.bdd.passBasedOnAppId
    }
  },
  watch: {
    updatePass() {
      this.loadPasses()
    }
  },
  async mounted() {
    this.setFilters()
    if (this.passBasedOnAppId) this.openModal()

    await this.loadRelated()
    await this.loadPasses()
  },
  methods: {
    setFilters() {
      const userFilters = this.$store.state.bdd.filters?.pass
      const initFilters = filters()

      this.filters = initFilters?.map(f => {
        if (!userFilters?.length) return f

        const userItem = userFilters?.find(uf => uf.id === f.id)

        return userItem || f
      })
    },
    getValueByField(field, source, related) {
      const value = this.related?.[related]?.data?.[source?.[field]]

      return value?.name || '—'
    },
    getVehicleFields(pass) {
      const vehicleFields = {
        contractor: '',
        reg_number: '',
        vehicle_type: '',
        model: ''
      }
      for (const key in vehicleFields) {
        vehicleFields[key] = pass.application?.vehicle
          ? typeof pass.application?.vehicle[key] === 'string'
            ? pass.application?.vehicle[key]
            : pass.application?.vehicle[key]?.name || '—'
          : '—'
      }
      return vehicleFields
    },
    searchChange(e) {
      this.filterText = e
    },
    async resetFilters() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.pass',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadPasses()
    },
    async updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.pass',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadPasses()
    },
    async loadPasses() {
      this.loading = true
      try {
        const filters = filtersEncoder(this.filters)

        const url = buildUrl(this.source_id, filters)
        this.uri = buildExportUri(this.source_id, filters)

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.passes = Object.values(data)
      } catch (e) {
        console.warn(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updatePass',
          value: false
        })
        this.loading = false
      }
    },
    async loadRelated() {
      this.loading = true
      for (const item in this.related) {
        try {
          const url = `objectInfo/${
            this.related[item].id
          }?config=${JSON.stringify(this.related[item].config)}`

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url
          })

          this.related[item].data = data
          this.filters = this.filters.map(filter => {
            return filter.id === `${item}_id`
              ? {
                ...filter,
                prop: Object.values(data).map(d => {
                  const fValue = !!filter?.prop?.find(fi => fi.id === d.id)?.value

                  return {
                    ...d,
                    value: fValue
                  }
                })
              }
              : filter
          })
        } catch (e) {
          console.warn(e)
        }
      }
      this.loading = false
    },
    openModal(row) {
      const { source_id, passes, related } = this
      const newPass = {
        number: null,
        date_from: null,
        date_to: null,
        permit_type_id: null,
        status: null,
        valid: null
      }

      const current = row ? passes.find(e => e.id === row.id) : newPass

      const value = {
        ...current,
        source_id,
        related,
        new: !row
      }

      this.$store.commit('BDD_SET_FIELD', {
        field: 'pass',
        value
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'bdd-pass-register-modal')
    }
  }
}
</script>

<style lang="scss">
.pass-register {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  align-content: start;
  height: 100%;

  &__filters {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: 16rem auto;
    padding: 0 1rem;
    background-color: var(--bg_panel_primary);
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &-table {
    &__action {
      &-cell {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
        justify-content: start;
      }

      &-button {
        border-radius: var(--border-radius);
        border: none;
        background-color: transparent;
        display: grid;
        grid-gap: 0.25rem;
        grid-auto-flow: column;
        cursor: pointer;

        &:hover {
          background-color: var(--accent_hover);
        }
      }
    }
  }

  &__content {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 100%;
    align-items: center;
    justify-content: stretch;

    .r-title {
      justify-content: center;
      width: 100%;
      padding: 1rem;
      text-align: center;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "pass-register:title": "Реестр пропусков",
    "pass-register:add-button": "Добавить пропуск"
  },
  "en": {
    "pass-register:title": "Pass register",
    "pass-register:add-button": "Add pass"
  }
}
</i18n>
