<template>
  <div
    v-if="checklistData"
    v-loading="isLoading"
    class="checklist"
  >
    <r-title
      :size="24"
      color-type="subhead"
    >
      {{ `Лист контрольного осмотра транспортного средства ${reg_number}` }}
    </r-title>
    <checklist-table
      v-if="checklistData && checklistData.main"
      :data="checklistData.main"
    />
    <checklist-table
      v-if="checklistData && checklistData.general"
      :data="checklistData.general"
    />
    <checklist-album />
    <checklist-docs
      v-if="application"
      :source_id="source_ids.applications"
      :source="application"
      :docs="documentsModel"
    />
    <component
      :is="`checklist-${block.type}`"
      v-for="block in checklistConfigs"
      :key="block.id"
      :app_source_id="source_ids.applications"
      :app_source="application"
      :app_docs="documentsModel"
      :data="block"
    />
    <checklist-confirm
      :app_docs="documentsModel"
      :app_source="application"
      :data="checklistConfigs || []"
      :checklist-data="checklistData"
    />
    <checklist-files-select-modal />
  </div>
</template>

<script>
import checklistDocs from './components/checklist-docs'
import checklistTable from './components/checklist-table/checklist-table'
import checklistAlbum from './components/checklist-album/checklist-album'
import checklistInspection from './components/checklist-inspection/checklist-inspection'
import checklistConfirm from './components/checklist-confirm/checklist-confirm'
import checklistFilesSelectModal from './components/checklist-files/checklist-files-select-modal'

import { getChecklistRequestConfig } from '../../configs'
import {
  configTransform,
  getMainData,
  getGeneralData,
  documentsModel
} from '../../helpers'

export default {
  components: {
    checklistDocs,
    checklistTable,
    checklistAlbum,
    checklistInspection,
    checklistConfirm,
    checklistFilesSelectModal
  },
  data() {
    return {
      isLoading: false,
      checklistAttrsGroup: null,
      checklistConfigs: null,
      checklistData: null,
      checklistId: this.$route.query.id,
      documentsModel
    }
  },
  computed: {
    reg_number() {
      return this.checklistData?.application?.vehicle?.reg_number || ''
    },
    application() {
      return this.checklistData?.application
    },
    source_ids() {
      const { bdd } = this.$store.state
      return {
        attributes: bdd.checklistAttrsUrl,
        applications: bdd.applicationsSourceId,
        groups: bdd.checklistAttrsGroupUrl,
        values: bdd.checklistAttrsValuesUrl,
        statusList: bdd.applicationsStatusListSourceId,
        checklist: bdd.checklistsUrl
      }
    }
  },
  created() {
    this.initChecklist()
  },
  methods: {
    async initChecklist() {
      try {
        this.isLoading = true
        await this.getGroups()
        await this.getData()
        await this.getAttrs()
        await this.getStatuses()
      } catch (e) {
        console.warn(e)
      } finally {
        this.isLoading = false
      }
    },
    async getGroups() {
      if (!this.source_ids.groups) return

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.source_ids.groups}`
        })
        this.checklistAttrsGroup = data
      } catch (e) {
        console.warn(e)
      }
    },
    async getData() {
      if (!this.checklistId) return

      try {
        const config = getChecklistRequestConfig(this.checklistId)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `inspection_checklist/${
            this.source_ids.checklist
          }?config=${JSON.stringify(config)}`
        })

        this.checklistData = data
        this.checklistData.main = getMainData(data)
        this.checklistData.general = getGeneralData(data.application?.vehicle)
        this.checklistData.group =
          data.attrs[''] && data.attrs[''][''] ? data.attrs[''][''] : null
      } catch (e) {
        console.warn(e)
      }
    },
    async getAttrs() {
      if (!this.source_ids.attributes) return

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.source_ids.attributes}`
        })
        const checklistValues = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.source_ids.values}?config=${JSON.stringify({
            where: [{ field: 'checklist_id', op: '=', value: this.checklistId }]
          })}`
        })

        this.checklistConfigs = configTransform(
          data,
          this.checklistAttrsGroup,
          checklistValues.data
        )
      } catch (e) {
        console.warn(e)
      }
    },
    async getStatuses() {
      if (!this.source_ids.statusList) return

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.source_ids.statusList}`
        })

        this.$store.commit('BDD_SET_FIELD', {
          field: 'statuses',
          value: Object.values(data)
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist {
  max-width: 800px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 1.5rem;
  align-items: center;
  align-content: start;
  padding: 1rem 0;
}
</style>
