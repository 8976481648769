import { render, staticRenderFns } from "./doc-item-img.vue?vue&type=template&id=23d69214"
import script from "./doc-item-img.vue?vue&type=script&lang=js"
export * from "./doc-item-img.vue?vue&type=script&lang=js"
import style0 from "./doc-item-img.vue?vue&type=style&index=0&id=23d69214&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports