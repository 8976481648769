<template>
  <div
    v-loading="loading"
    class="pt-sp-forecast"
  >
    <r-table-list
      v-if="data.length"
      :data="data"
      :fields-names="fieldsExplanation"
    />
    <div
      v-else
      class="pt-sp-schedule__nodata"
    >
      <r-text type="caption">
        Маршруты, проходящие через данную остановку, отсутствуют
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      data: [],
      fieldsExplanation: {
        name: 'Маршрут',
        pt_arrive_time: 'Прогноз прибытия'
      }
    }
  },
  computed: {
    cardId() {
      return this.$store.state.publicTransport.cardId
    }
  },
  watch: {
    cardId() {
      this.getSchedule()
    }
  },
  created() {
    this.getSchedule()
  },
  methods: {
    rowClickHandler({ id }) {
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardType',
        value: 'lines'
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardId',
        value: id
      })
    },
    async getSchedule() {
      try {
        this.loading = true

        const url = `stopPoint/${this.cardId}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        const info = Object.values(data)[0]
        const { veh_journeys_times, pt_arrive_times } = info

        this.data = Object.entries(veh_journeys_times).map(([id, item]) => {
          const ptArriveInfo = pt_arrive_times[id]
          const pt_arrive_time = ptArriveInfo
            ? ptArriveInfo.arrivals.join(', ').substr(0, 5)
            : item.arrivals
              .slice(0, 2)
              .map(time => time.substr(0, 5))
              .join(', ')

          return {
            name: item.name,
            pt_arrive_time,
            id
          }
        })

        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    },
    getArriveTimes(vehTimes, id) {
      return vehTimes
        .filter(({ line_route_id }) => line_route_id === id)
        .map(r => r.arr.slice(0, 5))
        .slice(0, 2)
        .join(', ')
    }
  }
}
</script>

<style lang="scss">
.pt-sp-forecast {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
