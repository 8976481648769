import { ritmDate } from '@/utils'

export const filtersEncoder = filters => {
  const where = []
  if (!filters) return where

  const activeFilters = filters.filter(f => f.active)

  activeFilters.forEach(f => {
    switch (f.id) {
      case 'cargoes.sender_id':
      case 'checkpoint_id':
      case 'warehouse_id':
      case 'pot_status_id':
      case 'status_pre_id':
      case 'cargoes.sender':
      case 'cargoes.subproject_id':
        where.push(simpleCheckbox(f))
        break
      case 'cargoes.forecast_delivery':
      case 'arrival_forecast':
        where.push(customInterval(f))
        break
      default:
        where.push(simpleString(f))
        break
    }
  })
  return where
}

const simpleString = f => {
  return {
    field: f.id,
    value: f.prop || '',
    op: 'like'
  }
}

const simpleCheckbox = f => {
  return {
    field: f.id,
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const customInterval = (f) => {
  let { from, to } = f.prop.interval

  if (!from || !to) {
    from = from || this.$ritmDate.date(0)
    to = to || this.$ritmDate.date()
  }

  from = ritmDate.toIso(from)
  to = ritmDate.toIso(to)

  return {
    field: f.id,
    op: 'between',
    value: `${from}/${to}`
  }
}
