<template>
  <div class="mt-cargo">
    <div class="mt-cargo__controls">
      <r-tabs v-model="currentTab">
        <r-tab
          v-for="tab in tabs"
          :id="tab.id"
          :key="tab.id"
          :name="tab.name"
        />
      </r-tabs>
      <el-select
        v-model="current"
        class="r-select"
        filterable
        placeholder="Выберите груз"
        :disabled="!hasCargo"
      >
        <el-option
          v-for="item in relevanceCargoList"
          :key="item.id"
          :label="`№ упак: ${item.packing_list_no || '—'} | ${item.name}`"
          :value="item.id"
        />
      </el-select>
    </div>
    <ul class="mt-cargo-attributes-list">
      <li v-if="!hasCargo">
        <r-text>
          Информация о грузе отсутствует
        </r-text>
      </li>
      <li
        v-for="({ name, value }, i) in currentCargo"
        :key="i"
        class="mt-cargo-attributes-list__item"
      >
        <r-title type="subtitle-2">
          {{ name }}
        </r-title>
        <r-text>{{ value }}</r-text>
      </li>
    </ul>
  </div>
</template>

<script>
import { hashToCleanArray } from '@/utils'
import { exceptAttrs, sortConfig } from './configs'

export default {
  props: {
    info: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    source_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tabs: [
        { id: 'actual', name: 'Актуальные' },
        { id: 'history', name: 'История' }
      ],
      currentTab: 'actual',
      current: null
    }
  },
  computed: {
    cargoesObjectFields() {
      return this.$store.state.monitoringTmc.cargoesObjectFields || null
    },
    hasCargo() {
      return this.relevanceCargoList?.length
        ? this.relevanceCargoList[0]?.id
        : null
    },
    currentCargo() {
      const cargo = this.relevanceCargoList?.find(
        ({ id }) => id === this.current
      )
      const except = exceptAttrs()
      const orders = sortConfig()

      return hashToCleanArray(cargo)
        ?.filter(a => !except?.includes(a.name))
        ?.sort((a, b) => {
          const orderA = orders[a.name]
          const orderB = orders[b.name]

          if (!orderA && !orderB) return 0
          if (orderA && !orderB) return -1
          if (orderA && orderB) {
            return orderA - orderB
          }
          return 0
        })?.map(c => {
          const field = this.cargoesObjectFields[c.name]
          const { type, alias, reflection } = field
          const name = reflection?.alias || alias || c.name

          switch (type) {
            case 'datetime':
            case 'date':
              return {
                name,
                value: c.value ? this.$ritmDate.toFormat(c.value, 'DD.MM.YYYY') : '—'
              }
            default:
              return {
                name,
                value: c.value || '—'
              }
          }
        })
    },
    relevanceCargoList() {
      return this.info?.cargoes?.filter(cargo => {
        const { receiving_date_fact } = cargo
        return this.currentTab === 'actual' ? !receiving_date_fact : !!receiving_date_fact
      })
    }
  },
  watch: {
    hasCargo(cargoId) {
      this.current = cargoId
    }
  }
}
</script>

<style lang="scss">
.mt-cargo {
  display: grid;
  grid-gap: 0.5rem;
  width: 100%;
  place-items: start;
  height: 100%;
  overflow: hidden;

  &__controls {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 250px minmax(auto, 500px);
    align-items: center;
  }

  &-attributes-list {
    height: 100%;
    width: 100%;
    overflow: auto;

    &__item {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 250px auto;
      border-bottom: 1px solid var(--dividers_low_contrast);

      &:first-child {
        border-top: 1px solid var(--dividers_low_contrast);
      }

      .r-title {
        padding: 0.33rem;
        background-color: var(--table_header_bg);
      }

      .r-text {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
