import mapboxgl from 'mapbox-gl'
import { mapToken } from '@/constants/tokens'
import { getFirstSymbolId, mapIcons } from '../configs/'

import { MapConfigEvents, getMapConfigs } from '@/utils'
import mapboxStyles from '@/constants/mapbox_styles'
import { loadIcons } from '@/components/monitoring/map/core'

export const initMap = component => {
  let { center, zoom } = getMapConfigs(component, 'situation_center_display')
  const theme = component.theme
  const style = component.isSatelliteActive
    ? mapboxStyles['satellite-streets']
    : theme === 'dark-theme'
      ? mapboxStyles.maptiler_dark
      : mapboxStyles.maptiler_basic

  mapboxgl.accessToken = mapToken

  center = center || component.mapConfig.center
  zoom = zoom || component.mapConfig.zoom
  const maxBounds = component.mapConfig.maxBounds

  component.mapgl = new mapboxgl.Map({
    container: 'situation-center-map',
    preserveDrawingBuffer: true,
    maxBounds,
    center,
    zoom,
    style
  })
  component.mapgl.dragRotate.disable()

  // controllers
  component.controllers.events = new MapConfigEvents(
    component,
    'situation_center_display'
  )

  component.mapgl.on('load', async() => {
    await loadIcons(component)
    Promise.all(
      mapIcons.map(
        ({ name, icon }) =>
          new Promise(resolve => {
            component.mapgl.loadImage(component[icon], function(_error, res) {
              component.mapgl.addImage(name, res)
              resolve()
            })
          })
      )
    ).then(() => {
      component.isReady = true
      component.updateActiveLayers()
      component.updateActiveTileLayers()
    })

    if (component.tileLayers && component.tileLayers.length) {
      component.tileLayers.forEach(e => {
        if (!component.mapgl.getLayer(e.id)) {
          component.mapgl.addLayer(e, getFirstSymbolId(component.mapgl) || '')
          component.mapgl.setLayoutProperty(e.id, 'visibility', 'none')
        }
      })
    }

    component.controllers.events.addEventHandler()
  })
}
