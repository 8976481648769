<template>
  <div
    v-loading="loading"
    class="pt-transport"
  >
    <r-table-list
      :data="data"
      :fields-names="fieldsExplanation"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      data: [],
      fieldsExplanation: {
        reg_number: 'Гос. номер',
        route: 'Маршрут',
        contragent: 'Подрядчик',
        model: 'Марка',
        online: 'Статус'
      }
    }
  },
  computed: {
    cardId() {
      return this.$store.state.publicTransport.cardId
    },
    cardType() {
      return this.$store.state.publicTransport.cardType
    },
    cardInfo() {
      return this.$store.state.publicTransport.cardInfo
    }
  },
  watch: {
    cardInfo: {
      handler: function() {
        this.getTransportList()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async getStopPointTransport() {
      const { id } = this.$store.state.publicTransport.model
      const url = `stop_point/${id}?format=stop_point_schedule&id=${this.cardId}`
      const { data } = await this.$store.dispatch('GET_REQUEST', { url })
      const vehicles = data.reduce((prev, el) => [...prev, ...el.vehicles], [])
      this.data = this.modifyVehicles(vehicles)
    },
    async getTransportList() {
      try {
        this.loading = true

        if (this.cardType === 'lines') {
          this.data = this.cardInfo?.vehicles || []
        } else {
          await this.getStopPointTransport()
        }

        this.$store.commit('SET_PT_FIELD', {
          field: 'filteredVehicles',
          value: this.data.map(v => v.reg_number)
        })
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },
    modifyVehicles(data) {
      return data?.map(t => ({
        reg_number: t.reg_number,
        online: t.online ? 'На связи' : 'Н/д',
        contragent: t.vrc_full_name,
        model: t.model ? t.model.name : 'Не указана'
      }))
    }
  }
}
</script>

<style lang="scss">
.pt-transport {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
