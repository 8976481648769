import $store from '@/store'

export const popupConfigs = {
  video_camera_kpp: {
    layer: 'Видеокамеры КПП',
    fields: [
      { name: 'Наименование', value: 'name' }
    ],
    clickHandler({ feature, id }) {
      $store.commit('SI_CENTER_TOGGLE_ITEM', ['objectCards', {
        data: feature,
        layer_id: 'video_camera_kpp',
        id: +id
      }])
    }
  },
  video_camera_road: {
    layer: 'Видеокамеры дорожные',
    fields: [
      { name: 'Наименование', value: 'name' }
    ],
    clickHandler({ feature, id }) {
      $store.commit('SI_CENTER_TOGGLE_ITEM', ['objectCards', {
        data: feature,
        layer_id: 'video_camera_road',
        id: +id
      }])
    }
  }
}
