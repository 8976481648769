import mapboxgl from 'mapbox-gl'
import { mapToken } from '@/constants/tokens'
import { VehicleController, TrackController, FcTrackController } from '../controllers'
import { loadIcons } from '../helpers'
import { MapConfigEvents, getMapConfigs } from '@/utils'

export const initMap = component => {
  // map creating
  const { center, zoom, baselayer } = getMapConfigs(component, 'monitoringTmc')

  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'mt-map',
    preserveDrawingBuffer: true,
    style: baselayer,
    center,
    zoom
  })

  // view handlers
  component.mapgl.on('pitch', e => {
    component.is3d = e.target.getPitch() !== 0
  })
  component.mapgl.on('rotate', e => {
    component.mapBearing = e.target.getBearing()
  })

  // controllers
  component.controllers.events = new MapConfigEvents(component, 'monitoringTmc')
  component.controllers.vehicle = new VehicleController(component)
  component.controllers.track = new TrackController(component)
  component.controllers.fcTrack = new FcTrackController(component)

  component.mapgl.on('load', async() => {
    try {
      // load icons to map
      await loadIcons(component)
      // load vehicle books
      await component.getVehicleCategory()

      component.controllers.events.addEventHandler()
      component.controllers.vehicle.addVehicles()

      component.timer = setInterval(() => {
        component.controllers.vehicle.updateVehicles()
      }, 30000)
    } catch (e) {
      console.warn(e)
    }
  })
}
